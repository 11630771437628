import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

class TweetJar extends Component {
  constructor(props) {
    super();
		this.state = {
      selectedOption: null
		};
  }

  render() {
    return (
      <div className="container-fluid page-background1">
        <Helmet>
          <meta name="description" content="Search tweets from your favorite sources on TweetJar."/>
          <title>TweetJar | Sandbox</title>
        </Helmet>
        <Header />
        <div className="container" style={{ marginBottom: '30rem' }}>
          <div className="row pt-3">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between">
                <h1
                  className="header-title-text mt-0"
                  onClick={() => this.gotToSandboxweb()}>
                  Sandbox
                </h1>
              </div>
            </div>
            <div className="col-sm-12">
              <Link to={'/tweetjar/googlesays'}><b>Google Says</b></Link>
            </div>
            <div className="col-sm-12">
              <Link to={'/tweetjar/googlesays'}>
                Search SEO tweets from Google team
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default TweetJar;
