import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import LOCALSTORAGE from '../../constants/LocalStorage';
import { GetAuthToken, GetAuthPlanId, GetAuthUserId } from '../../functions/AuthStatus';
import { withRouter } from 'react-router-dom';
import { RateLimitingApi } from '../../functions/RateLimitingApi';
// import { DisplayAccountGateCheck } from '../../functions/DisplayAccountGateCheck';
import { ParseMillisecondsToSeconds } from '../../functions/ParseMillisecondsToSeconds';
import SpeedTestMetricTabs from './SpeedTestMetricTabs';
import { DelayTimer } from '../../functions/DelayTimer';
import { SaveUserSettingApi } from '../../functions/UserSettingApi';
import SpeedTestRateLimitAlert from './SpeedTestRateLimitAlert';
import { updateSettingsDeviceSubject } from '../../observables/SettingsDeviceSubject';
import { MediaMatcher } from '../../functions/MediaMatcher';
import { GetUserSettingApi } from '../../functions/UserSettingApi';
import PRICING from '../../constants/Pricing';
import PLANS from '../../constants/Plans';
import { updateSignedInStatusSubject } from '../../observables/SignedInStatus';
import ReactGA from 'react-ga';
import { RemainingWstUsageStatus } from '../../functions/RemainingWstUsageStatus';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { AuthTokenStatus } from '../../functions/AuthTokenStatusApi';

class SpeedTestTool extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      url: '',
      displayedUrl: '',
      isDesktopToggled: false,
      sampleSizeArray: [3],
      selectedSampleSize: 3,
      numberOfProgressBarsBasedOnSampleSize: 3,
      showMobileProgressBars: false,
      showDesktopProgressBars: false,
      mobileLabMetrics: [],
      desktopLabMetrics: [],
      mobileLabMetricsAverage: {},
      desktopLabMetricsAverage: {},
      fieldMetrics: {},
      compleatedMobileSamples: 0,
      compleatedDesktopSamples: 0,
      isDisplayMetrics: false,
      isDesktopSamplesComplete: false,
      isMobileSamplesComplete: false,
      isInvalidUrl: false,
      isMobilePsiStarted: false,
      isDesktopPsiStarted: false,
      speedTestHasRun: false,
      desktopProgressBarsStatusObj: {},
      mobileProgressBarsStatusObj: {},
      isRemoveSpinner: false,
      maxSampleSize: 3,
      originFieldMetrics: {},
      originDesktopFieldMetrics: {},
      desktopFieldMetrics: {}
    };
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    if (this._isMounted) {
      this.setState({ url: value });
      this.setState({ isInvalidUrl: false });
    }
  }

  handleDeviceToggle = () => {
    this.setState({ isDesktopToggled: !this.state.isDesktopToggled }, () => {
      if (this.state.isDesktopToggled) {
        this.props.updateSelectedDevice('desktop');
        if (this._isMounted) {
          if (Object.keys(this.state.desktopProgressBarsStatusObj).length > 0) {
            this.setState({ showMobileProgressBars: false });
            this.setState({ showDesktopProgressBars: true });
          }
          if (this.state.isDesktopSamplesComplete) {
            this.setState({ isDisplayMetrics: true })
          } else {
            this.setState({ isDisplayMetrics: false });
            if (!this.state.isDesktopPsiStarted && this.state.speedTestHasRun) this.generateSampleSize();
          }
        }
      } else {
        this.props.updateSelectedDevice('mobile');
        if (this._isMounted) {
          if (Object.keys(this.state.mobileProgressBarsStatusObj).length> 0) {
            this.setState({ showDesktopProgressBars: false });
            this.setState({ showMobileProgressBars: true });
          }
          if (this.state.isMobileSamplesComplete) {
            this.setState({ isDisplayMetrics: true })
          } else {
            this.setState({ isDisplayMetrics: false });
            if (!this.state.isMobilePsiStarted && this.state.speedTestHasRun) this.generateSampleSize();
          }
        }
      }
      if (GetAuthToken()) this.saveSettingsApi();
    });
  }

  resetValues = () => {
    if (this._isMounted) {
      this.setState({ mobileLabMetrics: [] });
      this.setState({ desktopLabMetrics: [] });
      this.setState({ mobileLabMetricsAverage: {} });
      this.setState({ desktopLabMetricsAverage: {} });
      this.setState({ fieldMetrics: {} });
      this.setState({ compleatedMobileSamples: 0 });
      this.setState({ compleatedDesktopSamples: 0 });
      this.setState({ isDisplayMetrics: false });
      this.setState({ showMobileProgressBars: false });
      this.setState({ showDesktopProgressBars: false });
      this.setState({ displayedUrl: '' });
      this.setState({ isInvalidUrl: false });
      this.setState({ isMobileSamplesComplete: false });
      this.setState({ isDesktopSamplesComplete: false });
      this.setState({ isMobilePsiStarted: false });
      this.setState({ isDesktopPsiStarted: false });
      this.setState({ speedTestHasRun: false });
      this.setState({ desktopProgressBarsStatusObj: {} });
      this.setState({ mobileProgressBarsStatusObj: {} });
      this.setState({ isRemoveSpinner: false });
      this.setState({ originFieldMetrics: {} });
    }
  }

  handleSubmit = (event) => {
    if (event) event.preventDefault();
    if (this.state.url !== '') {
      this.resetValues();
      ReactGA.event({ category: 'speed test', action: 'test', label: 'test run' });
      if (this._isMounted) {
        this.setState({ speedTestHasRun: true });
        this.setState({ displayedUrl: this.state.url });
        this.setState({ numberOfProgressBarsBasedOnSampleSize: this.state.selectedSampleSize }, () => {
          if (GetAuthToken()) {
            this.isRateLimitReached().then(rateLimitStatusCode => {
              if (rateLimitStatusCode) {
                const mapStatusCodes = {
                  200: () => this.generateSampleSize(), // * Daily search limit not reached
                  400: () => this.displaySampleSizeRateLimitAlert(), // * Daily search limit reached
                  429: () => GetAuthPlanId() === 1 ? this.linkToFreeTrialPage() : this.displayTestRateLimitAlert(), // * Daily search limit reached
                  default: () => this.generateSampleSize() // * Daily search limit not reached
                }
                mapStatusCodes[rateLimitStatusCode]
                  ? mapStatusCodes[rateLimitStatusCode]()
                  : mapStatusCodes['default']();
                this.handleRemainingWstSearches();
              }
            });
          } else {
            const isFirstSpeedTest = JSON.parse(localStorage.getItem(LOCALSTORAGE.isFirstSpeedTest));
            const isEmailSubscribed = JSON.parse(localStorage.getItem(LOCALSTORAGE.isEmailSubscribed));
            // * Show gate
            if (isFirstSpeedTest && !isEmailSubscribed && !GetAuthToken()) {
              this.props.updateGateContent('createAccount');
              this.props.updateShowAccountGate(true);
            } else {
              this.generateSampleSize();
            }
          }
        });
      }
		}
  }

  checkUrlForHttp = (url) => {
    const isHttp = url.substring(0, 7) === 'http://';
    const isHttps = url.substring(0, 8) === 'https://';
    return !isHttp && !isHttps
      ? `http://${url}`
      : url;
  }

  // displayAccountGate = () => {
  //   if (DisplayAccountGateCheck()) {
  //     if (!this.props.displayGateTimer) {
  //       const displayGateTimer = setTimeout(() => {
  //         if(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)) {
  //             this.props.updateGateContent('signIn');
  //         } else {
  //             this.props.updateGateContent('createAccount');
  //         }
  //         this.props.updateShowAccountGate(true);
  //       }, 15000);
  //       this.props.updateDisplayGateTimer(displayGateTimer);
  //     }
  //   }
  // }

  handleUserTokenStatus = async () => {
    const response = await AuthTokenStatus(JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).token);

    if(response?.data.status === "SUCCESS") {
      this.handleRemainingWstSearches();
    } else {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel('', '', null, null, 1, null, 1, null)));
        localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, false);
        localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
      }
      updateSignedInStatusSubject.update(false);
      this.props.updateGateContent('signIn');
      this.props.updateShowAccountGate(true);
    }
  }

  handleRemainingWstSearches = async () => {
    const response = await RemainingWstUsageStatus();
    if(response) {
        if(response.numOfwebsiteSearchesLeft === null) {
          response.numOfwebsiteSearchesLeft = 0;
          this.props.updateRemainingWSTSearches(response.numOfwebsiteSearchesLeft);
        } else {
          this.props.updateRemainingWSTSearches(response.numOfwebsiteSearchesLeft);
        }
      }
  }

  isRateLimitReached = async () => {
    const response = await RateLimitingApi('speedTest', null, null, false, this.state.numberOfProgressBarsBasedOnSampleSize, this.state.url);
    const rateLimitResponseMapping = {
      200: () => 200,// * Daily limit not reached
      400: () => 400, // * Bad request
      429: () => 429, // * Daily limit reached
      default: () => 200 // * default to daily limit not reached if api fails
    };
    return await response && response.status && rateLimitResponseMapping[response.status]
      ? rateLimitResponseMapping[response.status]()
      : rateLimitResponseMapping['default']();
  }

  displayTestRateLimitAlert() {
    this.props.updateShowSpeedTestRateLimitAlert({
      isShow: true,
      type: 'rateLimit',
      maxSampleSize: this.state.maxSampleSize
    });
  }

  displaySampleSizeRateLimitAlert() {
    this.props.updateShowSpeedTestRateLimitAlert({
      isShow: true,
      type: 'sampleSizeRateLimit',
      maxSampleSize: this.state.maxSampleSize
    });
  }

  generateSampleSize = async () => {
    // Get desktop results
    if (this.state.isDesktopToggled) {
      const desktopProgressBarsStatusObjTemp = this.state.desktopProgressBarsStatusObj;

      if (this._isMounted) {
        this.setState({ showDesktopProgressBars: true });
        this.setState({ isDesktopPsiStarted: true });
      }

      for (let i = 0, il = this.state.numberOfProgressBarsBasedOnSampleSize; i < il; i++) {
        desktopProgressBarsStatusObjTemp[i] = {
          progress: 0,
          startProgress: false
        }

        if (this._isMounted) this.setState({ desktopProgressBarsStatusObj: desktopProgressBarsStatusObjTemp });

        await this.getPsiResults(i, 'desktop').then(res => {
          if (this.state.compleatedDesktopSamples === this.state.numberOfProgressBarsBasedOnSampleSize) {
            this.averageLabData('desktop');
            this.removeMetricsSpinner();
          };
        });
      }
    } else {
      // Get mobile results
      const mobileProgressBarsStatusObjTemp = this.state.mobileProgressBarsStatusObj;

      if (this._isMounted) {
        this.setState({ showMobileProgressBars: true });
        this.setState({ isMobilePsiStarted: true });
      }

      for (let i = 0, il = this.state.numberOfProgressBarsBasedOnSampleSize; i < il; i++) {
        mobileProgressBarsStatusObjTemp[i] = {
          progress: 0,
          startProgress: false
        }

        if (this._isMounted) this.setState({ mobileProgressBarsStatusObj: mobileProgressBarsStatusObjTemp });

        await this.getPsiResults(i, 'mobile').then(res => {
          if (this.state.compleatedMobileSamples === this.state.numberOfProgressBarsBasedOnSampleSize) {
            this.averageLabData('mobile');
            this.removeMetricsSpinner();
          }
        });
      }
    }
  }

  getPsiResults = async (sampleIndex, deviceType) => {
    const psiApiUrl = 'https://www.googleapis.com/pagespeedonline/v5/runPagespeed';
    const category = 'category=performance';
    const key = 'key=AIzaSyD73wLN60ugW3pVuW-SzioZotwfDvWf3zA';
    const strategy = `strategy=${deviceType}`;
    const url = this.checkUrlForHttp(this.state.url);
		const urlEncoded = `url=${encodeURIComponent(url)}`;
		const psiApi = `${psiApiUrl}?${key}&${urlEncoded}&${category}&${strategy}`;

    if (deviceType === 'desktop') {
      const desktopProgressBarsStatusObjTemp = this.state.desktopProgressBarsStatusObj;
      desktopProgressBarsStatusObjTemp[sampleIndex] = {
        progress: 0,
        startProgress: true,
        psi: null
      }
      if (this._isMounted) this.setState({ desktopProgressBarsStatusObj: desktopProgressBarsStatusObjTemp });
    }

    if (deviceType === 'mobile') {
      const mobileProgressBarsStatusObjTemp = this.state.mobileProgressBarsStatusObj;
      mobileProgressBarsStatusObjTemp[sampleIndex] = {
        progress: 0,
        startProgress: true,
        psi: null
      };
      if (this._isMounted) this.setState({ mobileProgressBarsStatusObj: mobileProgressBarsStatusObjTemp });
    }

		return axios.get(psiApi, { timeout: 300000 })
    // return axios.get('/json/DummyPsiResults.json', { timeout: 300000 })
    .then(res => {
      if (res && this._isMounted) {
        if (typeof localStorage !== 'undefined') localStorage.setItem(LOCALSTORAGE.isFirstSpeedTest, true);
        const { data: { lighthouseResult: { categories: { performance: { score }}}} } = res;
        const { data: { lighthouseResult: { finalUrl }}} = res;

        if (sampleIndex === 0) {
          ReactGA.event({ category: 'speed test', action: 'test', label: finalUrl });
          ReactGA.event({ category: 'speed test - usage', action: finalUrl, label: GetAuthUserId() });
        }

        this.setLabMetrics(res, deviceType);
        this.setFieldMetrics(res);
        this.setOriginFieldMetrics(res);

        if (deviceType === 'desktop') {
          const desktopProgressBarsStatusObjTemp = this.state.desktopProgressBarsStatusObj;
          desktopProgressBarsStatusObjTemp[sampleIndex] = {
            progress: 100,
            startProgress: true,
            psi: Math.round(score * 100)
          }

          if (this._isMounted) {
            this.setState({ desktopProgressBarsStatusObj: desktopProgressBarsStatusObjTemp })
            this.setState({ compleatedDesktopSamples: this.state.compleatedDesktopSamples + 1 });
            if (this.state.compleatedDesktopSamples === this.state.numberOfProgressBarsBasedOnSampleSize) this.setState({ isDesktopSamplesComplete: true });
          }
        }

        if (deviceType === 'mobile') {
          const mobileProgressBarsStatusObjTemp = this.state.mobileProgressBarsStatusObj;
          mobileProgressBarsStatusObjTemp[sampleIndex] = {
            progress: 100,
            startProgress: true,
            psi: Math.round(score * 100)
          };
          if (this._isMounted) {
            this.setState({ mobileProgressBarsStatusObj: mobileProgressBarsStatusObjTemp });
            this.setState({ compleatedMobileSamples: this.state.compleatedMobileSamples + 1 });
            if (this.state.compleatedMobileSamples === this.state.numberOfProgressBarsBasedOnSampleSize) this.setState({ isMobileSamplesComplete: true });
          }
        }

        if (this.state.displayedUrl !== finalUrl && this._isMounted) this.setState({ displayedUrl: finalUrl });
      }
      return res;
    }).catch(error => {
      console.error('getPsiResults Error: ', error);
      if (error?.response) {
        const errorMapping = {
          400: () => {
            if (this._isMounted) {
              this.setState({ showDesktopProgressBars: false });
              this.setState({ showMobileProgressBars: false });
              this.setState({ desktopProgressBarsStatusObj: {} });
              this.setState({ mobileProgressBarsStatusObj: {} });
              this.setState({ isInvalidUrl: true });
            }
          },
          500: () => {
            if (this._isMounted) {
              if (deviceType === 'mobile') {
                const mobileProgressBarsStatusObjTemp = this.state.mobileProgressBarsStatusObj;
                mobileProgressBarsStatusObjTemp[sampleIndex] = {
                  ...mobileProgressBarsStatusObjTemp[sampleIndex],
                  isPsiError: true
                };
                this.setState({ mobileProgressBarsStatusObj: mobileProgressBarsStatusObjTemp });
                this.setState({ compleatedMobileSamples: this.state.compleatedMobileSamples + 1 });
              }
              if (deviceType === 'desktop') {
                const desktopProgressBarsStatusObjTemp = this.state.desktopProgressBarsStatusObj;
                desktopProgressBarsStatusObjTemp[sampleIndex] = {
                  ...desktopProgressBarsStatusObjTemp[sampleIndex],
                  isPsiError: true
                };
                this.setState({ compleatedDesktopSamples: this.state.compleatedDesktopSamples + 1 });
                this.setState({ desktopProgressBarsStatusObj: desktopProgressBarsStatusObjTemp });
              }
            }
          },
          default: () => {
            if (this._isMounted) {
              if (deviceType === 'mobile') this.setState({ compleatedMobileSamples: this.state.compleatedMobileSamples + 1 });
              if (deviceType === 'desktop') this.setState({ compleatedDesktopSamples: this.state.compleatedDesktopSamples + 1 });
            }
          }
        };
        errorMapping[error.response.status] ? errorMapping[error.response.status]() : errorMapping['default']();
      }
    })
  }

  removeMetricsSpinner = async () => {
		await DelayTimer(750);
    if (this._isMounted) {
      this.state.isDesktopToggled && Object.keys(this.state.desktopLabMetricsAverage).length > 0
        ? await this.setState({ isDisplayMetrics: true })
        : this.setState({ isRemoveSpinner: true });

      !this.state.isDesktopToggled && Object.keys(this.state.mobileLabMetricsAverage).length > 0
        ? await this.setState({ isDisplayMetrics: true })
        : this.setState({ isRemoveSpinner: true });
    }
	}

  averageLabData = (deviceType) => {
    let labMetrics = [];
    const averagedData = {}
    const avgPsiDisplayValue = []; // seconds
    const avgFcpDisplayValue = []; // seconds
    const avgSiDisplayValue = []; // seconds
    const avgLcpDisplayValue = []; // seconds
    const avgTtiDisplayValue = []; // seconds
    const avgTbtDisplayValue = []; // miliseconds
    const avgClsDisplayValue = [];

    const avgPsiScore = [];
    const avgFcpScore = [];
    const avgSiScore = [];
    const avgLcpScore = [];
    const avgTtiScore = [];
    const avgTbtScore = [];
    const avgClsScore = [];

    const deviceMapping = {
      mobile: () => this.state.mobileLabMetrics,
      desktop: () => this.state.desktopLabMetrics
    };
    const deviceAverageMapping = {
      mobile: () => this.setState({ mobileLabMetricsAverage: averagedData }),
      desktop: () => this.setState({ desktopLabMetricsAverage: averagedData })
    };

    if (deviceMapping[deviceType]) labMetrics = deviceMapping[deviceType]();

    for (let i = 0, il = labMetrics.length; i < il; i++) {
      // Display values
      avgPsiDisplayValue.push(labMetrics[i].psi?.displayValue);
      avgFcpDisplayValue.push(this.removeNonNumericDecimal(labMetrics[i].fcp?.displayValue));
      avgSiDisplayValue.push(this.removeNonNumericDecimal(labMetrics[i].si?.displayValue));
      avgLcpDisplayValue.push(this.removeNonNumericDecimal(labMetrics[i].lcp?.displayValue));
      avgTtiDisplayValue.push(this.removeNonNumericDecimal(labMetrics[i].tti?.displayValue));
      avgTbtDisplayValue.push(this.removeNonNumericDecimal(labMetrics[i].tbt?.displayValue));
      avgClsDisplayValue.push(this.removeNonNumericDecimal(labMetrics[i].cls?.displayValue));
      // Scores
      avgPsiScore.push(labMetrics[i].psi?.score);
      avgFcpScore.push(labMetrics[i].fcp?.score);
      avgSiScore.push(labMetrics[i].si?.score);
      avgLcpScore.push(labMetrics[i].lcp?.score);
      avgTtiScore.push(labMetrics[i].tti?.score);
      avgTbtScore.push(labMetrics[i].tbt?.score);
      avgClsScore.push(labMetrics[i].cls?.score);
    }

    const psiDisplayValueAvg = avgPsiDisplayValue.length > 0 ? this.roundToOneDecimal(this.average(avgPsiDisplayValue.map(Number))) : null;
    const fcpDisplayValueAvg = avgFcpDisplayValue.length > 0 ? this.roundToOneDecimal(this.average(avgFcpDisplayValue.map(Number))) : null;
    const siDisplayValueAvg = avgSiDisplayValue.length > 0 ? this.roundToOneDecimal(this.average(avgSiDisplayValue.map(Number))) : null;
    const lcpDisplayValueAvg = avgLcpDisplayValue.length > 0 ? this.roundToOneDecimal(this.average(avgLcpDisplayValue.map(Number))) : null;
    const ttiDisplayValueAvg = avgTtiDisplayValue.length > 0 ? this.roundToOneDecimal(this.average(avgTtiDisplayValue.map(Number))) : null;
    const tbtDisplayValueAvg = avgTbtDisplayValue.length > 0 ? this.roundToOneDecimal(this.average(avgTbtDisplayValue.map(Number))) : null;
    const clsDisplayValueAvg = avgClsDisplayValue.length > 0 ? this.roundToTwoDecimal(this.average(avgClsDisplayValue.map(Number))) : null;

    const psiScoreAvg = avgPsiScore.length > 0 ? this.average(avgPsiScore) : null;
    const fcpScoreAvg = avgFcpScore.length > 0 ? this.average(avgFcpScore) : null;
    const siScoreAvg = avgSiScore.length > 0 ? this.average(avgSiScore) : null;
    const lcpScoreAvg = avgLcpScore.length > 0 ? this.average(avgLcpScore) : null;
    const ttiScoreAvg = avgTtiScore.length > 0 ? this.average(avgTtiScore) : null;
    const tbtScoreAvg = avgTbtScore.length > 0 ? this.average(avgTbtScore) : null;
    const clsScoreAvg = avgClsScore.length > 0 ? this.average(avgClsScore) : null;

    if (psiDisplayValueAvg !== null && psiScoreAvg !== null) {
      averagedData['psi'] = {
        displayValue: psiDisplayValueAvg,
        score: psiScoreAvg,
        label: 'psi',
      }
    }
    if (fcpDisplayValueAvg !== null && fcpScoreAvg !== null) {
      averagedData['fcp'] = {
        displayValue: `${fcpDisplayValueAvg} s`,
        score: fcpScoreAvg,
        label: 'fcp',
      }
    }
    if (siDisplayValueAvg !== null && siScoreAvg !== null) {
      averagedData['si'] = {
        displayValue: `${siDisplayValueAvg} s`,
        score: siScoreAvg,
        label: 'si',
      }
    }
    if (lcpDisplayValueAvg !== null && lcpScoreAvg !== null) {
      averagedData['lcp'] = {
        displayValue: `${lcpDisplayValueAvg} s`,
        score: lcpScoreAvg,
        label: 'lcp',
      }
    }
    if (ttiDisplayValueAvg !== null && ttiScoreAvg !== null) {
      averagedData['tti'] = {
        displayValue: `${ttiDisplayValueAvg} s`,
        score: ttiScoreAvg,
        label: 'tti',
      }
    }
    if (tbtDisplayValueAvg !== null && tbtScoreAvg !== null) {
      averagedData['tbt'] = {
        displayValue: `${tbtDisplayValueAvg} ms`,
        score: tbtScoreAvg,
        label: 'tbt',
      }
    }
    if (clsDisplayValueAvg !== null && clsScoreAvg !== null) {
      averagedData['cls'] = {
        displayValue: clsDisplayValueAvg,
        score: clsScoreAvg,
        label: 'cls',
      }
    }
    if (deviceAverageMapping[deviceType]) deviceAverageMapping[deviceType]();
  }

  removeNonNumericDecimal = (str) => {
    return str ? str.replace(/[^0-9.]/g, '') : '0';
  }

  average = (array) => {
    return array.reduce((a, b) => a + b) / array.length;
  }

  roundToOneDecimal = (num) => {
    return num % 1 !== 0
      ? num.toFixed(1)
      : num !== 0
        ? ((num * 100) / 100).toFixed(1)
        : num.toString();
  }

  roundToTwoDecimal = (num) => {
    return num % 1 !== 0
      ? num.toFixed(2)
      : num !== 0
        ? ((num * 100) / 100).toFixed(2)
        : num.toString();
  }

  fieldRoundToTwoDecimal = (num) => {
    return num % 1 !== 0
      ? num.toFixed(2)
      : num !== 0
        ? num/100
        : num.toString();
  }

  setLabMetrics = (res, deviceType) => {
		if (res && res.data && res.data.lighthouseResult && res.data.lighthouseResult.audits) {
      const { data: { lighthouseResult: { categories: { performance: { score }}}} } = res;
			const { data: { lighthouseResult: { audits: {
				'first-contentful-paint': firstContentfulPaint,
				'speed-index': speedIndex,
				'largest-contentful-paint': largestContentfulPaint,
				'total-blocking-time': totalBlockingTime,
				'cumulative-layout-shift': cumulativeLayoutShift,
				interactive
			}}}} = res;

			const labMetrics = {
				psi: {
					label: 'psi',
					displayValue: Math.round(score * 100),
					score: Math.round(score * 100)
				},
				fcp: {
					label: 'fcp',
					displayValue: firstContentfulPaint.displayValue,
					score: Math.round(firstContentfulPaint.score * 100)
				},
				si: {
					label: 'si',
					displayValue: speedIndex.displayValue,
					score: Math.round(speedIndex.score * 100)
				},
				lcp: {
					label: 'lcp',
					displayValue: largestContentfulPaint.displayValue,
					score: Math.round(largestContentfulPaint.score * 100)
				},
				tti: {
					label: 'tti',
					displayValue: interactive.displayValue,
					score: Math.round(interactive.score * 100)
				},
				tbt: {
					label: 'tbt',
					displayValue: totalBlockingTime.displayValue,
					score: Math.round(totalBlockingTime.score * 100)
				},
				cls: {
					label: 'cls',
					displayValue: cumulativeLayoutShift.displayValue,
					score: Math.round(cumulativeLayoutShift.score * 100)
				}
			};
			if (this._isMounted) {
        if (deviceType === 'mobile') this.setState({ mobileLabMetrics: [...this.state.mobileLabMetrics, labMetrics] });
        if (deviceType === 'desktop') this.setState({ desktopLabMetrics: [...this.state.desktopLabMetrics, labMetrics] });
      }
		}
	}

  setFieldMetrics = (res) => {
		if (res && res.data && res.data.loadingExperience && res.data.loadingExperience.metrics) {
			const { data: { loadingExperience: { metrics: {
				LARGEST_CONTENTFUL_PAINT_MS,
				FIRST_INPUT_DELAY_MS,
				CUMULATIVE_LAYOUT_SHIFT_SCORE,
				FIRST_CONTENTFUL_PAINT_MS
			}}}} = res;

			const speedColorMapping = {
				FAST: () => 'psi-score-pass',
				AVERAGE: () => 'psi-score-average',
				SLOW: () => 'psi-score-fail'
			};

			const fieldMetrics = {
				lcp: {
					label: 'lcp',
					displayValue: `${ParseMillisecondsToSeconds(LARGEST_CONTENTFUL_PAINT_MS?.percentile)} s`,
					speedColor: speedColorMapping[LARGEST_CONTENTFUL_PAINT_MS?.category] ? speedColorMapping[LARGEST_CONTENTFUL_PAINT_MS.category]() : ''
				},
				fid: {
					label: 'fid',
					displayValue: `${FIRST_INPUT_DELAY_MS?.percentile} ms`,
					speedColor: speedColorMapping[FIRST_INPUT_DELAY_MS?.category] ? speedColorMapping[FIRST_INPUT_DELAY_MS.category]() : ''
				},
				cls: {
					label: 'cls',
					displayValue: this.fieldRoundToTwoDecimal(CUMULATIVE_LAYOUT_SHIFT_SCORE?.percentile),
					speedColor: speedColorMapping[CUMULATIVE_LAYOUT_SHIFT_SCORE?.category] ? speedColorMapping[CUMULATIVE_LAYOUT_SHIFT_SCORE.category]() : ''
				},
				fcp: {
					label: 'fcp',
					displayValue: `${ParseMillisecondsToSeconds(FIRST_CONTENTFUL_PAINT_MS?.percentile)} s`,
					speedColor: speedColorMapping[FIRST_CONTENTFUL_PAINT_MS?.category] ? speedColorMapping[FIRST_CONTENTFUL_PAINT_MS.category]() : ''
				}
			};

			if (this._isMounted) {
        if(!this.state.isDesktopToggled) {
          this.setState({ fieldMetrics: fieldMetrics });
        } else  {
          this.setState({ desktopFieldMetrics: fieldMetrics });
        }
      }
		}
	}

  setOriginFieldMetrics = (res) => {
    if (res && res.data && res.data.originLoadingExperience && res.data.originLoadingExperience.metrics) {
      const { data: { originLoadingExperience: { metrics: {
        LARGEST_CONTENTFUL_PAINT_MS,
        FIRST_INPUT_DELAY_MS,
        CUMULATIVE_LAYOUT_SHIFT_SCORE,
        FIRST_CONTENTFUL_PAINT_MS
      }}}} = res;

      const speedColorMapping = {
        FAST: () => 'psi-score-pass',
        AVERAGE: () => 'psi-score-average',
        SLOW: () => 'psi-score-fail'
      };

      const fieldOriginMetrics = {
        lcp: {
          label: 'lcp',
          displayValue: `${ParseMillisecondsToSeconds(LARGEST_CONTENTFUL_PAINT_MS?.percentile)} s`,
          speedColor: speedColorMapping[LARGEST_CONTENTFUL_PAINT_MS?.category] ? speedColorMapping[LARGEST_CONTENTFUL_PAINT_MS.category]() : ''
        },
        fid: {
          label: 'fid',
          displayValue: `${FIRST_INPUT_DELAY_MS?.percentile} ms`,
          speedColor: speedColorMapping[FIRST_INPUT_DELAY_MS?.category] ? speedColorMapping[FIRST_INPUT_DELAY_MS.category]() : ''
        },
        cls: {
          label: 'cls',
          displayValue: this.fieldRoundToTwoDecimal(CUMULATIVE_LAYOUT_SHIFT_SCORE?.percentile),
          speedColor: speedColorMapping[CUMULATIVE_LAYOUT_SHIFT_SCORE?.category] ? speedColorMapping[CUMULATIVE_LAYOUT_SHIFT_SCORE.category]() : ''
        },
        fcp: {
          label: 'fcp',
          displayValue: `${ParseMillisecondsToSeconds(FIRST_CONTENTFUL_PAINT_MS?.percentile)} s`,
          speedColor: speedColorMapping[FIRST_CONTENTFUL_PAINT_MS?.category] ? speedColorMapping[FIRST_CONTENTFUL_PAINT_MS.category]() : ''
        }
      };

      if (this._isMounted){
        if(!this.state.isDesktopToggled) {
          this.setState({ originFieldMetrics: fieldOriginMetrics });
        } else  {
          this.setState({ originDesktopFieldMetrics: fieldOriginMetrics });
        }



       }
    }
  }

  saveSettingsApi = () => {
		const params = {
			deviceType: this.state.isDesktopToggled ? 2 : 1
		};
		SaveUserSettingApi(params);
	}

  // showUpgradeMessaging = () => {
  //   if (typeof localStorage !== 'undefined' && !JSON.parse(localStorage.getItem(LOCALSTORAGE.increaseSampleSizeAlert))) {
  //     this.props.updateShowSpeedTestRateLimitAlert({
  //       isShow: true,
  //       type: 'increaseSampleSize',
  //       maxSampleSize: this.state.maxSampleSize
  //     });
  //     localStorage.setItem(LOCALSTORAGE.increaseSampleSizeAlert, JSON.stringify(true));
  //   }
  // }

  setSampleSizearray = () => {
    if (GetAuthPlanId()) {
      const planId = GetAuthPlanId();
      const maxSampleSize = PRICING[PLANS[planId]].speedTestSampleLimit

      if (planId === 1) {
        if (this._isMounted) {
          this.setState({ sampleSizeArray: [3, 2, 1] });
          this.setState({ maxSampleSize: 3 });
        }
      } else {
        const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);
        if (this._isMounted) {
          this.setState({ sampleSizeArray: range(1, (maxSampleSize + 1)).reverse() });
          this.setState({ maxSampleSize: maxSampleSize });
        }
      }
    } else {
      if (this._isMounted) {
        this.setState({ sampleSizeArray: [3,2 ,1] });
        this.setState({ maxSampleSize: 3 });
      }
    }
  }

  linkToPricingPage = () => {
    ReactGA.event({ category: 'rate limiting - wst', action: 'click on upgrade', label: GetAuthUserId() });
    this.props.history.push({ pathname: '/pricing' });
  }

  linkToFreeTrialPage = () => {
    this.props.history.push({ pathname: '/free-trial-page'})
  }

	render() {
    return (
      <div className="row mb-2">
        <div className="col-sm-12 pb-3">
          <form>
            <div className="input-group pb-2">
              <input
                id="speedTest"
                type="text"
                className={`form-control ${this.state.isInvalidUrl ? 'error' : ''}`}
                placeholder="Enter a web page URL"
                aria-label="Enter a web page URL"
                aria-describedby="Enter a web page URL"
                value={this.state.url}
                onChange={this.handleInputChange}/>
              <button
                className="btn btn-outline-primary"
                type="submit"
                id="submitSpeedTest"
                data-ripple-color="dark"
                onClick={(e) => this.handleSubmit(e)}>
                analyze
              </button>
              {
                this.state.isInvalidUrl
                  ? <label className="error">
                      <span>Please enter a valid url</span>
                    </label>
                  : null
              }
            </div>
            {
              GetAuthToken()
                ? <div className="col-sm-12 remaining-searches">
                      <span className="ml-2 serp-psi-search-flag-copy body-small">Remaining Tests: {this.props.remainingWSTSearches}  <u className="pl-1 align-middle" onClick={() => this.linkToPricingPage()}>upgrade</u></span>
                  </div> : null
            }
          </form>
        </div>
        <div className="col-sm-12 pb-4">
          <div className="container d-flex justify-content-center p-0 text-center toggle-sample-size-container">
            <div className="toggle-switch-container">
              <span className="pr-2">
                <i className="fas fa-mobile-alt fa-lg"></i>
              </span>
              <div className="form-check form-switch d-inline-block">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="device-type-switch"
                  value={this.state.isDesktopToggled}
                  checked={this.state.isDesktopToggled}
                  onChange={this.handleDeviceToggle}
                  />
                <label className="form-check-label" htmlFor="device-type-switch">
                  <i className="fas fa-desktop fa-lg"></i>
                </label>
              </div>
            </div>
            <div>
              <select
                className="ml-2 mr-0 pt-1 pr-2 pb-1 pl-2"
                onChange={(e) => this.setState({ selectedSampleSize: Number(e.target.value) })}
                value={this.state.selectedSampleSize}>
                {
                  this.state.sampleSizeArray.map((i, index) => {
                    return <option
                            key={`sample-size${index}`}
                            value={i}>
                            {i}
                          </option>
                  })
                }
              </select>
              <span> Test Samples</span>
            </div>
          </div>
        </div>
         {
           (this.state.showMobileProgressBars || this.state.showDesktopProgressBars) || (this.state.compleatedMobileSamples === this.state.numberOfProgressBarsBasedOnSampleSize && this.state.compleatedDesktopSamples === this.state.numberOfProgressBarsBasedOnSampleSize)
             ? null
             : <div className="col-sm-12">
                 <p className="text-center body-small">We run multiple test samples on Google's Pagespeed Insights and return the averaged metrics</p>
               </div>
         }

        {/* {
          (this.state.showMobileProgressBars || this.state.showDesktopProgressBars) || (this.state.compleatedMobileSamples === this.state.numberOfProgressBarsBasedOnSampleSize && this.state.compleatedDesktopSamples === this.state.numberOfProgressBarsBasedOnSampleSize)
            ? <div className="col-sm-12 pb-3">
                <div className="d-flex align-items-center">
                  <div className="psi-score-scale-container">
                    <span className="psi-score-range psi-score-scale-range-fail">0–49</span>
                    <span className="psi-score-range psi-score-scale-range-average">50–89</span>
                    <span className="psi-score-range psi-score-scale-range-pass">90–100</span>
                  </div>
                </div>

                <p className="text-center mb-0" style={{ wordBreak: 'break-word'}}>{ this.state.displayedUrl }</p>
              </div>
            : <div className="col-sm-12">
                <p className="text-center body-small">We run multiple test samples and return averaged metrics from Google's Pagespeed Insights</p>
              </div>
        } */}

        {/* Desktop progress bars */}
        {/* {
          this.state.isDesktopToggled && this.state.showDesktopProgressBars && !this.state.isDesktopSamplesComplete
            ? <div className="col-sm-12 pb-3">
                {
                  [...Array(Number(this.state.numberOfProgressBarsBasedOnSampleSize))].map((elementInArray, index) => (
                    <div className="d-flex flex-row align-items-center justify-content-center mb-3" key={`sample-${index}`}>
                      <span className="pr-2">Sample { index + 1 }</span>
                      {
                        (this.state.desktopProgressBarsStatusObj[index]?.psi) || (!this.state.desktopProgressBarsStatusObj[index]?.psi && this.state.desktopProgressBarsStatusObj[index]?.psi === 0)
                          ? <span>
                              <span className="pr-1">PSI Score: <span className={`${LighthouseScoreColors(this.state.desktopProgressBarsStatusObj[index].psi)}`}> { this.state.desktopProgressBarsStatusObj[index].psi } </span></span>
                            </span>
                          : null
                      }

                      {
                        this.state.desktopProgressBarsStatusObj[index]?.startProgress
                          ? this.state.desktopProgressBarsStatusObj[index]?.isPsiError
                            ? <span> PSI Error</span>
                            : <ProgressBar progressBarsStatus={this.state.desktopProgressBarsStatusObj[index]}/>
                          : <EmptyProgressBar />
                      }
                    </div>
                  ))
                }
              </div>
            : null
        } */}
        {/* Mobile progress bars */}
        {/* {
          !this.state.isDesktopToggled && this.state.showMobileProgressBars && !this.state.isMobileSamplesComplete
            ? <div className="col-sm-12 pb-3">
                {
                  [...Array(Number(this.state.numberOfProgressBarsBasedOnSampleSize))].map((elementInArray, index) => (
                    <div className="d-flex flex-row align-items-center justify-content-center mb-3" key={`sample-${index}`}>
                      <span className="pr-2">Sample { index + 1 }</span>
                      {
                        (this.state.mobileProgressBarsStatusObj[index]?.psi) || (!this.state.mobileProgressBarsStatusObj[index]?.psi && this.state.mobileProgressBarsStatusObj[index]?.psi === 0)
                          ? <span>
                              <span className="pr-1">PSI Score: <span className={`${LighthouseScoreColors(this.state.mobileProgressBarsStatusObj[index].psi)}`}> { this.state.mobileProgressBarsStatusObj[index].psi } </span></span>
                            </span>
                          : null
                      }

                      {
                        this.state.mobileProgressBarsStatusObj[index]?.startProgress
                          ? this.state.mobileProgressBarsStatusObj[index]?.isPsiError
                            ? <span> PSI Error</span>
                            : <ProgressBar progressBarsStatus={this.state.mobileProgressBarsStatusObj[index]}/>
                          : <EmptyProgressBar />
                      }
                    </div>
                  ))
                }
              </div>
          : null
        } */}
        {/* shouldn't need these spinners anymore */}
        {/* {
          this.props.settingsDeviceSelected === 'desktop' && !this.state.isDisplayMetrics && this.state.compleatedDesktopSamples === this.state.numberOfProgressBarsBasedOnSampleSize && !this.state.isRemoveSpinner
            ? <div className="col-sm-12 text-center mb-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            : this.props.settingsDeviceSelected === 'mobile' && !this.state.isDisplayMetrics && this.state.compleatedMobileSamples === this.state.numberOfProgressBarsBasedOnSampleSize && !this.state.isRemoveSpinner
              ? <div className="col-sm-12 text-center mb-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              : null
        }*/}
        {
          Object.keys(this.state.desktopProgressBarsStatusObj).length > 0 || Object.keys(this.state.mobileProgressBarsStatusObj).length > 0
            ? <SpeedTestMetricTabs
                sampleSize={this.state.numberOfProgressBarsBasedOnSampleSize}
                fieldMetrics={this.state.fieldMetrics}
                desktopFieldMetrics={this.state.desktopFieldMetrics}
                mobileLabMetricsAverage={this.state.mobileLabMetricsAverage}
                desktopLabMetricsAverage={this.state.desktopLabMetricsAverage}
                url={this.state.url}
                isMobileSamplesComplete={this.state.isMobileSamplesComplete}
                isDesktopSamplesComplete={this.state.isDesktopSamplesComplete}
                desktopProgressBarsStatusObj={this.state.desktopProgressBarsStatusObj}
                mobileProgressBarsStatusObj={this.state.mobileProgressBarsStatusObj}
                isDesktopToggled={this.state.isDesktopToggled}
                originFieldMetrics={this.state.originFieldMetrics}
                originDesktopFieldMetrics={this.state.originDesktopFieldMetrics}
                showDesktopProgressBars={this.state.showDesktopProgressBars}
                showMobileProgressBars={this.state.showMobileProgressBars}
                compleatedMobileSamples={this.state.compleatedMobileSamples}
                compleatedDesktopSamples={this.state.compleatedDesktopSamples}
                onRef={ref => (this.child = ref)}
              />
          : null
        }
        {
          this.props.showSpeedTestRateLimitAlert.isShow
            ? <SpeedTestRateLimitAlert speedTestRateLimitAlertData={this.props.showSpeedTestRateLimitAlert}/>
            : null
        }
      </div>
    );
  }

  componentDidMount() {
    const isFirstSpeedTest = JSON.parse(localStorage.getItem(LOCALSTORAGE.isFirstSpeedTest));
    const isEmailSubscribed = JSON.parse(localStorage.getItem(LOCALSTORAGE.isEmailSubscribed));
    this._isMounted = true;


    if(GetAuthToken())
      this.handleUserTokenStatus();
    // * Show gate
    if (isFirstSpeedTest && !isEmailSubscribed && !GetAuthToken()) {
      this.props.updateGateContent('createAccount');
      this.props.updateShowAccountGate(true);
    }

    this.setSampleSizearray();

    if (GetAuthToken()) {
      GetUserSettingApi().then(data => {
        if (data) {
          const deviceMapping = {
            0: () => MediaMatcher(),
            1: () => 'mobile',
            2: () => 'desktop'
          };
          this.props.updateSelectedDevice(deviceMapping[data.deviceType]());
          if (this._isMounted) this.setState({ isDesktopToggled: this.props.settingsDeviceSelected === 'desktop' ? true : false });
        }
      });
    } else {
      if (!this.props.settingsDeviceSelected) {
        this.props.updateSelectedDevice(MediaMatcher());
        if (this._isMounted) this.setState({ isDesktopToggled: MediaMatcher() === 'desktop' ? true : false });
      } else {
        if (this._isMounted) this.setState({ isDesktopToggled: this.props.settingsDeviceSelected === 'desktop' ? true : false });
      }
    }

    this.subscription = updateSettingsDeviceSubject.get().subscribe(deviceSelected => {
     if (this._isMounted && deviceSelected) {
      deviceSelected === 'desktop'
        ? this.setState({ isDesktopToggled: true })
        : this.setState({ isDesktopToggled: false });
     }
    });

    this.signInSubscription = updateSignedInStatusSubject.get().subscribe(isUpdateSignedInStatusSubject => {
      this.setSampleSizearray();
    });
	}

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this._isMounted = false;
    this.subscription.unsubscribe();
    this.signInSubscription.unsubscribe();
    if(GetAuthToken())
      this.handleRemainingWstSearches();
	}
}

const mapStateToProps = state => {
  return {
    settingsDeviceSelected: state.settingsDeviceSelected,
    displayGateTimer: state.displayGateTimer,
    showSpeedTestRateLimitAlert: state.showSpeedTestRateLimitAlert,
    remainingWSTSearches: state.remainingWSTSearches
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
    updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate }),
    updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),
    updateSelectedDevice: (selected) => dispatch({ type: 'SETTINGS_DEVICE_SELECTED', value: selected }),
    updateShowSpeedTestRateLimitAlert: (showSpeedTestRateLimitAlert) => dispatch({ type: 'SHOW_SPEED_TEST_RATE_LIMIT_ALERT', value: showSpeedTestRateLimitAlert }),
    updateRemainingWSTSearches: (remainingSearches) => dispatch({ type: 'REMAINING_WST_SEARCHES', value: remainingSearches })

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SpeedTestTool));
