import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PRICING from '../constants/Pricing';
import LOCALSTORAGE from '../constants/LocalStorage';
import { CancelPaymentApi, GetPaypalAccessToken, CancelPaypalPaymentApi } from '../functions/PaymentApi';
import Footer from '../components/Footer/Footer';

class CancelPage extends Component {
  constructor(props) {
    super();
		this.state = {
      isCancelSuccessful: false,
      isCancelProcessing: false
    };
  }

  cancelSubscription = () => {
    var subId = "";
    const params = {
      planId: PRICING.free.planId,
      billingCycle: this.props.currentPlan.billingCycle
    };
    const paypalParams = {
      planId: PRICING.free.planId,
      billingCycle: this.props.currentPlan.billingCycle,
      paymentMethodId: subId
    };
    this.setState({ isCancelProcessing: true });

    GetPaypalAccessToken().then(res => {
    		if(res.data.status === "SUCCESS") {
    		    paypalParams.paymentMethodId = res.data.userSubscription;
    		}
        if(res.data.userSubscription!== "" && res.data.userSubscription !== null) {
          CancelPaypalPaymentApi(paypalParams).then(res => {
            if (res && res.status === 200) {
              this.props.updateCurrentPlan(PRICING.free);
              if (typeof localStorage !== 'undefined') {
                const usrAuthStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage));
                usrAuthStorage.planId = 1;
                usrAuthStorage.renewDate = res.data.renewsAt;
                usrAuthStorage.cancelDate = res.data.cancelsAt;
                usrAuthStorage.billingCycle = 1;
                usrAuthStorage.token = res.data.token;
                localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(usrAuthStorage));
              }
              this.setState({ isCancelSuccessful: true });
              this.setState({ isCancelProcessing: false });
            } else {
              this.setState({ isCancelProcessing: false });
              console.error('CancelPaymentApi error ', res);
            }
          }).catch(error => {
            this.setState({ isCancelProcessing: false });
            console.error('catch error cancel payment function ', error);
          });
        } else {
          CancelPaymentApi(params).then(res => {
            if (res && res.status === 200) {
              this.props.updateCurrentPlan(PRICING.free);
              if (typeof localStorage !== 'undefined') {
                const usrAuthStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage));
                usrAuthStorage.planId = 1;
                usrAuthStorage.renewDate = null;
                usrAuthStorage.cancelDate = null;
                usrAuthStorage.billingCycle = 1;
                usrAuthStorage.token = res.data.token;
                localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(usrAuthStorage));
              }
              this.setState({ isCancelSuccessful: true });
              this.setState({ isCancelProcessing: false });
            } else {
              this.setState({ isCancelProcessing: false });
              console.error('CancelPaymentApi error ', res);
            }
          }).catch(error => {
            this.setState({ isCancelProcessing: false });
            console.error('catch error cancel payment function ', error);
          });

      }
    }).catch(err => {
      console.log(err);
    });
  }

  render() {
    return (
      <div className="container-fluid page-background1 pb-5">
        <Helmet>
          <meta name="description" content=""/>
          <title>Cancel | Sandbox</title>
        </Helmet>
        <Header />
        <div className="container page-content-padding" style={{ marginBottom: '30rem' }}>
          <div className="row pt-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Cancel</li>
                </ol>
              </nav>
            </div>
            {
              this.state.isCancelSuccessful
                ? <div className="col-sm-12">
                    <p>We have cancelled your subscription. It will not renew</p>
                    <p>Under account, your plan may show as Free, but you'll continue to get paid service through the end of your current payment cycle.</p>
                  </div>
                : <div className="col-sm-12">
                    <p>
                      <b>Are you sure?</b>
                    </p>
                    {
                      this.state.isCancelProcessing
                        ?  <button
                            type="button"
                            className="btn btn-outline-primary w-25">
                            <span className="fas fa-spinner fa-spin fa-2x loading-icon"></span>
                          </button>
                        : <button
                            type="button"
                            className="btn btn-dark"
                            onClick={() => this.cancelSubscription()}>
                            Yes, please cancel
                          </button>

                    }
                    <span className="d-block mt-3">
                      <Link to={'/'}><u>No, take me back to tools</u></Link>
                    </span>
                  </div>
            }
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
  }

  componentWillUnmount() {
	}
}

const mapStateToProps = state => {
  return {
    currentPlan: state.currentPlan
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CancelPage));
