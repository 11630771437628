import React, { Component } from 'react';

class ProgressBar extends Component {
  _isMounted = false;
  progressBarInterval = null;
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.progressBarInterval = null;
    this.state = {
      progressPercentage: 0,
      percentageHangingState: 75
    };
  }

  increaseProgressBar = () => {
    let randomMilliseconds = 500;
		const millisecondsArry = [4000, 7000, 10000];
    
    this.progressBarInterval = setInterval(() => {
      if (this.state.progressPercentage <= this.state.percentageHangingState) {
        const randomNum = Math.floor(Math.random() * 5);
        randomMilliseconds = millisecondsArry[Math.floor(Math.random() * millisecondsArry.length)];
        if (this._isMounted) this.setState({ progressPercentage: this.state.progressPercentage + randomNum });
      }
      if (this.props.progressBarsStatus.progress >= this.state.percentageHangingState) clearInterval(this.progressBarInterval);
    }, randomMilliseconds);
  }

	render() {
    return (
      <div className="progress speed-test-progress-bar">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: this.props.progressBarsStatus.progress === 100 ? '100%' : this.state.progressPercentage + '%' }}
          aria-valuenow={ this.props.progressBarsStatus.progress === 100 ? 100 : this.state.progressPercentage }
          aria-valuemin="0"
          aria-valuemax="100">
        </div>
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.increaseProgressBar();
	}

  componentWillUnmount() {
		this._isMounted = false;
    clearInterval(this.progressBarInterval);
	}
}

export default ProgressBar;