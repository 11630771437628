import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
	render() {
    return (
      <footer className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3>Sandbox Web, LLC</h3>
            </div>
            <div className="col-sm-12 text-center">
              <p style={{ whiteSpace: 'pre-wrap' }}>
                <a
                  href="https://www.sandboxweb.io/our-services"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline mr-3">
                  About
                </a>
                <a
                  href="https://www.sandboxweb.io/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline mr-3">
                  Contact
                </a>
                <a
                  href="https://www.linkedin.com/company/sandbox-web"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline">
                  Follow
                </a>
              </p>
              <p>support@sandboxweb.io</p>
              <p>588 East 22nd st, Brooklyn, NY 11226</p>
            </div>
            <div className="col-sm-12 text-center pb-3">
              <a
                href="https://twitter.com/zacknotes"
                target="_blank"
                aria-label="Twitter"
                rel="noopener noreferrer">
                <i className="fab fa-twitter pr-3"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/sandbox-web"
                target="_blank"
                aria-label="LinkedIn"
                rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <div className="col-sm-12 text-center pt-3">
              <a
                href="https://www.sandboxweb.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline">
                Privacy Policy
              </a>
            
            </div>
          </div>
        </div>
      </footer>
    );
  }
  
  componentDidMount() {
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));