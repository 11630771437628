import React, { Component } from 'react';

class KeywordTabs extends Component {
  constructor(props) {
    super();
    this.state = {
        selectedType: "Category",
        categoryTT: `Enter a parent category, and we'll give you the children below. Use this only when you have a true category, like "pickled vegetables" or "books about witches"`,
        relatedTT: `The related button is best for coming up with semantically related keywords or topics. Enter “cabernet sauvignon” and you’ll get napa valley, sommelier, etc.. `,
        // similarTT: `Think of similar results as siblings, under the same parent category. “Flare Jeans” will give you Bell Bottoms, Bootcuts, etc.. "Rocky Road” will give you Strawberry, Mint Chocolate Chip…`,
        variationTT: `The “variation” button gives results that contain the seed keyword. Enter “drones” and you’ll get drone racing, drone with camera, etc.. `,
        showCatTT: false
    };
	}
    isCategoryTabSelected = (type) => {
      const keywordTypeSelectedMapping = {
        Category: () => 'active',
        Similar: () => '',
        Variation: () => '',
        Related: () => ''
      };
      return keywordTypeSelectedMapping[type] 
      ? keywordTypeSelectedMapping[type]() : '';
    }
      
    // isSimilarTabSelected = (type) => {
    //   const keywordTypeSelectedMapping = {
    //     Category: () => '',
    //     Similar: () => 'active',
    //     Variation: () => '',
    //     Related: () => ''
    //   };
    //   return keywordTypeSelectedMapping[type] 
    //   ? keywordTypeSelectedMapping[type]() : '';
    // }
      
    isVariationTabSelected = (type) => {
      const keywordTypeSelectedMapping = {
        Category: () => '',
        Similar: () => '',
        Variation: () => 'active',
        Related: () => ''
      };
      return keywordTypeSelectedMapping[type] 
      ? keywordTypeSelectedMapping[type]() : '';
    }
      
    isRelatedTabSelected = (type) => {
      const keywordTypeSelectedMapping = {
        Category: () => '',
        Similar: () => '',
        Variation: () => '',
        Related: () => 'active'
      };
      return keywordTypeSelectedMapping[type] 
      ? keywordTypeSelectedMapping[type]() : '';
    }

    handleToggleAlgorithmToolTip = (e, algo) => {
      e.preventDefault();
      if(algo === "Category") {
          const toolTipCat = document.getElementById(`category-tooltip`);
          if(!toolTipCat.classList.contains("none")) {
              toolTipCat.classList.add("none");
            }	else {
              toolTipCat.classList.remove("none");
            }
      } else if(algo ==="Variation"){
          const toolTipVar = document.getElementById(`variation-tooltip`);
          if(!toolTipVar.classList.contains("none")) {
              toolTipVar.classList.add("none");
            }	else {
              toolTipVar.classList.remove("none");
            }
      } else if(algo === "Related"){
          const toolTipRel = document.getElementById(`related-tooltip`);
          if(!toolTipRel.classList.contains("none")) {
              toolTipRel.classList.add("none");
            }	else {
              toolTipRel.classList.remove("none");
            }
      }
            // } else if(algo === "Similar") {
      //     const toolTipSim = document.getElementById(`similar-tooltip`);
      //     if(!toolTipSim.classList.contains("none")) {
      //         toolTipSim.classList.add("none");
      //       }	else {
      //         toolTipSim.classList.remove("none");
      //       }
    }

  render() {
    return (
        <div>
            <ul className="nav nav-tabs" role="tablist">
                <li 
                    className="nav-item  algo-tooltip-holder" 
                    role="presentation"
                    onMouseEnter={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Category") : null} 
                    onMouseLeave={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Category") : null}
                >
                    {
                        this.props.screenSize !== "mobile" ? 
                        <div
                            style={{top: '-125px'}}
                            id="category-tooltip"
                            className="algorithm-tooltip algorithm-tooltip-inner none">
                            <span
                                className="custom-tooltip"
                                >
                                {this.state.categoryTT}
                            </span>
                        </div>
                        : null 
                    }
                    <a 
                        className={`nav-link px-2 kw-tab ${this.props.selectedType ? this.isCategoryTabSelected(this.props.selectedType) : 'active'}`}
                        id="category-tab"
                        data-mdb-toggle="tab" 
                        role="tab"
                        aria-selected="true"
                        aria-controls="category-content"
                        href={'#category-content'}
                        onClick={(e) => {this.props.handleKeywordTabChange(e)}}
                        >
                        <b>Category</b>
                    </a>
                </li>
                {/* <li 
                    className="nav-item algo-tooltip-holder" 
                    role="presentation"
                    onMouseEnter={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Similar") : null} 
                    onMouseLeave={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Similar") : null}
                >
                {
                    this.props.screenSize !== "mobile" ? 
                        <div
                            style={{top: '-147px'}}
                            id="similar-tooltip"
                            className="algorithm-tooltip algorithm-tooltip-inner none">
                            <span
                                className="custom-tooltip"
                                >
                                {this.state.similarTT}
                            </span>
                        </div>
                    : null 
                }
                <a 
                    className={`nav-link px-2 kw-tab ${this.props.selectedType ? this.isSimilarTabSelected(this.props.selectedType) : ''}`} 
                    id="similar-tab"
                    data-mdb-toggle="tab" 
                    role="tab"
                    aria-selected="false"
                    aria-controls="similar-content"
                    href={'#similar-content'}
                    onClick={(e) => {this.props.handleKeywordTabChange(e)}}
                    ><b>Similar</b>
                </a>
                </li> */}
                <li 
                    className="nav-item algo-tooltip-holder" 
                    role="presentation"
                    onMouseEnter={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Variation") : null} 
                    onMouseLeave={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Variation") : null}
                >
                 {
                    this.props.screenSize !== "mobile" ? 
                    <div className="d-flex">
                        <div
                            style={{top: '-103px'}}
                            id="variation-tooltip"
                            className="algorithm-tooltip algorithm-tooltip-inner none">
                            <span
                                className="custom-tooltip"
                                >
                                {this.state.variationTT}
                            </span>
                        </div>
                    </div> : null 
                }
                <a 
                    className={`nav-link px-2 kw-tab ${this.props.selectedType ? this.isVariationTabSelected(this.props.selectedType) : ''}`}  
                    id="variation-tab"
                    data-mdb-toggle="tab" 
                    role="tab"
                    aria-selected="false"
                    aria-controls="variation-content"
                    href={'#variation-content'}
                    onClick={(e) => {this.props.handleKeywordTabChange(e)}}
                    ><b>Variation</b>
                </a>
                </li>
                <li 
                    className="nav-item algo-tooltip-holder" 
                    role="presentation"
                    onMouseEnter={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Related") : null} 
                    onMouseLeave={(e) => this.props.screenSize !== "mobile" ? this.handleToggleAlgorithmToolTip(e, "Related") : null}
                >   
                 {
                    this.props.screenSize !== "mobile" ? 
                    <div
                        style={{top: '-125px'}}
                        id="related-tooltip"
                        className="algorithm-tooltip algorithm-tooltip-inner none">
                        <span
                            className="custom-tooltip"
                            >
                            {this.state.relatedTT}
                        </span>
                    </div>: null 
                }
                <a 
                    className={`nav-link px-2 kw-tab ${this.props.selectedType ? this.isRelatedTabSelected(this.props.selectedType) : ''}`} 
                    id="related-tab"
                    data-mdb-toggle="tab" 
                    role="tab"
                    aria-selected="false"
                    aria-controls="related-content"
                    href={'#related-content'}
                    onClick={(e) => {this.props.handleKeywordTabChange(e)}}
                    ><b>Related</b>
                </a>
                </li>
        </ul>
    </div>
    );
  }


}

export default KeywordTabs;