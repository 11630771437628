class AuthStatusModel {
  constructor(name, email, userId, token, planId, renewDate, billingCycle, cancelDate, google) {
    this.name = name;
    this.email = email;
    this.userId = userId;
    this.token = token;
    this.planId = planId;
    this.renewDate = renewDate;
    this.billingCycle = billingCycle;
    this.cancelDate = cancelDate;
    this.google = google;
  }
}

export default AuthStatusModel;
