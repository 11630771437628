import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayAccountGateCheck } from '../../functions/DisplayAccountGateCheck';
import { GetAuthToken } from '../../functions/AuthStatus';

class MixLab extends Component {
  constructor(props){
    super(props);
    this._isMounted = false;
    this.state = {
      paginationTotal: 0,
      totalPDPs: 0,
      successfulScrapes: 0,
      totalErrors: 0,
      progressPercentage: 0,
      percentageHangingState: 75,
      scrapedSubmitted: false,
      showSpinner: false,
      codeWordAccepted: false,
      codeWord: '',
      scrapedPdpMapping: {},
      errorPdpMapping: [],
      apiResponseState: true
    };
  }
handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({codeWord: value});
}

displayAccountGate = () => {
  if (DisplayAccountGateCheck() && !this.props.displayGateTimer) {
    const displayGateTimer = setTimeout(() => {
      this.props.updateGateContent('createAccount');
      this.props.updateShowAccountGate(true);
    }, 1000);
    this.props.updateDisplayGateTimer(displayGateTimer);
  }
}

handleMixLabAuth = (event) => {
  if (event) event.preventDefault();
  if(this.state.codeWord === process.env.REACT_APP_CODE_WORD_AUTH) {
    this.setState({ codeWordAccepted: true });
    this.setState({ codeWord: ' '});
    console.log("Access Granted");
  }
}

handleSubmit = async ()  => {
  // if (e) e.preventDefault();
  if(!this.state.scrapedSubmitted) {
    console.log("Download Started");
    // e.persist();
    this.setState({ showSpinner: true });
    this.setState({ totalPDPs: 0 });
    this.setState({ successfulScrapes: 0 });
    this.setState({ totalErrors: 0 });
    this.setState({ progressPercentage: 0 });
    this.setState({ paginationTotal: 0 });
    this.setState({ scrapedPdpMapping: {} });
    this.setState({ scrapedSubmitted: true });
    this.setState({ apiResponseState: true });
    await axios.post(process.env.REACT_APP_MIX_LAB_API + "/scrapePLPs").then(response => {
        this.setState({ paginationTotal: response.data.totalPages });
        console.log(this.state.paginationTotal);
    }).catch(err => {
        console.log(err);
    });

    if(this.state.paginationTotal > 0) {
      for (var i = 0; i <= this.state.paginationTotal; i++) {
        console.log(i);
        if(this.state.apiResponseState) {
          if(i === this.state.paginationTotal) {
            this.setState({ progressPercentage: 100 });
            this.handleCreateCSVObjects(this.state.scrapedPdpMapping);
          }
          else {
            await this.handleScrapePDPs();
            // this.setState({ showSpinner: false });
            this.increaseProgressBar();
          }
        } else{
          break;
        }
      }
    }
  }
}

handleScrapePDPs = async () => {
  let cancelToken;
  cancelToken = axios.CancelToken.source();
  return axios.post(process.env.REACT_APP_MIX_LAB_API + "/scrapePage", { cancelToken: cancelToken?.token } ).then(response => {
    let responseArray = response.data;
    console.log(responseArray);
    if(responseArray?.api_response === "undefined") {
      console.log("api return undefined");
      this.setState({ apiResponseState: false });
      this.setState({ showSpinner: true });
      this.setState({ scrapedSubmitted: false });
      this.handleSubmit();
    } else {
      this.setState({ apiResponseState: true });
      this.setState({ totalPDPs: response.data.totalPdps });
      this.setState({ successfulScrapes: response.data.pdps.length });
      this.setState({ totalErrors: response.data.totalErrors });
      this.setState({ scrapedPdpMapping: responseArray });
      this.setState({ errorPdpMapping: response.data.errorPages });
      this.setState({ showSpinner: false });
    }
  }).catch(err => {
    console.log(err);
  })
}

handleCreateCSVObjects = (responseArray) => {
  let pdpMapping = [];
  let pdpVariantMapping = [];
  if(responseArray !== undefined)
  {
  for (var i = 0; i < responseArray?.pdps.length; i++) {
    const itemObj = {
      item_number: responseArray.pdps[i].item_number,
      url: responseArray.pdps[i].url,
      name: responseArray.pdps[i].name.replace(/,/g, ''),
      discount: responseArray.pdps[i].discount,
      discount_percentage: responseArray.pdps[i].discount_percentage,
      brand: responseArray.pdps[i].brand,
      offerCount: responseArray.pdps[i].offerCount,
      sku: responseArray.pdps[i].sku,
      gtin12: responseArray.pdps[i].gtin12,
      lowPrice: responseArray.pdps[i].lowPrice,
      highPrice: responseArray.pdps[i].highPrice,
      compound: responseArray.pdps[i].compound,
      availabilty: responseArray.pdps[i].availabilty,
      image: responseArray.pdps[i].image
    }

    if(responseArray.pdps[i].all_variants.length > 0) {
      var variantName = responseArray.pdps[i].all_variants[0].name
      var variantItemNum = responseArray.pdps[i].all_variants[0].item_number;
      if(responseArray.pdps[i].all_variants[0].variants.length > 0) {
    // eslint-disable-next-line
      responseArray.pdps[i].all_variants[0].variants.map(obj => {
        var shipping;
        var parsePrice;

        if(obj?.price !== null)
          parsePrice = obj?.price.replace(/[^0-9.]/g, "");
        // var parsedTotal = parseFloat(parsePrice);
        if(parsePrice >= 49) { shipping = 'True'; } else { shipping = 'False'; }
        const variantObj = {
            sku: obj.sku,
            item_number: variantItemNum,
            url: obj.url,
            name: variantName.replace(/,/g, ''),
            price: obj?.price,
            free_shipping: shipping || 'N/A',
            variant_one_name: '',
            variant_one_value: '',
            variant_two_name: '',
            variant_two_value: '',
            variant_three_name: '',
            variant_three_value: ''

        }
        for (var i = 0; i < obj.variant_types.length; i++) {
          if(i === 0)
          {
            variantObj.variant_one_name = obj.variant_types[0].type;
            variantObj.variant_one_value = obj.variant_types[0].value.replace(/,/g, '');
          }
          else if(i === 1)
          {
            variantObj.variant_two_name = obj.variant_types[1].type;
            variantObj.variant_two_value = obj.variant_types[1].value.replace(/,/g, '');
          }
          else if(i === 2)
          {
            variantObj.variant_three_name = obj.variant_types[2].type;
            variantObj.variant_three_value = obj.variant_types[2].value.replace(/,/g, '');
          }

        }
        pdpVariantMapping.push(variantObj);
        return variantObj;
      });

    }
      //   var parsePrice = obj.variant.mainObj.price.replace(/[^0-9.]/g, "")
      //   // var parsedTotal = parseFloat(parsePrice);
      //
      //   if(parsePrice >= 49) { shipping = 'True'; } else { shipping = 'False'; }
      //   const variantObj = {
      //       sku: obj.variant.mainObj.sku,
      //       item_number: obj.variant.mainObj.item_number,
      //       url: obj.variant.mainObj.canonical,
      //       name: obj.variant.name.replace(/,/g, ''),
      //       price: obj.variant.mainObj.price,
      //       free_shipping: shipping || 'N/A',
      //       variant_one_name: '',
      //       variant_one_value: '',
      //       variant_two_name: '',
      //       variant_two_value: '',
      //       variant_three_name: '',
      //       variant_three_value: ''
      //
      //   }
      //   for (var i = 0; i < obj.variant_types.length; i++) {
      //     if(i === 0)
      //     {
      //       variantObj.variant_one_name = obj.variant_types[0].type;
      //       variantObj.variant_one_value = obj.variant_types[0].value.replace(/,/g, '');
      //     }
      //     else if(i === 1)
      //     {
      //       variantObj.variant_two_name = obj.variant_types[1].type;
      //       variantObj.variant_two_value = obj.variant_types[1].value.replace(/,/g, '');
      //     }
      //     else if(i === 2)
      //     {
      //       variantObj.variant_three_name = obj.variant_types[2].type;
      //       variantObj.variant_three_value = obj.variant_types[2].value.replace(/,/g, '');
      //     }
      //
      //   }
      //   pdpVariantMapping.push(variantObj);
      //   return variantObj;
      // });
    }
    pdpMapping.push(itemObj);
  }
    this.exportChewyPdps(pdpMapping);
    this.exportChewyVariantPdps(pdpVariantMapping);
  }
}

exportChewyVariantPdps = (pdpObjs) => {
  const variantFile = 'scraped_variants';
  const variantHeaders = {
    sku: 'sku',
    item_number: 'item number',
    url: 'url',
    name: 'name',
    price: 'price',
    free_shipping: 'free shipping',
    variant_one_name: 'variant one name',
    variant_one_value: 'variant one value',
    variant_two_name: 'variant two name',
    variant_two_value: 'variant two value',
    variant_three_name: 'variant three name',
    variant_three_value: 'variant three value'
  }
  let values = [...pdpObjs.values()];
  this.exportVariantCSVFile(variantHeaders, values , variantFile);

}

exportChewyPdps = (pdpObjs) => {
  const itemsFile = 'scraped_items';
  const itemHeaders = {
    item_number: 'item_number',
    url: 'url',
    name: 'name',
    discount: 'discount',
    discount_percentage: 'default variant discount',
    brand: 'brand',
    offerCount: 'offerCount',
    sku: 'sku',
    gtin12: 'gtin12',
    lowPrice: 'low price',
    highPrice: 'high price',
    compound: 'compound',
    availabilty: 'availabilty',
    image: 'image'
  }

  let values = [...pdpObjs.values()];

  this.exportItemCSVFile(itemHeaders, values, itemsFile);
}

exportItemCSVFile = (headers, items, fileName) => {
  if(headers) items.unshift(headers);
  const jsonObject = JSON.stringify(items);
  const csv = this.convertToCSV(jsonObject);
  const exportedFileName = fileName + '.csv' || 'export.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if(navigator.msSaveBlob)
  {
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if(link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  this.setState({ scrapedSubmitted: false });
}

exportVariantCSVFile = (headers, items, fileName) => {
  if(headers) items.unshift(headers);
  const jsonObject = JSON.stringify(items);
  const csv = this.convertVariantsToCSV(jsonObject);
  const exportedFileName = fileName + '.csv' || 'export.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if(navigator.msSaveBlob)
  {
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if(link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

convertToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ',';
      line += array[i][index];
    }
    str += line + '\r\n';
  }

  return str;
}

convertVariantsToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ',';
      line += array[i][index];
    }
    str += line + '\r\n';
  }

  return str;
}

increaseProgressBar = () => {
  var percentageScraped = (this.state.successfulScrapes / this.state.totalPDPs) * 100;
  let progressBarInterval;
  let randomMilliseconds = 500;
  const millisecondsArry = [4000, 7000, 10000];
  progressBarInterval = setInterval(() => {
    const randomNum = Math.floor(Math.random() * 5);
    randomMilliseconds = millisecondsArry[Math.floor(Math.random() * millisecondsArry.length)];
    this.setState({ progressPercentage: percentageScraped + randomNum });
    clearInterval(progressBarInterval);
  }, randomMilliseconds);
    // this.nudgeProgressBar(percentageScraped);
  }

render(){
  return (
    <div className="row mb-2">
      <div className="col-md-3 col-sm-4 col-xs-4 pb-2">
        <h5>MixLab</h5>
        {!this.state.codeWordAccepted ?
        <form>
          <div className="input-group pb-2">
            <input
              id="codeWord"
              type="text"
              className="form-control code-word-input"
              placeholder="Enter password"
              aria-label="Enter password"
              aria-describedby="Enter password"
              value={this.state.codeWord}
              onChange={this.handleInputChange}
              />
            <button
              className="btn btn-outline-primary btn-sm"
              type="submit"
              id="submitCodeWord"
              data-ripple-color="dark"
              onClick={(e) => this.handleMixLabAuth(e)}>
              Submit
            </button>
          </div>
        </form> : null}
      </div>
      <div className="col-sm-10 pb-2">
        {
          GetAuthToken() && this.state.codeWordAccepted
          ?  <button
          className="btn btn-outline-primary mb-2"
          type="submit"
          id="submitChewyDownload"
          data-ripple-color="dark"
          onClick={(e) => this.handleSubmit()}
          >Scrape Chewy
        </button> : null
      }
        <br></br>
        {this.state.showSpinner
           ? <div className="col-sm-12 text-left mt-2 mb-2">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> : null}

        {this.state.paginationTotal > 0 && !this.state.showSpinner
          ?  <div className="row">
            <span className="mr-2">Total PLPs: {this.state.paginationTotal}</span>
            <span className="mr-2">Total PDPs: {this.state.totalPDPs}</span>
            <span className="mr-2">Scraped PDPs: {this.state.successfulScrapes}</span>
            <span
              className="mr-2 total-errors-btn"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne">
              Total PDP Errors: {this.state.totalErrors}
            </span>

          <div id="accordionError">
            <div className="card">
              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionError">
                <div className="card-body">
                  <ul className="meta-list">
                    {this.state.errorPdpMapping.map((url, index) => {
                      return <li key={index}>
                        <a href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link">{url}</a>
                      </li>
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="align-items-center">
          <div className="mixlab-bar progress mt-1 mb-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: this.state.progressPercentage + '%' }}
                  aria-valuenow={this.state.progressPercentage}
                  aria-valuemin="0"
                  aria-valuemax="100">
                </div>
              </div>
            </div>
          </div> : null
        }
      </div>

    </div>
  );
}

componentDidMount() {
  this.displayAccountGate();
}

componentWillUnmount() {
}

}

const mapStateToProps = state => {
    return {
      showAccountGate: state.showAccountGate,
      displayGateTimer: state.displayGateTimer
    };
  };

const mapDispatchToProps = dispatch => {
    return {
      updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
      updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate }),
      updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),

    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MixLab));
