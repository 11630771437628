import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import { ResetPasswordSendEmailApi } from '../functions/ResetPasswordSendEmailApi';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';

class ResetPasswordEmailPage extends Component {
  constructor(props) {
    super();
		this.state = {
      email: '',
			isEmailAddressValid: true,
			emailAddressErrorMsg: '',
			emailAddressErrorMsgOptions: {
				blank: 'Please enter your email address.',
				isRegistered: 'This email is already registered. Please sign in',
				invalid:'Invalid email, please double-check your information and try again.'
      },
      resetPasswordSettingsParams: '',
      isDisplayResendEmail: false,
      toggleCheckMark: false
		};
  }

  handleEmailInputChange = (event) => {
    const value = event.target.value;
    this.setState({ email: value });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const isEmailVaild = this.validateEmail();
		if (isEmailVaild) this.resetPassword();
	}

	validateEmail = () => {
		let isValid = false;
		if (this.state.email !== '') {
			const regex = new RegExp(/\S+@\S+\.\S+/, 'i');
			regex.test(this.state.email)
				? isValid = true
				: this.setState({emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.invalid})
		} else {
			this.setState({emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.blank});
		}
		this.setState({isEmailAddressValid: isValid});
		return isValid;
	}

	resetPassword = () => {
		ResetPasswordSendEmailApi(this.state.email, this.state.resetPasswordSettingsParams).then(res => {
			if (res && res.status === 200) this.setState({ isDisplayResendEmail: true });
		});
  }
  
  resendResetPasswordLink = () => {
    ResetPasswordSendEmailApi(this.state.email, this.state.resetPasswordSettingsParams).then(res => {
			if (res && res.status === 200) this.setState({ toggleCheckMark: true });
		});
  }

  render() {
    return (
			<div className="page-background1">
        <Helmet>
          <meta name="description" content=""/>
          <title>SEO Tools | Sandbox</title>
        </Helmet>
        <Header />
        <section className="container-fluid page-content-padding">
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={'/'}>Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Reset Password Request</li>
                  </ol>
                </nav>
              </div>
              {
                this.state.isDisplayResendEmail
                  ? <div className="col-sm-12">
                      <h3 className="mt-5 text-center">Go click the link in your email</h3>
					            <p className="text-center">The email should arrive soon. If you don't see it check your spam, or request a new send below.</p>
                      <div className="text-center pt-5 pb-5">
                        <button
                          className="btn btn-dark"
                          type="submit"
                          id="resendMagicLink"
                          data-ripple-color="dark"
                          onClick={()=> {this.resendResetPasswordLink()}}
                          disabled={this.state.toggleCheckMark}>
                          {
                            this.state.toggleCheckMark
                              ? <i className="fas fa-check"></i>
                              : <span>resend email</span>
                          }
                        </button>
                        {
                          this.state.toggleCheckMark
                            ? <div className="pt-5">
                                <p>Stil having issues?</p>
                                <p>Try adding no-reply@sandboxweb.io to your trusted list of senders, contacts or address book, or contact us for help at info@sandboxweb.io</p>
                              </div>
                            : null
                        }
                      </div>
                    </div>
                  : <div className="col-sm-12">
                      <h3 className="text-center">Reset Password</h3>
					            <p className="text-center">Enter the email address you signed up with, and we'll send you a link to reset your password.</p>
                      <form onSubmit={this.handleSubmit} noValidate>
                        <div className="row justify-content-center">
                          <div className="col-sm-12 col-md-6">
                            <div className="pb-3">
                              <label htmlFor="resetPasswordEmail">Email</label>
                              <input
                                type="email"
                                id="resetPasswordEmail"
                                className={`form-control ${!this.state.isEmailAddressValid ? 'error' : ''}`}
                                value={this.state.email}
                                onChange={this.handleEmailInputChange} />
                              <label className="error">
                                <span>
                                  {
                                    !this.state.isEmailAddressValid
                                      ? this.state.emailAddressErrorMsg
                                      : null
                                  }
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="pt-3 pb-3 text-center">
                          <button
                            className="btn btn-dark mr-3"
                            type="submit"
                            data-ripple-color="dark"
                            onClick={(e)=> {this.handleSubmit(e)}}>
                            submit
                          </button>
                        </div>
                      </form>
                    </div>
              }
            </div>
          </div>
        </section>
        <Footer />
			</div>
    );
  }
  
  componentDidMount() {
    this.setState({ resetPasswordSettingsParams: this.props.resetPaswordLinkSettingsParams ? this.props.resetPaswordLinkSettingsParams : { originUrl: '/' } })
  }
}

const mapStateToProps = state => {
  return {
		resetPaswordLinkSettingsParams: state.resetPaswordLinkSettingsParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordEmailPage);
