import React, { Component } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

class KeywordToolRedirectPage extends Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  goToPricingPage = () => {
    this.props.history.push({ pathname: '/pricing'})
  }

render() {
  return (
      <div className="serp-psi-page-bkg">
        <Header />
        <section className="container-fluid shadow-2-strong background1 page-content-padding">
          <div className="container">
            <div className="row pt-4">
              <div className="col-sm-12 pb-5 ">
                <h5 className="mb-0">
                  Thank you for your interest in our GPT3 keyword tool. With unexpected demand, we are now offering free access by invite only.
                  To request access, please email us info@sandboxweb.io with "GPT3" in the subject line.
                </h5>
                <h5>
                  To use this tool today, please see our <u onClick={() => this.goToPricingPage()}>pricing</u> page here.
                </h5>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
  );
};

}
export default KeywordToolRedirectPage;
