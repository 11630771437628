import React, { Component } from 'react';

class SerpPsiExportToolTipDisabled extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

  render() {
    return (
        <i
          className={`${this.props.plan > 1 ? 'fas fa-file-download fa-2x download-btn' : 'fas fa-lock export-lock' }`}
          id="tool-tip-export-psi-disabled"
          data-toggle="tooltip"
          data-placement="top"
          data-trigger='manual'
          title={`${this.props.plan > 1 ? "You can export once all results are loaded (30-45 seconds)" : "Paid users can export once all results are loaded (30-45 seconds)" }`}
          >
        </i>
    );
  }
}

export default SerpPsiExportToolTipDisabled;
