import React, { Component, Suspense } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { connect } from 'react-redux';
// import { Tooltip } from 'mdb-ui-kit';
import { DisplayAccountGateCheck } from '../../functions/DisplayAccountGateCheck';
import LOCALSTORAGE from '../../constants/LocalStorage';
import COUNTRIES from '../../constants/Countries';
import { GetAuthUserId, GetAuthPlanId, GetAuthToken } from '../../functions/AuthStatus';
import { withRouter } from 'react-router-dom';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { updateSignedInStatusSubject } from '../../observables/SignedInStatus';
import { AuthTokenStatus } from '../../functions/AuthTokenStatusApi';
import { RemainingKwtUsageStatus } from '../../functions/RemainingKwtUsageStatus';
import { KwtRateLimitingApi } from '../../functions/KwtRateLimitingApi';
import { IpRateLimitingApi } from '../../functions/IpRateLimitingApi';
import { MediaMatcher } from '../../functions/MediaMatcher';
import queryString from 'query-string'
import IdeaExportToolTip from './IdeaExportToolTip';

const IdeaCard = React.lazy(() => import("./IdeaCard"));

class ProgrammaticSeoTool extends Component {
  constructor(props){
    super(props);
    this._isMounted = false;
    this.state = {
      searchTerm: '',
      ideasRow: [],
      resultsReturned: true,
      updateCanViewContentFilterAlert: false,
      viewFilteredContent: true,
      searchButtonSubmitted: false,
      max_tokens: '',
      dectectedLanguage: '',
      contentFilterRank: 0,
      temperature: 0.7,
      temperatureInput: 0.7,
      searchTermForGaEvent: '',
      running: false,
      showOnboardingMessage: false,
      showCreateAccountMessage: false,
      showRateLimitAlert: false,
      showIdeaContainer: false,
      selectedIdeaSize: 5,
      ideaSizeArray: [5, 10, 15, 20],
      currentAlgorithm: "programmatic",
      isQueryParam: false,
      refreshCards: false,
      selectedType: "Programmatic",
      ideaCardResults: [],
      algorithms: ["programmatic"],
      count: 0,
      placeholderText: [
        "music streaming service", 
        "remote patient monitoring", 
        "raw dog food", 
        "fitness brand", 
        "bookstore"],
      deletingText: false,
      placeholderValue: 0,
      programmaticSeoInput: null, 
      progressPercentage: 0,
      percentageHangingState: 80,
      gatheringResults: ""
    };
  }

handleInputChange = (event) => {
  const value = event.target.value;
  if(value.length >= 175) {
    event.preventDefault();
    alert("only 175 max characters allowed");
  } else {
    this.setState({searchTerm: value});
  }
}

handleSubmit = async (event) => {
  if (event) event.preventDefault();
  if(this.state.searchButtonSubmitted)
    return;

  if(this.state.ideaCardResults.length > 0)
    this.handleClearResults();

  if(this.state.searchTerm !== '') {
    if (GetAuthUserId()) {
        // if(GetAuthPlanId() === 1) { //if we reach gpt3 api limit.
        //   this.linkToKeywordRedirectPage();
        // } else {
          // if(!this.state.rateLimitPerHourHit) {
          //   if(!this.state.rateLimitPerMinuteHit) {
              const keywordsLeft = await RemainingKwtUsageStatus();
              this.isRateLimitReached().then(isLimitReached => {
                if(!isLimitReached && keywordsLeft.kwtSearchesLeft > 0) {
                  this.setState({ showIdeaContainer: true });
                  this.setState({ resultsReturned: false });
                  this.setState({ searchButtonSubmitted: true });
                  this.setState({ showOnboardingMessage: true });
                  this.setState({ searchTermForGaEvent: this.state.searchTerm });
                  this.setState({ progressPercentage: 0 });
                  this.setState({gatheringResults: ""});

                  ReactGA.event({
                    category: 'Programmatic Seo Ideas Tool - ' + this.state.currentAlgorithm,
                    action: 'Idea Submitted',
                    label: this.state.searchTerm
                  });

                  this.handleOpenAIRequest(this.state.searchTerm);
                } else {
                  this.updateKeywordRateLimit(null, null).then(res => {});
                  if(GetAuthPlanId() === 1) {
                    this.linkToFreeTrialPage();
                  } else {
                    this.setState({ showRateLimitAlert: true });
                  }
                }
              });
    } else {
      this.setState({ showCreateAccountMessage: true });
    }
  }
}

handleOpenAIRequest = async (term) => {
    this.setState({ resultsReturned: false });
    const id = GetAuthUserId();
    let parsedTemp = parseFloat(this.state.temperature);

    let postObj = {
      userId: id,
      term: term,
      temp: parsedTemp,
      size: "",
      algorithm: this.state.currentAlgorithm,
    }

    if(this.props.remainingKwtSearches < this.state.selectedIdeaSize) 
      postObj.size = this.props.remainingKwtSearches;
    else 
      postObj.size = this.state.selectedIdeaSize;
    
    if(parsedTemp > 1) 
      postObj.temp = 1;
    else if(parsedTemp < 0) 
      postObj.temp = 0;
    

    this.setState({ gatheringResults: "Idea" });
    this.increaseProgressBar();
    await axios.post(process.env.REACT_APP_OPEN_AI_API, postObj)
        .then(response => {
        console.log('====================================');
        console.log(response);
        console.log('====================================');
        let ideaResultsMapping = this.state.ideaCardResults;

        response.data[0].res.forEach(idea => {
          if(idea.length > 2) {
            if(!ideaResultsMapping.includes(idea))
              ideaResultsMapping.push(idea);
          }

        });

        this.increaseProgressBar();
        if(this.state.progressPercentage < 100) {
            this.setState({progressPercentage: 100 });
        }

        this.updateKeywordRateLimit(this.state.searchTerm, ideaResultsMapping.length).then(isLimitReached => {});

        if(GetAuthPlanId() === 1)
            this.handleNonProPlanLimiting(ideaResultsMapping);

        this.setState({ resultsReturned: true });
        this.setState({ searchButtonSubmitted: false });
      }).catch(err => {
        console.log(err);
      });
}

handleClearResults = () => {
    this.setState({ ideaCardResults: [] });
    this.setState({ ideaRow: [] });
    this.setState({ showOnboardingMessage: false });
    this.setState({ showIdeaContainer: false });
    this.setState({ searchButtonSubmitted: false });
    // this.setState({ searchTerm: ''});
    this.setState({ searchTermForGaEvent: "" });
    this.setState({ gatheringResults: "" });
}

//----------filter functions----------

handleKeywordLengthFilter = (array) => {
  var suggestions = [];
  this.state.keywordRow.filter(function(word) {
      var keywordLengthTotal = 0;
      for (var i = 0; i < word.length; i++) {
        suggestions.push(word[i].keyword.length);
      }
      suggestions.forEach(length => {
        keywordLengthTotal += length;
      })
      var avg = keywordLengthTotal / suggestions.length;
        var checkLength = avg * 2;
        for (var r = 0; r < array[0].length; r++) {
            if(array[0][r].keyword.length >= checkLength){
              array[0].splice(r, 1);
            }
        }
      return array; 
    });

}

handleContentFilter = (rating) => {
  let rank = rating.reduce((a, b) => Math.max(a, b), -Infinity);
  
    if(rank === 0) {
      this.setState({ contentFilterRank: 0 });
      this.setState({ updateCanViewContentFilterAlert: false });
      this.setState({ viewFilteredContent: true });
    } else if(rank === 1) {
      this.setState({ contentFilterRank: 1 });
      this.setState({ updateCanViewContentFilterAlert: true });
      this.setState({ viewFilteredContent: true });
      this.setState({ showOverlay: true });
      // this.setState({ viewFilteredContent: false });
    }
    else if(rank === 2){
      this.setState({ contentFilterRank: 2 });
      this.setState({ updateCanViewContentFilterAlert: true });
      this.setState({ viewFilteredContent: true });
      this.setState({ showOverlay: true });
      // this.setState({ viewFilteredContent: false});
    }
}

viewResponse = (event) => {
    event.preventDefault();
    this.setState({ updateCanViewContentFilterAlert: false });
    this.setState({ showOverlay: false });
}

//----------rate limit functions----------
handleNonProPlanLimiting = (array) => {
  var totalKeywords = 0;
  for (var i = 0; i < array.length; i++) {
    totalKeywords += array[i].length;
    if(totalKeywords >= 21) { //21 includes searched keyword
        // localStorage.setItem(LOCALSTORAGE.ksr, true);
      if(totalKeywords === 23) {
        array[i].splice(1, i);
      }
      else if(totalKeywords === 22) {
        if(array[i].length === 3) {
          array[i].splice(2, i);
        } else if(array[i].length === 2) {
          array[i].splice(1, i);
        }
      }
    }
  }
}

isRateLimitReached = async (searchTerm) => {
  const response = await IpRateLimitingApi();
  const rateLimitResponseMapping = {
    "SUCCESS": () => false, // * Daily limit not reached
    "FAILURE": () => true, // * Daily limit reached
    default: () => false // * default to daily limit not reached if api fails
  };
  return await response && response.status && rateLimitResponseMapping[response.status]
    ? rateLimitResponseMapping[response.status]()
    : rateLimitResponseMapping['default']();

}

updateKeywordRateLimit = async (searchTerm, keywords) => {
  const response = await KwtRateLimitingApi(searchTerm, keywords);
  const rateLimitResponseMapping = {
    200: () => false, // * Daily limit not reached
    429: () => true, // * Daily limit reached
    default: () => false // * default to daily limit not reached if api fails
  };
  this.handleRemainingKwtSearches();
  return await response && response.status && rateLimitResponseMapping[response.status]
    ? rateLimitResponseMapping[response.status]()
    : rateLimitResponseMapping['default']();

}

handleRemainingKwtSearches = async () => {
  const response = await RemainingKwtUsageStatus();
  if(response) {
    if(response.kwtSearchesLeft === null) {
      response.kwtSearchesLeft = 0;
      this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
    } else {
      this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
    }
  }
}

handleUserTokenStatus = async () => {
  const response = await AuthTokenStatus(JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).token);
  if(response?.data.status === "SUCCESS") {
    this.handleRemainingKwtSearches();
  } else {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel('', '', null, null, 1, null, 1, null)));
      localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, false);
      localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
    }
    updateSignedInStatusSubject.update(false);
    this.props.updateGateContent('signIn');
    this.props.updateShowAccountGate(true);
  }
}

//----------modal functions----------
handleCategoryState = (algorithm) => {
   this.setState({ currentAlgorithm: algorithm });
}

handleShowKeywordSettingsModal = () => {
  this.props.updateShowKeywordSettingsModal(!this.props.showKeywordSettingsModal);
}

temperatureInput = (event) => {
    this.setState({ temperatureInput: event.currentTarget.value });
    this.setState({ temperature: this.state.temperatureInput});
}

temperatureRange = (event) => {
  if(event && this.state.showDeduplicationAlert)
    this.setState({ showDeduplicationAlert: false });

    this.setState({ temperature: event.currentTarget.value });
}

//----------redirect functions----------
linkToPricingPage = () => {
  ReactGA.event({ category: 'rate limiting - wst', action: 'click on upgrade', label: GetAuthUserId() });
  this.props.history.push({ pathname: '/pricing' });
}

linkToFreeTrialPage = () => {
  // ReactGA.event({ category: 'rate limiting - wst', action: 'click on upgrade', label: GetAuthUserId() });
  this.props.history.push({ pathname: '/free-trial-page'})
}

//----------alert functions----------
displayAccountGate = () => {
  if (DisplayAccountGateCheck() && !this.props.displayGateTimer) {
    const displayGateTimer = setTimeout(() => {
      if(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)) {
          this.props.updateGateContent('signIn');
      } else {
          this.props.updateGateContent('createAccount');
      }
      this.props.updateShowAccountGate(true);
    }, 1000);
    this.props.updateDisplayGateTimer(displayGateTimer);
  }
}

closeAlert = () => { //closes filter alert
  this.setState({ updateCanViewContentFilterAlert: false });
  this.setState({ searchButtonSubmitted: false });
  this.setState({ showOverlay: false });
  setTimeout(() => {
    this.setState({ resultsReturned: true });
  },1000);


}

closeCreateAccountAlert = () => {
  this.setState({ showCreateAccountMessage: false });
}

closeRateLimitAlert = () => {
  this.setState({ showRateLimitAlert: false });
}

getFlagIcon = () => {
  var jsonObj;
  if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined
  && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null
  && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
     jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
  }
      //<span> {langCode.toLowerCase()} <i className={`${flagCode} flag`}></i></span>
  let flagCode = 'us';
  // let langCode = 'english';
  if (jsonObj) {
    const findCountryCode = COUNTRIES.find(country => country.country_name === jsonObj.country);
    // const findLanguageCode = LANGUAGES.find(lang => lang.language_code === jsonObj.language);

    flagCode = findCountryCode
      ? findCountryCode.country_code
      : jsonObj.country_code
        ? jsonObj.country_code
        : 'us';
    // langCode = findLanguageCode
    //   ? findLanguageCode.language_name
    //     : jsonObj.language
    //       ? jsonObj.language
    //       : 'english';
  }
  return flagCode === 'aq'
    ? <img
      src={'https://www.countryflags.io/aq/flat/16.png'}
      style={{marginRight: '.5em'}}
      alt={flagCode}/>
    : <span> <i className={`${flagCode} flag`}></i></span>

}

handleRetryScrollToTop = (e) => {
    window.scrollTo(0, 0);
    var id = e.target.getAttribute('aria-describedby');
    var toolTip = document.getElementById(id);
    if(toolTip) toolTip.remove();
}

parseQueryString = () => {
  const queryParams = queryString.parse(this.props.location.search);
  if (this._isMounted && queryParams.searchTerm) {
    setTimeout(() => {
       this.setState({ currentAlgorithm: queryParams.algorithm });
     }, 100);
     // this.setState({ currentAlgorithm: queryParams.algorithm });
    this.setState({ searchTerm: queryParams.searchTerm }, () => this.handleSubmit(null));
    this.setState({ isQueryParam: true });
  }
}

copyToClipboard = async () => {
  if(navigator.clipboard) {
    let copyUrl = window.location + "?searchTerm=" + this.state.searchTerm;
    navigator.clipboard.writeText(copyUrl).then(() => {
      console.log("url copied! " + copyUrl);
    }).catch(() => {
      console.log("copy url failed");
    });
  }
}

increaseProgressBar = () => {
  let multiplier = 1;
  if(this.state.selectedIdeaSize === this.state.ideaSizeArray[0]) {
    multiplier = 7;
  } else if(this.state.selectedIdeaSize === this.state.ideaSizeArray[1]) {
    multiplier = 6;
  } else if(this.state.selectedIdeaSize === this.state.ideaSizeArray[2]) {
    multiplier = 5;
  } else if(this.state.selectedIdeaSize === this.state.ideaSizeArray[3]) {
    multiplier = 4;
  }

  let progressBarInterval;
  let randomMilliseconds = 500;
  const millisecondsArry = [4000, 7000, 10000];

  if (this.state.progressPercentage <= this.state.percentageHangingState) {
    progressBarInterval = setInterval(() => {
      const randomNum = Math.floor(Math.random() * multiplier);
      // const randomNum = 25;
      randomMilliseconds = millisecondsArry[Math.floor(Math.random() * millisecondsArry.length)];
      if (this._isMounted){
        if(this.state.progressPercentage >= this.state.percentageHangingState)
          this.setState({progressPercentage: 99 });
        else
           this.setState({progressPercentage: this.state.progressPercentage + randomNum });
      }
      if (this.state.progressPercentage >= this.state.percentageHangingState) clearInterval(progressBarInterval);
    }, randomMilliseconds);
  }

    if(this.state.progressPercentage >= 99) {
      setTimeout(() => {
        this.setState({progressPercentage: 100 });
    }, 1000);
    } 
}

exportCSVFile = () => {
  var fileName = "ideas";
  const jsonObject = JSON.stringify(this.state.ideaCardResults);
  const csv = this.convertToCSV(jsonObject);
  const exportedFileName = fileName + '.csv' || 'export.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  // const blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8,%EF%BB%BF'});

  if(navigator.msSaveBlob)
  {
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if(link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

convertToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    line += array[i];
    str += line + '\r\n';
  }
  return str;
}

render() {
    return (
    <div className="row mb-2">
        <div className="col-sm-12 p-2">
            <form>
              <div className="input-group pb-2">
                <input
                  id="ProgrammaticSeoSearch"
                  type="text"
                  className="form-control"
                  placeholder={""}
                  aria-label="Search Term"
                  aria-describedby="Search Term"
                  value={this.state.searchTerm}
                  onChange={this.handleInputChange}/>
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  id="submitIdeaSearch"
                  data-ripple-color="dark"
                  onClick={(e) => this.handleSubmit(e)}>
                  Search
                </button>
              </div>
              <div className="row">
                {
                  GetAuthToken()
                    ? <div className="col-sm-12 pb-3 kw-settings">
                      <div className="float-left justify-content-between">
                        <span>
                          Use
                          <select
                            className="ml-1 mr-0 pt-1 pr-1 pb-1 pl-1"
                            onChange={(e) => this.setState({ selectedIdeaSize: Number(e.target.value) })}
                            value={this.state.selectedIdeaSize}>
                            {
                              this.state.ideaSizeArray.map((i, index) => {
                                return <option
                                        key={`sample-size${index}`}
                                        value={i}>
                                        {i}
                                      </option>
                              })
                            }
                          </select>
                        </span>
                        <span className="pt-1 pl-1 serp-psi-search-flag-copy body-small kw-settings">of your {this.props.remainingKwtSearches} Keywords</span>
                      </div>
                        <span
                            className="pl-1 pt-1 serp-psi-search-flag-copy body-small float-right">
                            { this.getFlagIcon() }
                            <u
                              className="align-middle"
                              onClick={() => this.handleShowKeywordSettingsModal()}><strong>settings</strong></u>
                      </span>
                    </div> : null
                }
              </div>
            </form>
            <div className="row pl-2 pr-2 pb-5 pt-3">
                { 
                    this.state.showIdeaContainer ?
                        <div className="col-sm-12 keyword-container pt-0">
                            <div className="keyword-panel">
                                <div className="col-sm-12 keyword-inner-panel text-center">
                                {
                                !this.state.refreshCards && this.state.resultsReturned ?
                                    <ul className="keyword-list">
                                      <div className="col-sm-12 p-0 pb-0">
                                            <div className="text-right">
                                              <button
                                                onClick={() => this.exportCSVFile()}
                                                className="btn pr-0 google-serp-export">
                                                <IdeaExportToolTip />

                                              </button>
                                            </div>
                                      </div>
                                    <div id="idea-content">       
                                        {
                                            this.state.ideaCardResults.map((idea, index) => {
                                            return <li key={index}>
                                                <Suspense fallback={<div></div>}>
                                                    <IdeaCard
                                                    idea={idea}
                                                    country={this.props.serpCountry}
                                                    />
                                                </Suspense>
                                            </li>
                                            })
                                        }
                                    </div>
                                    </ul> 
                                :  <div className="d-flex justify-content-center align-middle col-sm-12 p-0">
                                        <p className="kwt-powered mb-0">Gathering {this.state.gatheringResults} Results...</p>
                                    </div>}
                                </div>
                            </div>
                            { !this.state.resultsReturned && this.state.progressPercentage < 100 && !this.state.refreshCards
                            ? <div className="row d-flex justify-content-center">
                                <div className="col-sm-12 keyword-progress-container">
                                <div className="kp-bar">
                                    <div className="keyword-progress progress">
                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: this.state.progressPercentage + '%' }}
                                        aria-valuenow={this.state.progressPercentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100">
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div> : null}
                        </div> : null
                    }
            </div>
        </div>
    </div>

);
}

  listenToScroll = () => {
      const scrolled = window.pageYOffset;
      if(scrolled < 300)
      {
        if(document.querySelector(".warning-container-fixed")) {
          var alert = document.querySelector(".warning-container-fixed");
          alert.classList.remove("warning-container-fixed");
          alert.classList.add("warning-container");
        }
      }
      else if(scrolled >= 300 && scrolled < 1000)
      {
          if(document.querySelector('.warning-container')) {
            var alert2 = document.querySelector(".warning-container");
            alert2.classList.remove("warning-container");
            alert2.classList.add("warning-container-fixed");
        }
      }
  }

  customTypeWriter = () => {
    var speed = 75;
    var deleteSpeed = 10;
    var delay = 5;
    const input = this.state.programmaticSeoInput;
    if(!this._isMounted) {
      clearTimeout(this.customTypeWriter);
      return;
    };
    if(input !== document.activeElement && input?.value?.length <= 0) { //make sure input is not focused by user & there is no input value
      if(!this.state.deletingText 
        && this.state.placeholderValue !== this.state.placeholderText.length 
        && this.state.count < this.state.placeholderText[this.state.placeholderValue].length + delay) {
          input.placeholder += this.state.placeholderText[this.state.placeholderValue].charAt(this.state.count);
                    
          this.setState({count: this.state.count + 1});
          setTimeout(this.customTypeWriter, speed);
      } else {
        this.setState({ deletingText: true });                                    //is deleting text 
        if(this.state.deletingText && this.state.count > -1) {
          input.placeholder = this.state.placeholderText[this.state.placeholderValue].substring(0, this.state.count);
          this.setState({count: this.state.count - 1});
          
          setTimeout(this.customTypeWriter, deleteSpeed);
        } else if(this.state.count <= 0) {                                        //if placeholder text empty change to next placeholder in array 
          if(this.state.placeholderValue >= this.state.placeholderText.length) {
            this.setState({placeholderValue: 0});                                 //if placedholder is at the end restart from 0
          } else {
            this.setState({ placeholderValue: this.state.placeholderValue + 1 }); //otherwise continue with array
          }
          this.setState({ count: -10 });                                        //for a short delay before typinh next placeholder
          this.setState({ deletingText: false });
          setTimeout(this.customTypeWriter, speed);
        }
      }
    } else if(input === document.activeElement) { 
        if(input.placeholder.length > 0 ) //if input is active stop placeholder text 
          input.placeholder = ""; 
        if(this.state.count > 0)                            //reset placeholder count to start from 0 when unfocusing input
          this.setState({count: 0 });
        setTimeout(this.customTypeWriter, speed);
    } else {
        //restart
        if(input?.placeholder.length > 0 ) //make sure placeholder text is clear for reset
          input.placeholder = ""; 
        if(this.state.count > 0)                            //make sure count is at 0 for reset
          this.setState({count: 0 });
        setTimeout(this.customTypeWriter, speed);
    }
  }

  componentDidMount() {
      this._isMounted = true;

      let input = document.getElementById("ProgrammaticSeoSearch");
      this.setState({programmaticSeoInput: input });
      
      this.customTypeWriter();
      window.addEventListener('scroll', this.listenToScroll, {passive: true});

      if(!GetAuthUserId()) this.displayAccountGate();
      if(GetAuthToken()) this.handleUserTokenStatus();

      this.parseQueryString();

      if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined
      && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null
      && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
        var jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
        var settingsObj = {
          country: "",
          language: ""
        }
        if(jsonObj.country === "") {
            settingsObj.country = "United States";
          if(jsonObj.language === "")
            settingsObj.language = "en";

          localStorage.setItem(LOCALSTORAGE.keywordSettings, JSON.stringify(settingsObj));
        }
      }

    this.props.updateScreenSize(MediaMatcher());
    window.addEventListener("resize", () => {
      this.props.updateScreenSize(MediaMatcher());
    });
}

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.parseQueryString();
    }
  }

  componentWillUnmount() {
    window.addEventListener('scroll', this.listenToScroll);
    this._isMounted = false;
  }
}

const mapStateToProps = state => {
    return {
      showAccountGate: state.showAccountGate,
      displayGateTimer: state.displayGateTimer,
      showKeywordSettingsModal: state.showKeywordSettingsModal,
      remainingKwtSearches: state.remainingKwtSearches,
      screenSize: state.screenSize,
      settingsDeviceSelected: state.settingsDeviceSelected,
      serpDomain: state.serpDomain,
      serpCountry: state.serpCountry,
      serpLanguage: state.serpLanguage,

    };
  };

const mapDispatchToProps = dispatch => {
    return {
      updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
      updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate }),
      updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),
      updateScreenSize: (screenSize) => dispatch({ type: 'SCREEN_SIZE', value: screenSize }),
      updateRemainingKwtSearches: (remainingKwtSearches) => dispatch({ type: 'REMAINING_KWT_SEARCHES', value: remainingKwtSearches }),
      updateShowKeywordSettingsModal: (showKeywordSettingsModal) => dispatch({ type: 'CAN_VIEW_KEYWORD_SETTINGS_MODAL', value: showKeywordSettingsModal }),
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgrammaticSeoTool));
