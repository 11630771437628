export const LOCALSTORAGE = {
  isFirstSerpSearch: '__sandbox_is_first_serp_search_storage_v2__',
  isEmailSubscribed: '__sandbox_is_email_subscribed_storage_v2__',
  usrAuthStorage: '__sandbox_usr_auth__storage__',
  isFirstSpeedTest: '__sandbox_is_first_speed_test_storage_v1__',
  increaseSampleSizeAlert: '__sandbox_increase_sample_size_alert_storage__',
  keywordsPerMinute: '__sandbox_hit_max_keywords_per_minute',
  keywordsPerHour: '__sandbox_hit_max_keywords_per_hour',
  keywordPerMinuteArray: '__keywordMinArray__',
  keywordPerHourArray: '__keywordHourArray__',
  minuteValue: '__min_value__',
  hourValue: '__hour_value__',
  secondsValue: '__seconds_value__',
  firstKeywordSearch: '__keyword_first_search__',
  keywordSettings: '__keyword_settings'
};

export default LOCALSTORAGE;
