import ReactGA from 'react-ga';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { LighthouseScoreColors } from '../../functions/LighthouseScoreColors';
import MetricTabs from './MetricTabs';
import { ParseMillisecondsToSeconds } from '../../functions/ParseMillisecondsToSeconds';
import { updateViewAllMetricsSubject } from '../../observables/ViewAllMetrics';
import { updateMetricSelectedSubject } from '../../observables/MetricLabSelected';
import SESSIONSTORAGE from '../../constants/SessionStorage';

class PsiResults extends Component {
	_isMounted = false;
  constructor(props) {
		super(props);
		this._isMounted = false;
    this.state = {
			showProgressBar: true,
			progressPercentage: 0,
			percentageHangingState: 75,
			viewMetricsUrl: `https://developers.google.com/speed/pagespeed/insights/?`,
			psiResultsError: false,
			retryAtemptFailed: false,
			showMetrics: false,
			labMetrics: {},
			fieldMetrics: {},
			metricType: 'Lab',
			metric: 'psi',
			metricDisplayValue: '',
			metricScoreColorClass: '',
			filmstrip: [],
			lastMetricTypeSelected: 'Lab',
			lastMetricSelected: 'psi',
			lastMetricValue: '',
			lastMetricIcon: ''
    };
  }

  capitialFirstLetter = (s) => {
    return typeof s === 'string'
		  ? `${s.charAt(0).toUpperCase()}${s.slice(1)}`
			: '';
  }

  getPsiResults = (isRetry) => {
		const psiApiUrl = 'https://www.googleapis.com/pagespeedonline/v5/runPagespeed';
    const category = 'category=performance';
    const key = 'key=AIzaSyCAvgSx5TPEVJCXN0fGR4Zx9AFqu9AgZok';
    const strategy = `strategy=${this.props.settingsDeviceSelected}`;
		const url = `url=${this.props.url}`;
		const urlEncoded = `url=${encodeURIComponent(this.props.url)}`;
		const psiApi = `${psiApiUrl}?${key}&${urlEncoded}&${category}&${strategy}`;
		if (this._isMounted) this.setState({ viewMetricsUrl: this.state.viewMetricsUrl + url });
		this.increaseProgressBar();
		// axios.get('/json/DummyPsiResults.json', { timeout: 300000 })
		axios.get(psiApi, { timeout: 300000 })
    .then(res => {
      if (res && this._isMounted) {
				const { data: { lighthouseResult: { categories: { performance: { score }}}} } = res;
				const { data: { lighthouseResult: { audits: { 'screenshot-thumbnails': { details: { items }}}}}} = res;

				if (this._isMounted) {
					this.setState({ progressPercentage: 100 });
        	this.setState({ showProgressBar: false });
					this.setState({ metricDisplayValue: Math.round(score * 100) });
					this.setState({ metricScoreColorClass: LighthouseScoreColors(this.state.metricDisplayValue) });
					this.setLabMetrics(res);
					this.setFieldMetrics(res);
					this.setState({ filmstrip: items });
					this.props.flimstripMap.set(this.props.serpPosition, [...items].pop());
					this.props.onFlimstripData(this.props.serpPosition, [...items].pop());
					this.props.parentMetricDisplay(this.handleMetricsDisplay(this.props.selectedMetrics), this.state.psiResultsError);
					this.props.parentProgressBarDisplay(this.state.progressPercentage, this.state.showProgressBar);
				}
				this.props.updatePsiCounter(this.props.psiCounter + 1);
				this.mapPsiScoreToExportData();
				if (this.props.exportGoogleSerpData.size === this.props.psiCounter) {
					this.props.updateEnableSerpExportBtn(true);
					if (typeof sessionStorage !== 'undefined') {
						const canViewAllDataAlert = JSON.parse(sessionStorage.getItem(SESSIONSTORAGE.canViewAllDataAlert));
						if ((canViewAllDataAlert && !this.props.moreResultsClicked) ||
								(canViewAllDataAlert === null && !this.props.moreResultsClicked)) {
							if(!this.props.viewAllDataToggledOn)
								this.props.updateCanViewAllDataAlert(true);
						}
					}
				}
				if (isRetry) ReactGA.event({ category: 'speed comparison', action: 'click', label: 'retry_success' });
      }
    }).catch(error => {
			console.error('getPsiResults Error: ', error);
			if (this._isMounted) {
				this.setState({ progressPercentage: 0 });
				this.setState({ showProgressBar: false });
				this.setState({ psiResultsError: true });
			}
			this.props.updatePsiCounter(this.props.psiCounter + 1);
			if (this.props.exportGoogleSerpData.size === this.props.psiCounter) {
				this.props.updateEnableSerpExportBtn(true);
				if (typeof sessionStorage !== 'undefined') {
					if (typeof sessionStorage !== 'undefined') {
						const canViewAllDataAlert = JSON.parse(sessionStorage.getItem(SESSIONSTORAGE.canViewAllDataAlert));
						if ((canViewAllDataAlert && !this.props.moreResultsClicked) ||
								(canViewAllDataAlert === null && !this.props.moreResultsClicked)) {
							this.props.updateCanViewAllDataAlert(true);
						}
					}
				}
			}
			ReactGA.event({ category: 'speed comparison', action: 'PSI API Fail', label: this.props.url });
			if (isRetry) {
				ReactGA.event({ category: 'speed comparison', action: 'click', label: 'retry_fail' });
				if (this._isMounted) this.setState({ retryAtemptFailed: true });
			}
    })
	}

	mapPsiScoreToExportData = () => {
		if (this.props.exportGoogleSerpData.has(this.props.serpPosition)) {
			const exportData = this.props.exportGoogleSerpData.get(this.props.serpPosition);
			let techData = [];
			let schemaTypeData = [];
			var coreWebVitals = [];
			var webVitalsStatus = "";

			for (var i = 0; i < this.props.tech.length; i++) {
				techData.push(this.props.tech[i].status);
			}

			if(this.props.types !== "no types") {
				for (var x = 0; x < this.props.types.length; x++) {
					schemaTypeData.push(this.props.types[x]);
				}
			}

			Object.keys(this.state.fieldMetrics).forEach((key, index) => {
					if(this.state.fieldMetrics[key].label !== "fcp") {
							coreWebVitals.push(this.state.fieldMetrics[key]);
						}
			});

			if(coreWebVitals.length > 0) {
				const hasPassed = (value) => value.speedColor === "psi-score-pass";
				var passOrFail = coreWebVitals.every(hasPassed);
				if(passOrFail) {
					webVitalsStatus = "Pass";
				} else {
					webVitalsStatus = "Fail";
				}
			}

			exportData.wordCount = this.props.wordCount ? this.props.wordCount : 'N/A';
			exportData.titleTag = this.props.meta.title !== "" && this.props.meta.title !== undefined && this.props.meta.title !== "site has no title" ? JSON.stringify(this.props.meta.title.trim()) : 'N/A';
			exportData.metaDesc = this.props.meta.description !== "" && this.props.meta.description !== undefined && this.props.meta.description !== "site has no description!" ? JSON.stringify(this.props.meta.description.trim()) : 'N/A';
			exportData.h1 = this.props.meta.h1 !== "" && this.props.meta.h1 !== undefined && this.props.meta.h1 !== "missing h1 tag!" ? JSON.stringify(this.props.meta.h1.trim())  : 'N/A';
			exportData.canonical = this.props.meta.url !== "" && this.props.meta.url !== undefined && this.props.meta.url !== "missing canonical url!" ? this.props.meta.url.trim() : 'N/A';
			exportData.httpStatus = this.props.tech.length > 0 ? this.props.tech.length > 1 ? JSON.stringify(techData.join(', ')) : techData : 'N/A';
			exportData.schemaTypes = schemaTypeData.length > 0 ? schemaTypeData.length > 1 ? JSON.stringify(schemaTypeData.join(', ')) : schemaTypeData : 'N/A';
			exportData.cwv = coreWebVitals.length > 0 ? webVitalsStatus : "Not Enough Data"

			exportData.labPsi = this.state.metricDisplayValue;
			exportData.labFcp = this.state.labMetrics.fcp ? this.parseCommasLetters(this.state.labMetrics.fcp.displayValue) : 'N/A';
			exportData.labSi = this.state.labMetrics.si ? this.parseCommasLetters(this.state.labMetrics.si.displayValue) : 'N/A';
			exportData.labLcp = this.state.labMetrics.lcp ? this.parseCommasLetters(this.state.labMetrics.lcp.displayValue) : 'N/A';
			exportData.labTti = this.state.labMetrics.tti ? this.parseCommasLetters(this.state.labMetrics.tti.displayValue) : 'N/A';
			exportData.labTbt = this.state.labMetrics.tbt ? this.parseCommasLetters(this.state.labMetrics.tbt.displayValue) : 'N/A';
			exportData.labCls = this.state.labMetrics.cls ? this.parseCommasLetters(this.state.labMetrics.cls.displayValue) : 'N/A';

			exportData.fieldLcp = this.state.fieldMetrics.lcp ? this.parseCommasLetters(this.state.fieldMetrics.lcp.displayValue) : 'N/A';
			exportData.fieldFid = this.state.fieldMetrics.fid ? this.parseCommasLetters(this.state.fieldMetrics.fid.displayValue) : 'N/A';
			exportData.fieldCls = this.state.fieldMetrics.cls ? this.parseCommasLetters(this.state.fieldMetrics.cls.displayValue) : 'N/A';
			exportData.fieldFcp = this.state.fieldMetrics.fcp ? this.parseCommasLetters(this.state.fieldMetrics.fcp.displayValue) : 'N/A';

			this.props.exportGoogleSerpData.set(this.props.serpPosition, exportData);
		}
	}

	parseCommasLetters = (str) => {
		return str.replace(/,/g, '').replace(/[^.0-9]/g, '');
	}

  increaseProgressBar = () => {
    let progressBarInterval;
    let randomMilliseconds = 500;
	const millisecondsArry = [4000, 7000, 10000];

    if (this.state.progressPercentage <= this.state.percentageHangingState) {
      progressBarInterval = setInterval(() => {
        const randomNum = Math.floor(Math.random() * 5);
        randomMilliseconds = millisecondsArry[Math.floor(Math.random() * millisecondsArry.length)];
        if (this._isMounted){
					this.setState({progressPercentage: this.state.progressPercentage + randomNum });
					this.props.parentProgressBarDisplay(this.state.progressPercentage, this.state.showProgressBar);
				}
        if (this.state.progressPercentage >= this.state.percentageHangingState) clearInterval(progressBarInterval);
      }, randomMilliseconds);
    }
  }

	retryPsiResults = (event) => {
		event.preventDefault();
		if (this._isMounted) {
			this.setState({ progressPercentage: 0 });
			this.setState({ showProgressBar: true });
			this.setState({ psiResultsError: false });
			this.getPsiResults(true);
		}
	}

	gaEventViewMetrics = () => {
		ReactGA.event({ category: 'speed comparison', action: 'click', label: 'view metrics' });
	}

	setLabMetrics = (res) => {
		if (res && res.data && res.data.lighthouseResult && res.data.lighthouseResult.audits) {
			const { data: { lighthouseResult: { audits: {
				'first-contentful-paint': firstContentfulPaint,
				'speed-index': speedIndex,
				'largest-contentful-paint': largestContentfulPaint,
				'total-blocking-time': totalBlockingTime,
				'cumulative-layout-shift': cumulativeLayoutShift,
				interactive
			}}}} = res;

			const labMetrics = {
				psi: {
					label: 'psi',
					displayValue: this.state.metricDisplayValue,
					score: this.state.metricDisplayValue
				},
				fcp: {
					label: 'fcp',
					displayValue: firstContentfulPaint.displayValue,
					score: Math.round(firstContentfulPaint.score * 100)
				},
				si: {
					label: 'si',
					displayValue: speedIndex.displayValue,
					score: Math.round(speedIndex.score * 100)
				},
				lcp: {
					label: 'lcp',
					displayValue: largestContentfulPaint.displayValue,
					score: Math.round(largestContentfulPaint.score * 100)
				},
				tti: {
					label: 'tti',
					displayValue: interactive.displayValue,
					score: Math.round(interactive.score * 100)
				},
				tbt: {
					label: 'tbt',
					displayValue: totalBlockingTime.displayValue,
					score: Math.round(totalBlockingTime.score * 100)
				},
				cls: {
					label: 'cls',
					displayValue: cumulativeLayoutShift.displayValue,
					score: Math.round(cumulativeLayoutShift.score * 100)
				}
			};

			if (this._isMounted) this.setState({ labMetrics: labMetrics });
		}
	}

	setFieldMetrics = (res) => {
		if (res && res.data && res.data.loadingExperience && res.data.loadingExperience.metrics) {
			const { data: { loadingExperience: { metrics: {
				LARGEST_CONTENTFUL_PAINT_MS,
				FIRST_INPUT_DELAY_MS,
				CUMULATIVE_LAYOUT_SHIFT_SCORE,
				FIRST_CONTENTFUL_PAINT_MS
			}}}} = res;

			const speedColorMapping = {
				FAST: () => 'psi-score-pass',
				AVERAGE: () => 'psi-score-average',
				SLOW: () => 'psi-score-fail'
			};

			const fieldMetrics = {
				lcp: {
					label: 'lcp',
					displayValue: `${ParseMillisecondsToSeconds(LARGEST_CONTENTFUL_PAINT_MS?.percentile)} s`,
					speedColor: speedColorMapping[LARGEST_CONTENTFUL_PAINT_MS?.category] ? speedColorMapping[LARGEST_CONTENTFUL_PAINT_MS.category]() : ''
				},
				fid: {
					label: 'fid',
					displayValue: `${FIRST_INPUT_DELAY_MS?.percentile} ms`,
					speedColor: speedColorMapping[FIRST_INPUT_DELAY_MS?.category] ? speedColorMapping[FIRST_INPUT_DELAY_MS.category]() : ''
				},
				cls: {
					label: 'cls',
					displayValue: this.fieldRoundToTwoDecimal(CUMULATIVE_LAYOUT_SHIFT_SCORE?.percentile).toString(),
					speedColor: speedColorMapping[CUMULATIVE_LAYOUT_SHIFT_SCORE?.category] ? speedColorMapping[CUMULATIVE_LAYOUT_SHIFT_SCORE.category]() : ''
				},
				fcp: {
					label: 'fcp',
					displayValue: `${ParseMillisecondsToSeconds(FIRST_CONTENTFUL_PAINT_MS?.percentile)} s`,
					speedColor: speedColorMapping[FIRST_CONTENTFUL_PAINT_MS?.category] ? speedColorMapping[FIRST_CONTENTFUL_PAINT_MS.category]() : ''
				}
			};

			if (this._isMounted) this.setState({ fieldMetrics: fieldMetrics });
		}
	}

	fieldRoundToTwoDecimal = (num) => {
    return num % 1 !== 0
      ? num.toFixed(2)
      : num !== 0
        ? num/100
        : num.toString();
  }

	showMetrics = () => {
		this.gaEventViewMetrics();
		this.setState({ showMetrics: !this.state.showMetrics });
	}

	closeMetricView = (isShowMetrics) => {
		if (this._isMounted) {
			this.setState({ showMetrics: isShowMetrics });
		}
	}

	selectedMetricsScoreColor = (metricType) => {
		// TODO: Need to handle when data is not compleate EX: gogle psi still running maybe make function async
		const metricTypeMapping = {
			Lab: () => {
				return this.state.labMetrics[this.props.selectedMetrics.metric]
					? LighthouseScoreColors(this.state.labMetrics[this.props.selectedMetrics.metric].score)
					: this.state.metricScoreColorClass;
			},
			Field: () => {
				return this.state.fieldMetrics[this.props.selectedMetrics.metric]
					? this.state.fieldMetrics[this.props.selectedMetrics.metric].speedColor
					: this.state.metricScoreColorClass;
			}
		};
		if (metricTypeMapping[metricType]) return metricTypeMapping[metricType]();
	}

	selectedMetricsScore = (metricType) => {
		const metricTypeMapping = {
			Lab: () => {
				return this.state.labMetrics[this.props.selectedMetrics.metric]
					? this.state.labMetrics[this.props.selectedMetrics.metric].displayValue
					: this.state.metricDisplayValue;
			},
			Field: () => {
				return this.state.fieldMetrics[this.props.selectedMetrics.metric]
					? this.state.fieldMetrics[this.props.selectedMetrics.metric].displayValue
					: this.state.metricDisplayValue;
			}
		};
		if (metricTypeMapping[metricType]) return metricTypeMapping[metricType]();
	}

	mapMetricTypeIcons = (metricType) => {
		const metricTypeIconMapping = {
			Lab: () => <i className="icon-item fas fa-flask fa-lg pr-1"></i>,
			Field: () => <i className="icon-item fas fa-users fa-lg pr-1"></i>
		};
		if (metricTypeIconMapping[metricType]) return metricTypeIconMapping[metricType]();
	}

	//// TODO: temporary until I can find a better solution/saves state of last metric display when switching to non metric tabs
	handleNonMetricTabsDisplay = (selectedMetrics) => {
		if(this.props.selectedMetrics?.metricType === 'Lab') {
		var lastSelectedLabIcon = this.mapMetricTypeIcons(this.props.selectedMetrics?.metricType);
		 this.setState({lastMetricTypeSelected: 'Lab'});
		 this.setState({lastMetricValue: selectedMetrics.metricDisplayValue});
		 this.setState({lastMetricSelected: selectedMetrics.metric });
		 this.setState({lastMetricIcon: lastSelectedLabIcon});
	 }
	 else if(this.props.selectedMetrics?.metricType === 'Field') {
		 var lastSelectedFieldIcon = this.mapMetricTypeIcons(this.props.selectedMetrics?.metricType);
		 this.setState({lastMetricTypeSelected: 'Field'});
		 this.setState({lastMetricValue: selectedMetrics.metricDisplayValue});
		 this.setState({lastMetricSelected: selectedMetrics.metric });
		 this.setState({lastMetricIcon: lastSelectedFieldIcon});
	 }
	}

	handleMetricsDisplay = (selectedMetrics) => {
		//// TODO: temporary until I can find a better solution
			if(this.props.selectedMetrics?.metricType === 'Filmstrip' || this.props.selectedMetrics?.metricType === 'Content' || this.props.selectedMetrics?.metricType === 'Type' || this.props.selectedMetrics?.metricType === 'Tech') {
				return <span className={this.state.showProgressBar ? ' ' : 'metrics-title'}>
					{
							<span>
									{ this.capitialFirstLetter(this.props.settingsDeviceSelected) }&nbsp;
									{
										this.props.selectedMetrics && this.props.selectedMetrics.metric && this.props.selectedMetrics.metric !== 'psi'
											? this.state.lastMetricIcon
											: ''
									}
									<span className="metric-uppercase">{ this.props.selectedMetrics ? this.props.selectedMetrics.metric : this.state.metric }&nbsp;</span>
									{
										(this.props.selectedMetrics && this.props.selectedMetrics.metric === 'psi') || (this.state.metric === 'psi')
											? <span className="pr-1">score</span>
											: ''
									}
									<span className={ this.props.selectedMetrics ? this.selectedMetricsScoreColor(this.state.lastMetricTypeSelected) : this.state.metricScoreColorClass }>
										{
											this.props.selectedMetrics
												? this.selectedMetricsScore(this.state.lastMetricTypeSelected)
												: this.state.lastMetricValue
										}
									</span>
								</span>
					}
					</span>
			}
			else {
				return <span className={this.state.showProgressBar ? ' ' : 'metrics-title'}>
					{
							<span>
									{ this.capitialFirstLetter(this.props.settingsDeviceSelected) }&nbsp;
									{
										this.props.selectedMetrics && this.props.selectedMetrics.metric && this.props.selectedMetrics.metric !== 'psi'
											? this.mapMetricTypeIcons(this.props.selectedMetrics.metricType)
											: ''
									}
									<span className="metric-uppercase">{ this.props.selectedMetrics ? this.props.selectedMetrics.metric : this.state.metric }&nbsp;</span>
									{
										(this.props.selectedMetrics && this.props.selectedMetrics.metric === 'psi') || (this.state.metric === 'psi')
											? <span className="pr-1">score</span>
											: ''
									}
									<span className={ this.props.selectedMetrics ? this.selectedMetricsScoreColor(this.props.selectedMetrics.metricType) : this.state.metricScoreColorClass }>
										{
											this.props.selectedMetrics
												? this.selectedMetricsScore(this.props.selectedMetrics.metricType)
												: this.state.metricDisplayValue
										}
									</span>
								</span>
					}
					</span>
			}
	}

  render() {

    return (
			<div className="bg-image">
				{/* {
					!this.state.psiResultsError
						? <div className={this.state.showProgressBar ? "d-flex align-items-center" : 'align-items-center'}>
								{/* {
									this.state.showProgressBar
										? <div className="progress">
												<div
													className="progress-bar"
													role="progressbar"
													style={{ width: this.state.progressPercentage + '%' }}
													aria-valuenow={this.state.progressPercentage}
													aria-valuemin="0"
													aria-valuemax="100">
												</div>
											</div>
										: !this.state.psiResultsError
												? <span className="more-data">
													</span>
												: null
								} */}
							{/* </div>
						: null
				} */}

				{/* {
					this.state.psiResultsError
						? <div>
								<span className="error">Error: </span>
								<a
									className="link"
									href={this.state.viewMetricsUrl}
									target="_blank"
									rel="noopener noreferrer">
									Load PSI
								</a>
								{
									!this.state.retryAtemptFailed
										? <span>
												<span> | </span>
												<a
													className="link"
													onClick={(e) => this.retryPsiResults(e)}
													href="/">
													Retry
												</a>
											</span>
										: null
								}
							</div>
						: null
				} */}
					{/* {this.props.textCounterStatus || !this.state.showProgressBar
								? <span className="more-data">
										<span
											onClick={() => this.showMetrics()}>
											{
												'more data'
											}
											{
												this.state.showMetrics
													? <i className="icon-item fas fa-caret-up pl-1"></i>
													: <i className="icon-item fas fa-caret-down pl-1"></i>
											}
										</span>
									</span> : null
					} */}

				{/* {
					this.state.psiResultsError
						? <div>
								<span className="error">Error: </span>
								<a
									className="link"
									href={this.state.viewMetricsUrl}
									target="_blank"
									rel="noopener noreferrer">
									Load PSI
								</a>
								{
									!this.state.retryAtemptFailed
										? <span>
												<span> | </span>
												<a
													className="link"
													onClick={(e) => this.retryPsiResults(e)}
													href="/">
													Retry
												</a>
											</span>
										: null
								}
							</div>
						: null
				} */}
				{
					this.state.showMetrics
						? <MetricTabs
							serpPosition={this.props.serpPosition}
							labMetrics={this.state.labMetrics}
							fieldMetrics={this.state.fieldMetrics}
							viewMetricsUrl={this.state.viewMetricsUrl}
							filmstrip={this.state.filmstrip}
							meta={this.props.meta}
							wordCount={this.props.wordCount}
							types={this.props.types}
							tech={this.props.tech}
							url={this.props.url}
							lastTabMetricSelected={this.state.lastMetricSelected}
							nonMetricTabsDisplay={this.handleNonMetricTabsDisplay}
							psiResultsError={this.state.psiResultsError}
						/>
					: null
				}
			</div>
    );
  }

  componentDidMount() {
		this._isMounted = true;
		this.getPsiResults(false);
		// * Update view all metrics for all cards
    this.subscriptionViewAllMetrics = updateViewAllMetricsSubject.get().subscribe(isUpdateViewAllMetricsSubject => {
			if (this._isMounted) this.setState({ showMetrics: isUpdateViewAllMetricsSubject });
    });
		this.subscriptionMetricSelected = updateMetricSelectedSubject.get().subscribe(isUpdateMetricSelectedSubject => {
			if (isUpdateMetricSelectedSubject) {
				this.props.updateSelectedMetrics(isUpdateMetricSelectedSubject);
				this.handleNonMetricTabsDisplay(this.props.selectedMetrics);
				this.props.parentMetricDisplay(this.handleMetricsDisplay(this.props.selectedMetrics), this.state.psiResultsError);
			}
		});

		this.props.onRef(this);
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.subscriptionViewAllMetrics.unsubscribe();
		this.subscriptionMetricSelected.unsubscribe();
		this.props.onRef(undefined);
	}
}

const mapStateToProps = state => {
  return {
		settingsDeviceSelected: state.settingsDeviceSelected,
		exportGoogleSerpData: state.exportGoogleSerpData,
		psiCounter: state.psiCounter,
		selectedMetrics: state.selectedMetrics,
		flimstripMap: state.flimstripMap,
		viewAllDataToggledOn: state.viewAllDataToggledOn
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updatePsiCounter: (psiCounter) => dispatch({ type: 'PSI_COUNTER', value: psiCounter }),
		updateEnableSerpExportBtn: (enableSerpExportBtn) => dispatch({ type: 'ENABLE_SERP_EXPORT_BTN', value: enableSerpExportBtn }),
		updateFlimstripMap: (flimstripMap) => dispatch({ type: 'FLIMSTRIP_MAP', value: flimstripMap }),
		updateCanViewAllDataAlert: (canViewAllDataAlert) => dispatch({ type: 'CAN_VIEW_ALL_DATA_ALERT', value: canViewAllDataAlert }),
		updateSelectedMetrics: (selectedMetrics) => dispatch({ type: 'SELECTED_METRICS', value: selectedMetrics }),

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PsiResults);
