import PRICING from '../constants/Pricing'
import { GetAuthPlanId, GetAuthRenewDate, GetAuthCancelDate } from '../functions/AuthStatus';
import PLANS from '../constants/Plans';
import PAYPALPLANS from '../constants/PaypalPlans';

const initialState = {
	// * Settings
	settingsDeviceSelected: null,
	isSaveBtnDisabled: true,
	// * Modals
	showSettingsModal: false,
	settingsModal: null,
	showAccountGate: false,
	accountGateContent: 'createAccount',
	showSignInCreateAccountModal: false,
	signInCreateAccountModalContent: 'signIn',
	signInCreateAccountModalEl: null,
	// * Site speed comparison
	serpDomain: 'google.com',
	serpCountry: 'us',
	serpLanguage: 'en',
	searchTermQuery: '',
	exportGoogleSerpData: new Map(),
	psiCounter: 0,
	enableSerpExportBtn: false,
	showRateLimitAlert: false,
	displayGateTimer: null,
	isSearchTermUrlAlert: false,
	screenSize: '',
	flimstripMap: new Map(),
	canViewAllDataAlert: false,
	canViewFilterClearedAlert: false,
	// * Magic link email address
	magicLinkEmail: '',
	magicLinkSettingsParams: null,
	// * Create password email address
	createPasswordEmail: '',
	// * Reset password link email address
	resetPaswordLinkEmail: '',
	resetPaswordLinkSettingsParams: '',
	emailSubscribeSearchQuery: '',
	// * Lab Metrics
	selectedMetrics: null,
	// * Plans
	selectedPlan: PRICING['free'],
	currentPlan: (() => {
		return {
			...PRICING[GetAuthPlanId() ? PLANS[GetAuthPlanId()] : 'free'],
			renewDate: GetAuthRenewDate() ? GetAuthRenewDate() : null,
			cancelDate: GetAuthCancelDate() ? GetAuthCancelDate() : null
		};
	})(),
	isChangePlan: false,
	isChangePayment: false,
	// * Speed Test
	showSpeedTestRateLimitAlert: {
		isShow: false,
		type: 'rateLimit',
		maxSampleSize: 3
	},
	viewAllDataToggledOn: false,
	remainingSerpSearches: 0,
	remainingWSTSearches: 0,
	remainingKwtSearches: 0,
	updateSchemaTypes: false,
	canViewTypes: false,
	clearSchemaFilters: false,
	isKeywordTool: false,
	canViewSerpChecker: false,
	viewKeywordRankingsModal: false,
	keywordExportOptions: {
		country: 'United States',
		language: 'en'
	},
	showKeywordExportModal: false,
	showKeywordSettingsModal: false,
	canViewMoreResultsAlert: false,
	canViewExportAlert: false,
	isPaypalPayment: false,
	paypalPlan: PAYPALPLANS,
	keywordSettingsModal: null,
	keywordDrawerGoogleImage: "",

}

const reducer = (state = initialState, action) => {
	const mapActions = {
		'SETTINGS_DEVICE_SELECTED': () => {
			return { ...state, settingsDeviceSelected: action.value }
		},
		'SETTINGS_MODAL': () => {
			return { ...state, settingsModal: action.value }
		},
		'IS_SAVED_BTN_DISABLED': () => {
			return { ...state, isSaveBtnDisabled: action.value }
		},
		'ACCOUNT_GATE_CONTENT': () => {
			return { ...state, accountGateContent: action.value }
		},
		'SHOW_ACCOUNT_GATE': () => {
			return { ...state, showAccountGate: action.value }
		},
		'SERP_DOMAIN': () => {
			return { ...state, serpDomain: action.value }
		},
		'SERP_COUNTRY': () => {
			return { ...state, serpCountry: action.value }
		},
		'SERP_LANGUAGE': () => {
			return { ...state, serpLanguage: action.value }
		},
		'SEARCH_TERM_QUERY': () => {
			return { ...state, searchTermQuery: action.value }
		},
		'EXPORT_GOOGLE_SERP_DATA': () => {
			return { ...state, exportGoogleSerpData: action.value }
		},
		'PSI_COUNTER': () => {
			return { ...state, psiCounter: action.value }
		},
		'ENABLE_SERP_EXPORT_BTN': () => {
			return { ...state, enableSerpExportBtn: action.value }
		},
		'SHOW_SIGN_IN_CREATE_ACCOUNT_MODAL': () => {
			return { ...state, showSignInCreateAccountModal: action.value }
		},
		'SIGN_IN_CREATE_ACCOUNT_MODAL_CONTENT': () => {
			return { ...state, signInCreateAccountModalContent: action.value }
		},
		'SIGN_IN_CREATE_ACCOUNT_MODAL_EL': () => {
			return { ...state, signInCreateAccountModalEl: action.value }
		},
		'SHOW_SETTINGS_MODAL': () => {
			return { ...state, showSettingsModal: action.value }
		},
		'MAGIC_LINK_EMAIL': () => {
			return { ...state, magicLinkEmail: action.value }
		},
		'MAGIC_LINK_SETTINGS_PARAMS': () => {
			return { ...state, magicLinkSettingsParams: action.value }
		},
		'CREATE_PASSWORD_EMAIL': () => {
			return { ...state, createPasswordEmail: action.value }
		},
		'RESET_PASSWORD_LINK_EMAIL': () => {
			return { ...state, resetPaswordLinkEmail: action.value }
		},
		'RESET_PASSWORD_LINK_SETTINGS_PARAMS': () => {
			return { ...state, resetPaswordLinkSettingsParams: action.value }
		},
		'EMAIL_SUBSCRIBE_SEARCH_QUERY': () => {
			return { ...state, emailSubscribeSearchQuery: action.value }
		},
		'SELECTED_METRICS': () => {
			return { ...state, selectedMetrics: action.value }
		},
		'SELECTED_PLAN': () => {
			return { ...state, selectedPlan: action.value }
		},
		'CURRENT_PLAN': () => {
			return { ...state, currentPlan: action.value }
		},
		'SHOW_RATE_LIMIT_ALERT': () => {
			return { ...state, showRateLimitAlert: action.value }
		},
		'IS_CHANGE_PLAN': () => {
			return { ...state, isChangePlan: action.value }
		},
		'IS_CHANGE_PAYMENT': () => {
			return { ...state, isChangePayment: action.value }
		},
		'DISPLAY_GATE_TIMER': () => {
			return { ...state, displayGateTimer: action.value }
		},
		'SHOW_SPEED_TEST_RATE_LIMIT_ALERT': () => {
			return { ...state, showSpeedTestRateLimitAlert: action.value }
		},
		'IS_SEARCH_TERM_URL_ALERT': () => {
			return { ...state, isSearchTermUrlAlert: action.value }
		},
		'SCREEN_SIZE': () => {
			return { ...state, screenSize: action.value }
		},
		'FLIMSTRIP_MAP': () => {
			return { ...state, flimstripMap: action.value }
		},
		'CAN_VIEW_ALL_DATA_ALERT': () => {
			return { ...state, canViewAllDataAlert: action.value }
		},
		'VIEW_ALL_DATA_TOGGLED_ON': () => {
			return {...state, viewAllDataToggledOn: action.value }
		},
		'REMAINING_SERP_SEARCHES': () => {
			return { ...state, remainingSerpSearches: action.value }
		},
		'REMAINING_WST_SEARCHES': () => {
			return { ...state, remainingWSTSearches: action.value }
		},
		'REMAINING_KWT_SEARCHES': () => {
			return {...state, remainingKwtSearches: action.value }
		},
		'CAN_VIEW_FILTER_CLEARED_ALERT': () => {
			return{ ...state, canViewFilterClearedAlert: action.value }
		},
		'CAN_UPDATE_SCHEMA_TYPES': () => {
			return {...state, updateSchemaTypes: action.value }
		},
		'CAN_VIEW_UPDATED_TYPES': () => {
			return {...state, canViewTypes: action.value}
		},
		'CLEAR_SCHEMA_FILTERS': () => {
			return {...state, clearSchemaFilters: action.value }
		},
		'USING_KWT': () => {
			return { ...state, isKeywordTool: action.value }
		},
		'CAN_VIEW_SERP_CHECKER': () => {
			return { ...state, canViewSerpChecker: action.value }
		},
		'CAN_VIEW_KEYWORD_RANKINGS_MODAL': () => {
			return { ...state, viewKeywordRankingsModal: action.value }
		},
		'KEYWORD_EXPORT_OPTIONS': () => {
			return { ...state, keywordExportOptions: action.value }
		},
		'CAN_VIEW_KEYWORD_EXPORT_MODAL': () => {
			return { ...state, showKeywordExportModal: action.value }
		},
		'CAN_VIEW_MORE_RESULTS_ALERT': () => {
			return {...state, canViewMoreResultsAlert: action.value }
		},
		'CAN_VIEW_EXPORT_ALERT': () => {
			return {...state, canViewExportAlert: action.value }
		},
		'IS_PAYPAL_PAYMENT': () => {
			return {...state, isPaypalPayment: action.value }
		},
		'CAN_VIEW_KEYWORD_SETTINGS_MODAL': () => {
			return {...state, showKeywordSettingsModal: action.value }
		},
		'KEYWORD_SETTINGS_MODAL': () => {
			return {...state, keywordSettingsModal: action.value }
		},
		'KEYWORD_DRAWER_GOOGLE_IMAGE': () => {
			return {...state, keywordDrawerGoogleImage: action.value }
		}

	}
	return mapActions[action.type]
		? mapActions[action.type]()
		: state
}

export default reducer;
