import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import { GetAuthToken, GetAuthName, GetAuthEmail } from '../functions/AuthStatus';
import LOCALSTORAGE from '../constants/LocalStorage';
import AuthStatusModel from '../functions/AuthStatusModel';
import { withRouter } from 'react-router-dom';
import { updateSignedInStatusSubject } from '../observables/SignedInStatus';
import { connect } from 'react-redux';
import Footer from '../components/Footer/Footer';
import format from 'date-fns/format';
// import { RemainingUsageStatus } from '../functions/RemainingSearchesApi';
import { RemainingSerpUsageStatus } from '../functions/RemainingSerpUsageStatus';
import { RemainingWstUsageStatus } from '../functions/RemainingWstUsageStatus';
import { RemainingKwtUsageStatus } from '../functions/RemainingKwtUsageStatus';

class AccountPage extends Component {
  constructor(props) {
    super();
		this.state = {

    };
  }

  logOut = () => {
		if (typeof localStorage !== 'undefined') {
			localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel('', '', null, null, 1, null, 1, null, null)));
			localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, false);
			localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
      localStorage.setItem(LOCALSTORAGE.keywordSettings, JSON.stringify({country: "", country_code: "", language: "", temp: ""}));
      localStorage.setItem(LOCALSTORAGE.minuteValue, Date.now() * -1);
    }
    updateSignedInStatusSubject.update(false);
    this.props.history.push({ pathname: '/' });
	}

  changePlan = () => {
    this.props.updateIsChangePlan(true);
    this.props.history.push({ pathname: '/pricing' });
  }

  changePayment = () => {
    this.props.updateIsChangePayment(true);
    this.props.history.push({ pathname: '/payment' });
  }

  cancelSubscription = () => {
    this.props.history.push({ pathname: '/cancel' });
  }

  parseRenewDate = () => {
    if (this.props.currentPlan.renewDate) {
      const date = new Date(this.props.currentPlan.renewDate);
      return `Renews: ${format(date, 'MMMM dd, yyyy')}`;
    } else {
      return '';
    }
  }

  parseCancelDate = () => {
    if (this.props.currentPlan.cancelDate) {
      const date = new Date(this.props.currentPlan.cancelDate);
      return `Your plan has been cancelled. Expires: ${format(date, 'MMMM dd, yyyy')}`;
    } else {
      return '';
    }
  }

  handleAccountRemainingSearches = async () => {
    const serpResponse = await RemainingSerpUsageStatus();
    const wstResponse  = await RemainingWstUsageStatus();
    const kwtResponse  = await RemainingKwtUsageStatus();

    if(serpResponse) {
      if(serpResponse.numOfSerpLeft === null) {
        serpResponse.numOfSerpLeft = 0;
        this.props.updateRemainingSerpSearches(serpResponse.numOfSerpLeft);
      } else {
        this.props.updateRemainingSerpSearches(serpResponse.numOfSerpLeft);
      }
    }

    if(wstResponse) {
      if(wstResponse.numOfwebsiteSearchesLeft === null) {
        wstResponse.numOfwebsiteSearchesLeft = 0;
        this.props.updateRemainingWSTSearches(wstResponse.numOfwebsiteSearchesLeft);
      } else {
        this.props.updateRemainingWSTSearches(wstResponse.numOfwebsiteSearchesLeft);
      }
    }

    if(kwtResponse) {
      if(kwtResponse.kwtSearchesLeft === null) {
        kwtResponse.kwtSearchesLeft = 0;
        this.props.updateRemainingKwtSearches(kwtResponse.kwtSearchesLeft);
      } else {
        this.props.updateRemainingKwtSearches(kwtResponse.kwtSearchesLeft);
      }
    }
  }

  render() {
    return (
      <div className="container-fluid page-background1 pb-5">
        <Helmet>
          <meta name="description" content=""/>
          <title>Account | Sandbox</title>
        </Helmet>
        <Header />
        <div className="container page-content-padding">
          <div className="row pt-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Account</li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12">
              <h3 className="pb-4">Your Account</h3>
            </div>
            <div className="col-sm-12">
              {
								GetAuthName()
									? <p className="mb-0">{GetAuthName()}</p>
									: null
							}
              <p>{GetAuthEmail()}</p>
              <span className="d-block">
                <u onClick={()=> {this.logOut()}}>Log out</u>
              </span>
              <hr/>
              {
                this.props.currentPlan.plan === 'free'
                  ? <p className="mb-0">Plan: { this.props.currentPlan.plan }</p>
                  : <p className="mb-0">Plan: { this.props.currentPlan.plan }, $
                      {
                        this.props.currentPlan.billingCycle === 0 || this.props.currentPlan.billingCycle === 1
                          ? <span>{ this.props.currentPlan.monthlyPrice } /mo</span>
                          : <span>{ this.props.currentPlan.yearlyPrice } /yr</span>
                      }
                    </p>
              }
              {
                this.props.currentPlan.renewDate !== null
                  ? <p className="mb-2">{ this.parseRenewDate() }</p>
                  : null
              }
              {
                this.props.currentPlan.cancelDate !== null
                  ? <p className="mb-2">{ this.parseCancelDate() }</p>
                  : null
              }
              {
                this.props.currentPlan.planId !== 1
                  ? <span className="d-block mb-2">
                      <u onClick={()=> {this.changePlan()}}>Change plan</u>
                    </span>
                  : null
              }

              {/* <span
                className="d-block mb-2"
                onClick={()=> {this.changePayment()}}>
                <u>Change Payment</u>
              </span> */}

              {
                this.props.currentPlan.planId !== 1
                  ? <span className="d-block mb-2">
                      <u onClick={()=> {this.cancelSubscription()}}>Cancel</u>
                    </span>
                  : null
              }
              <hr/>
              <div className="row">
                  <div className="col-sm-12 mb-2">
                    <span>Remaining this month:</span>
                  </div>
                  <div className="col-sm-12">
                    <span>SERP Analysis Searches: {this.props.remainingSerpSearches}</span>
                  </div>
                  <div className="col-sm-12">
                    <span>Speed Tests: {this.props.remainingWSTSearches}</span>
                  </div>
                  <div className="col-sm-12">
                    <span>GPT3 Keywords: {this.props.remainingKwtSearches}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
    if(GetAuthToken()) {
        this.handleAccountRemainingSearches();
    } else {
      this.logOut(); // test this
      console.log("could not get auth token");
    }

  }
  componentWillUnmount() {
    if(GetAuthToken())
      this.handleAccountRemainingSearches();
  }
}

const mapStateToProps = state => {
  return {
		currentPlan: state.currentPlan,
    remainingSerpSearches: state.remainingSerpSearches,
    remainingWSTSearches: state.remainingWSTSearches,
    remainingKwtSearches: state.remainingKwtSearches
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateIsChangePlan: (isChangePlan) => dispatch({ type: 'IS_CHANGE_PLAN', value: isChangePlan }),
    updateIsChangePayment: (isChangePayment) => dispatch({ type: 'IS_CHANGE_PAYMENT', value: isChangePayment }),
    updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan }),
    updateRemainingSerpSearches: (remainingSerpSearches) => dispatch({ type: 'REMAINING_SERP_SEARCHES', value: remainingSerpSearches }),
    updateRemainingWSTSearches: (remainingWSTSearches) => dispatch({ type: 'REMAINING_WST_SEARCHES', value: remainingWSTSearches }),
    updateRemainingKwtSearches: (remainingKwtSearches) => dispatch({ type: 'REMAINING_KWT_SEARCHES', value: remainingKwtSearches })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountPage));
