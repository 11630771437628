import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

class FreeTrialPage extends Component {
  constructor(props) {
    super();
    this.state = {

    }
  }

 goToPricingPage = () => {
   this.props.history.push({ pathname: '/pricing'})
 }

  render() {
    return (
        <div className="container-fluid page-background1 pb-5">
          <Helmet>
            <meta name="description" content=""/>
            <title>Free Trial | Sandbox</title>
          </Helmet>
          <Header />
          <div className="container page-content-padding">
            <div className="row pt-3">
              <div className="col-sm-12">
                <h4 className="mb-2 free-usage-message">You have reached the limits of free usage on this tool. To continue today, please see our <u onClick={() => this.goToPricingPage()}>pricing</u> page</h4>
                <p>Your free usage limits will reset next month. </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
  }
  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname);
  }
}
const mapStateToProps = state => {
  return {

  };
};
const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FreeTrialPage));
