import axios from 'axios';

export async function CreateAccountGoogleApi(params) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/auth/google-register`, params, { withCredentials: true})
    return response;
  } catch (error) {
        console.log(error);
    return error.response;
  }
}
