import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'mdb-ui-kit';
import CreateAccount from '../Account/CreateAccount';
import SignIn from '../Account/SignIn';
import { updateSignedInStatusSubject } from '../../observables/SignedInStatus';

class SignInCreateAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
		};
	}

	mapContent = () => {
		const contentMapping = {
			createAccount: () => <CreateAccount />,
			signIn: () => <SignIn />

		};
		return contentMapping[this.props.signInCreateAccountModalContent]
			? contentMapping[this.props.signInCreateAccountModalContent]()
			: contentMapping['createAccount']()
	}

  render() {
    return (
      <div
				className="modal fade"
				id="signInCreateAccountModal"
				tabIndex="-1"
				aria-labelledby="signInCreateAccountModalLabel"
				aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content sign-in-create-account-modal">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.mapContent()}
						</div>
					</div>
				</div>
			</div>
    );
	}

	componentDidMount() {
		const signInCreateAccountModal = document.getElementById('signInCreateAccountModal');
		if (typeof signInCreateAccountModal !== 'undefined') {
			const modal = new Modal(signInCreateAccountModal);
			modal.show();
			this.props.updateSignInCreateAccountModalEl(modal);
			signInCreateAccountModal.addEventListener('hide.bs.modal', (e) => {
				this.props.updateShowSignInCreateAccountModal(false);
				updateSignedInStatusSubject.update(false);
			});
    }
	}
}

const mapStateToProps = state => {
  return {
		signInCreateAccountModalContent: state.signInCreateAccountModalContent
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateShowSignInCreateAccountModal: (modal) => dispatch({ type: 'SHOW_SIGN_IN_CREATE_ACCOUNT_MODAL', value: modal }),
		updateSignInCreateAccountModalEl: (element) => dispatch({ type: 'SIGN_IN_CREATE_ACCOUNT_MODAL_EL', value: element })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInCreateAccountModal);
