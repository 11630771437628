import ReactGA from 'react-ga';
import React, { Component } from 'react';
import axios from 'axios';
import SerpPsiCard from './SerpPsiCard';
import { connect } from 'react-redux';
import DOMAINS from '../../constants/Domains';
import COUNTRIES from '../../constants/Countries';
import LOCALSTORAGE from '../../constants/LocalStorage';
import { SaveUserSettingApi } from '../../functions/UserSettingApi';
import { GetAuthToken, GetAuthUserId, GetAuthPlanId } from '../../functions/AuthStatus';
import { updateSerpToolSettings } from '../../observables/SerpToolSettingsSubject';
import { DisplayAccountGateCheck } from '../../functions/DisplayAccountGateCheck';
import { updateMetricSelectedSubject } from '../../observables/MetricLabSelected';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string'
import { RateLimitingApi } from '../../functions/RateLimitingApi';
import RateLimitAlert from './RateLimitAlert';
import ViewAllMetricsToggle from './ViewAllMetricsToggle';
import IsSearchTermUrl from './IsSearchTermUrl';
import { MediaMatcher } from '../../functions/MediaMatcher';
import ViewAllDataAlert from './ViewAllDataAlert';
import ViewFilterClearedAlert from './ViewFilterClearedAlert';
import { RemainingSerpUsageStatus } from '../../functions/RemainingSerpUsageStatus';
import SerpKeywordRankChecker from './SerpKeywordRankChecker';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { updateSignedInStatusSubject } from '../../observables/SignedInStatus';
import { AuthTokenStatus } from '../../functions/AuthTokenStatusApi';
import ViewMoreResultsAlert from './ViewMoreResultsAlert';
import ViewExportAlert from './ViewExportAlert';


class SerpPsiTool extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      searchTerm: '',
      serpPageNum: 1,
      organicSearchResults: [],
      showSerpPsiCards: false,
      showSerpPsiSpinner: false,
      serpSearchApiFail: false,
      searchSubmited: false,
      isQueryParam: false,
      moreResultsClicked: false,
      wordCountMapping: new Map(),
      metaContentMapping: new Map(),
      schemaTypeTabMapping: new Map(),
      techTabMapping: new Map(),
      schemaTypeMapping: [],
      avgWordCount: 0,
      statsCardSearchTerm: '',
      schemaTypeFilterState: [],
      resultLength: [],
      techCanonicalUrl: ""

    };
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({searchTerm: value});
  }

  handleSubmit = (event, ignoreRateLimit, isMoreResults) => {
    if (event) event.preventDefault();
    if (this.state.searchTerm !== '') {
      this.props.updateCanViewAllDataAlert(false);
      if (isMoreResults) {
        if (GetAuthToken()) {
            this.isRateLimitReached(this.state.searchTerm, this.state.serpPageNum, ignoreRateLimit).then(isLimitReached => {
              // TODO: Refactor once backend starts to handle serps api
              // * When more results is clicked for now we get serps reguardless of what rate limit api returns
              this.getSerps(this.state.searchTerm);
              // !isLimitReached
              //   ? this.getSerps(this.state.searchTerm) // * Daily search limit not reached
              //   : GetAuthPlanId() === 1 ? this.linkToFreeTrialPage() : this.displayRateLimitOnboarding(); // *
            });
        } else {
          // TODO: Refactor once backend starts to handle serps api
          this.getSerps(this.state.searchTerm);
        }
      } else {
        if (this._isMounted) this.setState({ searchSubmited: true });
        this.props.updateEmailSubscribeSearchQuery(this.state.searchTerm)
        this.props.updateSearchTermQuery(this.state.searchTerm);
        this.props.updateViewAllDataToggle(false);
        this.setState({ moreResultsClicked: false });
        this.setState({ organicSearchResults: [] });
        this.setState({ resultLength: [] });
        this.setState({ schemaTypeMapping: [] });
        this.setState({ wordCountMapping: new Map() });
        this.setState({ metaContentMapping: new Map() });
        this.setState({ schemaTypeTabMapping: new Map() });
        this.setState({ techTabMapping: new Map() });
        this.setState({ statsCardSearchTerm: this.state.searchTerm });
        this.setState({ serpPageNum: 1 }, () => {
          if (GetAuthToken()) {
            RemainingSerpUsageStatus().then(usage => {
              
              if(usage?.status === "SUCCESS") {
                this.getSerps(this.state.searchTerm) // * Daily search limit not reached
              } else {
                GetAuthPlanId() === 1 ? this.linkToFreeTrialPage() : this.displayRateLimitOnboarding(); // * Daily search limit reached
              }
            });
            // this.isRateLimitReached(this.state.searchTerm, this.state.serpPageNum, ignoreRateLimit).then(isLimitReached => {
            //   !isLimitReached
            //     ? this.getSerps(this.state.searchTerm) // * Daily search limit not reached
            //     : GetAuthPlanId() === 1 ? this.linkToFreeTrialPage() : this.displayRateLimitOnboarding(); // * Daily search limit reached
            // });
          } else {
            // TODO: Refactor once backend starts to handle serps api
            this.getSerps(this.state.searchTerm);
          }
        });
        // this.handleSerpCardFilter([]);
        this.props.updateExportGoogleSerpData(new Map());
        this.props.updateFlimstripMap(new Map());
        this.props.updateEnableSerpExportBtn(false);
        this.props.updatePsiCounter(0);
        this.props.updateIsViewAllDataAlert(false);
        this.props.canUpdateSchemaTypes(false);
        this.props.canViewUpdatedTypes(false);
        this.props.updateCanViewSerpChecker(false);
        this.props.updateCanViewMoreResultsAlert(false);
        this.props.updateCanViewExportAlert(false);
      }
		}
  }

  getSerps = async (searchTerm) => {
    const params = {
      searchTerm: searchTerm,
      device: this.props.settingsDeviceSelected,
      pageNum: this.state.serpPageNum,
      google_domain: this.props.serpDomain,
      gl: this.props.serpCountry,
      hl: this.props.serpLanguage
    };

    if (this._isMounted) {
      this.setState({ serpSearchApiFail: false });
      this.setState({ showSerpPsiSpinner: true });
    }
    this.displayAccountGate();
    // axios.get('/json/DummySerp.json')

   axios.post(process.env.REACT_APP_SERP_SEARCH_API, params)
    .then(res => {
      if (res) {
        if (this._isMounted) {
          this.setState({ showSerpPsiSpinner: false });
          this.setState({ serpPageNum: this.state.serpPageNum + 1 });
        }
        var answerBox = res.data.answer_box?.answers[0];
        var organicRes = [];

        if(answerBox) {
          var domain = "";
          var removeHttp = "";
          var removeSlashes = "";
          if(answerBox?.source?.link !== undefined) {
            domain = answerBox?.source?.link.match(/(www)(.*?\/)/) || answerBox?.source?.link.match(/(https:\/\/)(.*?\/)/);
            removeHttp = domain[0]?.replace(/https:/, "");
            removeSlashes = removeHttp?.replace(/\//g, "");
            var organicObj = {
              position: 1,
              title: answerBox?.source?.title || "",
              domain: removeSlashes,
              link: answerBox?.source?.link || ""
            }
            organicRes.push(organicObj);
          }

          for (var i = 0; i < res.data.organic_results.length; i++) {
            res.data.organic_results[i].position = res.data.organic_results[i].position + 1;
            organicRes.push(res.data.organic_results[i]);
          }
          organicRes
            ? this.parseOrganicSearchResults(organicRes)
            : console.error('error geting organic search results');
          organicRes
            ? this.getPageWordCount(organicRes)
            : console.error('error getting organic search results');
        } else {
          res.data.organic_results
            ? this.parseOrganicSearchResults(res.data.organic_results)
            : console.error('error geting organic search results');
          res.data.organic_results
            ? this.getPageWordCount(res.data.organic_results)
            : console.error('error getting organic search results');
        }

        this.saveSettingsApi();
        this.isSearchTermUrl(this.state.searchTerm);

        //moved ratelimit check here incase of api error
        this.isRateLimitReached(this.state.searchTerm, 1, false).then(isLimitReached => {});

				ReactGA.event({ category: 'speed comparison', action: 'query', label: 'query submit' });
				ReactGA.event({ category: 'speed comparison', action: 'query', label: searchTerm });
        ReactGA.event({ category: 'speed comparison - usage', action: searchTerm, label: GetAuthUserId() });
      }
    }).catch(error => {
      if (this._isMounted) {
        this.setState({ showSerpPsiSpinner: false });
        this.setState({ serpSearchApiFail: true });
      }
      console.error('getSerps Error: ', error);
      ReactGA.event({ category: 'speed comparison', action: 'aws api fail', label: 'serp-search ' });
    })
	}

  parseOrganicSearchResults = (organicResults) => {
    let results = [];
    var resLength = 0;
    if(this.state.serpPageNum > 2) {
      resLength = this.state.resultLength.length;
    }
    organicResults.forEach(result => {
      results.push({
        title: result.title.substring(0, 50) + "...",
        domain: result.domain,
        url: result.link,
        rank: result.position + resLength
      });
    });
    if(this._isMounted) this.setState({resultLength: [...this.state.resultLength, ...results ]}); // put this here to not have goProCardData added to the array to keep track of the serpCardOrder
    results.push({
      goProCardData: results
    });
    if (this._isMounted) this.setState({ organicSearchResults: [...this.state.organicSearchResults, ...results] });
    this.formatGoolgeSerpExport(organicResults);
    if (this._isMounted && results.length > 0) {
      this.setState({ showSerpPsiCards: true });
      localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
    }
	}

  getPageWordCount = (organicResults) => {
    axios.post(process.env.REACT_APP_TEXT_API_POST, organicResults)
    .then(response => {
      if (response?.data) {
        this.handleTechTabInfo(response.data.redirectArray);
        const responseArray = response.data.resultArray;
        const tempWordCountMap = this.state.wordCountMapping;
        const metaContentMap = this.state.metaContentMapping;
        const schemaContentMap = this.state.schemaTypeMapping;
        const schemaTabContentMap = this.state.schemaTypeTabMapping;

        let totalWordCount = 0;
        let eligibleWordCounts = [];

        for (let i = 0, il = responseArray?.length; i < il; i++) {
          tempWordCountMap.set(responseArray[i].site, { wordCount: responseArray[i].total });
          metaContentMap.set(responseArray[i].site, { meta: responseArray[i].meta });
          schemaTabContentMap.set(responseArray[i].site, { types: responseArray[i].schemaObject });

          if(responseArray[i].schemaObject !== undefined)
            schemaContentMap.push(responseArray[i].schemaObject);
        }

        this.setState({ wordCountMapping: tempWordCountMap });
        this.setState({ metaContentMapping: metaContentMap });
        this.setState({ schemaTypeMapping: schemaContentMap });
        this.setState({ schemaTypeTabMapping: schemaTabContentMap });

        this.state.wordCountMapping.forEach(count => {
          if(count.wordCount > 0)
            eligibleWordCounts.push(count);
        });
        for (var i = 0; i < eligibleWordCounts.length; i++) {
          totalWordCount += eligibleWordCounts[i].wordCount;
        }

        let avgWordCount = totalWordCount / eligibleWordCounts.length;
        this.setState({ avgWordCount: avgWordCount});

        this.props.canUpdateSchemaTypes(true);
        this.setState({moreResultsClicked: false });

      }

    }).catch(error => {
        console.error('getPageWordCount error: ', error);
        this.props.canUpdateSchemaTypes(true);
        this.setState({moreResultsClicked: false });
    });

  }

  handleTechTabInfo = (siteCodes) => {
    const techTabMap = this.state.techTabMapping;

    for (var i = 0; i < siteCodes.length; i++) {
      techTabMap.set(siteCodes[i].url, {tech: siteCodes[i].redirects});
    }
    this.setState({ techTabMapping: techTabMap });
    // this.setState({ techCanonicalUrl: })
  }

	moreResults = () => {
    if(GetAuthPlanId() > 1) {
        this.handleSubmit(null, false, true);
        this.props.updateEnableSerpExportBtn(false);
    		ReactGA.event({ category: 'speed comparison', action: 'click', label: 'load more results' });
        this.setState({ moreResultsClicked: true });

        if(this.state.schemaTypeFilterState.length === 0) {
          this.props.updateCanViewContentFilterAlert(false);
        } else {
          this.props.updateCanViewContentFilterAlert(true);
        }

        this.props.canUpdateSchemaTypes(false);
        this.props.canViewUpdatedTypes(false);
      } else {
        this.props.updateCanViewMoreResultsAlert(true);

      }
  }

  displayAccountGate = () => {
    if (DisplayAccountGateCheck() && !this.props.displayGateTimer) {
      const displayGateTimer = setTimeout(() => {
        if(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)) {
            this.props.updateGateContent('signIn');
        } else {
            this.props.updateGateContent('createAccount');
        }
        // this.props.updateShowAccountGate(true);
        this.handleUserTokenStatus();
      }, 30000);
      this.props.updateDisplayGateTimer(displayGateTimer);
    }
  }

  formatGoolgeSerpExport = (organicResults) => {
    if (this.props.exportGoogleSerpData.size > 0) {
      // * Add 1 to lastPosition to account for the track these scores go pro card in array data
      const lastPosition = this.props.exportGoogleSerpData.size + 1;
      organicResults.forEach((result, position) => {
        this.props.exportGoogleSerpData.set(position + lastPosition, {
          rank: (position + lastPosition),
          wordCount: 'N/A',
          titleTag: 'N/A',
          metaDesc: 'N/A',
          h1: 'N/A',
          canonical: 'N/A',
          httpStatus: 'N/A',
          schemaTypes: 'N/A',
          cwv: 'N/A',
          labPsi: 'N/A',
          labFcp: 'N/A',
          labSi: 'N/A',
          labLcp: 'N/A',
          labTti: 'N/A',
          labTbt: 'N/A',
          labCls: 'N/A',
          fieldLcp: 'N/A',
          fieldFid: 'N/A',
          fieldCls: 'N/A',
          fieldFcp: 'N/A',
          url: result.link
        });
      });
    } else {
      organicResults.forEach((result, position) => {
        // * Add 1 to rank to offset rank in order to start counting from 1 instead of 0
        this.props.exportGoogleSerpData.set(position, {
          rank: (position + 1),
          wordCount: 'N/A',
          titleTag: 'N/A',
          metaDesc: 'N/A',
          h1: 'N/A',
          canonical: 'N/A',
          httpStatus: 'N/A',
          schemaTypes: 'N/A',
          cwv: 'N/A',
          labPsi: 'N/A',
          labFcp: 'N/A',
          labSi: 'N/A',
          labLcp: 'N/A',
          labTti: 'N/A',
          labTbt: 'N/A',
          labCls: 'N/A',
          fieldLcp: 'N/A',
          fieldFid: 'N/A',
          fieldCls: 'N/A',
          fieldFcp: 'N/A',
          url: result.link
        });
      });
    }
  }

  getFlagIcon = (domainSelected) => {
    const domainObject = DOMAINS.find(domain => domain.google_domain === domainSelected);
    let flagCode = 'us';
    if (domainObject) {
      const findCountryCode = COUNTRIES.find(country => country.country_name === domainObject.country_name);
      flagCode = findCountryCode
        ? findCountryCode.country_code
        : domainObject.country_code
          ? domainObject.country_code
          : 'us';
    }
    return flagCode === 'aq'
      ? <img
        src={'https://www.countryflags.io/aq/flat/16.png'}
        style={{marginRight: '.5em'}}
        alt={flagCode}/>
      : <i className={`${flagCode} flag`}></i>
  }

  openSettingsModal = (e) => {
    e.preventDefault();
    this.closeNavbarToggler();
    this.props.updateShowSettingsModal(true);
    if (this.props.displayGateTimer) clearTimeout(this.props.displayGateTimer);
  }

  closeNavbarToggler = () => {
    const navbarToggler = document.getElementById('navbar-toggler');
    const navbar = document.getElementById('navbar');
    if (navbar.classList.contains('show')) {
      if (!navbarToggler.classList.contains('collapsed')) navbarToggler.classList.add('collapsed');
      navbar.classList.remove('show');
    }
  }

  saveSettingsApi = () => {
  		const params = {
  			deviceType: this.props.settingsDeviceSelected === 'mobile' ? 1 : 2,
  			googleDomain: this.props.serpDomain,
  			country: this.props.serpCountry,
  			language: this.props.serpLanguage,
        originUrl: window.location.pathname
      };
  		SaveUserSettingApi(params);
  }

  parseQueryString = () => {
    const queryParams = queryString.parse(this.props.location.search);
    console.log(queryParams);
    if (this._isMounted && queryParams.searchTerm) {
      this.setState({ searchTerm: queryParams.searchTerm }, () => this.handleSubmit(null, false, false));
      this.setState({ isQueryParam: true });
    }
  }

  handleUserTokenStatus = async () => {
    var responseStatus;
    if (typeof localStorage !== 'undefined') { //added this when getting incognito token error.
      if(localStorage.getItem(LOCALSTORAGE.usrAuthStorage) !== null) {
        const response = await AuthTokenStatus(JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).token);
        responseStatus = response?.data.status;
      }
    }
      if(responseStatus === "SUCCESS") {
        this.handleRemainingSerpSearches();
      } else {
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel('', '', null, null, 1, null, 1, null)));
          localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, false);
          localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
        }
        updateSignedInStatusSubject.update(false);
        // this.props.updateGateContent('signIn');
        this.props.updateShowAccountGate(true);
      }

  }

  handleRemainingSerpSearches = async () => {
      const response = await RemainingSerpUsageStatus();
      if(response) {
        if(response.numOfSerpLeft === null)
        {
          response.numOfSerpLeft = 0;
          this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
        }
        else
        {
          this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
        }
      }
  }

  isRateLimitReached = async (searchTerm, serpPageNum, ignoreRateLimit) => {
    console.log("isRateLimitReached: ", searchTerm, serpPageNum, ignoreRateLimit)
    const response = await RateLimitingApi('serpSearch', searchTerm, serpPageNum, ignoreRateLimit, null, null);
    const rateLimitResponseMapping = {
      200: () => false, // * Daily limit not reached
      429: () => true, // * Daily limit reached
      default: () => false // * default to daily limit not reached if api fails
    };
    this.handleRemainingSerpSearches();
    return await response && response.status && rateLimitResponseMapping[response.status]
      ? rateLimitResponseMapping[response.status]()
      : rateLimitResponseMapping['default']();

  }

  displayRateLimitOnboarding() {
    this.props.updateShowRateLimitAlert(true);
  }

  isSearchTermUrl = (searchTerm) => {
    if ((!this.props.showRateLimitAlert && searchTerm.includes('http')) ||
        (!this.props.showRateLimitAlert && searchTerm.includes('www')) ||
        (!this.props.showRateLimitAlert && searchTerm.includes('.com')) ||
        (!this.props.showRateLimitAlert && searchTerm.includes('.net'))
      ) {
        if(searchTerm.substring(0,5) !== "site:")
          if (!this.state.isQueryParam) this.props.updateIsSearchTermUrlAlert(true);
    }
  }

  linkToPricingPage = () => {
    ReactGA.event({ category: 'rate limiting - wst', action: 'click on upgrade', label: GetAuthUserId() });
    this.props.history.push({ pathname: '/pricing' });
  }

  linkToFreeTrialPage = () => {
    this.props.history.push({ pathname: '/free-trial-page'})
  }

  handleFindYourSite = () => {
    this.props.updateCanViewSerpChecker(!this.props.canViewSerpChecker);
  }

	render() {
    return (
      <div className="row mb-2">
        <div className="col-sm-12 pb-2">
          <form>
            <div className="input-group pb-2">
              <input
                id="serpSearch"
                type="text"
                className="form-control"
                placeholder="Enter Google search term"
                aria-label="Search Term"
                aria-describedby="Search Term"
                value={this.state.searchTerm}
                onChange={this.handleInputChange}/>
              <button
                className="btn btn-outline-primary"
                type="submit"
                id="submitSerpSearch"
                data-ripple-color="dark"
                onClick={(e) => this.handleSubmit(e, false, false)}>
                Search
              </button>
            </div>

            {
              this.props.screenSize !== "mobile"
              ? <div className="d-flex row">
                  <p className="serp-psi-search-flag-copy body-small col-sm-6">
                   Searching {this.props.serpDomain}&nbsp;
                    <span>
                      { this.getFlagIcon(this.props.serpDomain) }
                      <span onClick={(e) => this.openSettingsModal(e)}><u><b>change</b></u></span>
                    </span>
                  </p>
                  {
                    GetAuthToken()
                      ?  <span className="col-sm-6 remaining-searches serp-psi-search-flag-copy body-small">Remaining Searches: {this.props.remainingSerpSearches} <u className="pl-1 align-middle" onClick={() => this.linkToPricingPage()}>upgrade</u></span>
                      : null
                  }
              </div>
              : <div className="row">
                  <div className="d-flex col-sm-12 justify-content-between">
                    <p className="serp-psi-search-flag-copy body-small d-inline text-left ml-1">
                       {this.props.serpDomain}&nbsp;
                      <span>
                        { this.getFlagIcon(this.props.serpDomain) }
                        <span onClick={(e) => this.openSettingsModal(e)}><u><b>change</b></u></span>
                      </span>
                    </p>
                    {
                      GetAuthToken()
                        ?  <span className="remaining-searches serp-psi-search-flag-copy body-small">Searches: {this.props.remainingSerpSearches} <u className="pl-1 align-middle" onClick={() => this.linkToPricingPage()}>upgrade</u></span>
                        : null
                    }
                  </div>
                </div>
          }
          </form>
        </div>
              {
                this.props.screenSize !== "mobile" && this.state.showSerpPsiCards && this.state.organicSearchResults.length > 0
                  ? <div className="d-flex align-items-center">
                      <div className="psi-score-scale-container">
                        <span className="psi-score-range psi-score-scale-range-fail">0–49</span>
                        <span className="psi-score-range psi-score-scale-range-average">50–89</span>
                        <span className="psi-score-range psi-score-scale-range-pass">90–100</span>
                      </div>
                    </div>
                  : null
                }
        {
          this.state.showSerpPsiCards && this.state.organicSearchResults.length > 0
            ? <ViewAllMetricsToggle
              enableSerpExportBtn={this.props.enableSerpExportBtn}
              searchTerm={this.state.statsCardSearchTerm}
              wordCount={this.state.avgWordCount}
              deviceSelected={this.props.settingsDeviceSelected}
              schemaType={this.state.schemaTypeMapping}
              meta={this.state.metaContentMapping}
              moreResultsClicked={this.state.moreResultsClicked}
            />
            : null
        }

        {
          this.state.showSerpPsiCards
            ? this.state.organicSearchResults.map(({title, domain, url, goProCardData, display, showMoreData, rank}, index) => {
                return <SerpPsiCard
                  title={title}
                  domain={domain}
                  url={url}
                  serpPosition={index}
                  key={index}
                  wordCount={this.state.wordCountMapping?.has(url) ? this.state.wordCountMapping.get(url).wordCount : 'N/A'}
                  meta={this.state.metaContentMapping?.has(url) ? this.state.metaContentMapping.get(url).meta : ' '}
                  types={this.state.schemaTypeTabMapping?.has(url) ? this.state.schemaTypeTabMapping.get(url).types : 'no types'}
                  schemaTypeFilter={this.state.schemaTypeFilterState.length > 0 ? display : true }
                  rank={rank}
                  checkData={this.state.schemaTypeFilterState.length > 0 ? this.state.schemaTypeFilterState : []}
                  goProCardData={goProCardData}
                  moreResultsClicked={this.state.moreResultsClicked}
                  techTabResults={this.state.techTabMapping?.has(url) ? this.state.techTabMapping.get(url).tech : ""}
                  />
              })
            : null
        }

        {
          this.state.showSerpPsiCards && this.state.organicSearchResults.length > 0
          ? <div className="mb-2 serp-rank-checker">
              <span className="find-site-btn">
                <span onClick={() => this.handleFindYourSite()}>
                {
                  this.props.canViewSerpChecker
                  ? <i className="icon-item fas fa-caret-up pr-1"></i>
                  : <i className="icon-item fas fa-caret-down pr-1"></i>
                }
                  find your site
                </span>
              </span>
            </div>
          : null
        }

        {
          this.props.canViewSerpChecker && this.state.showSerpPsiCards
            ? <SerpKeywordRankChecker
                searchTerm={this.state.searchTerm}
              />
            : null
        }

        {
          this.state.showSerpPsiSpinner
            ? <div className="col-sm-12 text-center mb-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            : null
        }
        {
          this.state.serpSearchApiFail
            ? <div className="col-sm-12 mb-5">
                <p className="error">&ldquo;Doh! Something went wrong. Go have a snack and try again in 5 minutes.&rdquo;</p>
              </div>
            : null
        }
        {
          this.state.showSerpPsiCards && !this.state.showSerpPsiSpinner && !this.props.showRateLimitAlert
            ? <div className="col-sm-12 text-center mb-3">
                <button
                  type="button"
                  className="btn btn-primary max-width text-center"
                  onClick={() => this.moreResults()}>
                  {GetAuthPlanId() <= 1 ? <i className="fas fa-lock mr-1"></i> : null}
                  More Results
                </button>
              </div>
          : null
        }
        {
          this.props.showRateLimitAlert
            ? <RateLimitAlert />
            : null
        }
        {
          this.props.isSearchTermUrlAlert
            ? <IsSearchTermUrl />
            : null
        }
        {
          this.props.canViewAllDataAlert && this.state.showSerpPsiCards
            ? <ViewAllDataAlert />
            : null
        }
        {
          this.props.canViewFilterClearedAlert
            ? <ViewFilterClearedAlert />
            : null
        }

        {
          this.props.canViewMoreResultsAlert && this.state.showSerpPsiCards
            ? <ViewMoreResultsAlert
                history={this.props.history}
              /> : null
        }
        {
          this.props.canViewExportAlert && this.state.showSerpPsiCards
            ? <ViewExportAlert
              history={this.props.history}
              /> : null
       }
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;

    // * Run search when setting change
    this.subscription = updateSerpToolSettings.get().subscribe(isUpdateSerpToolSettings => {
      if (isUpdateSerpToolSettings &&
          this.props.searchTermQuery &&
          this.props.searchTermQuery !== ''
      ) {
        if (this.state.searchTerm !== this.props.searchTermQuery) this.setState({ searchTerm: this.props.searchTermQuery });
        this.handleSubmit(null, true, false);
        updateSerpToolSettings.update(false);
      }
    });
    if(GetAuthToken())
        this.handleUserTokenStatus();
    // * Show gate
    // if (isFirstSerpSearch && !isEmailSubscribed && !GetAuthToken()) {
    //   this.props.updateGateContent('signIn');
    //   this.props.updateShowAccountGate(true);

    // }
    // * Update metrics selected for all cards
    this.subscriptionMetricSelected = updateMetricSelectedSubject.get().subscribe(isUpdateMetricSelectedSubject => {
      if (isUpdateMetricSelectedSubject) this.props.updateSelectedMetrics(isUpdateMetricSelectedSubject);
    });
    // * Run search when searchTerm query params is in url
    this.parseQueryString();

    this.props.updateScreenSize(MediaMatcher());

    window.addEventListener("resize", () => {
      this.props.updateScreenSize(MediaMatcher());
      if (MediaMatcher() === 'mobile' && this.props.selectedMetrics?.metricType === 'Filmstrip') {
        updateMetricSelectedSubject.update({
          metricType: 'Lab',
          metric: 'psi'
        });
      }
    });
	}

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.parseQueryString();
    }
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this._isMounted = false;
    this.subscription.unsubscribe();
    this.subscriptionMetricSelected.unsubscribe();
    if (this.props.displayGateTimer) clearTimeout(this.props.displayGateTimer);

	}
}

const mapStateToProps = state => {
  return {
    settingsDeviceSelected: state.settingsDeviceSelected,
    showAccountGate: state.showAccountGate,
    serpDomain: state.serpDomain,
    serpCountry: state.serpCountry,
    serpLanguage: state.serpLanguage,
    exportGoogleSerpData: state.exportGoogleSerpData,
    enableSerpExportBtn: state.enableSerpExportBtn,
    searchTermQuery: state.searchTermQuery,
    showRateLimitAlert: state.showRateLimitAlert,
    displayGateTimer: state.displayGateTimer,
    isSearchTermUrlAlert: state.isSearchTermUrlAlert,
    selectedMetrics: state.selectedMetrics,
    canViewAllDataAlert: state.canViewAllDataAlert,
    viewAllDataToggledOn: state.viewAllDataToggledOn,
    remainingSerpSearches: state.remainingSerpSearches,
    canViewFilterClearedAlert: state.canViewFilterClearedAlert,
    updateSchemaTypes: state.updateSchemaTypes,
    clearSchemaFilters: state.clearSchemaFilters,
    screenSize: state.screenSize,
    canViewSerpChecker: state.canViewSerpChecker,
    canViewMoreResultsAlert: state.canViewMoreResultsAlert,
    canViewExportAlert: state.canViewExportAlert
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
    updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate }),
    updateSearchTermQuery: (searchTermQuery) => dispatch({ type: 'SEARCH_TERM_QUERY', value: searchTermQuery }),
    updateExportGoogleSerpData: (serpData) => dispatch({ type: 'EXPORT_GOOGLE_SERP_DATA', value: serpData }),
    updateEnableSerpExportBtn: (enableSerpExportBtn) => dispatch({ type: 'ENABLE_SERP_EXPORT_BTN', value: enableSerpExportBtn }),
    updatePsiCounter: (psiCounter) => dispatch({ type: 'PSI_COUNTER', value: psiCounter }),
    updateShowSettingsModal: (showSettingsModal) => dispatch({ type: 'SHOW_SETTINGS_MODAL', value: showSettingsModal }),
    updateEmailSubscribeSearchQuery: (emailSubscribeSearchQuery) => dispatch({ type: 'EMAIL_SUBSCRIBE_SEARCH_QUERY', value: emailSubscribeSearchQuery }),
    updateSelectedMetrics: (selectedMetrics) => dispatch({ type: 'SELECTED_METRICS', value: selectedMetrics }),
    updateShowRateLimitAlert: (showRateLimitAlert) => dispatch({ type: 'SHOW_RATE_LIMIT_ALERT', value: showRateLimitAlert }),
    updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),
    updateIsSearchTermUrlAlert: (isSearchTermUrlAlert) => dispatch({ type: 'IS_SEARCH_TERM_URL_ALERT', value: isSearchTermUrlAlert }),
    updateScreenSize: (screenSize) => dispatch({ type: 'SCREEN_SIZE', value: screenSize }),
    updateFlimstripMap: (flimstripMap) => dispatch({ type: 'FLIMSTRIP_MAP', value: flimstripMap }),
    updateIsViewAllDataAlert: (isViewAllDataAlert) => dispatch({ type: 'IS_VIEW_ALL_DATA_ALERT', value: isViewAllDataAlert }),
    updateCanViewAllDataAlert: (canViewAllDataAlert) => dispatch({ type: 'CAN_VIEW_ALL_DATA_ALERT', value: canViewAllDataAlert }),
    updateViewAllDataToggle: (viewAllDataToggle) => dispatch({ type: 'VIEW_ALL_DATA_TOGGLED_ON', value: viewAllDataToggle }),
    updateRemainingSerpSearches: (remainingSearches) => dispatch({ type: 'REMAINING_SERP_SEARCHES', value: remainingSearches }),
    updateCanViewContentFilterAlert: (canViewFilterClearedAlert) => dispatch({ type: 'CAN_VIEW_FILTER_CLEARED_ALERT', value: canViewFilterClearedAlert }),
    canUpdateSchemaTypes: (updateSchemasTypes) => dispatch({type: 'CAN_UPDATE_SCHEMA_TYPES', value: updateSchemasTypes }),
    canViewUpdatedTypes: (canViewTypes) => dispatch( {type: 'CAN_VIEW_UPDATED_TYPES', value: canViewTypes }),
    updateCanViewSerpChecker: (canViewSerpChecker) => dispatch({ type: 'CAN_VIEW_SERP_CHECKER', value: canViewSerpChecker }),
    updateCanViewMoreResultsAlert: (canViewMoreResultsAlert) => dispatch({ type: 'CAN_VIEW_MORE_RESULTS_ALERT', value: canViewMoreResultsAlert }),
    updateCanViewExportAlert: (canViewExportAlert) => dispatch({ type: 'CAN_VIEW_EXPORT_ALERT', value: canViewExportAlert })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SerpPsiTool));
