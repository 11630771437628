import React, { Component } from 'react';

class KeywordAlerts extends Component {
    constructor(props) {
        super();
        this.state = {

        };
    }
    
    render() {
        return (
        <div>
          {
              this.props.duplicationAlert ?
                <div className="alert-container fade-in">
                  <div className="row d-flex justify-content-center">
                    <div className="col-sm-10 col-md-7 text-center">
                      <div
                        className="alert alert-warning alert-onboarding"
                        role="alert"
                        data-color="info"
                        onClick={() => this.props.closeDeduplicationAlert()}>
                        <div className="d-flex justify-content-between">
                          {this.props.temperature <= 0.99
                            ? <span className="alert-copy">
                                the response does not contain any unique terms, try increasing the randomness in <u onClick={() => this.props.showSettingsModal()}><strong>settings</strong></u>
                              </span>
                            : null}
                          {this.props.temperature === 1
                          ? <span className="alert-copy">
                            the response does not contain any unique terms, try adjusting your search term
                            </span>
                        : null }
                          <i className="fas fa-times fa-sm alert-close"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              : null
            }
            {
                this.props.showCreateAccountMessage ?
                <div className="alert-container fade-in">
                <div className="row d-flex justify-content-center">
                    <div className="col-sm-10 col-md-7 text-center">
                    <div
                        className="alert alert-warning alert-onboarding"
                        role="alert"
                        data-color="info"
                        onClick={() => this.props.closeCreateAccountAlert()}>
                        <div className="d-flex justify-content-between">
                        <span className="alert-copy">
                            please create an account or sign in
                        </span>
                        <i className="fas fa-times fa-sm alert-close"></i>
                        </div>
                    </div>
                    </div>
                </div>
                </div> : null
            }
            {
                this.props.showRateLimitAlert ?
                <div className="alert-container fade-in">
                    <div className="row d-flex justify-content-center">
                    <div className="col-sm-10 col-md-7 text-center">
                        <div
                        className="alert alert-info alert-onboarding"
                        role="alert"
                        data-color="info"
                        onClick={() => this.props.closeRateLimitAlert()}>
                        <div className="d-flex justify-content-between">
                            <span className="alert-copy">
                            You have hit your monthly search limit. <u onClick={() => this.props.linkToPricingPage()}>Upgrade here</u>.
                            </span>
                            <i className="fas fa-times fa-sm alert-close"></i>
                        </div>
                        </div>
                    </div>
                </div>
                </div> : null
            }
        </div>
        );
    }
}


export default KeywordAlerts;