import axios from 'axios';
import { GetAuthToken } from './AuthStatus';

export async function IpRateLimitingApi(keywordTerm, keywordTotal) {
  const token = GetAuthToken();
    try {

      var config = {
        method: 'get',
        url: `${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/ip-rate-limit/ip-limit`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      return axios(config)
        .then(function (response) {
          return response.data;
        }).catch(function (error) {
          console.log(error);
        });

    } catch (error) {
      return error.response;
    }
}
