import React, { Component } from 'react';
import CreateAccount from '../Account/CreateAccount';
import SignIn from '../Account/SignIn';
import { connect } from 'react-redux';
import CreatePassword from '../Account/CreatePassword';

class AccountGateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
		};
	}

	mapAccountGateContent = () => {
		const contentMapping = {
			createAccount: () => <CreateAccount />,
			signIn: () => <SignIn />,
			createPassword: () => <CreatePassword />
		};
		return contentMapping[this.props.accountGateContent]
			? contentMapping[this.props.accountGateContent]()
			: contentMapping['createAccount']()
	}

  render() {
    return (
      <div className="account-gate-modal fade-in">
				<div className="modal-dialog modal-xl">
					<div className="account-gate-modal-container modal-content p-4">
						{this.mapAccountGateContent()}
					</div>
        </div>
			</div>
    );
	}

	componentDidMount() {
		const bodyEl = document.getElementsByTagName('body')[0];
		if (bodyEl) bodyEl.classList.add('modal-open');
	}
}

const mapStateToProps = state => {
  return {
		accountGateContent: state.accountGateContent
  };
};

export default connect(mapStateToProps)(AccountGateModal);
