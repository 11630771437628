import LOCALSTORAGE from '../constants/LocalStorage';
import { GetAuthToken } from './AuthStatus';

export function DisplayAccountGateCheck() {
  if (typeof localStorage !== 'undefined') {
    const isFirstSerpSearch = JSON.parse(localStorage.getItem(LOCALSTORAGE.isFirstSerpSearch));
    const isEmailSubscribed = JSON.parse(localStorage.getItem(LOCALSTORAGE.isEmailSubscribed));
    // * Display account gate
    if ((!isEmailSubscribed && !GetAuthToken()) || (!isEmailSubscribed && GetAuthToken() === null)) {
      if (!isFirstSerpSearch) localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
      return true;
    } else {
      return false;
    }
  }
}
