import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import ReactGA from 'react-ga';

class HomePage extends Component {
  constructor(props) {
    super();
		this.state = {
      selectedOption: null
		};
  }

  render() {
    return (
      <div className="container-fluid page-background1">
        <Helmet>
          <meta name="description" content=""/>
          <title>SEO Tools | Sandbox</title>
        </Helmet>
        <Header />
        <div className="container" style={{ marginBottom: '30rem' }}>
          <div className="row pt-3">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between">
                <h1
                  className="header-title-text mt-0"
                  // onClick={() => this.gotToSandboxweb()}
                  >
                  Sandbox
                </h1>
              </div>
            </div>
            
            <div className="col-sm-12">
              <Link to={'/serp-analysis-tool'}><b>SERP Analysis</b></Link>
            </div>
            <div className="col-sm-12 pb-3">
              <Link to={'/serp-analysis-tool'}>
                Compare site speed and page content across results of a Google search
              </Link>
            </div>
            
            <div className="col-sm-12">
              <Link to={'/serp-checker'}><b>SERP Checker</b></Link>
            </div>
            <div className="col-sm-12 pb-3">
              <Link to={'/serp-checker'}>
                Instantly check your Google rankings with our fast and accurate SERP Checker tool
              </Link>
            </div>

            <div className="col-sm-12">
              <Link to={'/keyword-suggestion-tool'}><b>Programmatic SEO Keyword Tool</b></Link>
            </div>
            <div className="col-sm-12  pb-3">
              <Link to={'/keyword-suggestion-tool'}>
                Find large keyword sets, apply modifiers
              </Link>
            </div>

            <div className="col-sm-12">
              <Link to={'/programmatic-seo-idea-tool'}><b>Programmatic SEO Idea Tool</b></Link>
            </div>
            <div className="col-sm-12  pb-3">
              <Link to={'/programmatic-seo-idea-tool'}>
                Get keyword ideas, entities, and categories for programmatic SEO
              </Link>
            </div>

            <div className="col-sm-12">
              <Link to={'/website-speed-test'}><b>Website Speed Test</b></Link>
            </div>
            <div className="col-sm-12 pb-3">
              <Link to={'/website-speed-test'}>
                Run a speed test with multiple samples for higher accuracy
              </Link>
            </div>

            <div className="col-sm-12">
              <Link to={'/tweetjar/googlesays'}><b>Google Says</b></Link>
            </div>
            <div className="col-sm-12">
              <Link to={'/tweetjar/googlesays'}>
                Search SEO tweets from Google team
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}
export default HomePage;
