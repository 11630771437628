// import ReactGA from 'react-ga';
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SerpKeywordRankingModal from '../Modals/SerpKeywordRankingModal';

class SerpKeywordRankChecker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serpRankUrl: "",
      rankResult: false,
      rankingPosition: 0,
      resultTitle: "",
      domain: "",
      link: "",
      serpFound: ""
    }
    this.inputRef = React.createRef();
  }

  handleRankInputChange = (event) => {
    const value = event.target.value;
    this.setState({ serpRankUrl: value });
  }

  handleRankSearch = (event) => {
    if (event) event.preventDefault();
    this.inputRef.current.blur();
    if(this.state.serpRankUrl !== "") {
      this.props.updateCanViewKeywordRankingsModal(true);
      const params = {
        searchTerm: this.props.searchTerm,
        device: this.props.settingsDeviceSelected,
        pageNum: 1,
        google_domain: this.props.serpDomain,
        gl: this.props.serpCountry,
        hl: this.props.serpLanguage
      }

      this.setState({ serpRankUrl: this.state.serpRankUrl });
      axios.post(process.env.REACT_APP_SERP_CHECKER_API, params)
        .then(response => {
          const urlRankingMap = response.data.organic_results;
          var domain = "";
          var getDomain = this.state.serpRankUrl.match(/\/\/(.*?\/)/) || this.state.serpRankUrl.match(/\/\/(.*)/g);
          if(getDomain !== null) {
            var removeSlashes = getDomain[0].replace(/\//g, "");
            domain = removeSlashes.replace(/www./, "");
          } else if(this.state.serpRankUrl.substr(0,4) === "www.") {
            domain = this.state.serpRankUrl.replace(/www./, "");
          } else {
            domain = this.state.serpRankUrl;
          }

          if(urlRankingMap.length > 0){
            for (var i = 0; i < urlRankingMap.length; i++) {
                if(urlRankingMap[i].domain.replace(/www./, "") === domain.trim()){
                  this.setState({ rankingPosition: urlRankingMap[i].position });
                  this.setState({ resultTitle: urlRankingMap[i].title });
                  this.setState({ domain: urlRankingMap[i].domain });
                  this.setState({ link: urlRankingMap[i].link });
                  break;
                }

            }
            if(this.state.rankingPosition === 0) this.setState({ serpFound: "N/A"});
            this.props.updateCanViewKeywordRankingsModal(true);
          }
        }).catch(err => {
          console.log(err);
        });
    }
  }

  render() {
    return (
    <div className="row p-0 ml-0">
      <form className="serp-checker-form">
        <div className="input-group pb-2">
          <input ref={this.inputRef}
            id="serpCheckerSearch"
            type="text"
            className="form-control"
            placeholder="https://"
            aria-label="Search Term"
            aria-describedby="Search Term"
            value={this.state.serpRankUrl}
            onChange={this.handleRankInputChange}
          />
          <button style={{display: this.props.screenSize !== 'mobile' ? "" : "none"}}
            className="btn btn-outline-primary"
            type="submit"
            id="submitSerpSearch"
            data-ripple-color="dark"
            onClick={(e) => this.handleRankSearch(e)}>
            find
          </button>
        </div>
      </form>
      {
        this.props.viewKeywordRankingsModal ?
        <SerpKeywordRankingModal
          domain={this.state.domain}
          title={this.state.resultTitle}
          serpRankUrl={this.state.serpRankUrl}
          rankingPosition={this.state.rankingPosition > 0 ? this.state.rankingPosition : this.state.serpFound}
          link={this.state.link}
         />
      : null
    }

    </div>

  );
  }

}

  const mapStateToProps = state => {
    return {
      screenSize: state.screenSize,
      settingsDeviceSelected: state.settingsDeviceSelected,
      serpDomain: state.serpDomain,
      serpCountry: state.serpCountry,
      serpLanguage: state.serpLanguage,
      viewKeywordRankingsModal: state.viewKeywordRankingsModal
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      updateCanViewKeywordRankingsModal: (canViewRankingsModal) => dispatch({ type: 'CAN_VIEW_KEYWORD_RANKINGS_MODAL', value: canViewRankingsModal })
    };
  }


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SerpKeywordRankChecker));
