import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Footer from '../components/Footer/Footer';

class ConfirmationPage extends Component {
  constructor(props) {
    super();
		this.state = {
    };
  }

  navigateToHp = () => {
    this.props.history.push({ pathname: '/' });
  }

  render() {
    return (
      <div className="container-fluid page-background1 pb-5">
        <Helmet>
          <meta name="description" content=""/>
          <title>Confirmation | Sandbox</title>
        </Helmet>
        <Header />
        <div className="container page-content-padding" style={{ marginBottom: '30rem' }}>
          <div className="row pt-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Confirmation</li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12">
              <p>
                <b>Success! You have subscribed to the {this.props.currentPlan.plan.charAt(0).toUpperCase() + this.props.currentPlan.plan.slice(1)} Plan</b>
              </p>
              {/* <p>Your plan will renew on XX/XX/XX</p> */}
              <button
                type="button"
                className="btn btn-dark btn-sm"
                onClick={() => this.navigateToHp()}>
                get started
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
  }

  componentWillUnmount() {
	}
}

const mapStateToProps = state => {
  return {
    currentPlan: state.currentPlan
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmationPage));