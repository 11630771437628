import React, { Component, Suspense } from 'react';
import SerpPsiTool from '../components/SerpPsiTool/SerpPsiTool';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import ReactGA from 'react-ga';
// import Typewriter from 'typewriter-effect';
import BackToTopArrow from '../components/BackToTopArrow';
// import { InView } from 'react-intersection-observer';

const SerpPageVideo = React.lazy(() => import("../components/SerpPsiTool/SerpPageVideo"));
const TypewriterLazy = React.lazy(() => import('../components/SerpPsiTool/TypewriterLazy'));

class SerpPsiPage extends Component {
  constructor(props) {
    super();
		this.state = {
      arrow: null,
      competitorSchemas: "../images/competitorschemas.jpg",
      faqPage: "../images/faqpage.jpg",
      faqValidator: "../images/faqvalidator.jpg",
      mobileAnalysis: "../images/mobile_analysis.jpg",
      schemaAnalysis: "../images/schema_analysis.jpg",
      schemaComparison: "../images/schemacomparison.jpg",
      siteSearch: "../images/sitesearch.jpg",
      siteSpeed: "../images/sitespeed.jpg",
      userAgentGoogle: false
		};
  }

  // siteSpeedConsultingGaEvent = () => {
  //   ReactGA.event({ category: 'speed comparison', action: 'click', label: 'consulting link in paragraph' });
  // }

  handleButtonJump = (element) => {
    var siteSpeedAnalysis = document.getElementById(element);
    var totalDis = window.pageYOffset + siteSpeedAnalysis.getBoundingClientRect().top;
    window.scrollTo(0, totalDis - 80);
  }

  render() {
    return (
      <div className="serp-psi-page-bkg">
        <Helmet>
          <meta name="description" content="Compare site speed and page content across results of a Google search"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <title>SERP Analysis Tool | SEO Keyword Rank Checker | Sandbox</title>
          {/* Facebook meta tags */}
          <meta property="og:title" content="SERP Analysis Tool | Keyword-driven SEO Speed Test | Sandbox" />
          <meta property="og:image" content="" />
          <meta property="og:description" content="Compare site speed and page content across results of a Google search" />
          <meta property="og:url" content="https://tools.sandboxweb.io/serp-analysis-tool" />
          <meta property="og:type" content="website" />
          {/* Twitter meta tags */}
          <meta property="twitter:title" content="SERP Analysis Tool | Keyword-driven SEO Speed Test | Sandbox" />
          <meta property="twitter:description" content="Compare site speed and page content across results of a Google search" />
          <meta property="twitter:image" content="" />
          <meta property="twitter:card" content="" />
        </Helmet>
        <Header />

        <section className="container-fluid shadow-2-strong background1 page-content-padding">
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={'/'}>Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">SERP Analysis</li>
                  </ol>
                </nav>
              </div>
              <div className="col-sm-12">
                <h3 className="compare-site-speed">Compare <span className="type-writer">
                  <Suspense fallback={<div>site speed</div>}>
                    <TypewriterLazy/>
                  </Suspense>
                  </span>
                  {
                    this.props.screenSize !== "mobile"
                    ? <span className="block-title">across results of a Google search</span>
                    : <span className="block-title">across SERP results</span>
                  }
                </h3>
              </div>
              <div className="col-sm-12">
                <SerpPsiTool />
              </div>
            </div>
          </div>
          <BackToTopArrow />
        </section>

      <section className="container-fluid seo-container pt-5">
        <div className="container">
          <section>
            <p>
              We&apos;re proud of the data our tool provides, but as any marketer worth their salt knows, data without context is useless.
              To that end, we&apos;ve constructed a list of what we believe are the most important SEO ranking factors in {this.getYear()}.
            </p>
            <p>
              We encourage you to use this list while conducting your analysis.
              Consider how the SERP &apos;winners&apos; are successfully ticking these boxes, and where there might be an opportunity to oust them from the top.
            </p>
            <p>
              And if you want to get to know all of the ins and outs of the tool itself, worry no longer. There are <span role="button" onClick={() => this.handleButtonJump("our-seo-tool")}><b>in-depth instructions</b></span> at the tip of your fingertips. From us, to you.
            </p>
            <h2>
              The Most Important SEO Ranking Factors for {this.getYear()}.
            </h2>
            <div className="row p-3 pb-0">
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("contentrel")} className="btn btn-primary anchor-btn">Content Relevance</button></div>
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("ux")} className="btn btn-primary anchor-btn">User Experience</button></div>
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("sitespeed")} className="btn btn-primary anchor-btn">Site Speed</button></div>
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("backlinks")} className="btn btn-primary anchor-btn">Backlinks</button></div>
            </div>
            <div className="row p-3 pb-5 pt-0">
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("internallinks")} className="btn btn-primary anchor-btn">Internal Links</button></div>
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("authority")} className="btn btn-primary anchor-btn">Brand Authority</button></div>
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("schema")} className="btn btn-primary anchor-btn">Schema Markup</button></div>
              <div className="col-sm-3 p-0 text-center anchor-btn-holder"> <button type="button" onClick={() => this.handleButtonJump("serp")} className="btn btn-primary anchor-btn">SERP Features</button></div>
            </div>
            <p className="pt-3">
              As the great 1960s marketer Don Draper predicted, "One day, Madison Avenue will fall, the internet will rise, and SEO will rule the land.”
            </p>
            <p>
              In 1998, that prediction came true, as Google launched their storied search engine.
            </p>
            <p>
              Internet marketers took notice, and within about 5 minutes, they began  their attempts to hack the algorithm.
            </p>
            <p>
              With that, SEO was born.
            </p>
            <p>
              From extreme traffic growth to brand awareness, the right SEO strategy can be lightning in a bottle.
            </p>
            <p>
             However, as the web evolves, so do Google's ranking factors.
            </p>
            <p>
             To win in {this.getYear()}, you need to be aware of the current state of the algorithm, and understand how it affects your website.
            </p>
            <p>
              So for you, we&apos;ve put together the most important SEO ranking factors:
            </p>
            <section id="contentrel">
              <h3>
                Content Relevance
              </h3>
              <p>
                In the beginning, the algorithm&apos;s intelligence level was akin to that of a dumb 5 year old.
                Easily distracted and wired for simple pleasures, it could be satisfied with a bowl of sugar-dusted keywords and a sippy cup of sweet, cheap backlinks.
              </p>
              <p>
                Then, in 2012, everything changed. The child grew up and got much wiser.
              </p>
              <p>
                Today, your content must be more relevant. Beyond simple keyword optimization,
                it must be informative, valuable, and most importantly, it must satisfy the user&apos;s intent,
                which comes in a billion different flavors and is unique to every keyword.
              </p>
              <p>
                For many keywords, relevance equates to content recency, so you can improve your rankings by regularly publishing fresh, original,
                and timely content as it relates to your niche.
              </p>
            </section>
            <section id="ux">
              <h3>The User Experience</h3>
              <p>
                So, Google wants to see content that's relevant to the query.
                That's always been the case. But the algorithm is evolving to consider UX as well. Google rewards pages which give users the best experience.
              </p>
              <p>
               In whole, we believe this is a stronger signal than any other in Google&apos;s algorithm, including backlinks.
               So, how does Google actually measure this?
               Beyond the <a href="https://developers.google.com/search/docs/advanced/experience/page-experience" target="_blank" rel="noopener noreferrer"><u>Page Experience metrics</u></a>, dwell rate is an important
               concept to understand. You can learn more about that
               <a href="https://www.google.com/url?q=https://www.sandboxweb.io/site-speed-consulting&sa=D&source=docs&ust=1654229474412492&usg=AOvVaw2ghsV3dAHva0oo1uS_po0A" target="_blank" rel="noopener noreferrer"> <u>here.</u></a>
              </p>
              <p>
              Curious what poor UX looks like? Imagine you are on a website that sells shoes. You're trying to buy the best rated running shoes. You have a hard time finding running shoes,
              then you can&apos;t figure out how to sort by the avg # of review stars, then the estimated delivery date doesn&apos;t make sense. Frustrating!
              </p>
            </section>
            <section id="sitespeed">
              <h3>Site Speed</h3>
              <p>
                Yes, Site Speed is a ranking factor. Google confirmed it here when they announced the “Speed Update.”
                Rolled out in 2018, it targets sites that deliver the “slowest experience.”
              </p>
              <p>
                To try and discern a more quantifiable impact of site speed on rankings,
                <a href="https://neilpatel.com/blog/does-speed-impact-rankings/#:~:text=Rank%206%20was%2C%20on%20average,to%20first%20byte%20(TTFB)" target="_blank" rel="noopener noreferrer"> <u>this</u> </a>
                study used a random keyword generator to sample over 140,000 URLs across 5,000 SERPs. It showed that “Rank 6 was, on average, 20% slower than rank 1.”
              </p>
              <p>
              Another compelling statistic: Bounce rates increase by 9 percent if your site takes 2 seconds to load. That stat goes up to nearly 40 percent if it takes 5 seconds to load.
              </p>
              <p>
              In today's fast-paced world, visitors expect to be able to find what they're looking for quickly and easily.
              If your site is slow to load, they're likely to click away and look elsewhere.
              </p>
              <p>
              There are a few things you can do to improve your site speed, such as optimizing your images and using a content delivery network.
              Try <a href="https://pagespeed.web.dev/" target="_blank" rel="noopener noreferrer"><u>PageSpeed Insights</u></a>, Google&apos;s own site speed tool, to learn more.
              </p>
            </section>
            <section id="backlinks">
              <h3>Backlinks</h3>
              <p>
                Using backlinks &#40;links from other websites&#41; as a ranking factor is one of the things that set Google apart from Yahoo, Lycos, and other early search engines.
              </p>
              <p>
                Today, backlinks are still an important ranking factor, although Google has gotten much better at evaluating how meaningful, and thus valuable, any given link is.
                Links from small, unrelated websites are obviously much less valuable than a link from a major website in a relevant field.
              </p>
              <p>
                There are a many ways to build backlinks, including:
              </p>
              <ul>
                <li>Guest blogging</li>
                <li>Creating and sharing infographics</li>
                <li>Gaining links from online mentions that weren&apos;t previously linked</li>
                <li>Snagging your competitor&apos;s broken backlinks</li>
                <li>Sponsoring niche-specific or community events, either online or in-person</li>
              </ul>
              <p>
                You can also reach out to other websites in your niche and see if they're interested in linking to your site or high-quality article.
              </p>
            </section>
            <section id="internallinks">
              <h3>Internal Links</h3>
              <p>
                As we discussed above, Google cares deeply about the relevance of content to the search queries it&apos;s &#40;trying to&#41; rank for. Internal links send important signals about relevancy and
                relative importance of content on your website.
              </p>
              <p>
                Put simply, the more links a page receives, the more important it will appear to search engines. After all, if you keep bringing something up in a conversation, it stands to reason that you value
                that thing pretty heavily!
              </p>
              <p>
                To improve your internal linking, make sure you&apos;re linking to your own pages pretty much whenever it&apos;s relevant and makes sense to do so. For example, if you mention popcorn in your page
                about the best snacks to eat while watching a movie, you should link to your other article about the best ways to make popcorn.
              </p>
              <p>
                Make sure to use natural language anchor text for your links, so users and search engines alike can understand what kind of content they&apos;re being linked to before they click.
                Avoid generic anchor texts like “Click here to learn more” which are useless and provide no contextual relevance.
              </p>
            </section>
            <section id="authority">
              <h3>Expertise - Authority - Trustworthiness</h3>
              <p>
                Building a solid brand is important for any business, and that holds true in the context of SEO as well. Google highly values “Expertise - Authority - Trustworthiness” &#40;E-A-T&#41; as a
                ranking signal; for good reason. Just like users want to consume content from trustworthy sources, Google wants to make sure it&apos;s serving content that will actually help its users.
              </p>
              <p>
                Continually positioning your business as an expert on your topic of choice is key. Do this by consistently publishing strong, authoritative content, and consider hiring experts to write some of that
                content as well. Bylines carry a lot of weight in the E-A-T world!
              </p>
            </section>
            <section id="schema">
              <h3>Schema Markup</h3>
              <p>
                Schema markup is a form of structured data that organizes and conveys information to search engines like Google about things like events, products, people, etc.
              </p>
              <p>
                Implementing these technical SEO elements can improve the way your site displays in the SERPs allowing Google to display your content in rich snippets which may increase your site's clickthrough rate
                and page ranking.
              </p>
              <p>
                Schema is a broad, complicated language and definitely not something we suggest you try to implement without an expert.
              </p>
            </section>
            <section id="serp">
              <h3>SERP Features</h3>
              <p>
                SERP features are the non-standard result types that appear on certain SERPs. While they aren&apos;t really a ranking factor, these special features are definitely something to keep in mind when
                designing and optimizing your content.
              </p>
              <p>
                The specific features to target will vary based on your content, but it&apos;s easy to imagine the benefit of adding review stars to an ecommerce page, or having your informational article displayed at
                the top in a featured snippet.
              </p>
              <p>
                If you want to learn more, Moz has an excellent resource cataloging and discussing the obtainability of all the <a href="https://moz.com/learn/seo/serp-features" target = "_blank" rel="noopener noreferrer"><u>different types of SERP features.</u></a>
              </p>
            </section>
            <section>
              <h3>Stay on top of it with our SEO Twitter tool, <i>Google Says</i></h3>
              <p>
                In the fast-moving world of SEO, it's important to keep an eye on what Google is saying about ranking factors.
              </p>
              <p>
                One way to do this is to subscribe to the
                <a href="https://developers.google.com/search/blog" target="_blank" rel="noopener noreferrer"> <u>Google Search Central Blog</u></a>
                , an excellent resource for SEO news and updates.
              </p>
              <p>
               In addition, a powerful tool you can use to see what Googlers are tweeting about SEO and other related releases, news, products and more is our very own
              <a href="https://tools.sandboxweb.io/tweetjar/googlesays" target="_blank" rel="noopener noreferrer"> <u>Google Says</u></a>.
              </p>
              <p>
                The tool gives one of the best windows into Google's black box by letting you search and filter tweets from John Mueller, Martin Splitt, Danny Sullivan, etc.
              </p>
              <p>
                By keeping up with the latest news from Google, you can make sure that your site is continually optimized for the latest algorithm changes. As we always say, SEO is an ongoing process. Because of how dynamic the industry is, no site is ever finished being optimized, and no individual SEO is ever finished learning.
              </p>
            </section>
          </section>
          <section>
            <div id="our-seo-tool">
              <h2>Using the Sandbox SERP Analysis Tool</h2>
              <div className="row pt-4 pb-5">
                  <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("contentanalysis")} className="btn btn-primary anchor-btn">Content Analysis</button></div>
                  <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("speedanalysis")} className="btn btn-primary anchor-btn">Site Speed Analysis</button></div>
                  <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("schemaanalysis")} className="btn btn-primary anchor-btn">Schema Analysis</button></div>
                  <div className="col-sm-3 p-0 text-center anchor-btn-holder"><button type="button" onClick={() => this.handleButtonJump("serpsim")} className="btn btn-primary anchor-btn">SERP Simulation</button></div>
              </div>
              <p>
                As you can see, the strategy for better search engine ranking is essential for modern marketers to stay competitive. Fortunately, there are several ways that this SERP analytics tool can help you with your SERP analysis and research so that you can quickly and easily improve your website ranking.
              </p>
            </div>
            <div id="contentanalysis">
              <h3>Content Analysis</h3>
              <p>
                For your website to rank well, you need to have high-quality, relevant and keyword-rich content. Sandbox can help you analyze your content needs in a few different ways.
              </p>
              <p>
                Our SERP Analysis tool finds the current ranking content for the keyword that you put in the search bar. It shows you the top ranking URL&apos;s, their title tag, mobile website speed score, and word count.
              </p>
              <p>
                This information is very useful to see what competitors you are facing and what the ranking word count is for the keyword you put in. A broader keyword very often requires a longer-form content than a very, narrow or specific topic.
              </p>
              <p>
                The &apos;more&apos; button shows you data and SERP analytics like content headers, schema types, and website load data.
              </p>
            </div>
            <div id="speedanalysis">
              <h3>Site Speed Analysis</h3>
              <p>
                As we have seen above, website speed is an important ranking factor and comparing &amp; improving your website load speed against your competitors can give you the advantage edge over them.
              </p>
              <p>
                You can do a few things to improve your site speed, such as optimizing your images and using a content delivery network.
              </p>
              <p>
                More importantly, site speed isn&apos;t just about reducing the size of your images. It&apos;s about optimizing all aspects of your website for fast loading times. This includes everything from your hosting environment to your code architecture.
              </p>
              <p>
                If you aren't sure about your website's performance, you can compare site speed and page content across results of a Google search with Sandbox's
                <a href="https://tools.sandboxweb.io/website-speed-test"> <u>Website Speed Test</u></a>.
                The tool lets you run a speed test with multiple samples for higher accuracy.
              </p>
            </div>
            <div id="schemaanalysis">
              <h3>Schema Analysis</h3>
              <p>
                If you want your website to appear in certain SERP features, you&apos;ll need to implement schema markup. Google&apos;s rich results are powered by structured data, which is code that helps search engines understand the content on your website.
              </p>
              <p>
                Our tool shows you which schema types are implemented on your competitor&apos;s website. Again…this knowledge can gain you the edge.
              </p>
              <p>
                If you&apos;d like to see a great demo video on our Schema tool, check out this video from Steve Toth at SEO Notebook
              </p>
              {
                !this.state.userAgentGoogle
                ? <Suspense fallback={<div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}>
                    <SerpPageVideo />
                  </Suspense>
                : <iframe className="yt-video mt-2 mb-4" src="https://www.youtube.com/embed/31wYvqJgiRk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }

              <p>
                Implementing schema markup doesn&apos;t require web development skills.  This can be as easy as utilizing a schema markup generator and implementing it in Google Tag Manager.
              </p>
              <p>
                Some quick online study of this coding, however, may be useful if you want to build out more thorough markup than the basic generators often provide.
              </p>
              <div>
                <h4>The Top 10 Schema Types</h4>
                <p>
                  Below are a few of the more popular and useful schema types that can help you improve your website&apos;s appearance in SERP features.
                </p>
                <ol className="serp-pg-schema-type-list">
                  <li>
                    <b>FAQ page schema:</b> This one is perfect for websites with a lot of content. It contains specialized markup that you can add to a webpage&apos;s code that contains a list of questions
                    and answers. Google then reads this markup and uses it to generate a rich snippet.
                  </li>
                  <li>
                    <b>Breadcrumb markup:</b> As its name suggests, this schema type gives your website the ability to create snippet-rich breadcrumbs to help users navigate your website.
                  </li>
                  <li>
                    <b>Article schema:</b> Adding this schema markup to your blogs and news articles often enhance their appearance in Google search engine results.
                      <img className="mt-2 mb-2 serp-pg-img" src={this.state.schemaAnalysis} alt="An analysis of the article schema used by k9oofmine.com in a Sandbox query for the keyword “organic dog food.”"></img>
                      <p> An analysis of the article schema used by k9oofmine.com in a Sandbox query for the keyword “organic dog food.”</p>
                  </li>

                  <li>
                    <b>News article schema:</b> Like article schema, news article schema will enhance the appearance of blogs and news articles when they are searched on Google.
                  </li>
                  <li>
                    <b>Organization:</b> This schema offers a brand signal which enhances a Knowledge Graph and presence of website snippets in search engine results.
                  </li>
                  <li>
                    <b>Product schema:</b> Product schema markup tells Google about a product and its offers. This can help your product appear in a rich snippet or even in an ad.
                  </li>
                  <li>
                    <b>Website schema markup:</b> You can use this schema markup to help Google understand your site&apos;s structure.
                    This can also be helpful for getting your website featured in rich snippets or even in search ads.
                  </li>
                  <li>
                    <b>Site navigation element:</b> This schema markup helps increase search results by elevating Google&apos;s understanding of your site structure and navigation.
                    Additionally, it can be used to sway organic sitelinks in your favor.
                  </li>
                  <li>
                    <b>Video object schema:</b> With this schema markup, you can help Google understand your videos. This can be helpful for getting your videos to display in SERP features like video carousels.
                  </li>
                  <li>
                    <b>Ratings markup schema:</b> If you want your website&apos;s ratings and reviews to appear in SERP features, you&apos;ll need to implement a rating markup schema.
                    This schema type allows you to add specialized code to a webpage that contains ratings and reviews. Google then reads this markup and uses it to generate a rich snippet.
                  </li>
                </ol>
                <p>
                  Other schema types can be helpful for your website, but these 10 are a great place to start.
                </p>
                <p>
                  Here is an example of how the <u>Sandbox SERP Analysis tool</u> compares competitor schema types, word count and site speed metrics.
                </p>
                <p>
                  Search for the keyword “organic dog food.”
                </p>
                <img className="mb-4 serp-pg-img" src={this.state.siteSearch} alt="SEO SERP anaylsis tool being used for schema research"></img>
                <p>
                  The main types of schema types are listed alongside Site Speed metrics.
                </p>
                <img className="mb-4 serp-pg-img" src={this.state.siteSpeed} alt="View of site speed analysis and schema anaylsis using Sandbox's SERP Analysis Tool"></img>
                <p>
                  Clicking on Schema Types, you can see which types competitor sites use.
                </p>
                <img className="mb-4 serp-pg-img" src={this.state.competitorSchemas} alt="Competitor using FAQ schema"></img>
                <p>Clicking the FAQPage allows you to validate the FAQ schema used.</p>
                <img className="mb-4 serp-pg-img" src={this.state.faqValidator} alt="Competitor using News Article schema"></img>
                <img className="serp-pg-img" src={this.state.faqPage} alt="FAQ Page schema validator"></img>
              </div>
            </div>
            <div id="serpsim">
              <h3>SERP Simulation</h3>
              <p>
                One of the best things about the web is that it&apos;s available worldwide for people to use. Therefore, your keywords must target the best markets in every part of the world where these users are located.
              </p>
              <p>
                The SERP Simulation allows you to easily change the country from a dropdown menu to find the best keyword opportunities in the country you are targeting.
              </p>
              <p>
                That&apos;s where Sandbox comes in, and you can easily change the languages—there are -X- number of languages available.
              </p>
              <img className="mb-4 serp-pg-img" src={this.state.schemaComparison} alt="Schema Comparison"></img>
              <p>
                Also, you can change the device from mobile to desktop for SERP scores.
              </p>
              <img className="mb-4 serp-settings-img" src={this.state.mobileAnalysis} alt="Mobile Analysis"></img>
            </div>
            <div>
              <h2>FAQ</h2>
              <p>
                Here are a few of the questions we are frequently asked at SEO Sandbox.
              </p>
              <h3>How do you analyze a SERP?</h3>
              <p>
              There are a few different ways to analyze a SERP. You can look at the overall layout of the page, the number and types of results, and the position of each result. You can also look at the SERP features that are present, such as rich snippets and featured snippets. Our SERP Analysis Tool offers an automated and powerful analysis.
              </p>
              <h3>What are SERP Analytics?</h3>
              <p>
              SERP Analytics is the systematic analysis of SERP data, with the goal of understanding the ranking positions of your competitors. This data comes in many forms. For example, when analyzing backlink profiles, you’ll review data that measures the quantity and quality of the domains that point to the SERP pages. If you’re comparing word count, you’ll examine data that accurately reflects the number of user-visible words in the rendered html.
              </p>
              <h3>What is a SERP Checker?</h3>
              <p>
                Our SERP Checker tool allows you to check the Google SERP ranking of your domain on any keyword, across any combination of device and location.  This is useful if your domain is not ranking on the first page. We check the top 100 SERP Results.
              </p>
              <h3>What are Google SERP features?</h3>
              <p>
                Google SERP features are elements that appear on the search engine results pages &#40;SERPs&#41;. They are designed to give users more information about the results and
                help them find what they&apos;re looking for. Common SERP features include rich snippets, featured snippets, Knowledge Graphs and carousels.
              </p>
              <h3>What is Search Intent?</h3>
              <p>
                Search intent is the reason why someone is searching for something on the internet. It&apos;s generally categorized as one of four types: navigational, informational, commercial, or transactional.
                Understanding search intent is important for SEO because it can help you create content that better meets the needs of your audience.
              </p>
            </div>
            <h2>Let Sandbox Help Your SERP Ranking Today</h2>
            <p>
              <i>Sandbox works with startups and small caps to drive growth through the intersection of user experience and SEO. Our team is made up of product managers, UX designers,
                SEO analysts and web developers who work with your company to deliver the ultimate in user experience.</i>
            </p>
            <p >
              You can learn more <a href="https://www.sandboxweb.io/" target="_blank" rel="noopener noreferrer"><u>here</u></a>.
            </p>
            <br></br>
          </section>
        </div>
      </section>

        <section className="container-fluid background1 page-content-padding">
          <Footer />
        </section>
      </div>
    );
	}

  getYear() {
    return new Date().getFullYear();
  }

  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    const agent = window.navigator.userAgent;
    // const testAgent = "Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)";
    if (agent.toLowerCase().includes("googlebot")){
      this.setState({ userAgentGoogle: true });
    }
  }

}

const mapStateToProps = state => {
  return {
    screenSize: state.screenSize
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SerpPsiPage);
