import React, { Component } from 'react';

class SerpPsiExportToolTipEnabled extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

  render() {
    return (
      <i
        className={`${this.props.plan > 1 ? 'fas fa-file-download fa-2x download-btn' : 'fas fa-lock export-lock' }`}
        id="tool-tip-export-psi-enabled"
        data-toggle="tooltip"
        data-placement="top"
        data-trigger='manual'
        title={`${this.props.plan > 1 ? "Export CSV with all metrics" : "Paid users can export a csv with all metrics" }`}
        >
      </i>
    );
  }

}

export default SerpPsiExportToolTipEnabled;
