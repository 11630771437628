import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import { GetAuthToken } from '../functions/AuthStatus';
import { connect } from 'react-redux';
import PRICING from '../constants/Pricing';
import { updateSignedInStatusSubject } from '../observables/SignedInStatus';
import { withRouter } from 'react-router-dom';
import BILLING_CYCLE_ID from '../constants/BillingCycleId';
import BILLING_CYCLE_TEXT from '../constants/BillingCycleText';
import Footer from '../components/Footer/Footer';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import ReactGA from 'react-ga';

class PricingPage extends Component {
  constructor(props) {
    super();
		this.state = {
     selectedPlan: '',
     isAnnualBillingCycleToggled: false, // * false is monthly and true is annual
     selectedBillingCycle: 'monthly',
     signUpClicked: false
    };
  }

  signUpForPlan = (planSelected) => {
    this.updateSelectedPlanData(planSelected);
    this.setState({ signUpClicked: true });
    if (!GetAuthToken()) {
      this.props.updateSignInCreateAccountModalContent('signIn');
      this.props.updateShowSignInCreateAccountModal(true);
    } else {
      this.props.updatePaypalSelection(false);
      this.props.history.push({ pathname: '/payment' });
    }
  }

  signUpForPaypalPlan = (planSelected) => {
    this.updateSelectedPlanData(planSelected);
    this.setState({ signUpClicked: true });
    if (!GetAuthToken()) {
      this.props.updateSignInCreateAccountModalContent('signIn');
      this.props.updateShowSignInCreateAccountModal(true);
    } else {
      this.props.updatePaypalSelection(true);
      this.props.history.push({ pathname: '/payment' });
    }
  }

  updateSelectedPlanData = (planSelected) => {
    const availablePlans = new Set(['free', 'lite', 'guru', 'pro']);
    if (availablePlans.has(planSelected)) {
      const selectedPlan = {
        ...PRICING[planSelected],
        billingCycle: BILLING_CYCLE_TEXT[this.state.selectedBillingCycle]
      }
      this.props.updateSelectedPlan(selectedPlan);
    }
  }

  handleBillingCycleToggle = (event) => {
    this.setState({ isAnnualBillingCycleToggled: !this.state.isAnnualBillingCycleToggled }, () => {
      this.state.isAnnualBillingCycleToggled
        ? this.setState({ selectedBillingCycle: 'annual' })
        : this.setState({ selectedBillingCycle: 'monthly' });
    });
  }

  render() {
    return (
      <div className="container-fluid page-background1 pb-5">
        <Helmet>
          <meta name="description" content=""/>
          <title>Pricing | Sandbox</title>
        </Helmet>
        <Header />
        <div className="container page-content-padding">
          <div className="row pt-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Plans</li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12 text-center">
              <h3>Select Your Plan</h3>
              <p>Choose the plan that's right for you, and upgrade or downgrade anytime.</p>
            </div>

            {/* Monthly/Annual billing toggle */}
            <div className="col-sm-12">
              <div className="container d-flex justify-content-center p-0">
                <div className="col-sm-12 col-md-6 pl-0 text-center monthly-annual-toggle-plans-container toggle-switch-container">
                  <label className="pr-2">Monthly</label>
                  <div className="form-check form-switch d-inline-block">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="monthly-annual-switch"
                      value={this.state.isAnnualBillingCycleToggled}
                      checked={this.state.isAnnualBillingCycleToggled}
                      onChange={this.handleBillingCycleToggle}/>
                    <label className="form-check-label" htmlFor="monthly-annual-switch">Annual</label>
                  </div>
                </div>
              </div>
            </div>

            {/* Free Plan */}
            <div className="col-sm-12 col-md-4 mb-3">
              <div className={`card h-100 shadow-2-strong ${this.state.selectedPlan === 'free' ? 'selectedPlanCard' : ''}`}>
                <div className="card-body text-center">
                  <h3 className="card-title mb-0"><b>Free</b></h3>
                  <p>
                    {
                      this.state.isAnnualBillingCycleToggled
                        ? <b>${PRICING.free.yearlyPrice}/yr</b>
                        : <b>${PRICING.free.monthlyPrice}/mo</b>
                    }
                  </p>
                  <p>monthly usage:</p>
                  <p><b>{PRICING.free.searchLimit} SERP analysis searches</b></p>
                  <p><b>{PRICING.free.speedTestLimit} speed test</b> with max of <b>{PRICING.free.speedTestSampleLimit} test samples</b></p>
                  <p><b>{PRICING.free.keywordSearchLimit} ChatGPT keyword suggestions</b></p>
                  {
                    this.state.selectedPlan === 'free'
                      ? <h6>your current plan</h6>
                      : null
                  }
                </div>
              </div>
            </div>

            {/* Lite Plan */}
            <div className="col-sm-12 col-md-4 mb-3">
              <div className={`card h-100 shadow-2-strong ${this.state.selectedPlan === 'lite' ? 'selectedPlanCard' : ''}`}>
                <div className="card-body text-center">
                  <h3 className="card-title mb-0"><b>Lite</b></h3>
                  <p>
                    {
                      this.state.isAnnualBillingCycleToggled
                        ? <b>${PRICING.lite.yearlyPrice}/yr</b>
                        : <b>${PRICING.lite.monthlyPrice}/mo</b>
                    }
                  </p>
                  <p>monthly usage:</p>
                  <p><b>{PRICING.lite.searchLimit} SERP analysis searches</b></p>
                  <p><b>{PRICING.lite.speedTestLimit} speed tests</b> with max of <b>{PRICING.lite.speedTestSampleLimit} samples</b></p>
                  <p><b>{PRICING.lite.keywordSearchLimit} ChatGPT keyword suggestions </b></p>
                  {/* ({PRICING.lite.keywordsPerMin}/min {PRICING.lite.keywordsPerHour}/hr) */}

                  {
                    this.state.selectedPlan === 'lite'
                      ? <h6>your current plan</h6>
                      : <div>
                          {/* <button
                            type="button"
                            className="btn btn-dark btn-sm"
                            onClick={(e) => this.signUpForPaypalPlan('lite')}>
                            pay with paypal
                          </button>
                          <br></br> */}
                          <button
                            type="button"
                            className="btn btn-dark btn-sm mt-2"
                            onClick={(e) => this.signUpForPlan('lite')}>
                            pay with stripe
                          </button>
                        </div>
                  }
                </div>
              </div>
            </div>

            {/* Guru Plan */}
            {/* <div className="col-sm-12 col-md-4 mb-3">
              <div className={`card h-100 shadow-2-strong ${this.state.selectedPlan === 'guru' ? 'selectedPlanCard' : ''}`}>
                <div className="card-body text-center">
                  <h3 className="card-title mb-0"><b>Guru</b></h3>
                  <p>
                    {
                      this.state.isAnnualBillingCycleToggled
                        ? <b>${PRICING.guru.yearlyPrice}/yr</b>
                        : <b>${PRICING.guru.monthlyPrice}/mo</b>
                    }
                  </p>
                  <p>monthly usage:</p>
                  <p><b>{PRICING.guru.searchLimit} SERP analysis searches</b></p>
                  <p><b>{PRICING.guru.speedTestLimit} speed tests</b> with max of <b>{PRICING.guru.speedTestSampleLimit} samples</b></p>
                  <p><b>{PRICING.guru.keywordSearchLimit} ChatGPT keyword suggestions </b></p>
                    {/* ({PRICING.guru.keywordsPerMin}/min {PRICING.guru.keywordsPerHour}/hr) */}
                  {/* {
                    this.state.selectedPlan === 'guru'
                      ? <h6>your current plan</h6>
                      : <div>
                          {/* <button
                            type="button"
                            className="btn btn-dark btn-sm"
                            onClick={(e) => this.signUpForPaypalPlan('guru')}>
                            pay with paypal
                          </button>
                          <br></br> 
                          <button
                            type="button"
                            className="btn btn-dark btn-sm mt-2"
                            onClick={(e) => this.signUpForPlan('guru')}>
                            pay with stripe
                          </button>
                        </div>
                  } 
                </div>
              </div>
            </div> */}

            {/* Pro Plan */}
            <div className="col-sm-12 col-md-4 mb-3">
              <div className={`card h-100 shadow-2-strong ${this.state.selectedPlan === 'pro' ? 'selectedPlanCard' : ''}`}>
                <div className="card-body text-center">
                  <h3 className="card-title mb-0"><b>Pro</b></h3>
                  <p>
                    {
                      this.state.isAnnualBillingCycleToggled
                        ? <b>${PRICING.pro.yearlyPrice}/yr</b>
                        : <b>${PRICING.pro.monthlyPrice}/mo</b>
                    }
                  </p>
                  <p>monthly usage:</p>
                  <p><b>{PRICING.pro.searchLimit} SERP analysis searches</b></p>
                  <p><b>{PRICING.pro.speedTestLimit} speed tests</b> with max of <b>{PRICING.pro.speedTestSampleLimit} samples</b></p>
                  <p><b>{PRICING.pro.keywordSearchLimit} ChatGPT keyword suggestions </b></p>
                  {/* ({PRICING.pro.keywordsPerMin}/min {PRICING.pro.keywordsPerHour}/hr) */}
                  {
                    this.state.selectedPlan === 'pro'
                      ? <h6>your current plan</h6>
                      :  <div>
                          {/* <button
                            type="button"
                            className="btn btn-dark btn-sm"
                            onClick={(e) => this.signUpForPaypalPlan('pro')}>
                            pay with paypal
                          </button>
                          <br></br> */}
                          <button
                            type="button"
                            className="btn btn-dark btn-sm mt-2"
                            onClick={(e) => this.signUpForPlan('pro')}>
                            pay with stripe
                          </button>
                        </div>
                  }
                </div>
              </div>
            </div>

            <div className="col-sm-12 text-center pt-5">
              <p><i className="fas fa-check"></i>  <b>All plans include:</b></p>
              <p>Schema Types</p>
              <p>Word Count</p>
              <p>Content Data incl H1, H2, Meta, and Titles</p>
              <p>Core Web Vitals and all PSI metrics</p>
              <p>Mobile &amp; Desktop Searches</p>
              <p>International Settings</p>
              <p>Multiple SERP pages</p>
              <p>CSV Export</p>
            </div>

            <div className="col-sm-12 text-center pt-5">
              <p><i className="fas fa-ban"></i>  <b>No-hassle cancellation</b></p>
              <p>Cancel your subscription online, no phone call required</p>
            </div>

            <div className="col-sm-12 text-center pt-5">
              <p><i className="far fa-envelope"></i>  <b>Fast email support</b></p>
              <p>We also offer a free 1:1 onboarding zoom call</p>
            </div>
            <div className="col-sm-12 text-center pt-5">
              <h3>Twitter Love from SEOs</h3>
              <TwitterTweetEmbed tweetId={'1308834474122149889'}/>
              <TwitterTweetEmbed tweetId={'1332846239742914561'}/>
              <TwitterTweetEmbed tweetId={'1309131697825423363'}/>
              <TwitterTweetEmbed tweetId={'1310547205980336128'}/>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.setState({ selectedPlan: this.props.currentPlan.plan });
    this.setState({ selectedBillingCycle: BILLING_CYCLE_ID[this.props.currentPlan.billingCycle] });
    this.setState({ isAnnualBillingCycleToggled: this.props.currentPlan.billingCycle === 2 ? true : false });
    if(!GetAuthToken())
      this.setState({ selectedPlan: 'free' });

    this.subscription = updateSignedInStatusSubject.get().subscribe(isUpdateSignedInStatusSubject => {
      if (isUpdateSignedInStatusSubject) {
        // * Navigate to payment page
        if(this.state.signUpClicked) {
          this.props.history.push({ pathname: '/payment' });
        } else {
          this.props.history.push({ pathname: '/pricing' });
          this.setState({ selectedPlan: this.props.currentPlan.plan });
        }

      } else {
        // * Reset current plan to defaults
        const defaultPlan = {
          ...PRICING['free'],
          billingCycle: BILLING_CYCLE_TEXT[this.state.selectedBillingCycle]
        }
        this.setState({ selectedPlan: 'free' });
        this.setState({ isAnnualBillingCycleToggled: false });
        this.setState({ selectedBillingCycle: 'monthly' });
        this.props.updateCurrentPlan(defaultPlan);
        this.props.updatePaypalSelection(false);
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
	}
}

const mapStateToProps = state => {
  return {
    currentPlan: state.currentPlan
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan }),
    updateSignInCreateAccountModalContent: (content) => dispatch({ type: 'SIGN_IN_CREATE_ACCOUNT_MODAL_CONTENT', value: content }),
    updateShowSignInCreateAccountModal: (modal) => dispatch({ type: 'SHOW_SIGN_IN_CREATE_ACCOUNT_MODAL', value: modal }),
    updateSelectedPlan: (selectedPlan) => dispatch({ type: 'SELECTED_PLAN', value: selectedPlan }),
    updatePaypalSelection: (isPaypalPayment) => dispatch({type: 'IS_PAYPAL_PAYMENT', value: isPaypalPayment })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PricingPage));
