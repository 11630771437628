import axios from 'axios';
import { GetAuthToken } from './AuthStatus';

export async function KwtRateLimitingApi(keywordTerm, keywordTotal) {
  if(GetAuthToken) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const params = {
        keywordString: keywordTerm,
        keywordsUsed: keywordTotal
      }

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/kwt-rate-limit`, params, { headers: headers, withCredentials: true})
      return response;
    } catch (error) {
      return error.response;
    }
  }
}
