import axios from 'axios';

export async function GoogleSignInApi(params) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/auth/google-login`, params, { withCredentials: true})
    return response;
  } catch (error) {
    return error.response;
  }
}
