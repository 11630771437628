import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class IsSearchTermUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

	closeAlert = () => {
		this.props.updateIsSearchTermUrlAlert(false);
	}

	linkToSiteSpeedCompareWithQuery = () => {
		this.props.history.push({
			pathname: '/serp-analysis-tool',
			search: `?searchTerm=site:${this.props.searchTermQuery}`
		});
  }

	linkToWebsiteSpeedTest = () => {
		this.props.history.push({ pathname: '/website-speed-test' });
  }
	
  render() {
    return (
			<div className="alert-container fade-in">
				<div className="row d-flex justify-content-center">
					<div className="col-sm-10 col-md-7 text-center">
						<div
							className="alert alert-info alert-onboarding"
							role="alert"
							data-color="info"
							onClick={() => this.closeAlert()}>
							<div className="d-flex justify-content-between">
								<span className="alert-copy">
									Hey! Are these the results you expected? If not, try using this <u onClick={() => this.linkToSiteSpeedCompareWithQuery()}>query</u>, or try our <u onClick={() => this.linkToWebsiteSpeedTest()}>website speed test instead</u>.
								</span>
								<i className="fas fa-times fa-sm alert-close"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }

	componentDidMount() {}
}

const mapStateToProps = state => {
  return {
    searchTermQuery: state.searchTermQuery
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateIsSearchTermUrlAlert: (isSearchTermUrlAlert) => dispatch({ type: 'IS_SEARCH_TERM_URL_ALERT', value: isSearchTermUrlAlert })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IsSearchTermUrl));