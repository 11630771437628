import React, { Component } from 'react';
import { connect } from 'react-redux';
import SESSIONSTORAGE from '../../constants/SessionStorage';
import { updateSerpViewAllDataSubject } from '../../observables/SerpViewAllDataSubject';

class ViewAllDataAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

	closeAlert = () => {
    this.props.updateCanViewAllDataAlert(false);
		if (typeof sessionStorage !== 'undefined') {
			sessionStorage.setItem(SESSIONSTORAGE.canViewAllDataAlert, JSON.stringify(false));
			this.props.updateCanViewAllDataAlert(false);
		}
	}

	viewAllDataToggle = (e) => {
		if (e) e.stopPropagation();
    this.props.updateCanViewAllDataAlert(false);
		updateSerpViewAllDataSubject.update(true);
    this.backToTop();
	}

  backToTop = () => {
    window.scrollTo({
      top:370,
      behavior: "smooth"
    });
  }

  render() {
    return (
			<div className="alert-container fade-in">
				<div className="row d-flex justify-content-center">
					<div className="col-sm-10 col-md-7 text-center">
						<div
							className="alert alert-info alert-onboarding"
							role="alert"
							data-color="info"
							onClick={() => this.closeAlert()}>
							<div className="d-flex justify-content-between">
								<span className="alert-copy">
									All results have loaded. <u onClick={(e) => this.viewAllDataToggle(e)}>View SERP stats</u>
								</span>
								<i className="fas fa-times fa-sm alert-close"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }

	componentDidMount() {}
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
		updateCanViewAllDataAlert: (canViewAllDataAlert) => dispatch({ type: 'CAN_VIEW_ALL_DATA_ALERT', value: canViewAllDataAlert })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllDataAlert);
