import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { GetAuthUserId } from '../../functions/AuthStatus';
import { withRouter } from 'react-router-dom';

class RateLimitAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

	closeRateLimitAlert = () => {
		this.props.updateShowRateLimitAlert(false);
	}

	dailySearchLimitGaEvent = () => {
    ReactGA.event({ category: 'rate limiting - ssct', action: 'daily search limit', label: GetAuthUserId() });
  }

	linkToPricingPage = () => {
    ReactGA.event({ category: 'rate limiting - ssct', action: 'click on upgrade', label: GetAuthUserId() });
		this.props.history.push({ pathname: '/pricing' });
  }

  render() {
    return (
			<div className="alert-container fade-in">
				<div className="row d-flex justify-content-center">
					<div className="col-sm-10 col-md-7 text-center">
						<div
							className="alert alert-info alert-onboarding"
							role="alert"
							data-color="info"
							onClick={() => this.closeRateLimitAlert()}>
							<div className="d-flex justify-content-between">
								<span className="alert-copy">
								You have hit your monthly search limit. <u onClick={() => this.linkToPricingPage()}>Upgrade here</u>.
								</span>
								<i className="fas fa-times fa-sm alert-close"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }

	componentDidMount() {
		this.dailySearchLimitGaEvent();
	}
}

const mapDispatchToProps = dispatch => {
  return {
		updateShowRateLimitAlert: (showRateLimitAlert) => dispatch({ type: 'SHOW_RATE_LIMIT_ALERT', value: showRateLimitAlert })
  };
}

export default connect(null, mapDispatchToProps)(withRouter(RateLimitAlert));
