import LOCALSTORAGE from '../constants/LocalStorage';

export function GetAuthName() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).name
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).name
    : null;
}

export function GetAuthEmail() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).email
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).email
    : null;
}

export function GetAuthUserId() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).userId
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).userId
    : null;
}

export function GetAuthToken() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).token
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).token
    : null;
}

export function GetAuthPlanId() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).planId
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).planId
    : null;
}

export function GetAuthRenewDate() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).renewDate
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).renewDate
    : null;
}

export function GetBillingCycle() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).billingCycle
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).billingCycle
    : null;
}

export function GetAuthCancelDate() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
    ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
    : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).cancelDate
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).cancelDate
    : null;
}

export function GetAuthGoogleUser() {
  const usrAuthStorage = typeof localStorage !== 'undefined'
  ? localStorage.getItem(LOCALSTORAGE.usrAuthStorage)
  : null;
  return usrAuthStorage && JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).google
    ? JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).google
    : null;
}
