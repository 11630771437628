import React, {Component } from 'react';
import { connect } from 'react-redux';

class SerpKeywordRankingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
}
titleClick = (url) => {
  this.setState({showTitleClick: true});
  setTimeout(() => {
    this.setState({showTitleClick: false});
    window.open(url, '_blank');
  }, 100);
}
  closeAlert = () => {
     this.props.updateCanViewKeywordRankingsModal(false);
     this.props.updateCanViewSerpChecker(false);
  }

    render () {
      return (
        <div
          className="rankings-modal fade-in"
          onClick={() => this.closeAlert()}
          >
          <div className="modal-dialog modal-md">
            <div className="rankings-modal-container modal-content pt-1 pb-4">
              <div
                className="mr-2"
                onClick={() => this.closeAlert()}>
                <i className="fas fa-times fa-sm alert-close mb-1 float-right"></i>
              </div>
              <div className="row justify-content-center card-bd">
                <div className="col-sm-12 mb-1 ranking-checker-col">
                {
                  this.props.rankingPosition > 0
                  ? <div className="checker-mg">
                      <div className="d-flex">
                          <h4 className="position-title mb-1">Found at Position</h4>
                          <div className="rank-holder">
                            <span className="rank-position">{this.props.rankingPosition}</span>
                          </div>
                        </div>
                        <div onClick={()=> this.titleClick(this.props.link)}>
                            <h5 className="card-title">{this.props.domain}</h5>
                            <a
                              className="card-subtitle mb-2 link"
                              href={this.props.link}
                              target="_blank"
                              rel="noopener noreferrer">
                              {this.props.title}
                            </a>
                          </div>
                      </div>
                      : this.props.rankingPosition !== "N/A" ?
                        <div>
                          <div className="col-sm-12 text-center mb-4">
                            <h4 className="position-title mb-1">Searching top 100 results...</h4>
                          </div>
                          <div className="col-sm-12 text-center mb-5">
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                          </div>
                        </div>
                        : <div onClick={()=> this.titleClick(this.props.serpRankUrl)}>
                            <h5 className="card-title">{this.props.serpRankUrl}</h5>
                            <h5><b>Your site is not ranking in the top 100</b></h5>
                          </div>
                }

                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

 }

 const mapStateToProps = state => {
   return {

   }
 };
const mapDispatchToProps = dispatch => {
  return {
    updateCanViewKeywordRankingsModal: (canViewRankingsModal) => dispatch({ type: 'CAN_VIEW_KEYWORD_RANKINGS_MODAL', value: canViewRankingsModal }),
    updateCanViewSerpChecker: (canViewSerpChecker) => dispatch({ type: 'CAN_VIEW_SERP_CHECKER', value: canViewSerpChecker })
};
}

export default connect(mapStateToProps, mapDispatchToProps)(SerpKeywordRankingModal);
