
export function LighthouseScoreColors(score) {
  const minFail = 0,
        maxFail = 49,
        minAverage = 50,
        maxAverage = 89
  
  if (isBetween(score, 0, 100)) {
    if (isBetween(score, minFail, maxFail)) {
      return 'psi-score-fail';
    } else if (isBetween(score, minAverage, maxAverage)) {
      return 'psi-score-average';
    } else {
      return 'psi-score-pass';
    }
  }
  
  function isBetween(x, min, max) {
    return x >= min && x <= max;
  }
}


