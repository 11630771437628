import React, { Component } from 'react';
import { Tooltip } from 'mdb-ui-kit';

class IdeaExportToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

  render() {
    return (
      <i
        className="fas fa-file-download fa-2x"
        id="idea-export"
        data-toggle="tooltip"
        data-placement="top"
        data-trigger='hover'
        title="Export idea data">
      </i>
    );
  }

  componentDidMount() {
    const toolTipExport = document.getElementById(`idea-export`);
    if(toolTipExport) new Tooltip(toolTipExport, { boundary: 'window' });
  }

}

export default IdeaExportToolTip;
