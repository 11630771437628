import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import MixLab from '../components/MixLab/MixLab';

class MixLabPage extends Component {
  constructor(props){
    super();
    this.state = {
    };
  }


render() {
  return (
    <div className="serp-psi-page-bkg">
      <Header />
      <section className="container-fluid shadow-2-strong background1 page-content-padding">
        <div className="container">
          <div className="row pt-3">
            <div className="col-sm-12">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={'/'}>Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">MixLab</li>
                  </ol>
                </nav>
              </div>
              <MixLab/>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid background1 page-content-padding">
          <Footer />
      </section>
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};



export default connect(mapStateToProps, null)(MixLabPage);
