import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import SpeedTestTool  from '../components/SpeedTest/SpeedTestTool';
import ReactGA from 'react-ga';

class SpeedTestPage extends Component {
  constructor(props) {
    super();
		this.state = {
		};
  }

  render() {
    return (
      <div className="serp-psi-page-bkg">
        <Helmet>
          <meta name="description" content="Compare site speed across results of a Google search"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <title>Accurate Website Speed Test - Multiple Samples | Sandbox</title>
          {/* Facebook meta tags */}
          <meta property="og:title" content="Accurate Website Speed Test - Multiple Samples | Sandbox" />
          <meta property="og:image" content="" />
          <meta property="og:description" content="Compare site speed across results of a Google search" />
          <meta property="og:url" content="https://tools.sandboxweb.io/website-speed-test" />
          <meta property="og:type" content="website" />
          {/* Twitter meta tags */}
          <meta property="twitter:title" content="Accurate Website Speed Test - Multiple Samples | Sandbox" />
          <meta property="twitter:description" content="Compare site speed across results of a Google search" />
          <meta property="twitter:image" content="" />
          <meta property="twitter:card" content="" />
        </Helmet>
        <Header />
        <section className="container-fluid shadow-2-strong background1 page-content-padding">
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={'/'}>Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Website Speed Test</li>
                  </ol>
                </nav>
              </div>
              <div className="col-sm-12">
                <h3>Run a website speed test with more accuracy</h3>
              </div>
              <div className="col-sm-12">
                <SpeedTestTool />
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid seo-container">
          <div className="container">
            <div className="row mt-5 pt-3 pb-5">
              <div className="col-sm-12 col-md-4">
                <p><strong>Why does the PageSpeed Insights score change from run to run?</strong></p>
                <p>
                  Variability in the score is introduced via several different channels, with some channels being more impactful than others. Typical causes of metric variability include the local network availability, the client's hardware availability, and the client's resource contention.
                </p>
              </div>
              <div className="col-sm-12 col-md-4">
                <p><strong>How much variability is there in the PageSpeed Insights score?</strong></p>
                <p>
                  A <a href="https://docs.google.com/document/d/1BqtL-nG53rxWOI5RO0pItSRPowZVnYJ_gBEQCJ5EeUE/edit#" target="_blank" rel="noopener noreferrer"><u>study</u></a> by Patrick Hulce- former Google engineer and primary maintainer of Lighthouse- showed that with one test, a site with a true performance score of 50 would return scores in the range of 35-65, a deviation of +/- 15.
                </p>
              </div>
              <div className="col-sm-12 col-md-4">
                <p><strong>How much can variability be reduced with multiple test samples?</strong></p>
                <p>
                  With three tests, the range of 35-65 would be reduced to 39-61, a variability of +/-11. With 5 tests, it's reduced further, to only +/- 8. With this study in mind, we built this page speed test to help you improve your test accuracy.
                </p>
                <p>Need help improving site speed? Sandbox offers <a href="https://www.sandboxweb.io/site-speed-consulting" target="_blank" rel="noopener noreferrer"><b><u>site speed consulting</u></b></a></p>
              </div>
            </div>
          </div>
        </section>

        <section className="container-fluid background1 page-content-padding">
          <Footer />
        </section>
      </div>
    );
	}

  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeedTestPage);
