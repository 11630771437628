import ReactGA from "react-ga";
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DOMAINS from "../../constants/Domains";
import COUNTRIES from "../../constants/Countries";
import LOCALSTORAGE from "../../constants/LocalStorage";
import {
  GetAuthToken,
  GetAuthUserId,
  GetAuthPlanId,
} from "../../functions/AuthStatus";
import { RemainingSerpUsageStatus } from "../../functions/RemainingSerpUsageStatus";
import { MediaMatcher } from "../../functions/MediaMatcher";
import AuthStatusModel from "../../functions/AuthStatusModel";
import { AuthTokenStatus } from "../../functions/AuthTokenStatusApi";
import { DisplayAccountGateCheck } from "../../functions/DisplayAccountGateCheck";
import { updateSignedInStatusSubject } from "../../observables/SignedInStatus";
import { RateLimitingApi } from "../../functions/RateLimitingApi";
import { SaveUserSettingApi } from '../../functions/UserSettingApi';

class SerpChecker extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      serpRankUrl: "",
      serpRankKeyword: "",
      rankResult: false,
      rankingPosition: 0,
      resultTitle: "",
      domain: "",
      link: "",
      serpFound: "",
      showSerpCheckerSpinner: false,
      searchSubmitted: false,
    };
    this.inputRef = React.createRef();
    this.inputRef2 = React.createRef();
  }

  titleClick = (url) => {
    this.setState({ showTitleClick: true });
    setTimeout(() => {
      this.setState({ showTitleClick: false });
      window.open(url, "_blank");
    }, 100);
  };

  handleRankInputChange = (event) => {
    const value = event.target.value;
    this.setState({ serpRankUrl: value });
  };

  handleKeywordInputChange = (event) => {
    const value = event.target.value;
    this.setState({ serpRankKeyword: value });
  };

  handleClearResults = () => {
    this.setState({ rankingPosition: 0 });
    this.setState({ resultTitle: "" });
    this.setState({ domain: "" });
    this.setState({ link: "" });
  }

  handleRankSearch = (event) => {
    if (event) event.preventDefault();
    this.inputRef.current.blur();
    this.inputRef2.current.blur();
    this.handleClearResults();

    if (this.state.serpRankUrl !== "" && this.state.serpRankKeyword !== "") {
      this.setState({ searchSubmitted: true });
      const params = {
        searchTerm: this.state.serpRankKeyword,
        device: this.props.settingsDeviceSelected,
        pageNum: 1,
        google_domain: this.props.serpDomain,
        gl: this.props.serpCountry,
        hl: this.props.serpLanguage,
      };

      this.setState({ serpRankUrl: this.state.serpRankUrl });
      if (GetAuthToken()) {
        this.isRateLimitReached(this.state.serpRankKeyword, 0, false).then(
          (isLimitReached) => {
            !isLimitReached
              ? this.getRank(params) // * Daily search limit not reached
              : GetAuthPlanId() === 1
              ? this.linkToFreeTrialPage()
              : this.displayRateLimitOnboarding(); // * Daily search limit reached
          }
        );
        this.setState({ showSerpCheckerSpinner: true });
      } else {
        if(DisplayAccountGateCheck()) {
          this.displayAccountGate();
        }
      }

    }
  };

  getRank = (params) => {
    axios
      .post(process.env.REACT_APP_SERP_CHECKER_API, params)
      .then((response) => {
        const urlRankingMap = response.data.organic_results;
        var domain = "";
        var getDomain =
          this.state.serpRankUrl.match(/\/\/(.*?\/)/) ||
          this.state.serpRankUrl.match(/\/\/(.*)/g);
        if (getDomain !== null) {
          var removeSlashes = getDomain[0].replace(/\//g, "");
          domain = removeSlashes.replace(/www./, "");
        } else if (this.state.serpRankUrl.substr(0, 4) === "www.") {
          domain = this.state.serpRankUrl.replace(/www./, "");
        } else {
          domain = this.state.serpRankUrl;
        }

        if (urlRankingMap.length > 0) {
          for (var i = 0; i < urlRankingMap.length; i++) {
            if (urlRankingMap[i].domain.replace(/www./, "") === domain.trim()) {
              this.setState({ rankingPosition: urlRankingMap[i].position });
              this.setState({ resultTitle: urlRankingMap[i].title });
              this.setState({ domain: urlRankingMap[i].domain });
              this.setState({ link: urlRankingMap[i].link });
              break;
            }
          }
          if (this.state.rankingPosition === 0)
            this.setState({ rankingPosition: "N/A" });
        }
        this.setState({ searchSubmitted: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFlagIcon = (domainSelected) => {
    const domainObject = DOMAINS.find(
      (domain) => domain.google_domain === domainSelected
    );
    let flagCode = "us";
    if (domainObject) {
      const findCountryCode = COUNTRIES.find(
        (country) => country.country_name === domainObject.country_name
      );
      flagCode = findCountryCode
        ? findCountryCode.country_code
        : domainObject.country_code
        ? domainObject.country_code
        : "us";
    }
    return flagCode === "aq" ? (
      <img
        src={"https://www.countryflags.io/aq/flat/16.png"}
        style={{ marginRight: ".5em" }}
        alt={flagCode}
      />
    ) : (
      <i className={`${flagCode} flag`}></i>
    );
  };

  handleUserTokenStatus = async () => {
    var responseStatus;
    if (typeof localStorage !== "undefined") {
      //added this when getting incognito token error.
      if (localStorage.getItem(LOCALSTORAGE.usrAuthStorage) !== null) {
        const response = await AuthTokenStatus(
          JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).token
        );
        responseStatus = response?.data.status;
      }
    }
    if (responseStatus === "SUCCESS") {
      this.handleRemainingSerpSearches();
    } else {
      if (typeof localStorage !== "undefined") {
        localStorage.setItem(
          LOCALSTORAGE.usrAuthStorage,
          JSON.stringify(
            new AuthStatusModel("", "", null, null, 1, null, 1, null)
          )
        );
        localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, false);
        localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
      }
      updateSignedInStatusSubject.update(false);
      // this.props.updateGateContent('signIn');
      this.props.updateShowAccountGate(true);
    }
  };

  handleRemainingSerpSearches = async () => {
    const response = await RemainingSerpUsageStatus();
    if (response) {
      if (response.numOfSerpLeft === null) {
        response.numOfSerpLeft = 0;
        this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
      } else {
        this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
      }
    }
  };

  isRateLimitReached = async (searchTerm, serpPageNum, ignoreRateLimit) => {
    const response = await RateLimitingApi(
      "serpSearch",
      searchTerm,
      serpPageNum,
      ignoreRateLimit,
      null,
      null
    );
    const rateLimitResponseMapping = {
      200: () => false, // * Daily limit not reached
      429: () => true, // * Daily limit reached
      default: () => false, // * default to daily limit not reached if api fails
    };
    this.handleRemainingSerpSearches();
    return (await response) &&
      response.status &&
      rateLimitResponseMapping[response.status]
      ? rateLimitResponseMapping[response.status]()
      : rateLimitResponseMapping["default"]();
  };

  displayRateLimitOnboarding() {
    this.props.updateShowRateLimitAlert(true);
  }
  displayAccountGate = () => {
    if (DisplayAccountGateCheck() && !this.props.displayGateTimer) {
      const displayGateTimer = setTimeout(() => {
        if(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)) {
            this.props.updateGateContent('signIn');
        } else {
            this.props.updateGateContent('createAccount');
        }

      }, 1000);
      this.props.updateDisplayGateTimer(displayGateTimer);
      this.handleUserTokenStatus();
    }
  }
  linkToPricingPage = () => {
    ReactGA.event({
      category: "rate limiting - wst",
      action: "click on upgrade",
      label: GetAuthUserId(),
    });
    this.props.history.push({ pathname: "/pricing" });
  };

  linkToFreeTrialPage = () => {
    this.props.history.push({ pathname: "/free-trial-page" });
  };

  openSettingsModal = (e) => {
    e.preventDefault();
    this.closeNavbarToggler();
    this.props.updateShowSettingsModal(true);
    // if (this.props.displayGateTimer) clearTimeout(this.props.displayGateTimer);
  };

  closeNavbarToggler = () => {
    const navbarToggler = document.getElementById("navbar-toggler");
    const navbar = document.getElementById("navbar");
    if (navbar.classList.contains("show")) {
      if (!navbarToggler.classList.contains("collapsed"))
        navbarToggler.classList.add("collapsed");
      navbar.classList.remove("show");
    }
  };
  saveSettingsApi = () => {
    const params = {
      deviceType: this.props.settingsDeviceSelected === 'mobile' ? 1 : 2,
      googleDomain: this.props.serpDomain,
      country: this.props.serpCountry,
      language: this.props.serpLanguage,
      originUrl: window.location.pathname
    };
    SaveUserSettingApi(params);
}
  render() {
    return (
      <div className="row p-0 ml-0">
        <form className="serp-checker-form p-0 mb-5">
          <div className="input-group pb-2">
            <div className="input-group-prepend" style={{ width: "100%" }}>
              <input
                ref={this.inputRef2}
                id="serpCheckerKeyword"
                type="text"
                className="form-control"
                placeholder="Enter Google search term"
                aria-label="Search Term"
                aria-describedby="Search Term"
                value={this.state.serpRankKeyword}
                onChange={this.handleKeywordInputChange}
              />
              <h2 className="mt-4 mb-1 ml-1" style={{ fontSize: "17px" }}>
                enter your site
              </h2>
              <input
                ref={this.inputRef}
                id="serpCheckerSearch"
                type="text"
                className="form-control"
                placeholder="https://"
                aria-label="Search Url"
                aria-describedby="Search Url"
                value={this.state.serpRankUrl}
                onChange={this.handleRankInputChange}
              />
              <div className="pt-2">

              {this.props.screenSize !== "mobile" ? (
                <div className="d-flex row">
                  <p className="serp-psi-search-flag-copy body-small col-sm-6">
                    Searching {this.props.serpDomain}&nbsp;
                    <span>
                      {this.getFlagIcon(this.props.serpDomain)}
                      <span onClick={(e) => this.openSettingsModal(e)}>
                        <u>
                          <b>change</b>
                        </u>
                      </span>
                    </span>
                  </p>
                  {GetAuthToken() ? (
                    <span className="col-sm-6 remaining-searches serp-psi-search-flag-copy body-small">
                      Remaining Searches: {this.props.remainingSerpSearches}{" "}
                      <u
                        className="pl-1 align-middle"
                        onClick={() => this.linkToPricingPage()}
                      >
                        upgrade
                      </u>
                    </span>
                  ) : null}
                </div>
              ) : (
                <div className="row">
                  <div className="d-flex col-sm-12 justify-content-between">
                    <p className="serp-psi-search-flag-copy body-small d-inline text-left ml-1">
                      {this.props.serpDomain}&nbsp;
                      <span>
                        {this.getFlagIcon(this.props.serpDomain)}
                        <span onClick={(e) => this.openSettingsModal(e)}>
                          <u>
                            <b>change</b>
                          </u>
                        </span>
                      </span>
                    </p>
                    {GetAuthToken() ? (
                      <span className="remaining-searches serp-psi-search-flag-copy body-small">
                        Searches: {this.props.remainingSerpSearches}{" "}
                        <u
                          className="pl-1 align-middle"
                          onClick={() => this.linkToPricingPage()}
                        >
                          upgrade
                        </u>
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
                
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <button
                  style={{
                    width: this.props.screenSize === "mobile" ? "100%" : "50%",
                  }}
                  className="btn btn-outline-primary"
                  type="submit"
                  id="submitSerpChecker"
                  data-ripple-color="dark"
                  onClick={(e) => this.handleRankSearch(e)}
                >
                  search
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="col-sm-12 d-flex justify-content-center mb-5 p-0">
          {this.state.showSerpCheckerSpinner && this.state.searchSubmitted ? (
            <div>
              <div className="col-sm-12 text-center mb-4">
                <h4 className="position-title mb-1">
                  Searching top 100 results...
                </h4>
              </div>
              <div className="col-sm-12 text-center mb-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          ) : null}

          {this.state.showSerpCheckerSpinner && !this.state.searchSubmitted ? (
            <div>
              {this.state.rankingPosition > 0 ? (
                <div className="col-sm-12 px-3">
                  <div className="d-flex" style={{ width: "100%" }}>
                    <h4 className="position-title mb-1">Found at Position</h4>
                    <div className="rank-holder">
                      <span className="rank-position">
                        {this.state.rankingPosition}
                      </span>
                    </div>
                  </div>
                  <div onClick={() => this.titleClick(this.state.link)}>
                    <div>
                      <h5 role="button" className="card-title">
                        {this.state.domain}
                      </h5>
                      <a
                        className="card-subtitle mb-2 link"
                        href={this.state.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.state.resultTitle}
                      </a>
                    </div>
                  </div>
                </div>
              ) : this.state.rankingPosition !== "N/A" ? null : (
                <div onClick={() => this.titleClick(this.state.serpRankUrl)}>
                  <h5 className="card-title">{this.state.serpRankUrl}</h5>
                  <h5>
                    <b>Your site is not ranking in the top 100</b>
                  </h5>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  componentDidMount() {
    this._isMounted = true;
    if (GetAuthToken()) this.handleUserTokenStatus();
    this.props.updateScreenSize(MediaMatcher());
    window.addEventListener("resize", () => {
      this.props.updateScreenSize(MediaMatcher());
    });
  }
}

const mapStateToProps = (state) => {
  return {
    screenSize: state.screenSize,
    showAccountGate: state.showAccountGate,
    settingsDeviceSelected: state.settingsDeviceSelected,
    serpDomain: state.serpDomain,
    serpCountry: state.serpCountry,
    serpLanguage: state.serpLanguage,
    remainingSerpSearches: state.remainingSerpSearches,
    showRateLimitAlert: state.showRateLimitAlert,
    displayGateTimer: state.displayGateTimer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateRemainingSerpSearches: (remainingSearches) =>
      dispatch({ type: "REMAINING_SERP_SEARCHES", value: remainingSearches }),
    updateGateContent: (gateContent) =>
      dispatch({ type: "ACCOUNT_GATE_CONTENT", value: gateContent }),
    updateShowAccountGate: (isShowAccountGate) =>
      dispatch({ type: "SHOW_ACCOUNT_GATE", value: isShowAccountGate }),
    updateScreenSize: (screenSize) =>
      dispatch({ type: "SCREEN_SIZE", value: screenSize }),
    updateShowSettingsModal: (showSettingsModal) =>
      dispatch({ type: "SHOW_SETTINGS_MODAL", value: showSettingsModal }),
    updateShowRateLimitAlert: (showRateLimitAlert) =>
      dispatch({ type: "SHOW_RATE_LIMIT_ALERT", value: showRateLimitAlert }),
      updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SerpChecker));
