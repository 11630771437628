import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { GetAuthUserId } from '../../functions/AuthStatus';

class SpeedTestRateLimitAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

	closeRateLimitAlert = () => {
		this.props.updateShowSpeedTestRateLimitAlert({
      isShow: false,
      type: 'rateLimit',
			maxSampleSize: 3
    });
	}

	dailyTestLimitGaEvent = () => {
    ReactGA.event({ category: 'rate limiting - wst', action: 'daily test limit', label: GetAuthUserId() });
  }

	sampleLimitLimitGaEvent = () => {
    ReactGA.event({ category: 'sample limiting  - wst', action: 'sample limit', label: GetAuthUserId() });
  }

	linkToPricingPage = () => {
    ReactGA.event({ category: 'rate limiting - wst', action: 'click on upgrade', label: GetAuthUserId() });
		this.props.history.push({ pathname: '/pricing' });
  }

  render() {
    return (
			<div className="alert-container fade-in">
				<div className="row d-flex justify-content-center">
					<div className="col-sm-10 col-md-7 text-center">
						<div
							className="alert alert-info alert-onboarding"
							role="alert"
							data-color="info"
							onClick={() => this.closeRateLimitAlert()}>
							<div className="d-flex justify-content-between">
								<span className="alert-copy">
									{
										this.props.speedTestRateLimitAlertData.type === 'increaseSampleSize'
											? <span>
													<u onClick={() => this.linkToPricingPage()}>Upgrade</u> to run more than {this.props.speedTestRateLimitAlertData.maxSampleSize} samples.
												</span>
											: null
									}
									{
										this.props.speedTestRateLimitAlertData.type === 'rateLimit'
											? <span>
													You have hit your monthly test limit. <u onClick={() => this.linkToPricingPage()}>Upgrade here</u>.
												</span>
											: null
									}
									{
										this.props.speedTestRateLimitAlertData.type === 'sampleSizeRateLimit'
											? <span>
													You have hit your sample size limit. <u onClick={() => this.linkToPricingPage()}>Upgrade here</u>.
												</span>
											: null
									}
								</span>
								<i className="fas fa-times fa-sm alert-close"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
    );
  }

	componentDidMount() {
		const alertType = {
			increaseSampleSize: () => this.sampleLimitLimitGaEvent(),
			sampleSizeRateLimit: () => this.sampleLimitLimitGaEvent(),
			rateLimit: () => this.dailyTestLimitGaEvent()
		};
		if (alertType[this.props.speedTestRateLimitAlertData.type]) alertType[this.props.speedTestRateLimitAlertData.type]();
	}
}

const mapDispatchToProps = dispatch => {
  return {
		updateShowSpeedTestRateLimitAlert: (showSpeedTestRateLimitAlert) => dispatch({ type: 'SHOW_SPEED_TEST_RATE_LIMIT_ALERT', value: showSpeedTestRateLimitAlert })
  };
}

export default connect(null, mapDispatchToProps)(withRouter(SpeedTestRateLimitAlert));
