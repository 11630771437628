import axios from 'axios';

export async function VerifySecureLinkApi(token) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/secure-link/${token}`, { withCredentials: true})
    return response;
  } catch (error) {
    return error.response;
  }
}

