import axios from 'axios';
import { GetAuthToken } from './AuthStatus';

export function SaveUserSettingApi(userSettings) {
  if (GetAuthToken()) {
    const headers = {
      'Authorization': `Bearer ${GetAuthToken()}`,
      'Content-Type': 'application/json'
    };
    const params = {
      deviceType: userSettings.deviceType,
      googleDomain: userSettings.googleDomain,
      country: userSettings.country,
      language: userSettings.language,
      searchKeyword: userSettings.searchKeyword,
      originUrl: window.location.pathname
    };
    axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/settings`, params, { headers: headers, withCredentials: true })
    .then(res => {}).catch(error => {
      console.error('save settings Error: ', error);
    })
  }
}

export async function GetUserSettingApi() {
  if (GetAuthToken()) {
    const headers = {
      'Authorization': `Bearer ${GetAuthToken()}`,
      'Content-Type': 'application/json'
    };
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/settings`, { headers: headers, withCredentials: true })
      return response.data;
    } catch (error) {
      console.error('get settings Error: ', error);
    }
  }
}

