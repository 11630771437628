import React, { Component } from 'react';
import { connect } from 'react-redux';
import AccountGateModal from '../Modals/AccountGateModal';
import SignInCreateAccountModal from '../Modals/SignInCreateAccountModal';
import SettingsModal from '../Modals/SettingsModal';
// import { GetAuthToken, GetAuthUserId } from '../../functions/AuthStatus';
import { GetAuthToken, GetAuthName, GetAuthEmail, GetAuthUserId, GetAuthPlanId, GetAuthRenewDate, GetBillingCycle, GetAuthCancelDate, GetAuthGoogleUser } from '../../functions/AuthStatus';
import { VerifySecureLinkApi } from '../../functions/VerifySecureLinkApi';
import { VerifyResetPasswordApi } from '../../functions/VerifyResetPasswordApi';
import LOCALSTORAGE from '../../constants/LocalStorage';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { GetUserSettingApi } from '../../functions/UserSettingApi';
import { withRouter } from 'react-router-dom';
import { updateSerpToolSettings } from '../../observables/SerpToolSettingsSubject';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import PRICING from '../../constants/Pricing'
import PLANS from '../../constants/Plans';
import { AuthTokenStatus } from '../../functions/AuthTokenStatusApi';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBar: null,
      hamburgerMenu: null
    };
  }

  gotToSandboxweb = () => {
    window.location.href = 'https://www.sandboxweb.io/';
  }

  openSettingsModal = (e) => {
    e.preventDefault();
    this.closeNavbarToggler();
    this.props.updateShowSettingsModal(true);
    if (this.props.displayGateTimer) clearTimeout(this.props.displayGateTimer);
  }

  closeNavbarToggler = () => {
    if (window.matchMedia('(max-width: 992px)').matches) {
      const navbarToggler = document.getElementById('navbar-toggler');
      const navbar = document.getElementById('navbar');
      this.toggleHamburger();
      if (navbar.classList.contains('show')) {
        if (!navbarToggler.classList.contains('collapsed')) navbarToggler.classList.add('collapsed');
        navbar.classList.remove('show');
      }
		}
  }

  signIn = (e) => {
    e.preventDefault();
    this.closeNavbarToggler();
    this.props.updateSignInCreateAccountModalContent('signIn');
    this.props.updateShowSignInCreateAccountModal(true);
  }

  toggleHamburger = () => {
    if (this.state.hamburgerMenu) this.state.hamburgerMenu.classList.toggle("change");
    if (this.state.hamburgerMenu && this.state.hamburgerMenu.classList.contains('change')) {
      if (!this.state.navBar.classList.contains('shadow-fade-in-out'))
        this.state.navBar.classList.add('shadow-fade-in-out');
    } else {
      if (window.pageYOffset < 60 && this.state.navBar.classList.contains('shadow-fade-in-out')) {
        this.state.navBar.classList.remove('shadow-fade-in-out');
      }
    }
  }

  handleScroll = () => {
    if (window.pageYOffset > 60 && !this.state.navBar.classList.contains('shadow-fade-in-out')) {
      this.state.navBar.classList.add('shadow-fade-in-out');
    }
    if (window.matchMedia('(max-width: 576px)').matches) {
      if (window.pageYOffset < 60 && this.state.navBar.classList.contains('shadow-fade-in-out') && !this.state.hamburgerMenu.classList.contains('change')) {
        this.state.navBar.classList.remove('shadow-fade-in-out');
      }
    } else {
      if (window.pageYOffset < 60 && this.state.navBar.classList.contains('shadow-fade-in-out')) {
        this.state.navBar.classList.remove('shadow-fade-in-out');
      }
    }
  }

  displaySettings = () => {
    const urlPath = window.location.pathname;
    const settingsMapping = {
      '/': () => false,
      '/tweetjar': () => false,
      '/tweetjar/googlesays': () => false,
      '/pro': () => false,
      '/site-speed-tracking': () => true,
      '/serp-analysis-tool': () => true,
      '/website-speed-test': () => true
    };
    return settingsMapping[urlPath]
      ? settingsMapping[urlPath]()
      : null;
  }

  GetUserSetting = (origin) => {
    GetUserSettingApi().then(data => {
      if (data) {
        const deviceMapping = {
          0: () => {
            return window.matchMedia('(max-width: 576px)').matches
              ? 'mobile'
              : 'desktop'
          },
          1: () => 'mobile',
          2: () => 'desktop'
        };
        this.props.updateSelectedDevice(deviceMapping[data.deviceType]());
        this.props.updateSerpDomain(data.googleDomain);
        this.props.updateSerpCountry(data.country);
        this.props.updateSerpLanguage(data.language);

        if (data.originUrl && data.originUrl !== '') {
          if (origin === 's') {
            this.props.updateSearchTermQuery(data.searchKeyword);
            this.props.history.push({ pathname: data.originUrl });
            if (data.originUrl === '/serp-analysis-tool') updateSerpToolSettings.update(true);
          }
          if (origin === 'p') {
            this.props.updateSearchTermQuery(data.searchKeyword);
            this.props.updateResetPaswordLinkSettingsParams({
              deviceType: data.deviceType,
              googleDomain: data.googleDomain,
              language: data.language,
              originUrl: data.originUrl
            });
            this.props.history.push({ pathname: '/reset-password' });
          }
        }
      }
    });
  }

  updateCurrentPlan = (userData) => {
    const currentPlan = PRICING[PLANS[userData.planId]];
    currentPlan.renewDate = userData.renewsAt;
    currentPlan.billingCycle = userData.billingCycle;
    currentPlan.cancelDate = userData.cancelsAt;
    this.props.updateCurrentPlan(currentPlan);
  }

  redirectToLastPage = () => {
    const search = window.location.search;
    const token = new URLSearchParams(search).get('token');
    const origin = new URLSearchParams(search).get('origin');

    const verifyTokenMapping = {
      s: () => {
        VerifySecureLinkApi(token).then(res => {
          if (res && res.status === 200) {
            if (typeof localStorage !== 'undefined' && res.data.token) {
              const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
              localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
            }
            this.GetUserSetting(origin);
            this.updateCurrentPlan(res.data);
          }
        });
      },
      p: () => {
        VerifyResetPasswordApi(token).then(res => {
          if (res && res.status === 200) {
            if (typeof localStorage !== 'undefined' && res.data.token) {
              const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
              localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
            }
            this.GetUserSetting(origin)
            this.props.updateResetPaswordLinkEmail(res.data.email);
            this.updateCurrentPlan(res.data);
          }
        });
      },
      default: () => this.GetUserSetting()
    };
    if (verifyTokenMapping[origin]) verifyTokenMapping[origin]();
  }

  handleBrowserBackBtnClick = () => {
    const avoidClosingAccountGateModalPages = new Set([
      '/serp-analysis-tool'
    ]);
    window.onpopstate = ()=> {
      if (!avoidClosingAccountGateModalPages.has(window.location.pathname)) this.props.updateShowAccountGate(false);
      this.props.updateShowSignInCreateAccountModal(false);
      this.props.updateShowSettingsModal(false);
    }
  }

  handleAuthTokenStatus = () => {
    if (GetAuthToken()) {
      AuthTokenStatus(GetAuthToken()).then(res => {
        if (res?.status === 200 && typeof localStorage !== 'undefined' && res?.data?.token) {
          const token = res.data.token;
          localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(GetAuthName(), GetAuthEmail(), GetAuthUserId(), token, GetAuthPlanId(), GetAuthRenewDate(), GetBillingCycle(), GetAuthCancelDate(), GetAuthGoogleUser())));
          this.GetUserSetting();
        }
      });
    }
  }

	render() {
    return (
      <header>
        <nav
          id="nav-bar"
          className="container-fluid navbar navbar-expand-lg fixed-top background1">
          <div className="container">
              <a
                href="https://www.sandboxweb.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
              <h4
                className="mt-0 mb-0 header-link"
                // onClick={() => this.gotToSandboxweb()}
                >
                {GetAuthPlanId() === 4 ? "Sandbox Pro" : "Sandbox"}
              </h4></a>
            <div
              id="navbar-toggler"
              className="navbar-toggler hamburger"
              type="button"
              data-toggle="collapse"
              data-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.toggleHamburger()}>
              <div className="bar1"></div>
              <div className="invis"></div>
              <div className="bar3"></div>
            </div>

            <div className="collapse navbar-collapse" id="navbar">
              <ul className="navbar-nav ml-auto mt-2 mb-2 mb-lg-0 text-center">
                {
                  this.displaySettings()
                    ? <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/"
                          onClick={(e) => this.openSettingsModal(e)}>
                          Settings
                        </a>
                      </li>
                    : null
                }
                <li className="nav-item">
                  <Link to={'/'} className="nav-link">Tools</Link>
                </li>
                <li className="nav-item">
                  <Link to={'/pricing'} className="nav-link">Pricing</Link>
                </li>
                {
                  GetAuthToken()
                    ?  <li className="nav-item">
                        <Link to={'/account'} className="nav-link">Account</Link>
                      </li>
                    : <li className="nav-item">
                        <a
                          className="nav-link"
                          href="/"
                          onClick={(e) => this.signIn(e)}>
                          Sign In
                        </a>
                      </li>
                }

              </ul>
            </div>
          </div>
        </nav>
        {
          this.props.showAccountGate
            ? <AccountGateModal />
            : null
        }
        {
          this.props.showSignInCreateAccountModal
            ? <SignInCreateAccountModal />
            : null
        }
        {
          this.props.showSettingsModal
            ? <SettingsModal />
            : null
        }
      </header>
    );
  }

  componentDidMount() {
    this.setState({ navBar: document.querySelector('#nav-bar') ? document.querySelector('#nav-bar') : null });
    this.setState({ hamburgerMenu: document.getElementById('navbar-toggler') ? document.getElementById('navbar-toggler') : null });
    window.addEventListener('scroll', this.handleScroll);
    this.props.updateSelectedDevice('mobile');
    this.redirectToLastPage();
    this.handleBrowserBackBtnClick();

    if (GetAuthToken()) {
      // * Persistent user
        if(GetAuthGoogleUser()) {
          ReactGA.event({ category: 'authentication - Google Sign in', action: 'persistent', label: GetAuthUserId() });
        }
        else {
            ReactGA.event({ category: 'authentication', action: 'persistent', label: GetAuthUserId() });
        }
    }
    this.handleAuthTokenStatus();
  }
}

const mapStateToProps = state => {
  return {
    showSignInCreateAccountModal: state.showSignInCreateAccountModal,
    showAccountGate: state.showAccountGate,
    showSettingsModal: state.showSettingsModal,
    displayGateTimer: state.displayGateTimer,
    settingsDeviceSelected: state.settingsDeviceSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateShowSignInCreateAccountModal: (modal) => dispatch({ type: 'SHOW_SIGN_IN_CREATE_ACCOUNT_MODAL', value: modal }),
    updateSignInCreateAccountModalContent: (content) => dispatch({ type: 'SIGN_IN_CREATE_ACCOUNT_MODAL_CONTENT', value: content }),
    updateSelectedDevice: (selected) => dispatch({ type: 'SETTINGS_DEVICE_SELECTED', value: selected }),
    updateShowSettingsModal: (showSettingsModal) => dispatch({ type: 'SHOW_SETTINGS_MODAL', value: showSettingsModal }),
    updateSerpDomain: (domainSelected) => dispatch({ type: 'SERP_DOMAIN', value: domainSelected }),
		updateSerpCountry: (countrySelected) => dispatch({ type: 'SERP_COUNTRY', value: countrySelected }),
    updateSerpLanguage: (languageSelected) => dispatch({ type: 'SERP_LANGUAGE', value: languageSelected }),
    updateResetPaswordLinkEmail: (email) => dispatch({ type: 'RESET_PASSWORD_LINK_EMAIL', value: email }),
    updateResetPaswordLinkSettingsParams: (settingsParams) => dispatch({ type: 'RESET_PASSWORD_LINK_SETTINGS_PARAMS', value: settingsParams }),
    updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate }),
    updateSearchTermQuery: (searchTermQuery) => dispatch({ type: 'SEARCH_TERM_QUERY', value: searchTermQuery }),
    updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan }),
    updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),
    updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
