import axios from 'axios';
// import { GetAuthName, GetAuthEmail, GetAuthUserId, GetAuthPlanId, GetAuthRenewDate, GetBillingCycle, GetAuthCancelDate } from './AuthStatus';
// import AuthStatusModel from './AuthStatusModel';
// import LOCALSTORAGE from '../constants/LocalStorage';
import { GetAuthEmail } from './AuthStatus';

export async function AuthTokenStatus(token) {
  if (token) {
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const params = {
        token: token,
        email: GetAuthEmail()
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/auth/refresh`, params, { headers: headers, withCredentials: true})
      return response;
    } catch (error) {
      return error.response;
    }
  }
}
