import React, { Component } from 'react';
import { Tooltip } from 'mdb-ui-kit';
import { updateViewAllMetricsSubject } from '../../observables/ViewAllMetrics';
import SerpPsiExportToolTipEnabled from './SerpPsiExportToolTipEnabled';
import SerpPsiExportToolTipDisabled from './SerpPsiExportToolTipDisabled';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { updateSerpViewAllDataSubject } from '../../observables/SerpViewAllDataSubject';
import { MediaMatcher } from '../../functions/MediaMatcher';
import { updateMetricSelectedSubject } from '../../observables/MetricLabSelected';
import { GetAuthPlanId } from '../../functions/AuthStatus';

class ViewAllMetricsToggle extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      isViewAllMetrics: false,
      avgLabPsi: '',
      avgLabLcp: '',
      avgLabFid: '',
      avgLabCls: '',
      avgWordCount: 0,
      avgPsiColorState: '',
      avgLcpColorState: '',
      avgFidColorState: '',
      avgClsColorState: '',
      avgSchemaTypeState: [],
      viewAllDataToggled: true,
      schemaFilterArray: [],
      avgScoresUpdate: false,
      avgTypesUpdated: false,
      schemasToViewAmount: 0,
      schemaArrow: "fa-chevron-down",
      scrollerHeight: "260px",
      toolState: "",
      toolStateEn: "",
      showCopiedUrlToolTip: false
    };
	}

  handleViewAllMetricsToggle = () => {
    if (this._isMounted) this.setState({ isViewAllMetrics: !this.state.isViewAllMetrics });
    updateViewAllMetricsSubject.update(!this.state.isViewAllMetrics);
    this.setState({viewAllDataToggled: !this.state.viewAllDataToggled});
    this.props.updateViewAllDataToggle(this.state.viewAllDataToggled);
    // this.props.updateCanViewAllDataAlert(false);
  }

  handleUpdateAvgScores = () => {
    let values = [...this.props.exportGoogleSerpData.values()];
    this.getAvgValues(values);
    this.setState({avgScoresUpdate: !this.state.avgScoresUpdate });
  }

  handleUpdateAvgTypes = () => {
    this.getAvgTypes(this.props.schemaType);
    this.props.canUpdateSchemaTypes(false);
  }

  getAvgTypes = (types) => {
    let totalWordCount = this.props.wordCount;
    let type = {};
    types.map((data) => {
      for (var i = 0; i < data.length; i++) {
          const schema = data[i].charAt(0).toUpperCase() + data[i].slice(1);
          type[schema] = (type[schema] + 1) || 1
      }
      return type;
    })
    let entries = Object.entries(type);
    let sorted = entries.sort((a, b) => b[1] - a[1]);
    let hiddenSchemasType = sorted.length - 4;
    let newSort = sorted.map((obj, index) => {
      if(obj.color !== "selected-filter-btn")
      {
        obj.color = "filter-btn";
        obj.icon = "";
      }
      return obj;
    });

    this.setState(prevState => ({
      avgSchemaTypeState: newSort
    }))
    this.setState({ schemasToViewAmount: hiddenSchemasType });
    this.setState({ avgWordCount: totalWordCount.toFixed(0) });
    this.setState({ avgTypesUpdated: true });
    this.props.canViewUpdatedTypes(true);

  }

  getAvgValues = (objValues) => {
    let totalPsi = 0;
    let totalLCP = 0;
    let totalFID = 0;
    let totalCLS = 0;

    for (var i = 0; i < objValues.length; i++) {
        if(objValues[i].labPsi > 0 && objValues[i] !== "N/A")
        {
          totalPsi += objValues[i].labPsi;
        }
        if(objValues[i].labLcp !== "N/A")
        {
          totalLCP += JSON.parse(objValues[i].labLcp);
        }
        if(objValues[i].fieldFid !== "N/A" && objValues[i].fieldFid !== "")
        {
          totalFID += JSON.parse(objValues[i].fieldFid);
        }
        if(objValues[i].labCls !== "N/A")
        {
          totalCLS += JSON.parse(objValues[i].labCls);
        }
    }

    let avgPsi = totalPsi / objValues.length;
    let avgLcp = totalLCP / objValues.length;
    let avgFid = totalFID / objValues.length;
    let avgCls = totalCLS / objValues.length;

    this.handleAverageMetricsDisplay(avgPsi, avgLcp, avgFid, avgCls);

    this.setState({ avgLabPsi: avgPsi.toFixed(1) });
    this.setState({ avgLabLcp: avgLcp.toFixed(1) });
    this.setState({ avgLabFid: avgFid.toFixed(1) });
    this.setState({ avgLabCls: avgCls.toFixed(3) });
    this.props.updateCanViewAllDataAlert(true);
  }

  handleAverageMetricsDisplay = (avgPsi, avgLcp, avgFid, avgCls) => {
    let avgPsiColor, avgLcpColor, avgFidColor, avgClsColor = '';

    if(avgPsi <= 49) avgPsiColor = "psi-score-fail";
    if(avgPsi > 49 && avgPsi <= 89) avgPsiColor = "psi-score-average";
    if(avgPsi > 89) avgPsiColor = "psi-score-pass";

    if(avgLcp <= 2.5) avgLcpColor = "psi-score-pass";
    if(avgLcp > 2.5 && avgLcp < 4.0) avgLcpColor = "psi-score-average";
    if(avgLcp >= 4.0) avgLcpColor = "psi-score-fail";

    if(avgFid < 100) avgFidColor = "psi-score-pass";
    if(avgFid >= 100 && avgFid < 300) avgFidColor = "psi-score-average";
    if(avgFid >= 300) avgFidColor = "psi-score-fail";

    if(avgCls * 100 < 10) avgClsColor = "psi-score-pass";
    if(avgCls * 100 >= 10 && avgCls * 100 < 25) avgClsColor = "psi-score-average";
    if(avgCls * 100 >= 25) avgClsColor = "psi-score-fail";

    this.setState({ avgPsiColorState: avgPsiColor });
    this.setState({ avgLcpColorState: avgLcpColor });
    this.setState({ avgFidColorState: avgFidColor });
    this.setState({ avgClsColorState: avgClsColor });

  }

  exportGoogleSerp = () => {
    // CSV format
    if (this.props.enableSerpExportBtn) {
      if(GetAuthPlanId() > 1) {
        const fileTitle = 'google_serp';
        const headers = {
          rank: 'Rank'.replace(/,/g, ''), // * remove commas to avoid errors
          wordCount: 'word count',
          titleTag: 'title tag',
          metaDesc: 'meta desc',
          h1: 'h1',
          canonical: 'canonical',
          httpStatus: 'http status',
          schemaTypes: 'schema types',
          cwv: 'cwv',
          labPsi: 'performance score',
          labFcp: 'lab-fcp (s)',
          labSi: 'lab-si (s)',
          labLcp: 'lab-lcp (s)',
          labTti: 'lab-tti (s)',
          labTbt: 'lab-tbt (ms)',
          labCls: 'lab-cls (score)',
          fieldLcp: 'field-lcp (s)',
          fieldFid: 'field-fid (ms)',
          fieldCls: 'field-cls (score)',
          fieldFcp: 'field-fcp (s)',
          url: 'URL'
        };
        let values = [...this.props.exportGoogleSerpData.values()];
        this.exportCSVFile(headers, values, fileTitle);
        ReactGA.event({ category: 'speed comparison', action: 'export', label: 'google serp' });
      } else {
        ReactGA.event({ category: 'speed comparison gated', action: 'export', label: 'google serp' });
        this.props.updateCanViewExportAlert(true);
      }
    }
  }

  exportCSVFile = (headers, items, fileTitle) => {
    if (headers) items.unshift(headers);
    // * Convert Object to JSON
    const jsonObject = JSON.stringify(items);
    const csv = this.convertToCSV(jsonObject);
    const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });


    if (navigator.msSaveBlob) { // * IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // * feature detection
        // * Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';
        line += array[i][index];
      }
      // str += line + '\r\n';
      str += line.split(/\r\n|\n|\r/) + '\r\n';
    }
    return str.replace("/\r\n/", "");
  }

  handleNewFilterSerpCards = async (e, type) => {
    if(e.currentTarget.classList.contains("filter-btn")) {
      e.currentTarget.classList.remove("filter-btn");
      e.currentTarget.classList.add("selected-filter-btn");
      e.currentTarget.children[0].classList.add("selected-filter-icon");
    } else if(e.currentTarget.classList.contains("selected-filter-btn")) {
      e.currentTarget.classList.remove("selected-filter-btn");
      e.currentTarget.classList.add("filter-btn");
      e.currentTarget.children[0].classList.remove("selected-filter-icon");
    } else if(e.currentTarget.parentNode.children[0].children[0].classList.contains("filter-btn")) {
      e.currentTarget.parentNode.children[0].children[0].classList.remove("filter-btn");
      e.currentTarget.parentNode.children[0].children[0].classList.add("selected-filter-btn");
      e.currentTarget.parentNode.children[0].children[0].children[0].classList.add("selected-filter-icon");
    } else if(e.currentTarget.parentNode.children[0].children[0].classList.contains("selected-filter-btn")) {
      e.currentTarget.parentNode.children[0].children[0].classList.remove("selected-filter-btn");
      e.currentTarget.parentNode.children[0].children[0].classList.add("filter-btn");
      e.currentTarget.parentNode.children[0].children[0].children[0].classList.remove("selected-filter-icon");
    }

    if((this.props.selectedMetrics?.metricType !== "Type" && e.currentTarget.classList.contains("selected-filter-btn")) ||
      (this.props.selectedMetrics?.metricType !== "Type" && e.currentTarget.parentNode.children[0].children[0].classList.contains("selected-filter-btn"))) {
      updateMetricSelectedSubject.update({
        metricType: 'Type',
        metric: 'psi'
      });
    }
    updateViewAllMetricsSubject.update(true);

    if(this.props.canViewTypes) {
      var filterRes;
      let schemaFilterMapping = this.state.schemaFilterArray;

        if(schemaFilterMapping.includes(type[0])) {
          filterRes = schemaFilterMapping.filter(function(word) {
              if(word !== type[0]) {
                return word;
              } else { //test make sure it does not affect schema filtering
                return null;
              }
            });

           schemaFilterMapping = filterRes;
           await this.setState({schemaFilterArray: schemaFilterMapping});
        } else {
          ReactGA.event({ category: 'speed comparison', action: 'filter', label: type[0] });
          if(!this.state.isViewAllMetrics) updateSerpViewAllDataSubject.update(true);
            schemaFilterMapping.push(type[0]);
            await this.setState({schemaFilterArray: schemaFilterMapping});
        }

      let cards = document.querySelectorAll(".serp-card");

      cards.forEach((card, index) => {
          var reactHandlerKey=Object.keys(card).filter(function(item){
            return item.indexOf('__reactEventHandlers')>=0
          });

          var serpEvents = card[reactHandlerKey[0]];
          if(serpEvents.children) {                                         //check if serpCardEvents are null
            if(schemaFilterMapping.length > 0) {                            //check for filters if none then show all cards
              var getTypes = serpEvents.children._owner.memoizedProps.types;
              let arrayMatch = schemaFilterMapping.every(function(string) { // check filter array strings
                return getTypes.includes(string);
              });
              if(!arrayMatch) {                                            //if cards schema types match filter array
                if(!card.classList.contains("none"))
                   card.classList.add("none");
              } else {
                if(card.classList.contains("none"))                        // if the do match and are hidden show them
                  card.classList.remove("none");
              }
            } else {
              if(card.classList.contains("none")) {
                card.classList.remove("none");
              }
            }
           }
        });
    }
  }

  clearFilter = async () => {
    let cards = document.querySelectorAll(".serp-card");
    let filterBtns = document.querySelectorAll(".sf-btn");

    await this.setState({ schemaFilterArray: [] });

    cards.forEach(card => {
      if(card.classList.contains("none")) {
        card.classList.remove("none");
      }
    });

    filterBtns.forEach(btn => {
      if(btn.classList.contains("selected-filter-btn")) {
        btn.classList.remove("selected-filter-btn");
        btn.classList.add("filter-btn");
        btn.children[0].classList.remove("selected-filter-icon");
      }
    })
    // this.props.handleSerpCardFilter(this.state.schemaFilterArray);
    // this.resetAvgSchemaState(this.state.schemaFilterArray);

  }

  // resetAvgSchemaState = async (schemaFilterMapping) => {
  //   var results = this.state.avgSchemaTypeState.map((obj, index) => {
  //     if(schemaFilterMapping.includes(obj[0])) {
  //       obj.color = "selected-filter-btn";
  //       obj.icon = "selected-filter-icon";
  //     }else {
  //       obj.color = "filter-btn";
  //       obj.icon = "";
  //     }
  //     return obj;
  //   })
  //   this.setState({ avgSchemaTypeState: [...results] });
  // }

  handleSerpCardExpand = () => {
    if(this.state.scrollerHeight === "100%") {
      this.setState({ scrollerHeight: "260px" });
      this.setState({ schemaArrow: "icon-item fas fa-caret-down pl-1"});
    }
    else {
      this.setState({ scrollerHeight: "100%" });
      this.setState({ schemaArrow: "icon-item fas fa-caret-up pl-1"});
    }
  }

  resetScrollerHeight = () => {
    this.setState({ scrollerHeight: "260px" });
    this.setState({ schemaArrow: "fa-chevron-down"});
  }

  handleExportToolTip = (e) => {
    const exportToolTip = document.getElementById(`tool-tip-export-psi-enabled`);
    const exportDisabledToolTip = document.getElementById(`tool-tip-export-psi-disabled`);
      if(exportToolTip) {
        let tool = new Tooltip(exportToolTip, {boundary: 'window' });
        tool.show();
        this.setState({toolStateEn: tool});
      }
      if(exportDisabledToolTip) {
          let tool;
        tool = new Tooltip(exportDisabledToolTip, {boundary: 'window' });
          if(tool.element.hidden === false) {
            tool.show();
            this.setState({toolState: tool});
        }
      }
  }

  handleLeaveExportToolTip = (e) => {
      if(this.state.toolState) {
        this.state.toolState.hide();
        this.setState({ toolState: null });
      }

      if(this.state.toolStateEn) {
        this.state.toolStateEn.hide();
        this.setState({ toolStateEn: null });
      }
  }

  copyToClipboard = async (e) => {
    if(navigator.clipboard) {
      let copyTool;
      let copyUrl = window.location + "?searchTerm=" + this.props.searchTerm;

      navigator.clipboard.writeText(copyUrl).then(() => {
        console.log("url copied! " + copyUrl);
        const toolTipCopyUrl = document.getElementById("copy-tool-tip");
        if(toolTipCopyUrl)

        if(toolTipCopyUrl){
          copyTool = new Tooltip(toolTipCopyUrl, { boundary: 'window' });
          copyTool.show();
        }
        setTimeout(() => {
          if(copyTool) copyTool.hide();
        }, 3000);
      }).catch(() => {
        console.log("copy url failed");
      });
    }
  }

  render() {
    let values = [...this.props.exportGoogleSerpData.values()];
    let pagesLeft = values.length - this.props.psiCounter;

    return (
      <div className="toggle-view-all-metrics-container pb-3">

          <div className="pb-0 toggle-switch-container flex-horizontal-right-sm d-flex align-self-stretch">
            <div className="flex-grow-1 form-check form-switch" style={{ display: "inline-block" }}>
              <div
                id="google-serp-export-parent"
                className="d-flex float-right">
                {/* className="d-none d-md-flex float-right"> */}
                <span
                  id="copy-tool-tip"
                  className="btn btn-primary mr-2"
                  data-ripple-color="dark"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-trigger='manual'
                  title="share link copied to clipboard"
                  onClick={(e) => this.copyToClipboard(e)}>
                  <i className="fas fa-share fa-lg"></i>
                </span>
                <button
                  id="google-serp-export"
                  onMouseEnter={(e) => this.handleExportToolTip(e)}
                  onMouseLeave={(e) => this.handleLeaveExportToolTip(e)}
                  className={`btn btn-primary d-flex ${!this.props.enableSerpExportBtn ? 'disabled serp-export' : ''}`}
                  type="button"
                  data-ripple-color="dark"
                  onClick={() => this.exportGoogleSerp()}>
                  {
                    this.props.enableSerpExportBtn
                    ? <SerpPsiExportToolTipEnabled plan={GetAuthPlanId()}/>
                    : <SerpPsiExportToolTipDisabled plan={GetAuthPlanId()}/>
                  }
                  <span
                    className="export-text"
                    >Export</span>
                  </button> 
                </div>
              </div>
            </div>
          <div className="col-sm-12 avg-card">
              <div className="card shadow-2-strong">
                <div className="card-body clickable">
                  <div className="scroller-parent">
                    <h4 className="avg-searched-term">Serp Stats</h4>
                    <span className='meta-content avg-word-count'>{ JSON.stringify(this.props.searchTerm) }</span>
                    <br></br>
                    <div className="d-flex">
                    <span className='meta-content avg-word-count'>Avg Word Count: </span>
                      {
                         this.props.canViewTypes ?
                        <div className="meta-content avg-word-count ml-1"> { this.state.avgWordCount } </div>
                        : <div className="rect-animated-img-holder ml-1">
                              <span className="metrics-rectangle-animated rectangle-skeleton-holder mt-1">
                                <img
                                  className="rect-skeleton"
                                  src="../images/skeletonRect.PNG"
                                  alt="skeleton"
                                />
                                </span>
                          </div>
                      }</div>

                  </div>
                <div className="d-flex avg-scroller">
                  <div>
                    <div className="structured-data" style={{ height: `${this.state.scrollerHeight}` }}>
                      <h5 className="site-metric-title"
                        id="tool-tip-schema-types"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-trigger='hover'
                        title="Website Schemas are HTML tags in a “shared vocabulary” that can be used to convey info to search engines like Google. Implementing Schema can improve the way your site displays in the SERPs, increasing click through rate and lifting ranking."
                        >Schema Types</h5>
                        {
                          this.props.schemaType.length < 4 || this.props.moreResultsClicked
                          ? <ul className="avg-content avg-list">
                               <li className="schema-item align-items-center d-flex">
                                 <div>
                                   <span className="btn-outline-primary filter-btn">
                                     <i className="fas fa-filter fa-lg pb-0"></i></span>
                                 </div>
                                   <div className="schema-animated-img-holder ml-1 mb-1">
                                       <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                          <img
                                            className="rect-skeleton"
                                            src="../images/skeletonRect.PNG"
                                            alt="skeleton"
                                            />
                                         </span>
                                     </div>
                                </li>
                               <li className="schema-item align-items-center d-flex">
                                 <div>
                                   <span className="btn-outline-primary filter-btn">
                                     <i className="fas fa-filter fa-lg pb-0"></i></span>
                                 </div>
                                   <div className="schema-animated-img-holder ml-1 mb-1">
                                       <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                          <img
                                            className="rect-skeleton"
                                            src="../images/skeletonRect.PNG"
                                            alt="skeleton"
                                            />
                                         </span>
                                     </div>

                               </li>
                              <li className="schema-item align-items-center d-flex">
                                <div>
                                  <span className="btn-outline-primary filter-btn">
                                    <i className="fas fa-filter fa-lg pb-0"></i></span>
                                </div>
                                  <div className="schema-animated-img-holder ml-1 mb-1">
                                      <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                         <img
                                           className="rect-skeleton"
                                           src="../images/skeletonRect.PNG"
                                           alt="skeleton"
                                           />
                                        </span>
                                    </div>
                              </li>
                              <li className="schema-item align-items-center d-flex">
                                <div>
                                  <span className="btn-outline-primary filter-btn">
                                    <i className="fas fa-filter fa-lg pb-0"></i></span>
                                </div>
                                  <div className="schema-animated-img-holder ml-1 mb-1">
                                      <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                         <img
                                           className="rect-skeleton"
                                           src="../images/skeletonRect.PNG"
                                           alt="skeleton"
                                           />
                                        </span>
                                    </div>
                              </li>
                            </ul>
                          :  <ul className="avg-content avg-list">
                                {  this.state.avgSchemaTypeState.map((objKey, index) => {
                                    return <li key={index} className="schema-item align-items-center d-flex">
                                      <div>
                                        <span
                                          className={`btn-outline-primary ${objKey.color} schema-filter sf-btn`}
                                          onClick={(e) => this.handleNewFilterSerpCards(e, objKey)}
                                          >
                                          <i className={`fas fa-filter fa-lg pb-0 ${objKey.icon}`}></i>
                                        </span>
                                      </div>
                                      <span
                                        className="ml-2 schema-filter sf-word"
                                        onClick={(e) => this.handleNewFilterSerpCards(e, objKey)}>
                                          {
                                            this.props.canViewTypes
                                            ? `${objKey.join(": (")})`
                                            : <div className="schema-animated-img-holder ml-1">
                                                <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                                   <img
                                                     className="rect-skeleton"
                                                     src="../images/skeletonRect.PNG"
                                                     alt="skeleton"
                                                     />
                                                </span>
                                              </div>
                                         }
                                      </span>
                                      </li>
                                  })}
                              </ul>
                          }
                          </div>

                          {
                            this.props.schemaType.length > 0 && this.props.canViewTypes && this.state.schemasToViewAmount > 0
                            ? <div
                                className="view-more-schema"
                                onClick={() => this.handleSerpCardExpand()}>
                                {
                                  this.state.scrollerHeight === "260px" ?
                                  <div> <span className={`fas ${this.state.schemaArrow}`}></span> view {this.state.schemasToViewAmount} more </div>
                                  : null
                                }
                              </div>
                             : null
                          }
                    </div>

                    <div className="meta-content-data">
                      <div>
                      <h5 className="site-metric-title"
                        id="tool-tip-site-speed-metrics"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-trigger='hover'
                        title="We display data from Google's PageSpeed insights. This includes simulated lab data and field data, measured from real users."
                        >Site Speed Metrics</h5>
                        <ul className="avg-content avg-list">
                          <li  className="schema-item align-items-center d-flex">
                            {this.props.deviceSelected === "mobile" ?
                            <span
                              id="tool-tip-psi-score"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-trigger='hover'
                              title="This is the avg overall score for the simulated lab data from Google's PageSpeed insights. We average across all pages in the SERP results.">
                              Avg Mobile PSI Score:
                            </span>
                            : <span
                              id="tool-tip-psi-score"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-trigger='hover'
                              title="This is the avg overall score for the simulated lab data from Google's PageSpeed insights. We average across all pages in the SERP results.">
                              Avg Desktop PSI Score:
                            </span>
                             }
                            <span className={this.state.avgPsiColorState}>
                                { this.props.enableSerpExportBtn ?
                                    <span className="ml-1">  {this.state.avgLabPsi} </span>
                                    :  this.props.moreResultsClicked ?  // TODO temporary fix until I figure out how to make animations stay in sync on more results clicked
                                        <div className="rect-animated-img-holder ml-1">
                                            <span className="metrics-rectangle-animated rectangle-skeleton-holder">
                                               <img
                                                 className="rect-skeleton"
                                                 src="../images/skeletonRect.PNG"
                                                 alt="skeleton"
                                                 />
                                              </span>
                                          </div>
                                        : <div className="rect-animated-img-holder ml-1">
                                              <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                                <img
                                                  className="rect-skeleton"
                                                  src="../images/skeletonRect.PNG"
                                                  alt="skeleton"
                                                />
                                              </span>
                                        </div>
                                    }
                                </span>
                          </li>
                          <li  className="schema-item align-items-center d-flex">
                            <span
                              id="tool-tip-avg-lcp"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-trigger='hover'
                              title="Largest Contentful Paint. Measures loading performance. This is one of Google's Core Web Vitals, and it is field data.">
                              Avg LCP:
                            </span>
                            <span className={this.state.avgLcpColorState}>
                                { this.props.enableSerpExportBtn ?
                                  <span className="ml-1"> {this.state.avgLabLcp}s </span>
                                : this.props.moreResultsClicked ?
                                <div className="rect-animated-img-holder ml-1">
                                    <span className="metrics-rectangle-animated rectangle-skeleton-holder">
                                       <img
                                         className="rect-skeleton"
                                         src="../images/skeletonRect.PNG"
                                         alt="skeleton"
                                         />
                                      </span>
                                  </div>
                                : <div className="rect-animated-img-holder ml-1">
                                      <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                        <img
                                          className="rect-skeleton"
                                          src="../images/skeletonRect.PNG"
                                          alt="skeleton"
                                        />
                                      </span>
                                </div>
                               }
                             </span>
                          </li>
                          <li className="schema-item align-items-center d-flex">
                              <span
                                id="tool-tip-avg-fid"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-trigger='hover'
                                title="First Interactive Delay. Measure interactivity. This is one of Google's Core Web Vitals, and it is field data.">
                                Avg FID:
                              </span>
                            <span className={this.state.avgFidColorState}>
                              {
                              this.props.enableSerpExportBtn ?
                                <span className="ml-1"> {this.state.avgLabFid}ms </span>
                                : this.props.moreResultsClicked ?
                                    <div className="rect-animated-img-holder ml-1">
                                      <span className="metrics-rectangle-animated rectangle-skeleton-holder">
                                       <img
                                         className="rect-skeleton"
                                         src="../images/skeletonRect.PNG"
                                         alt="skeleton"
                                         />
                                      </span>
                                </div>
                              : <div className="rect-animated-img-holder ml-1">
                                    <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                      <img
                                        className="rect-skeleton"
                                        src="../images/skeletonRect.PNG"
                                        alt="skeleton"
                                      />
                                    </span>
                              </div>
                             }
                          </span>
                          </li>
                          <li className="schema-item align-items-center d-flex">
                            <span
                              id="tool-tip-avg-cls"
                              data-toggle="tooltip"
                              data-placement="top"
                              data-trigger='hover'
                              title="Cumulative Layout Shift. Measures visual stability.  This is one of Google's Core Web Vitals, and it is field data, measured from real users.">
                              Avg CLS:
                            </span>
                             <span className={this.state.avgClsColorState}>
                              {
                                this.props.enableSerpExportBtn ?
                              <span className="ml-1"> {this.state.avgLabCls }</span>
                              : this.props.moreResultsClicked ?
                              <div className="rect-animated-img-holder ml-1">
                                  <span className="metrics-rectangle-animated rectangle-skeleton-holder">
                                     <img
                                       className="rect-skeleton"
                                       src="../images/skeletonRect.PNG"
                                       alt="skeleton"
                                       />
                                    </span>
                                </div>
                              : <div className="rect-animated-img-holder ml-1">
                                    <span className="schema-rectangle-animated rectangle-skeleton-holder">
                                      <img
                                        className="rect-skeleton"
                                        src="../images/skeletonRect.PNG"
                                        alt="skeleton"
                                      />
                                    </span>
                              </div>
                             }
                               </span>
                          </li>
                        </ul>
                      </div>
                      {
                        pagesLeft > 0 ?
                          <div className="pages-left">
                          {
                            pagesLeft === 1 ?
                              <span className="psi-avg-waiting-text">{pagesLeft} page still loading</span>
                            : <span className="psi-avg-waiting-text">{pagesLeft} pages still loading</span>
                          }
                          </div> : null
                        }
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="toggle-switch-container flex-horizontal-left-sm d-flex align-self-stretch mt-4 mb-1 pb-0">
            <div className="flex-grow-1 form-check form-switch" style={{ display: "inline-block" }}>
              <input
                className="form-check-input"
                disabled={this.props.enableSerpExportBtn || this.props.schemaType.length > 0 ? false : true}
                type="checkbox"
                id="view-all-metrics-switch"
                value={this.state.isViewAllMetrics}
                checked={this.state.isViewAllMetrics}
                onChange={this.handleViewAllMetricsToggle}/>
              <span className="form-check-label" htmlFor="view-all-metrics-switch">
                view all data
              </span>
              <i
                id="view-all-metrics-switch-info"
                className="fas fa-info-circle pl-2"
                data-toggle="tooltip"
                data-placement="top"
                data-trigger='hover'
                title="Shows all data on every search result.">
              </i>
            </div>
        </div>
        {
          this.state.schemaFilterArray.length > 0 ?
          <div className="col-sm-12 mt-3 flex-horizontal-left-sm">
            {
              this.state.schemaFilterArray.length === 1
              ? <span className="toggle-amount-text">You have {this.state.schemaFilterArray.length} filter applied. <span onClick={(e) => this.clearFilter()}><u>clear</u></span></span>
              : <span className="toggle-amount-text">You have {this.state.schemaFilterArray.length} filters applied. <span onClick={(e) => this.clearFilter()}><u>clear</u></span></span>
            }
          </div>
          : null
        }
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
    const toolTipEl = document.getElementById(`view-all-metrics-switch-info`);
		if (toolTipEl)  new Tooltip(toolTipEl, { boundary: 'window' });

    var screenSize = MediaMatcher();

    if(screenSize !== 'mobile')
    {
      const toolTipFid = document.getElementById(`tool-tip-avg-fid`);
      if (toolTipFid)  new Tooltip(toolTipFid, { boundary: 'window' });

      const toolTipPsi = document.getElementById(`tool-tip-psi-score`);
      if (toolTipPsi)  new Tooltip(toolTipPsi, { boundary: 'window' });

      const toolTipLcp = document.getElementById(`tool-tip-avg-lcp`);
      if (toolTipLcp)  new Tooltip(toolTipLcp, { boundary: 'window' });

      const toolTipCls = document.getElementById(`tool-tip-avg-cls`);
      if (toolTipCls)  new Tooltip(toolTipCls, { boundary: 'window' });

      const toolTipSchemaTypes = document.getElementById(`tool-tip-schema-types`);
      if(toolTipSchemaTypes) new Tooltip(toolTipSchemaTypes, { boundary: 'window' });

      const toolTipSpeedMetrics = document.getElementById(`tool-tip-site-speed-metrics`);
      if(toolTipSpeedMetrics) new Tooltip(toolTipSpeedMetrics, { boundary: 'window' });

    }

    // * Update serpViewAllDataSubject
    this.subscriptionSerpViewAllDataSelected = updateSerpViewAllDataSubject.get().subscribe(isUpdateSerpViewAllDataSubject => {
      if (isUpdateSerpViewAllDataSubject) {
        this.handleViewAllMetricsToggle();
        const viewDataToggle = document.querySelector("#view-all-metrics-switch");
        if(!this.state.isViewAllMetrics){
          viewDataToggle.checked = true;
          viewDataToggle.value = true;
        }
      }
    });


  }

  componentDidUpdate() {
    if(this.props.enableSerpExportBtn && !this.state.avgScoresUpdate)
      this.handleUpdateAvgScores();

    if(this.props.moreResultsClicked && this.state.schemaFilterArray.length > 0)
      this.clearFilter();

    if(this.props.updateSchemaTypes) {
      this.handleUpdateAvgTypes();
      this.resetScrollerHeight();
    }

    if(!this.props.canViewTypes && this.state.schemaFilterArray > 0) {
      this.clearFilter();
    }
  }

  componentWillUnmount() {
    this.subscriptionSerpViewAllDataSelected.unsubscribe();
  }
}

const mapStateToProps = state => {
  return {
    exportGoogleSerpData: state.exportGoogleSerpData,
    canViewAllDataAlert: state.canViewAllDataAlert,
    viewAllDataToggledOn: state.viewAllDataToggledOn,
    psiCounter: state.psiCounter,
    updateSchemaTypes: state.updateSchemaTypes,
    canViewTypes: state.canViewTypes,
    canViewFilterClearedAlert: state.canViewFilterClearedAlert,
    clearSchemaFilters: state.clearSchemaFilters,
    screenSize: state.screenSize,
    selectedMetrics: state.selectedMetrics
  };
};

const mapDispatchToProps = dispatch => {
  return {
    		updateCanViewAllDataAlert: (canViewAllDataAlert) => dispatch({ type: 'CAN_VIEW_ALL_DATA_ALERT', value: canViewAllDataAlert }),
        updateViewAllDataToggle: (viewAllDataToggle) => dispatch({ type: 'VIEW_ALL_DATA_TOGGLED_ON', value: viewAllDataToggle }),
        canUpdateSchemaTypes: (updateSchemasTypes) => dispatch({ type: 'CAN_UPDATE_SCHEMA_TYPES', value: updateSchemasTypes }),
        canViewUpdatedTypes: (canViewTypes) => dispatch( {type: 'CAN_VIEW_UPDATED_TYPES', value: canViewTypes }),
        updateClearSchemaFilters: (clearSchemaFilters) => dispatch({ type: 'CLEAR_SCHEMA_FILTERS', value: clearSchemaFilters }),
        updateCanViewExportAlert: (canViewExportAlert) => dispatch({ type: 'CAN_VIEW_EXPORT_ALERT', value: canViewExportAlert })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllMetricsToggle);
