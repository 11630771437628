import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import LOCALSTORAGE from '../constants/LocalStorage';
import { ResetPasswordApi } from '../functions/ResetPasswordApi';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { updateSerpToolSettings } from '../observables/SerpToolSettingsSubject';
import Footer from '../components/Footer/Footer';

class ResetPasswordPage extends Component {
  constructor(props) {
    super();
		this.state = {
      password: '',
			isPasswordVaild: true,
			passwordErrorMsg: '',
			passwordErrorMsgOptions: {
				blank: 'Please enter a password.',
				invalid: 'Password must contain between 8 and 16 characters.'
			},
      toggleCheckMark: false
		};
  }

  handlePasswordInputChange = (event) => {
		const value = event.target.value;
    this.setState({ password: value });
  }

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.validatePassword()) this.savePassword();
	}

	validatePassword = () => {
		let isValid = false;
		if (this.state.password !== '') {
			this.state.password.length >= 8 && this.state.password.length <= 16
				? isValid = true
				: this.setState({ passwordErrorMsg: this.state.passwordErrorMsgOptions.invalid });
		} else {
			this.setState({ passwordErrorMsg: this.state.passwordErrorMsgOptions.blank });
		}
		this.setState({ isPasswordVaild: isValid });
		return isValid;
	}

	updateIsEmailSubscribedLocalStorage = () => {
		if (typeof localStorage !== 'undefined')
			localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, true);
	}

	savePassword = () => {
		const params = {
			password: this.state.password
		};
		ResetPasswordApi(params).then(res => {
			if (res && res.status === 200) {
        this.setState({ toggleCheckMark: true });
        if (this.props.resetPaswordLinkSettingsParams &&
            this.props.resetPaswordLinkSettingsParams.originUrl &&
            this.props.resetPaswordLinkSettingsParams.originUrl !== ''
        ) {
          this.props.history.push({ pathname: this.props.resetPaswordLinkSettingsParams.originUrl });
          if (this.props.resetPaswordLinkSettingsParams.originUrl === '/serp-analysis-tool') updateSerpToolSettings.update(true);
        }
      }
		});
	}

	toggleShowPassword = () => {
		const resetPasswordInputEl = document.querySelector('#resetPassword');
		const showResetPasswordToggleBtn = document.querySelector('#resetPasswordToggle');
		if (resetPasswordInputEl && showResetPasswordToggleBtn) {
			resetPasswordInputEl.type = resetPasswordInputEl.type === 'password'
				? 'text'
				: 'password';
				showResetPasswordToggleBtn.innerHTML = resetPasswordInputEl.type === 'password'
				? 'show'
				: 'hide';
		}
	}

  render() {
    return (
			<div className="page-background1">
        <Helmet>
          <meta name="description" content=""/>
          <title>SEO Tools | Sandbox</title>
        </Helmet>
        <Header />
        <section className="container-fluid page-content-padding">
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={'/'}>Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Reset Password</li>
                  </ol>
                </nav>
              </div>
              <div className="col-sm-12">
                <h3 className="mt-5 text-center">Reset your password</h3>
                <form onSubmit={this.handleSubmit} noValidate>
                  <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8">
                      <div className="pb-3">
                        <label htmlFor="resetPassword">Enter a password</label>
                        <div className="input-group">
                          <input
                            type="password"
                            id="resetPassword"
                            className={`form-control ${!this.state.isPasswordVaild ? 'error' : ''}`}
                            value={this.state.password}
                            onChange={this.handlePasswordInputChange} />
                          <button
                            className="btn btn-outline-primary"
                            type="button"
                            id="resetPasswordToggle"
                            data-ripple-color="dark"
                            onClick={()=> {this.toggleShowPassword()}}>
                            show
                          </button>
                        </div>
                        <label className="error">
                          <span>
                            {
                              !this.state.isPasswordVaild
                                ? this.state.passwordErrorMsg
                                : null
                            }
                          </span>
                        </label>
                        {
                          this.state.isPasswordVaild
                            ? <span className="input-notes">8 - 16 characters</span>
                            : null
                        }
                      </div>
                    </div>
                  </div>

                  <div className="pt-3 pb-3 text-center">
                    <button
                      className="btn btn-dark mr-3"
                      type="submit"
                      data-ripple-color="dark"
                      onClick={(e)=> {this.handleSubmit(e)}}
                      disabled={this.state.toggleCheckMark}>
                        {
                          this.state.toggleCheckMark
                            ? <i className="fas fa-check"></i>
                            : <span>submit</span>
                        }
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
			</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetPaswordLinkSettingsParams: state.resetPaswordLinkSettingsParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordPage));
