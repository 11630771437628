import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import LOCALSTORAGE from '../../constants/LocalStorage';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { GetUserSettingApi } from '../../functions/UserSettingApi';
import { CreateAccountApi } from '../../functions/CreateAccountApi';
import { CreateAccountGoogleApi } from '../../functions/CreateAccountGoogleApi';
import { updateSearchTwitterSubject } from '../../observables/SearchTwitter';
import { updateSignedInStatusSubject } from '../../observables/SignedInStatus';
import PRICING from '../../constants/Pricing'
import PLANS from '../../constants/Plans';
import { GetAuthUserId } from '../../functions/AuthStatus';
import { RemainingSerpUsageStatus } from '../../functions/RemainingSerpUsageStatus';
import { RemainingWstUsageStatus } from '../../functions/RemainingWstUsageStatus';
import { RemainingKwtUsageStatus } from '../../functions/RemainingKwtUsageStatus';

import { GoogleLogin } from '@react-oauth/google';
import { GoogleSignInApi } from '../../functions/GoogleSignInApi';
import jwt_decode from "jwt-decode";

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
			firstName: '',
			email: '',
			password: '',
			isEmailAddressValid: true,
			emailAddressErrorMsg: '',
			emailAddressErrorMsgOptions: {
				blank: 'Please enter your email address.',
				isRegistered: 'This email is already registered. Please sign in.',
				invalid:'Invalid email, please double-check your information and try again.'
			},
			isPasswordVaild: true,
			passwordErrorMsg: '',
			passwordErrorMsgOptions: {
				blank: 'Please enter a password.',
				invalid: 'Password must contain between 8 and 16 characters.'
			},
      signInMsg: 'Register now to view these results, and compare speed scores today. No credit card required.',
      keywordSignInMsg: 'Register now to try our GPT3 powered keyword tool. No credit card required.'
		};
	}

	handleNameInputChange = (event) => {
    const value = event.target.value;
    this.setState({ firstName: value });
	}

	handleEmailInputChange = (event) => {
    const value = event.target.value;
    this.setState({ email: value });
	}

	handlePasswordInputChange = (event) => {
		const value = event.target.value;
    	this.setState({ password: value });
  }

	handleSubmit = (event) => {
		event.preventDefault();
		const isEmailVaild = this.validateEmail();
		const isPasswordVaild = this.validatePassword();
		if (isEmailVaild && isPasswordVaild) this.createAccount();
	}

	validateEmail = () => {
		let isValid = false;
		if (this.state.email !== '') {
			const regex = new RegExp(/\S+@\S+\.\S+/, 'i');
			regex.test(this.state.email)
				? isValid = true
				: this.setState({emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.invalid})
		} else {
			this.setState({emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.blank});
		}
		this.setState({isEmailAddressValid: isValid});
		return isValid;
	}

	validatePassword = () => {
		let isValid = false;
		if (this.state.password !== '') {
			this.state.password.length >= 8 && this.state.password.length <= 16
				? isValid = true
				: this.setState({passwordErrorMsg: this.state.passwordErrorMsgOptions.invalid});
		} else {
			this.setState({passwordErrorMsg: this.state.passwordErrorMsgOptions.blank});
		}
		this.setState({isPasswordVaild: isValid});
		return isValid;
	}

	accountEmailSubscribe = (emailAddress, firstName) => {
    const params = {
			emailAddress: emailAddress,
			firstName: firstName,
			searchTerm: this.props.emailSubscribeSearchQuery
		};

    axios.post(`${process.env.REACT_APP_ACCOUNT_EMAIL_SUBSCRIBE_API}`, params)
    .then(res => {
			if (res) this.props.updateEmailSubscribeSearchQuery('');
    }).catch(error => {
      console.error('create account email subscribe Error: ', error);
    })
	}

	updateIsEmailSubscribedLocalStorage = () => {
		if (typeof localStorage !== 'undefined')
			localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, true);
	}

	toggleSignIn = () => {
		this.props.updateGateContent('signIn');
		this.props.updateSignInCreateAccountModalContent('signIn');
	}

	toggleShowPassword = () => {
		const createAccountPasswordInputEl = document.querySelector('#createAccountPassword');
		const showPasswordToggleBtn = document.querySelector('#createAccountShowPasswordToggle');
		if (createAccountPasswordInputEl && showPasswordToggleBtn) {
			createAccountPasswordInputEl.type = createAccountPasswordInputEl.type === 'password'
				? 'text'
				: 'password';
			showPasswordToggleBtn.innerHTML = createAccountPasswordInputEl.type === 'password'
				? 'show'
				: 'hide';
		}
	}

	createAccount = () => {
		const params = {
			email: this.state.email,
			name: this.state.firstName,
			password: this.state.password
		};

		CreateAccountApi(params).then(res => {
			if (res) {
				if (res.status === 200) {
					if (typeof localStorage !== 'undefined' && res.data.token) {
            const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
            localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
					}
					this.accountEmailSubscribe(this.state.email, this.state.firstName);
					const bodyEl = document.getElementsByTagName('body')[0];
					if (bodyEl) bodyEl.classList.remove('modal-open');
					this.updateIsEmailSubscribedLocalStorage();
					this.props.updateShowAccountGate(false);
					if (this.props.signInCreateAccountModalEl) this.props.signInCreateAccountModalEl.hide();
					this.props.updateShowSignInCreateAccountModal(false);
					GetUserSettingApi().then(data => {
						if (data) {
							this.props.updateSelectedDevice(data.deviceType = 1 ? 'mobile' : 'desktop');
							this.props.updateSerpDomain(data.googleDomain);
							this.props.updateSerpCountry(data.country);
							this.props.updateSerpLanguage(data.language);
						}
					});
					this.updateCurrentPlan(res.data);
					updateSearchTwitterSubject.update(true);
					updateSignedInStatusSubject.update(true);
					ReactGA.event({ category: 'authentication', action: 'create account', label: GetAuthUserId() });
        //   window.location.reload();
          this.handleRemainingWstSearches();
          this.handleRemainingSerpSearches();
          this.handleRemainingKwtSearches();
        } else {
					if (res.status === 409) {
						this.setState({ isEmailAddressValid: false })
						this.setState({ emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.isRegistered })
					}
				}
			}
		})
	}

	updateCurrentPlan = (userData) => {
    const currentPlan = PRICING[PLANS[userData.planId]];
    currentPlan.renewDate = userData.renewsAt;
		currentPlan.billingCycle = userData.billingCycle;
		currentPlan.cancelDate = userData.cancelsAt;
    this.props.updateCurrentPlan(currentPlan);
  	}

	handleRemainingSerpSearches = async () => {
		const response = await RemainingSerpUsageStatus();
		if(response) {
		if(response.numOfSerpLeft === null)
		{
			response.numOfSerpLeft = 0;
			this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
		}
		else
		{
			this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
		}
		}
	}

	handleRemainingWstSearches = async () => {
		const response = await RemainingWstUsageStatus();
		if(response) {
		if(response.numOfwebsiteSearchesLeft === null)
		{
			response.numOfwebsiteSearchesLeft = 0;
			this.props.updateRemainingWSTSearches(response.numOfwebsiteSearchesLeft);
		}
		else
		{
			this.props.updateRemainingWSTSearches(response.numOfwebsiteSearchesLeft);
		}
		}
	}

	handleRemainingKwtSearches = async () => {
		const response = await RemainingKwtUsageStatus();
		if(response) {
			if(response.kwtSearchesLeft === null) {
				response.kwtSearchesLeft = 0;
				this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
			} else {
				this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
			}
		}
	}

  	credentialResponse = async (response) => {
		var decoded = jwt_decode(response.credential);
		const params = {
		email: decoded.email,
		name: decoded.given_name + " " + decoded.family_name,
		isGoogleUser: true
		};

    CreateAccountGoogleApi(params).then(res => {
      if (res) {
        if (res.status === 200) {
          if (typeof localStorage !== 'undefined' && res.data.token) {
            const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
            localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
          }
          this.accountEmailSubscribe(decoded.email, decoded.given_name);

          const bodyEl = document.getElementsByTagName('body')[0];
          if (bodyEl) bodyEl.classList.remove('modal-open');

          this.updateIsEmailSubscribedLocalStorage();
          this.props.updateShowAccountGate(false);

          if (this.props.signInCreateAccountModalEl) this.props.signInCreateAccountModalEl.hide();
          this.props.updateShowSignInCreateAccountModal(false);

          GetUserSettingApi().then(data => {
            if (data) {
              this.props.updateSelectedDevice(data.deviceType = 1 ? 'mobile' : 'desktop');
              this.props.updateSerpDomain(data.googleDomain);
              this.props.updateSerpCountry(data.country);
              this.props.updateSerpLanguage(data.language);
            }
          });

          this.updateCurrentPlan(res.data);
          updateSearchTwitterSubject.update(true);
          updateSignedInStatusSubject.update(true);
          ReactGA.event({ category: 'authentication - Google Sign in', action: 'create account', label: GetAuthUserId() });
          window.location.reload();
          this.handleRemainingWstSearches();
          this.handleRemainingSerpSearches();
          this.handleRemainingKwtSearches();
        } else {
          if (res.status === 409) {
            //change error message here?
            this.setState({ isEmailAddressValid: false })
            this.setState({ emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.isRegistered });
            const signInParams = {
                email: decoded.email,
            };
            GoogleSignInApi(signInParams).then(res => {
              if (res) {
                if (res.status === 200) {
                  if (typeof localStorage !== 'undefined' && res.data.token) {
                    const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
                    localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
                  }
                  this.updateIsEmailSubscribedLocalStorage();
                  this.props.updateShowAccountGate(false);

                  if (this.props.signInCreateAccountModalEl) this.props.signInCreateAccountModalEl.hide();
                  this.props.updateShowSignInCreateAccountModal(false);

                  GetUserSettingApi().then(data => {
                    if (data) {
                      this.props.updateSelectedDevice(data.deviceType = 1 ? 'mobile' : 'desktop');
                      this.props.updateSerpDomain(data.googleDomain);
                      this.props.updateSerpCountry(data.country);
                      this.props.updateSerpLanguage(data.language);
                    }
                  });

                  this.updateCurrentPlan(res.data);
                  updateSearchTwitterSubject.update(true);
                  updateSignedInStatusSubject.update(true);
                  ReactGA.event({ category: 'authentication - Google Sign In', action: 'sign in', label: GetAuthUserId() });

                  this.handleRemainingWstSearches();
                  this.handleRemainingSerpSearches();
                  this.handleRemainingKwtSearches();
                  window.location.reload();
                } else {
                  this.signInErrorHandler(res);
                }
              }
            }).catch(error => {
              console.error('catch error sign in function ', error);
            });
          }
        }
      }
    })
}

  render() {
    return (
			<div>
				<div>
					<h3 className="mt-3 text-center">Create Your Free Sandbox Account</h3>
					{
						this.props.showAccountGate
            ? <p className="text-center">
              {this.props.isKeywordTool ?
                this.state.keywordSignInMsg : this.state.signInMsg
              }
            </p>
            : null
					}

				</div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-8 pt-3 pb-3">
            <div className="google-sign-in">
              <GoogleLogin
                logo_alignment="left"
                type="standard"
                text="signin_with"
                theme="filled_black"
                size="medium"
                width="275px"
                scope='profile email'
                onSuccess={this.credentialResponse}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>
            {/* <GoogleCustom
              googleSignIn={this.signInCredentialResponse}
            /> */}
            <div className="text-center row pt-3 pb-2">
              <div className="col-5 pr-0">
                <hr className="signin-hr"/>
              </div>
              <div className="col-2 p-0 mt-1">
                or
              </div>
              <div className="col-5 pl-0">
                <hr className="signin-hr"/>
              </div>


            </div>
          </div>
        </div>
				<div className="create-account-form">
					<form onSubmit={this.handleSubmit} noValidate>
						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-8">
								<div className="pb-3">
									<label htmlFor="createAccountName">Name</label>
									<input
										type="text"
										id="createAccountName"
										className="form-control"
										value={this.state.firstName}
										onChange={this.handleNameInputChange} />
								</div>
							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-8">
								<div className="pb-3">
									<label htmlFor="createAccountEmail">Email</label>
									<input
										type="email"
										id="createAccountEmail"
										className={`form-control ${!this.state.isEmailAddressValid ? 'error' : ''}`}
										value={this.state.email}
										onChange={this.handleEmailInputChange} />
									<label className="error">
										<span>
											{
												!this.state.isEmailAddressValid
													? this.state.emailAddressErrorMsg
													: null
											}
										</span>
									</label>
								</div>
							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-8">
								<div className="pb-3">
								<label htmlFor="createAccountPassword">Password</label>
									<div className="input-group">
										<input
											type="password"
											id="createAccountPassword"
											className={`form-control ${!this.state.isPasswordVaild ? 'error' : ''}`}
											value={this.state.password}
											onChange={this.handlePasswordInputChange} />
										<button
											className="btn btn-outline-primary"
											type="button"
											id="createAccountShowPasswordToggle"
											data-ripple-color="dark"
											onClick={()=> {this.toggleShowPassword()}}>
											show
										</button>
									</div>
									<label className="error">
										<span>
											{
												!this.state.isPasswordVaild
													? this.state.passwordErrorMsg
													: null
											}
										</span>
									</label>
									{
										this.state.isPasswordVaild
											? <span className="input-notes">8 - 16 characters</span>
											: null
									}
								</div>
							</div>
						</div>

						<div className="pt-3 pb-3 text-center">
							<button
								className="btn btn-dark"
								type="submit"
								id="createAccountSubmit"
								data-ripple-color="dark">
								CREATE ACCOUNT
							</button>

							<p className="pt-3">Already have an account? <u onClick={()=> {this.toggleSignIn()}}>Sign In</u></p>
						</div>
					</form>
				</div>
			</div>
    );
	}
}

const mapStateToProps = state => {
  return {
		showSignInCreateAccountModal: state.showSignInCreateAccountModal,
		signInCreateAccountModalEl: state.signInCreateAccountModalEl,
		showAccountGate: state.showAccountGate,
		emailSubscribeSearchQuery: state.emailSubscribeSearchQuery,
    isKeywordTool: state.isKeywordTool
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
		updateShowAccountGate: (showAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: showAccountGate }),
		updateSignInCreateAccountModalContent: (content) => dispatch({ type: 'SIGN_IN_CREATE_ACCOUNT_MODAL_CONTENT', value: content }),
		updateShowSignInCreateAccountModal: (showSignInCreateAccountModal) => dispatch({ type: 'SHOW_SIGN_IN_CREATE_ACCOUNT_MODAL', value: showSignInCreateAccountModal }),
		updateSelectedDevice: (selected) => dispatch({ type: 'SETTINGS_DEVICE_SELECTED', value: selected }),
		updateSerpDomain: (domainSelected) => dispatch({ type: 'SERP_DOMAIN', value: domainSelected }),
		updateSerpCountry: (countrySelected) => dispatch({ type: 'SERP_COUNTRY', value: countrySelected }),
		updateSerpLanguage: (languageSelected) => dispatch({ type: 'SERP_LANGUAGE', value: languageSelected }),
		updateEmailSubscribeSearchQuery: (emailSubscribeSearchQuery) => dispatch({ type: 'EMAIL_SUBSCRIBE_SEARCH_QUERY', value: emailSubscribeSearchQuery }),
		updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan }),
    updateRemainingWSTSearches: (remainingSearches) => dispatch({ type: 'REMAINING_WST_SEARCHES', value: remainingSearches }),
    updateRemainingSerpSearches: (remainingSearches) => dispatch({ type: 'REMAINING_SERP_SEARCHES', value: remainingSearches }),
    updateRemainingKwtSearches: (remainingKwtSearches) => dispatch({ type: 'REMAINING_KWT_SEARCHES', value: remainingKwtSearches })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
