import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import DOMAINS from '../../constants/Domains';
import COUNTRIES from '../../constants/Countries';
import LANGUAGES from '../../constants/Languages';
import ReactGA from 'react-ga';
import { Modal } from 'mdb-ui-kit';
import { SaveUserSettingApi } from '../../functions/UserSettingApi';
import { updateSerpToolSettings } from '../../observables/SerpToolSettingsSubject';
import { GetAuthToken } from '../../functions/AuthStatus';
import { GetUserSettingApi } from '../../functions/UserSettingApi';
import { DisplayAccountGateCheck } from '../../functions/DisplayAccountGateCheck';
import { updateSettingsDeviceSubject } from '../../observables/SettingsDeviceSubject';

const CustomOption = ({ innerRef, innerProps, value, label }) => {
	return (
		<div
			ref={innerRef} {...innerProps}
			className="custom-option">
				{
					value.country_code === 'aq'
						? <img
								src={'https://www.countryflags.io/aq/flat/16.png'}
								style={{marginRight: '.5em'}}
								alt={value.country_code}/>
						: <i className={`${value.country_code} flag`}></i>
				}
			<span>{label}</span>
		</div>
	)
}

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
			deviceSelected: '',
			toggleCheckMark: false,
			domainOptions: [],
			countryOptions: [],
			languageOptions: [],
			domainSelected: 'google.com',
			countrySelected: 'us',
			languageSelected: 'en'
		};
	}

	handleDeviceTypeChange = (event) => {
		const value = event.target.value;
		this.setState({ deviceSelected: value });
		this.props.isSavedBtnDisabled(false);
	}

	formatDomainOptions = () => {
		const list = [];
		let countryObj = {};
		DOMAINS.forEach(domain => {
			countryObj = COUNTRIES.find(o => o.country_name === domain.country_name);
			if (countryObj) domain = {...domain, ...{country_code: countryObj['country_code']}};
			list.push({
				value: domain,
				label: `${domain.country_name} - ${domain.google_domain}`
			});
		});
		this.setState({domainOptions: list});
	}

	formatCountryOptions = () => {
		const list = [];
		COUNTRIES.forEach(country => {
			list.push({
				value: country,
				label: `${country.country_name} - ${country.country_code}`,
				countryCode: country.country_code
			});
		});
		this.setState({countryOptions: list});
	}

	formatLanguageOptions = () => {
		const list = [];
		LANGUAGES.forEach(language => {
			list.push({
				value: language,
				label: language.language_name
			});
		});
		this.setState({languageOptions: list});
	}

	saveChanges = () => {
		this.setState({ toggleCheckMark: true });
		this.props.updateSelectedDevice(this.state.deviceSelected);
		this.props.isSavedBtnDisabled(true);
		if (window.location.pathname.indexOf('/website-speed-test') === -1) {
			this.props.updateSerpDomain(this.state.domainSelected);
			this.props.updateSerpCountry(this.state.countrySelected);
			this.props.updateSerpLanguage(this.state.languageSelected);
		}
		this.saveSettingsApi();
		this.internationalTracking();
		updateSettingsDeviceSubject.update(this.state.deviceSelected);

		setTimeout(() => {
			setTimeout(() => {
				this.props.settingsModal.hide();
				if (this.props.searchTermQuery !== '') updateSerpToolSettings.update(true);
			}, 300);
			this.setState({ toggleCheckMark: false });
		}, 500);
	}

	internationalTracking = () => {
		if (this.state.domainSelected !== 'google.com' || this.state.countrySelected !== 'us' || this.state.languageSelected !== 'en') {
			const languageName = LANGUAGES.find(obj => obj.language_code === this.state.languageSelected).language_name;
			const trackingLabel = `${this.state.domainSelected}, ${this.state.countrySelected}, ${languageName}`;
			ReactGA.event({ category: 'speed comparison', action: 'international settings', label: trackingLabel });
		}
	}

	saveSettingsApi = () => {
		const params = {
			deviceType: this.state.deviceSelected === 'mobile' ? 1 : 2,
			googleDomain: this.state.domainSelected,
			country: this.state.countrySelected,
			language: this.state.languageSelected
		};
		SaveUserSettingApi(params);
	}

	setDefaultDomain = () => {
		const defaultDomain = DOMAINS.find(d => d.google_domain === this.props.serpDomain);
		return defaultDomain
			? {
					value: { google_domain: defaultDomain.google_domain, country_name: defaultDomain.country_name },
					label: `${defaultDomain.country_name} - ${defaultDomain.google_domain}`
				}
			: {
					value: { google_domain: 'google.com', country_name: 'United States' },
					label: 'United States - google.com'
				}
	}

	setDefaultCountry = () => {
		const defaultCountry = COUNTRIES.find(c => c.country_code === this.props.serpCountry);
		return defaultCountry
			? {
					value: { country_code: defaultCountry.country_code, country_name: defaultCountry.country_name },
					label: `${defaultCountry.country_name} - ${defaultCountry.country_code}`
				}
			: {
					value: { country_code: 'us', country_name: 'United States' },
					label: 'United States - us'
				}
	}

	setDefaultLanguage = () => {
		const defaultLanguage = LANGUAGES.find(l => l.language_code === this.props.serpLanguage);
		return defaultLanguage
			? {
					value: { language_code: defaultLanguage.language_code, language_name: defaultLanguage.language_name },
					label: defaultLanguage.language_name
				}
			: {
					value: { language_code: 'en', language_name: 'English' },
					label: 'English'
				}
	}

	GetUserSetting = () => {
    GetUserSettingApi().then(data => {
      if (data) {
        const deviceMapping = {
          0: () => {
            return window.matchMedia('(max-width: 576px)').matches
              ? 'mobile'
              : 'desktop'
          },
          1: () => 'mobile',
          2: () => 'desktop'
        };
        this.props.updateSelectedDevice(deviceMapping[data.deviceType]());
        this.props.updateSerpDomain(data.googleDomain);
        this.props.updateSerpCountry(data.country);
				this.props.updateSerpLanguage(data.language);

				this.setState({ deviceSelected: deviceMapping[data.deviceType]() });
				this.setState({ domainSelected: data.googleDomain });
				this.setState({ countrySelected: data.country });
				this.setState({ languageSelected: data.language});
      } else {
				this.setState({ deviceSelected: this.props.settingsDeviceSelected });
			}
    });
  }

	displaySettingsOptions = () => {
    const urlPath = window.location.pathname;
    const settingsMapping = {
      '/website-speed-test': () => false,
			'default': () => true
    };
    return settingsMapping[urlPath]
      ? settingsMapping[urlPath]()
      : settingsMapping['default']();
  }

	componentDidMount() {
		if (GetAuthToken()) {
			this.GetUserSetting();
		} else {
			this.setState({ deviceSelected: this.props.settingsDeviceSelected });
		}
		const settingsModalEl = document.getElementById('settingsModal');
		if (typeof settingsModalEl !== 'undefined') {
			const modal = new Modal(settingsModalEl);
			this.props.updateSettingsModal(modal);
			modal.show();
			settingsModalEl.addEventListener('hide.bs.modal', (e) => {
				this.props.isSavedBtnDisabled(true);
				this.props.updateShowSettingsModal(false);
				if (DisplayAccountGateCheck() && this.props.searchTermQuery !== '') {
					const displayGateTimer = setTimeout(() => {
						this.props.updateGateContent('createAccount');
						this.props.updateShowAccountGate(true);
					}, 15000);
					this.props.updateDisplayGateTimer(displayGateTimer);
				}
			});
		}

		this.formatDomainOptions();
		this.formatCountryOptions();
		this.formatLanguageOptions();
		ReactGA.event({ category: 'speed comparison', action: 'click', label: 'settings' });
	}

  render() {
    return (
      <div
				className="modal fade"
				id="settingsModal"
				tabIndex="-1"
				aria-labelledby="settingsModalLabel"
				aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h3 className="modal-title" id="settingsModalLabel">
								Settings
							</h3>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{/* Device type */}
							<div className="form-check">
								<input
									className="form-check-input"
									type="radio"
									name="deviceType"
									id="mobileScores"
									value="mobile"
									checked={this.state.deviceSelected === 'mobile'}
									onChange={this.handleDeviceTypeChange} />
								<label className="form-check-label" htmlFor="mobileScores">
									Mobile Scores
								</label>
							</div>

							<div className="form-check">
								<input
									className="form-check-input"
									type="radio"
									name="deviceType"
									id="desktopScores"
									value="desktop"
									checked={this.state.deviceSelected === 'desktop'}
									onChange={this.handleDeviceTypeChange} />
								<label className="form-check-label" htmlFor="desktopScores">
									Desktop Scores
								</label>
							</div>

							{/* Google Domain */}
							{
								this.displaySettingsOptions()
									? <div className="row mt-3">
											<div className="col-sm-12">
												<label>Google Domain</label>
												<Select
													isSearchable
													menuPlacement="auto"
													options={this.state.domainOptions}
													components={{ Option: CustomOption }}
													onChange={e => {
														this.setState({ domainSelected: e.value.google_domain });
														this.props.isSavedBtnDisabled(false);
													}}
													defaultValue={this.setDefaultDomain()}/>
											</div>
										</div>
									: false
							}

							{/* Country */}
							{
								this.displaySettingsOptions()
									? <div className="row mt-3">
											<div className="col-sm-12">
												<label>Country</label>
												<Select
													isSearchable
													menuPlacement="auto"
													options={this.state.countryOptions}
													components={{ Option: CustomOption }}
													onChange={e => {
														this.setState({ countrySelected: e.value.country_code });
														this.props.isSavedBtnDisabled(false);
													}}
													defaultValue={this.setDefaultCountry()}/>
											</div>
										</div>
									: null
							}

							{/* Language */}
							{
								this.displaySettingsOptions()
									? <div className="row mt-3">
											<div className="col-sm-12">
												<label>Language</label>
												<Select
													isSearchable
													menuPlacement="auto"
													options={this.state.languageOptions}
													onChange={e => {
														this.setState({ languageSelected: e.value.language_code });
														this.props.isSavedBtnDisabled(false);
													}}
													defaultValue={this.setDefaultLanguage()}
													/>
											</div>
										</div>
									: null
							}
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-primary success"
								onClick={() => this.saveChanges()}
								disabled={this.props.isSaveBtnDisabled}>
								{
									this.state.toggleCheckMark
										? <i className="fas fa-check"></i>
										: <span>Save changes</span>
								}
							</button>
						</div>
					</div>
				</div>
			</div>
    );
	}
}

const mapStateToProps = state => {
  return {
		settingsDeviceSelected: state.settingsDeviceSelected,
		settingsModal: state.settingsModal,
		isSaveBtnDisabled: state.isSaveBtnDisabled,
		serpDomain: state.serpDomain,
		serpCountry: state.serpCountry,
		serpLanguage: state.serpLanguage,
		searchTermQuery: state.searchTermQuery,
		displayGateTimer: state.displayGateTimer
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateSelectedDevice: (selected) => dispatch({ type: 'SETTINGS_DEVICE_SELECTED', value: selected }),
		isSavedBtnDisabled: (isDisabled) => dispatch({ type: 'IS_SAVED_BTN_DISABLED', value: isDisabled }),
		updateSerpDomain: (domainSelected) => dispatch({ type: 'SERP_DOMAIN', value: domainSelected }),
		updateSerpCountry: (countrySelected) => dispatch({ type: 'SERP_COUNTRY', value: countrySelected }),
		updateSerpLanguage: (languageSelected) => dispatch({ type: 'SERP_LANGUAGE', value: languageSelected }),
		updateSettingsModal: (modal) => dispatch({ type: 'SETTINGS_MODAL', value: modal }),
		updateShowSettingsModal: (showSettingsModal) => dispatch({ type: 'SHOW_SETTINGS_MODAL', value: showSettingsModal }),
		updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),
		updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
		updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);
