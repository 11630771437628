import React from 'react';
import {
  Elements,
  ElementsConsumer
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentForm from './StripePaymentForm';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const InjectedPaymentForm = () => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <StripePaymentForm stripe={stripe} elements={elements}/>
    )}
  </ElementsConsumer>
);

class StripeWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <Elements stripe={ stripePromise }>
        <InjectedPaymentForm />
      </Elements>
    );
  }
};

export default StripeWrapper;

