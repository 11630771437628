import axios from 'axios';
import { GetAuthToken } from './AuthStatus';

export async function SubscribePaymentApi(paymentParams) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments`, paymentParams, { headers: headers, withCredentials: true })
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export async function ChangePaymentApi(paymentParams) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments`, paymentParams, { headers: headers, withCredentials: true })
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export async function CancelPaymentApi(paymentParams) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments`, paymentParams, { headers: headers, withCredentials: true })
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export async function SubscribePaypalPaymentApi(paymentParams) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments/paypal`, paymentParams, { headers: headers, withCredentials: true })
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export async function UpdatePaypalPaymentApi(paymentParams) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments/paypal`, paymentParams, { headers: headers, withCredentials: true })

      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export async function CancelPaypalPaymentApi(paymentParams) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments/paypal`, paymentParams, { headers: headers, withCredentials: true })
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export async function CapturePaypalPaymentApi(paymentParams) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments/capture`, paymentParams, { headers: headers, withCredentials: true })
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export async function GetPaypalAccessToken() {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json',
      };
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/payments/paypal`, { headers: headers, withCredentials: true });
      return response;
    } catch (error) {
      return error.response;
    }
  }
}
