import React, { Component } from 'react';
import { Tab } from 'mdb-ui-kit';
import { LighthouseScoreColors } from '../../functions/LighthouseScoreColors';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import EmptyProgressBar from './EmptyProgressBar';

class SpeedTestMetricTabs extends Component {
	_isMounted = false;
  constructor(props) {
		super();
		this._isMounted = false;
    this.state = {
			viewMetricsUrl: `https://developers.google.com/speed/pagespeed/insights/?url=`,
			selectedLabMetric: 'psi',
			selectedFieldMetric: '',
			isLabToggled: true,
			isGlobalToggled: true,
			urlState: ''
    };
	}

	toggleTabs = () => {
		// this.setState({ isLabToggled: !this.state.isLabToggled })
		this.setState({ isGlobalToggled: !this.state.isGlobalToggled });
	}

	goToSsctPage = () => {
		this.props.history.push({ pathname: '/serp-analysis-tool' });
	}

  render() {
    return (
      <div className="text-center">
					<div className="row justify-content-center">
						<div className="col-sm-12">
							<div>
								<hr className="hr"></hr>
								<h5 className="mb-1"><i className="icon-item fas fa-flask fa-lg pr-1"></i> <b>Lab Data</b></h5>
								<p className="text-center mb-4" style={{ wordBreak: 'break-word'}}>{ this.state.urlState }</p>
								<div className="col-sm-12 pb-3">
		                <div className="d-flex align-items-center">
		                  <div className="psi-score-scale-container">
		                    <span className="psi-score-range psi-score-scale-range-fail">0–49</span>
		                    <span className="psi-score-range psi-score-scale-range-average">50–89</span>
		                    <span className="psi-score-range psi-score-scale-range-pass">90–100</span>
			                </div>
			            	</div>
								</div>
								{
									this.props.isDesktopToggled && this.props.showDesktopProgressBars
										? <div className="col-sm-12 pb-3">
												{
													[...Array(Number(this.props.sampleSize))].map((elementInArray, index) => (
														<div className="d-flex flex-row align-items-center justify-content-center mb-3" key={`sample-${index}`}>
															<span className="pr-2">Sample { index + 1 }</span>
															{
																(this.props.desktopProgressBarsStatusObj[index]?.psi) || (!this.props.desktopProgressBarsStatusObj[index]?.psi && this.props.desktopProgressBarsStatusObj[index]?.psi === 0)
																	? <span>
																			<span className="pr-1">PSI Score: <span className={`${LighthouseScoreColors(this.props.desktopProgressBarsStatusObj[index].psi)}`}> { this.props.desktopProgressBarsStatusObj[index].psi } </span></span>
																		</span>
																	: null
															}

															{
																this.props.desktopProgressBarsStatusObj[index]?.startProgress
																	? this.props.desktopProgressBarsStatusObj[index]?.isPsiError
																		? <span> PSI Error</span>
																		: <ProgressBar progressBarsStatus={this.props.desktopProgressBarsStatusObj[index]}/>
																	: <EmptyProgressBar />
															}
														</div>
													))
												}
											</div>
										: null
								}

								{
				          !this.props.isDesktopToggled && this.props.showMobileProgressBars
				            ? <div className="col-sm-12 pb-3">
				                {
				                  [...Array(Number(this.props.sampleSize))].map((elementInArray, index) => (
				                    <div className="d-flex flex-row align-items-center justify-content-center mb-3" key={`sample-${index}`}>
				                      <span className="pr-2">Sample { index + 1 }</span>
				                      {
				                        (this.props.mobileProgressBarsStatusObj[index]?.psi) || (!this.props.mobileProgressBarsStatusObj[index]?.psi && this.props.mobileProgressBarsStatusObj[index]?.psi === 0)
				                          ? <span>
				                              <span className="pr-1">PSI Score: <span className={`${LighthouseScoreColors(this.props.mobileProgressBarsStatusObj[index].psi)}`}> { this.props.mobileProgressBarsStatusObj[index].psi } </span></span>
				                            </span>
				                          : null
				                      }

				                      {
				                        this.props.mobileProgressBarsStatusObj[index]?.startProgress
				                          ? this.props.mobileProgressBarsStatusObj[index]?.isPsiError
				                            ? <span> PSI Error</span>
				                            : <ProgressBar progressBarsStatus={this.props.mobileProgressBarsStatusObj[index]}/>
				                          : <EmptyProgressBar />
				                      }
				                    </div>
				                  ))
				                }
				              </div>
				          : null
				        }
								<div>
										{
											this.props.settingsDeviceSelected === 'mobile'
												? Object.keys(this.props.mobileLabMetricsAverage).length > 0
													? <div>
															<div>
																<p>Mobile PSI scores averaged across {this.props.sampleSize} samples</p>
																{/* psi */}
																<div className="pb-3">
																	<span className={`display-6 ${LighthouseScoreColors(this.props.mobileLabMetricsAverage.psi.score)}`}>
																		{this.props.mobileLabMetricsAverage.psi.displayValue}
																	</span>
																	<span className="display-6">/100</span>
																</div>
																{/* fcp */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.mobileLabMetricsAverage.fcp.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.mobileLabMetricsAverage.fcp.score)}`}>
																		{this.props.mobileLabMetricsAverage.fcp.displayValue}
																	</span>
																</div>
																{/* lcp */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.mobileLabMetricsAverage.lcp.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.mobileLabMetricsAverage.lcp.score)}`}>
																		{this.props.mobileLabMetricsAverage.lcp.displayValue}
																	</span>
																</div>
																{/* tbt */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.mobileLabMetricsAverage.tbt.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.mobileLabMetricsAverage.tbt.score)}`}>
																	{this.props.mobileLabMetricsAverage.tbt.displayValue}
																	</span>
																</div>
																{/* si */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.mobileLabMetricsAverage.si.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.mobileLabMetricsAverage.si.score)}`}>
																	{this.props.mobileLabMetricsAverage.si.displayValue}
																	</span>
																</div>
																{/* tti */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.mobileLabMetricsAverage.tti.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.mobileLabMetricsAverage.tti.score)}`}>
																	{this.props.mobileLabMetricsAverage.tti.displayValue}
																	</span>
																</div>
																{/* cls */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.mobileLabMetricsAverage.cls.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.mobileLabMetricsAverage.cls.score)}`}>
																	{this.props.mobileLabMetricsAverage.cls.displayValue}
																	</span>
																</div>
															</div>
														</div>
													: !this.props.isMobileSamplesComplete
														? null
														: <div className="metrics-no-data">
																<h4 className="m-0">no data</h4>
															</div>
												: null
										}
										{
											this.props.settingsDeviceSelected === 'desktop'
												? Object.keys(this.props.desktopLabMetricsAverage).length > 0
													? <div>
															<div>
																<p>Desktop PSI scores averaged across {this.props.sampleSize} samples</p>
																{/* psi */}
																<div className="pb-3">
																	<span className={`display-6 ${LighthouseScoreColors(this.props.desktopLabMetricsAverage.psi.score)}`}>
																		{this.props.desktopLabMetricsAverage.psi.displayValue}
																	</span>
																	<span className="display-6">/100</span>
																</div>
																{/* fcp */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.desktopLabMetricsAverage.fcp.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.desktopLabMetricsAverage.fcp.score)}`}>
																		{this.props.desktopLabMetricsAverage.fcp.displayValue}
																	</span>
																</div>
																{/* lcp */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.desktopLabMetricsAverage.lcp.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.desktopLabMetricsAverage.lcp.score)}`}>
																		{this.props.desktopLabMetricsAverage.lcp.displayValue}
																	</span>
																</div>
																{/* tbt */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.desktopLabMetricsAverage.tbt.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.desktopLabMetricsAverage.tbt.score)}`}>
																	{this.props.desktopLabMetricsAverage.tbt.displayValue}
																	</span>
																</div>
																{/* si */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.desktopLabMetricsAverage.si.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.desktopLabMetricsAverage.si.score)}`}>
																	{this.props.desktopLabMetricsAverage.si.displayValue}
																	</span>
																</div>
																{/* tti */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.desktopLabMetricsAverage.tti.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.desktopLabMetricsAverage.tti.score)}`}>
																	{this.props.desktopLabMetricsAverage.tti.displayValue}
																	</span>
																</div>
																{/* cls */}
																<div className="pb-3">
																	<span className="metric-uppercase pr-1">{this.props.desktopLabMetricsAverage.cls.label}</span>
																	<span className={`${LighthouseScoreColors(this.props.desktopLabMetricsAverage.cls.score)}`}>
																	{this.props.desktopLabMetricsAverage.cls.displayValue}
																	</span>
																</div>
															</div>
														</div>
													: !this.props.isDesktopSamplesComplete
														? null
														: <div className="metrics-no-data">
															<h4 className="m-0">no data</h4>
														</div>
												: null
										}
								</div>
						  </div>
							<hr className="hr"></hr>
							<h5><i className="icon-item fas fa-users fa-lg pr-1"></i> <b>Field Data</b></h5>
								<p className="mb-2">real user data - 28 day avg</p>
							<div className="tab-content wst-tab-content">
								<div className="row justify-content-center">
									<div className="col-sm-8">
									<div className="dropdown mb-2">
										<div className="btn btn-primary metric-dropdown-btn p-3" type="button" id="metricsDropDown" data-toggle="dropdown" aria-expanded="false">
											<span><b>{this.state.isGlobalToggled ? 'Origin (All Pages)' : 'This page only'}</b><i className="icon-item fas fa-caret-down pl-1"></i></span>
										</div>
												<ul className="dropdown-menu metric-dropdown-options" aria-labelledby="metricsDropDown"
													id={'psi-tabs-dropdown'}
													role="tablist">
													<li>
														<a
															className={`dropdown-item ${
																this.state.isGlobalToggled
																	? 'active'
																	: ''
															}`}
															id={'global'}
															data-mdb-toggle="tab"
															href="#global-content"
															role="tab"
															aria-controls="global-content"
															aria-selected="true">
															<i className="icon-item fas fa-flask fa-lg pr-1"></i>
															<b>Origin (All Pages)</b>
														</a>
													</li>
													<li>
															<a
																className={`dropdown-item ${
																this.state.isGlobalToggled
																	? ''
																	: 'active'
															}`}
															id={'this-page'}
															data-mdb-toggle="tab"
															href="#this-page-content"
															role="tab"
															aria-controls="this-page-content"
															aria-selected="false">
															<i className="icon-item fas fa-flask fa-lg pr-1"></i>
															<b>This page only</b>
														</a>
													</li>
												</ul>
									</div>
									</div>
							</div>
							<div
								className={`tab-pane ${
									this.state.isGlobalToggled
											? 'fade show active'
											: ''
									}`}
									id="global-content"
									role="tabpanel"
									aria-labelledby="global">
									{  !this.props.isDesktopToggled
											? Object.keys(this.props.originFieldMetrics).length > 0
												? <div>
															<div className="metric-field-container justify-content-center">
																<div className="metric-field-flex-container">
																	<div>
																		<p className="core-web-vitals">Core Web Vitals</p>
																		{
																			Object.keys(this.props.originFieldMetrics).length > 0
																			? this.handlePassFailOriginCoreWebVitals()
																			: null
																		}
																	</div>
																	{
																		Object.keys(this.props.originFieldMetrics).map((objKey, index) => {
																			return index < 3
																				? <div className="ml-1 mr-1 mt-1" key={`field-metric-${this.props.originFieldMetrics[objKey].label}`}>
																						<span className="mb-1 mr-1 metric-uppercase">
																							{this.props.originFieldMetrics[objKey].label}
																						</span>
																						<span className={this.props.originFieldMetrics[objKey].speedColor}>
																							{this.props.originFieldMetrics[objKey].displayValue}
																						</span>
																					</div>
																				: null
																		})
																	}
																</div>
																	{
																		this.props.originFieldMetrics['fcp']
																			? <div className="metirc-field-fcp-flex-container">
																					<span className="ml-1 mr-1 metric-uppercase">
																						{this.props.originFieldMetrics['fcp'].label}
																					</span>
																					<span className={this.props.originFieldMetrics['fcp'].speedColor}>
																						{this.props.originFieldMetrics['fcp'].displayValue}
																					</span>
																				</div>
																			: null
																	}
																	</div>
														</div>
												: this.props.compleatedMobileSamples > 0
													? <div className="metrics-no-data ">
															<h5 className="m-1 pb-3">
																The origin does not have enough real user data for this report
															</h5>
														</div>
													: <div className="col-sm-12 text-center mt-4">
														<div className="spinner-border" role="status">
															<span className="sr-only">Loading...</span>
														</div>
													</div> : null
									}

									{
										 this.props.isDesktopToggled
										? Object.keys(this.props.originDesktopFieldMetrics).length > 0
											? <div>
														<div className="metric-field-container justify-content-center">
															<div className="metric-field-flex-container">
																<p className="core-web-vitals">Core Web Vitals</p>
																{
																	Object.keys(this.props.originFieldMetrics).length > 0
																	? this.handlePassFailOriginCoreWebVitals()
																	: null
																}
																{
																	Object.keys(this.props.originDesktopFieldMetrics).map((objKey, index) => {
																		return index < 3
																			? <div className="ml-1 mr-1 mt-1" key={`field-metric-${this.props.originDesktopFieldMetrics[objKey].label}`}>
																					<span className="mb-1 mr-1 metric-uppercase">
																						{this.props.originDesktopFieldMetrics[objKey].label}
																					</span>
																					<span className={this.props.originDesktopFieldMetrics[objKey].speedColor}>
																						{this.props.originDesktopFieldMetrics[objKey].displayValue}
																					</span>
																				</div>
																			: null
																	})
																}
															</div>
																{
																	this.props.originDesktopFieldMetrics['fcp']
																		? <div className="metirc-field-fcp-flex-container">
																				<span className="ml-1 mr-1 metric-uppercase">
																					{this.props.originDesktopFieldMetrics['fcp'].label}
																				</span>
																				<span className={this.props.originDesktopFieldMetrics['fcp'].speedColor}>
																					{this.props.originDesktopFieldMetrics['fcp'].displayValue}
																				</span>
																			</div>
																		: null
																}
																</div>
													</div>
											: this.props.compleatedDesktopSamples > 0
												? <div className="metrics-no-data ">
														<h5 className="m-1 pb-3">
															The origin does not have enough real user data for this report
														</h5>
													</div>
												: <div className="col-sm-12 text-center mt-4">
													<div className="spinner-border" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												</div> : null
									}
								</div>
								<div
									className={`tab-pane ${
										this.state.isGlobalToggled
										? ''
										: 'fade show active'
									}`}
									id="this-page-content"
									role="tabpanel"
									aria-labelledby="this-page">
									{/* if mobile is toggled */}
									{
										!this.props.isDesktopToggled
											? <div>
											{
												Object.keys(this.props.fieldMetrics).length > 0
												? <div className="metric-field-container justify-content-center">
															<div className="metric-field-flex-container">
																<div>
																	<p className="core-web-vitals">Core Web Vitals</p>
																	{
																		Object.keys(this.props.fieldMetrics).length > 0
																		? this.handlePassFailCoreWebVitals()
																		: null
																	}
																</div>
																{
																	Object.keys(this.props.fieldMetrics).map((objKey, index) => {
																		return index < 3
																			? <div className="ml-1 mr-1 mt-1" key={`field-metric-${this.props.fieldMetrics[objKey].label}`}>
																					<span className="mb-1 mr-1 metric-uppercase">
																						{this.props.fieldMetrics[objKey].label}
																					</span>
																					<span className={this.props.fieldMetrics[objKey].speedColor}>
																						{this.props.fieldMetrics[objKey].displayValue}
																					</span>
																				</div>
																			: null
																	})
																}
															</div>
																{
																	this.props.fieldMetrics['fcp']
																		? <div className="metirc-field-fcp-flex-container">
																				<span className="ml-1 mr-1 metric-uppercase">
																					{this.props.fieldMetrics['fcp'].label}
																				</span>
																				<span className={this.props.fieldMetrics['fcp'].speedColor}>
																					{this.props.fieldMetrics['fcp'].displayValue}
																				</span>
																			</div>
																		: null
																}
																</div>

											: this.props.compleatedMobileSamples > 0
												? <div className="metrics-no-data ">
														<h5 className="m-1 pb-3">
															This page does not have enough real user data for this report
														</h5>
													</div>
												: <div className="col-sm-12 text-center mt-4">
													<div className="spinner-border" role="status">
														<span className="sr-only">Loading...</span>
													</div>
													</div> }
												</div> : null
									}
									{/* if desktop is toggled */}
									{
										this.props.isDesktopToggled
										 ? <div>
											 {
												 Object.keys(this.props.desktopFieldMetrics).length > 0
													?	<div className="metric-field-container justify-content-center">
															<div className="metric-field-flex-container">
																<div>
																	<p className="core-web-vitals">Core Web Vitals</p>
																	{
																		Object.keys(this.props.fieldMetrics).length > 0
																		? this.handlePassFailCoreWebVitals()
																		: null
																	}
																</div>
																{
																	Object.keys(this.props.desktopFieldMetrics).map((objKey, index) => {
																		return index < 3
																			? <div className="ml-1 mr-1 mt-1" key={`field-metric-${this.props.desktopFieldMetrics[objKey].label}`}>
																					<span className="mb-1 mr-1 metric-uppercase">
																						{this.props.desktopFieldMetrics[objKey].label}
																					</span>
																					<span className={this.props.desktopFieldMetrics[objKey].speedColor}>
																						{this.props.desktopFieldMetrics[objKey].displayValue}
																					</span>
																				</div>
																			: null
																	})
																}
															</div>
																{
																	this.props.desktopFieldMetrics['fcp']
																		? <div className="metirc-field-fcp-flex-container">
																				<span className="ml-1 mr-1 metric-uppercase">
																					{this.props.desktopFieldMetrics['fcp'].label}
																				</span>
																				<span className={this.props.desktopFieldMetrics['fcp'].speedColor}>
																					{this.props.desktopFieldMetrics['fcp'].displayValue}
																				</span>
																			</div>
																		: null
																}
																</div>
											: this.props.compleatedDesktopSamples > 0
												? <div className="metrics-no-data ">
														<h5 className="m-1 pb-3">
															This page does not have enough real user data for this report
														</h5>
													</div>
												: <div className="col-sm-12 text-center mt-4">
													<div className="spinner-border" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												</div> }
											</div> : null
									}
								</div>
						</div>
						<div className="pt-4 pb-4">
							<p>See how fast your competitors are</p>
							<button
								className="btn btn-dark"
								type="button"
								data-ripple-color="dark"
								onClick={() => this.goToSsctPage()}>
								try our comparison tool
							</button>
						</div>
					</div>
				</div>
				{/*  <div className="col-sm-12 text-center mb-5">
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div> */}
			</div>
    );
	}

	handlePassFailOriginCoreWebVitals = () => {
		var coreWebVitals = [];
		Object.keys(this.props.originFieldMetrics).forEach((key, index) => {
				if(this.props.originFieldMetrics[key].label !== "fcp") {
						coreWebVitals.push(this.props.originFieldMetrics[key]);
					}
		});

		if(coreWebVitals.length > 0) {
			const hasPassed = (value) => value.speedColor === "psi-score-pass";
			var passOrFail = coreWebVitals.every(hasPassed);
			if(passOrFail) {
				return <div className="metric-badge-holder"><span className="badge bg-success metric-badge">Pass</span></div>
			} else {
				return <div className="metric-badge-holder"><span className="badge bg-danger metric-badge">Fail</span></div>
			}
		}
	}

	handlePassFailCoreWebVitals = () => {
		var coreWebVitals = [];
		Object.keys(this.props.fieldMetrics).forEach((key, index) => {
				if(this.props.fieldMetrics[key].label !== "fcp") {
						coreWebVitals.push(this.props.fieldMetrics[key]);
					}
		});

		if(coreWebVitals.length > 0) {
			const hasPassed = (value) => value.speedColor === "psi-score-pass";
			var passOrFail = coreWebVitals.every(hasPassed);
			if(passOrFail) {
				return <div className="metric-badge-holder"><span className="badge bg-success metric-badge">Pass</span></div>
			} else {
				return <div className="metric-badge-holder"><span className="badge bg-danger metric-badge">Fail</span></div>
			}
		}
	}

	componentDidMount() {
		this._isMounted = true;
		var self = this;

		const triggerTabList = [].slice.call(document.querySelectorAll(`#psi-tabs-dropdown a`));
		triggerTabList.forEach((triggerEl) => {
			const tabTrigger = new Tab(triggerEl);
  		triggerEl.addEventListener('click', (event) => {
				event.preventDefault();
				if(self.state.isGlobalToggled) {
					if(event.path[0]['id'] === 'this-page' || event.path[0]['parentElement']['id'] === 'this-page' ) {
						self.toggleTabs();
						tabTrigger.show();
					}
				}

				if(!self.state.isGlobalToggled) {
					if(event.path[0]['id'] === 'global' || event.path[0]['parentElement']['id'] === 'global') {
						self.toggleTabs();
						tabTrigger.show();
					}
				}


  		});
		});
		this.setState({ urlState: this.props.url });
		// this.props.onRef(this);
	}

	componentWillUnmount() {
		this._isMounted = false;
		// this.props.onRef(undefined);
	}
}


const mapStateToProps = state => {
  return {
		selectedMetrics: state.selectedMetrics,
		settingsDeviceSelected: state.settingsDeviceSelected
  };
};

export default connect(mapStateToProps, null)(withRouter(SpeedTestMetricTabs));
