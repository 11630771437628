import React from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { SubscribePaymentApi, ChangePaymentApi } from '../../functions/PaymentApi'
import { withRouter } from 'react-router-dom';
import LOCALSTORAGE from '../../constants/LocalStorage';

const CARD_NUMBER = {
  showIcon: true,
  placeholder: 'Card Number',
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontFamily: "proxima-nova, Helvetica Neue, Helvetica, Arial, sans-serif, itc-avant-garde-gothic-pro"
    },
    invalid: {
      iconColor: '#ff4e42',
      color: '#ff4e42'
    }
  }
};

const CARD_EXPIRY = {
  placeholder: 'MM / YY',
  style: {
    base: {
      color: '#000',
      fontFamily: "proxima-nova, Helvetica Neue, Helvetica, Arial, sans-serif, itc-avant-garde-gothic-pro"
    },
    invalid: {
      color: '#ff4e42'
    }
  }
};

const CARD_CVC = {
  placeholder: 'CVC',
  style: {
    base: {
      fontFamily: "proxima-nova, Helvetica Neue, Helvetica, Arial, sans-serif, itc-avant-garde-gothic-pro"
    },
    invalid: {
      color: '#ff4e42'
    }
  }
};

class StripePaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      postal: '',
      billingEmailAddress: '',
      isEmailAddressValid: true,
			emailAddressErrorMsg: '',
			emailAddressErrorMsgOptions: {
				blank: 'Please enter your email address.',
				invalid: 'Please enter a valid email address.'
			},
      isCardNameError: false,
      isCardBillingEmailError: true,
      isCardZipCodeError: false,
      isCardNumberError: false,
      isCardExpiryError: false,
      isCardCvcError: false,
      cardNameErrorMessage: '',
      cardBillingEmailErrorMessage: '',
      cardZipCodeErrorMessage: '',
      cardNumberErrorMessage: '',
      cardExpiryErrorMessage: '',
      cardCvcErrorMessage: '',
      processingPayment: false,
      isPaymentError: false,
      paymentErrorMessage: 'There was an error processing your payment please try again.'
    };
  }

  validateEmail = () => {
		let isValid = false;
		if (this.state.billingEmailAddress !== '') {
			const regex = new RegExp(/\S+@\S+\.\S+/, 'i');
			regex.test(this.state.billingEmailAddress)
				? isValid = true
				: this.setState({ cardBillingEmailErrorMessage: this.state.emailAddressErrorMsgOptions.invalid })
		} else {
			this.setState({ cardBillingEmailErrorMessage: this.state.emailAddressErrorMsgOptions.blank });
		}
    this.setState({ isCardBillingEmailError: isValid });
    if (isValid) this.setState({ cardBillingEmailErrorMessage: '' });
		return isValid;
	};

  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    const { name, postal, billingEmailAddress } = this.state;
    const isEmailVaild = this.validateEmail();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({ processingPayment: true });

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: name,
        email: billingEmailAddress,
        address: {
          postal_code: postal
        }
      }
    });

    if (payload.error || name === '' || billingEmailAddress === '' || !isEmailVaild || postal === '') {
      // * Invalid fields
      // * add error state to card name and zip code
      this.setState({ isCardNameError: name === '' ? true : false });
      this.setState({ isCardZipCodeError: postal === '' ? true : false });
      // * add error messgae
      this.setState({ cardNameErrorMessage: this.state.isCardNameError ? 'Please enter name on card' : '' })
      this.setState({ cardZipCodeErrorMessage: this.state.isCardZipCodeError ? 'Please enter your billing zipcode or postal code' : '' })
      this.setState({ processingPayment: false });
    } else {
      const paymentParams = {
        name: name,
        email: billingEmailAddress,
        zipcode: postal,
        planId: this.props.selectedPlan.planId,
        paymentMethodId: payload.paymentMethod.id,
        billingCycle: this.props.selectedPlan.billingCycle
      };
      if (this.props.isChangePlan) {
        // * Update payment method
        ChangePaymentApi(paymentParams).then(res => {
          if (res && res.status === 200) {
            this.updateCurrentPlan(this.props.selectedPlan, res.data);
            this.updateUsrAuthStorage(res.data);
            this.props.updateIsChangePlan(false);
            this.props.updateIsChangePayment(false);
            this.setState({ isPaymentError: false });
            this.setState({ processingPayment: false });
            this.props.history.push({ pathname: '/confirmation' });
          } else {
            this.paymentErrorHandler(res);
          }
        }).catch(error => {
          console.error('catch error change payment function ', error);
          this.setState({ isPaymentError: true });
          this.setState({ processingPayment: false });
        });
      } else {
        // * Subscribe to plan
        SubscribePaymentApi(paymentParams).then(res => {
          if (res && res.status === 200) {
            this.updateCurrentPlan(this.props.selectedPlan, res.data);
            this.updateUsrAuthStorage(res.data);
            this.setState({ isPaymentError: false });
            this.setState({ processingPayment: false });
            this.props.history.push({ pathname: '/confirmation' });
          } else {
            this.paymentErrorHandler(res);
          }
        }).catch(error => {
          console.error('catch error payment function ', error);
          this.setState({ isPaymentError: true });
          this.setState({ processingPayment: false });
        });
      }
    }
  };

  paymentErrorHandler = (res) => {
    if (res) {
      const errorMessageMapping = {
        500: ()=> {
          this.setState({ paymentErrorMessage: `You are already subscribed our ${this.props.selectedPlan.plan}` });
        },
        default: () => {
          this.setState({ paymentErrorMessage: 'There was an error processing your payment please try again.' });
        }
      };
      if (errorMessageMapping[res.status]) errorMessageMapping[res.status]();
      this.setState({ isPaymentError: true });
      this.setState({ processingPayment: false });
    }
  }

  updateUsrAuthStorage = (res) => {
    if (typeof localStorage !== 'undefined') {
      const usrAuthStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage));
      usrAuthStorage.planId = this.props.selectedPlan.planId;
      usrAuthStorage.renewDate = res.renewsAt ? res.renewsAt : this.props.selectedPlan.renewDate;
      usrAuthStorage.billingCycle = this.props.selectedPlan.billingCycle;
      usrAuthStorage.cancelDate = res.cancelsAt ? res.cancelsAt : this.props.selectedPlan.cancelDate;
      usrAuthStorage.token = res.token;
      localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(usrAuthStorage));

      if(usrAuthStorage.planId === 4){
        var empty = [];
        // localStorage.setItem(LOCALSTORAGE.ksr, false); no longer using ksr 
        localStorage.setItem(LOCALSTORAGE.keywordsPerMinute, false);
        localStorage.setItem(LOCALSTORAGE.keywordsPerHour, false);
        localStorage.setItem(LOCALSTORAGE.keywordPerHourArray, JSON.stringify(empty));
      }
    }
  }

  updateCurrentPlan = (selectedPlan, res) => {
    const currentPlan = selectedPlan;
    currentPlan.renewDate = res.renewsAt ? res.renewsAt : selectedPlan.renewsAt;
		currentPlan.cancelDate = res.cancelsAt ? res.cancelsAt : selectedPlan.cancelsAt;
    this.props.updateCurrentPlan(currentPlan);
  }

  render() {
    const { stripe, selectedPlan, currentPlan, isChangePayment } = this.props;
    const  { postal, name, billingEmailAddress } = this.state;
    return (
      <form onSubmit={ this.handleSubmit } noValidate>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6">
            <fieldset className={`payment-form-group ${this.state.isCardNameError ? 'border-error' : ''}`}>
              <div className="payment-form-row">
                <input
                  id="name"
                  className={`payment-form-row-input ${this.state.isCardNameError ? 'error' : ''}`}
                  required
                  placeholder="Name on Card"
                  value={name}
                  onChange={(event) => {
                    this.setState({ name: event.target.value });
                    this.setState({ isCardNameError: event.target.value === '' ? true : false });
                    this.setState({ cardNameErrorMessage: event.target.value === '' ? 'Please enter name on card' : '' });
                  }}
                  onBlur={(event) => {
                    this.setState({ isCardNameError: event.target.value === '' ? true : false });
                    this.setState({ cardNameErrorMessage: event.target.value === '' ? 'Please enter name on card' : '' });
                  }}/>
              </div>
            </fieldset>
            <label className="error">
              <span>{this.state.cardNameErrorMessage}</span>
            </label>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6">
            <fieldset className={`stripe-element-container ${this.state.isCardNumberError ? 'border-error' : ''}`}>
              <div className="stripe-element">
                <CardNumberElement
                  options={ CARD_NUMBER }
                  onReady={(event) => {
                    // console.log("CardNumberElement [ready]", event);
                  }}
                  onChange={event => {
                    if (event.error) {
                      this.setState({ isCardNumberError: true });
                      this.setState({ cardNumberErrorMessage: event.error.message });
                    } else {
                      this.setState({ isCardNumberError: false });
                      this.setState({ cardNumberErrorMessage: '' });
                    }
                  }}
                  onBlur={(event) => {
                    // console.log("CardNumberElement [blur]", event);
                  }}
                  onFocus={(event) => {
                    // console.log("CardNumberElement [focus]", event);
                  }}
                />
              </div>
            </fieldset>
            <label className="error">
              <span>{ this.state.cardNumberErrorMessage }</span>
            </label>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-3">
            <fieldset className={`stripe-element-container ${this.state.isCardExpiryError ? 'border-error' : ''}`}>
              <div className="stripe-element">
                <CardExpiryElement
                  options={ CARD_EXPIRY }
                  onReady={(event) => {
                    // console.log("CardExpiryElement [ready]", event);
                  }}
                  onChange={event => {
                    if (event.error) {
                      this.setState({ isCardExpiryError: true });
                      this.setState({ cardExpiryErrorMessage: event.error.message });
                    } else {
                      this.setState({ isCardExpiryError: false });
                      this.setState({ cardExpiryErrorMessage: '' });
                    }
                  }}
                  onBlur={(event) => {
                    // console.log("CardExpiryElement [blur]", event);
                  }}
                  onFocus={(event) => {
                    // console.log("CardExpiryElement [focus]", event);
                  }}
                />
              </div>
            </fieldset>
            <label className="error">
              <span>{ this.state.cardExpiryErrorMessage }</span>
            </label>
          </div>

          <div className="col-sm-6 col-md-3">
            <fieldset className={`stripe-element-container ${this.state.isCardCvcError ? 'border-error' : ''}`}>
              <div className="stripe-element">
                <CardCvcElement
                  options={ CARD_CVC }
                  onReady={(event) => {
                    // console.log("CardCvcElement [ready]", event);
                  }}
                  onChange={event => {
                    if (event.error) {
                      this.setState({ isCardCvcError: true });
                      this.setState({ cardCvcErrorMessage: event.error.message });
                    } else {
                      this.setState({ isCardCvcError: false });
                      this.setState({ cardCvcErrorMessage: '' });
                    }
                  }}
                  onBlur={(event) => {
                    // console.log("CardCvcElement [blur]", event);
                  }}
                  onFocus={(event) => {
                    // console.log("CardCvcElement [focus]", event);
                  }}
                />
              </div>
            </fieldset>
            <label className="error">
              <span>{ this.state.cardCvcErrorMessage }</span>
            </label>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6">
            <fieldset className={`payment-form-group ${!this.state.isCardBillingEmailError ? 'border-error' : ''}`}>
              <div className="payment-form-row">
                <input
                  id="billingEmail"
                  className={`payment-form-row-input ${!this.state.isCardBillingEmailError ? 'error' : ''}`}
                  type="email"
                  required
                  placeholder="Billing email address"
                  value={billingEmailAddress}
                  onChange={(event) => {
                    this.setState({ billingEmailAddress: event.target.value });
                  }}
                  onBlur={(event) => {
                    this.validateEmail();
                  }}/>
              </div>
            </fieldset>
            <label className="error">
              <span>{this.state.cardBillingEmailErrorMessage}</span>
            </label>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6">
            <fieldset className={`payment-form-group ${this.state.isCardZipCodeError ? 'border-error' : ''}`}>
              <div className="payment-form-row">
                <input
                  id="postal"
                  className={`payment-form-row-input ${this.state.isCardZipCodeError ? 'error' : ''}`}
                  required
                  placeholder="Zip code or postal code"
                  value={postal}
                  onChange={(event) => {
                    this.setState({ postal: event.target.value });
                    this.setState({ isCardZipCodeError: event.target.value === '' ? true : false });
                    this.setState({ cardZipCodeErrorMessage: event.target.value === '' ? 'Please enter your billing zipcode or postal code' : '' });
                  }}
                  onBlur={(event) => {
                    this.setState({ isCardZipCodeError: event.target.value === '' ? true : false });
                    this.setState({ cardZipCodeErrorMessage: event.target.value === '' ? 'Please enter your billing zipcode or postal code' : '' });
                  }}/>
              </div>
            </fieldset>
            <label className="error">
              <span>{this.state.cardZipCodeErrorMessage}</span>
            </label>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-sm-12 text-center">
            <div className="d-grid gap-2 col-6 mx-auto">
              {
                this.state.processingPayment
                  ? <button
                      type="button"
                      className="btn btn-outline-primary btn-lg w-100">
                      <span className="fas fa-spinner fa-spin fa-2x loading-icon"></span>
                    </button>
                  : currentPlan.planId === selectedPlan.planId && !isChangePayment
                      ? <button
                          type="button"
                          className="btn btn-outline-primary btn-lg w-100">
                          <span>Subscribed</span>
                        </button>
                      : selectedPlan.planId === 1
                        ? <button
                            type="button"
                            className="btn btn-outline-primary btn-lg w-100">
                            <span>Subscribed</span>
                          </button>
                        : <button
                          type="submit"
                          className="btn btn-outline-primary btn-lg w-100"
                          disabled={!stripe}>
                          {
                            selectedPlan.billingCycle === 2
                              ? <span>Pay ${selectedPlan.yearlyPrice}.00</span>
                              : <span>Pay ${selectedPlan.monthlyPrice}.00</span>
                          }
                        </button>

              }
            </div>
            {
              this.state.isPaymentError
                ? <div className="col-sm-12 text-center mt-3">
                    <p className="error">There was an error processing your payment please try again.</p>
                  </div>
                : null
            }
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
		currentPlan: state.currentPlan,
    selectedPlan: state.selectedPlan,
    isChangePlan: state.isChangePlan,
    isChangePayment: state.isChangePayment
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateIsChangePlan: (isChangePlan) => dispatch({ type: 'IS_CHANGE_PLAN', value: isChangePlan }),
    updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan }),
    updateIsChangePayment: (isChangePayment) => dispatch({ type: 'IS_CHANGE_PAYMENT', value: isChangePayment })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StripePaymentForm));
