import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import StripeWrapper from '../components/Stripe/StripeWrapper';
// import PaypalWrapper from '../components/Paypal/PaypalWrapper';
import { connect } from 'react-redux';
import Footer from '../components/Footer/Footer';

class PaymentPage extends Component {
  constructor(props) {
    super();
		this.state = {

    };
  }

  render() {
    return (
      <div className="container-fluid page-background1 pb-5">
        <Helmet>
          <meta name="description" content=""/>
          <title>Payment | Sandbox</title>
        </Helmet>
        <Header />
        <div className="container page-content-padding">
          <div className="row pt-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Payment</li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12 text-center">
              {
                this.props.isChangePayment
                  ? <h3>Change Payment Method for { this.props.selectedPlan.plan.charAt(0).toUpperCase() + this.props.selectedPlan.plan.slice(1) } Plan</h3>
                  : <h3>{ this.props.selectedPlan.plan.charAt(0).toUpperCase() + this.props.selectedPlan.plan.slice(1) } Plan Payment</h3>
              }
            </div>
            <StripeWrapper />
            {/* {this.props.isPaypalPayment
            ?  <PaypalWrapper />
            : <StripeWrapper />} */}


          </div>
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    this.props.updateSelectedPlan(this.props.currentPlan);
    this.props.updateIsChangePlan(false);
    this.props.updateIsChangePayment(false);
  }
}

const mapStateToProps = state => {
  return {
    selectedPlan: state.selectedPlan,
    currentPlan: state.currentPlan,
    isChangePlan: state.isChangePlan,
    isChangePayment: state.isChangePayment,
    isPaypalPayment: state.isPaypalPayment
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedPlan: (selectedPlan) => dispatch({ type: 'SELECTED_PLAN', value: selectedPlan }),
    updateIsChangePlan: (isChangePlan) => dispatch({ type: 'IS_CHANGE_PLAN', value: isChangePlan }),
    updateIsChangePayment: (isChangePayment) => dispatch({ type: 'IS_CHANGE_PAYMENT', value: isChangePayment })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
