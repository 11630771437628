import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import COUNTRIES from '../../constants/Countries';
import LANGUAGES from '../../constants/Languages';
import { Tooltip } from 'mdb-ui-kit';
import { Modal } from 'mdb-ui-kit';
import { GetAuthToken } from '../../functions/AuthStatus';
import { GetUserSettingApi } from '../../functions/UserSettingApi';
import LOCALSTORAGE from '../../constants/LocalStorage';

const CustomOption = ({ innerRef, innerProps, value, label }) => {
	return (
		<div
			ref={innerRef} {...innerProps}
			className="custom-option">
				{
					value.country_code === 'aq'
						? <img
								src={'https://www.countryflags.io/aq/flat/16.png'}
								style={{marginRight: '.5em'}}
								alt={value.country_code}/>
						: <i className={`${value.country_code} flag`}></i>
				}
			<span>{label}</span>
		</div>
	)
}

class KeywordSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryOptions: [],
      languageOptions: [],
      countrySelected: 'United States',
			countryCode: '',
      languageSelected: 'en',
      tempToolTip: [],
			// algorithmToolTip:`CATEGORY
			// parent level category input -> child level keyword output (cars -> toyota)
			//
			// SIMILAR
			// child level keyword input -> child level keyword output (toyota -> honda)
			//
			// RELATED
			// semantically related
			// (toyota -> electric cars)
			//
			// VARIATIONS
			// old school modifiers
			// (toyota -> buy toyota car)
			//
			// QUESTIONS
			// on-topic questions
			// (toyota -> why is toyota building humanoid robots ?)`
    }
  }

  formatCountryOptions = () => {
		  const list = [];
  		COUNTRIES.forEach(country => {
  			list.push({
  				value: country,
  				label: `${country.country_name} - ${country.country_code}`,
  				countryCode: country.country_code
  			});
  		});
  		this.setState({countryOptions: list});
	}

	formatLanguageOptions = () => {
  		const list = [];
  		LANGUAGES.forEach(language => {
  			list.push({
  				value: language,
  				label: language.language_name
  			});
  		});
  		this.setState({ languageOptions: list });
	}

	setDefaultCountry = () => {
		var defaultCountry;
		if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
				var jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
				defaultCountry = COUNTRIES.find(c => c.country_code === jsonObj.country_code);
		} else {
		 	defaultCountry = COUNTRIES.find(c => c.country_code === this.state.countryCode);
		}

		return defaultCountry
			? {
					value: { country_code: defaultCountry.country_code, country_name: defaultCountry.country_name },
					label: `${defaultCountry.country_name} - ${defaultCountry.country_code}`
				}
			: {
					value: { country_code: 'us', country_name: 'United States' },
					label: 'United States - us'
				}
	}

  setDefaultLanguage = () => {
			var defaultLanguage;
			if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
				var jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
				defaultLanguage = LANGUAGES.find(l => l.language_code === jsonObj.language);
			} else {
				defaultLanguage = LANGUAGES.find(l => l.language_code === this.state.languageSelected);
			}

			return defaultLanguage
				? {
						value: { language_code: defaultLanguage.language_code, language_name: defaultLanguage.language_name },
						label: defaultLanguage.language_name
					}
				: {
						value: { language_code: 'en', language_name: 'English' },
						label: 'English'
					}
  }

  displaySettingsOptions = () => {
    const urlPath = window.location.pathname;
    const settingsMapping = {
      '/website-speed-test': () => false,
			'default': () => true
    };
    return settingsMapping[urlPath]
      ? settingsMapping[urlPath]()
      : settingsMapping['default']();
  }

	GetUserSetting = () => { //getting user settings to update export modal. Export modal does not update saved settings.
    GetUserSettingApi().then(data => {
      if (data) {
					const defaultCountry = COUNTRIES.find(c => c.country_code === data.country);
					this.setState({ countrySelected: defaultCountry.country_name });
					this.setState({ languageSelected: data.language});
      }
    });
  }

	handleSave = (e) => {
			var lang;
			if(this.state.languageSelected === "iw") { //TODO temp fix for hebrew
				lang = "he";
			} else {
				lang = this.state.languageSelected;
			}
			var settingsObj = {
					temperature: this.props.temperature,
					country: this.state.countrySelected,
					country_code: this.state.countryCode,
					language: lang,
					algorithm: ""
			}

			localStorage.setItem(LOCALSTORAGE.keywordSettings, JSON.stringify(settingsObj))
			this.props.handleSubmit(e);
			if(this.state.currentAlgorithmTT) {
				console.log(this.state.currentAlgorithmTT);

			}
			this.handleCloseModal();
	}

	handleCloseModal = () => {
		setTimeout(() => {
			this.props.keywordSettingsModal.hide();
		}, 300)

	}

	// handleToggleAlgorithmToolTip = (e) => {
	// 	const toolTipAl = document.getElementById(`algorithm-tooltip`);
	// 	if(!toolTipAl.classList.contains("none")) {
	// 		toolTipAl.classList.add("none");
	// 	}	else {
	// 		toolTipAl.classList.remove("none");
	// 	}
	// }
	//
	// handleSelectAlgorithm = (e) => {
	// 	var algBtns = document.querySelectorAll(".keyword-object");
	// 	algBtns.forEach(btn => {
	// 		if(btn === e.currentTarget) {
	// 			if(!e.currentTarget.classList.contains("algorithm-selected")) {
	// 				e.currentTarget.classList.add("algorithm-selected");
	// 			}
	// 			//set state here or make a global redux function to update
	// 			//might need to update localstorage settings here too.
	// 			this.props.handleCategoryState(e.currentTarget.innerText);
	// 		} else {
	// 			if(btn.classList.contains("algorithm-selected")) {
	// 				btn.classList.remove("algorithm-selected");
	// 			}
	// 		}
	// 	})
	// }
	//
	// handleCurrentSelectedAlgorithm = () => {
	// 	var algBtns = document.querySelectorAll(".keyword-object");
	// 	algBtns.forEach(btn => {
	// 		if(this.props.currentAlgorithm === btn.innerText) {
	// 			btn.classList.add("algorithm-selected");
	// 		}
	// 	});
	// }

  componentDidMount () {
  		if (GetAuthToken()) {
				if(localStorage.getItem(LOCALSTORAGE.keywordSettings) === undefined
				|| localStorage.getItem(LOCALSTORAGE.keywordSettings) === null
				|| localStorage.getItem(LOCALSTORAGE.keywordSettings) === "") {
					this.GetUserSetting();
					var settingsObj = {
						country: this.state.countrySelected,
						language: this.state.languageSelected
					}
					localStorage.setItem(LOCALSTORAGE.keywordSettings, JSON.stringify(settingsObj));
				} else {

						if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined
						&& localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null
						&& localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
							var jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
						this.setState({ countrySelected: jsonObj.country });
						this.setState({ countryCode: jsonObj.country_code });
						this.setState({ languageSelected: jsonObj.language });
					}

				}
  		}
			// this.handleCurrentSelectedAlgorithm();
			this.formatCountryOptions();
			this.formatLanguageOptions();

  		const settingsModalEl = document.getElementById('keywordSettingsModal');
  		if (typeof settingsModalEl !== 'undefined') {
  			const modal = new Modal(settingsModalEl);
  			this.props.updateKeywordSettingsModal(modal);
  			modal.show();
  			settingsModalEl.addEventListener('hide.bs.modal', (e) => {
  				this.props.updateShowKeywordSettingsModal(false);
  			});
  		}

      const toolTipEl = document.getElementById(`temperature-tooltip`);
      if (toolTipEl) {
        var toolTipSettings = new Tooltip(toolTipEl, { boundary: 'window' });
        toolTipSettings.show(); //had to show this here because of destroy null bug
        toolTipSettings.hide();
      }
	}

render() {
  return (
    <div
      className="modal fade"
      id="keywordSettingsModal"
      tabIndex="-1"
      aria-labelledby="keywordSettingsModalLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="keywordSettingsModalLabel">
              Settings
            </h3>
            <button
							type="button"
							className="close"
							// data-dismiss="modal"
							aria-label="Close"
							onClick={() => this.handleCloseModal()}
							>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
						<div className="row justify-content-center mb-4">
							{/* <div className="col-sm-12">
								<div className="d-flex justify-content-center">
									<p
										className="algorithm-title">Algorithm</p>
										<i
											onClick={(e) => this.handleToggleAlgorithmToolTip(e)}
											className="fas fa-info-circle pl-2">
										</i>
										<div
											id="algorithm-tooltip"
											className="algorithm-tooltip-inner none">
											<p
												className="custom-tooltip alg-inner"
												data-placement="top"
												>{this.state.algorithmToolTip}
											</p>
										</div>
								</div>
							</div> */}
							{/* <div className="col-sm-12 text-center">
								<span
									onClick={(e) => this.handleSelectAlgorithm(e)}
									className="keyword-object btn btn-outline-dark btn-rounded">category</span>
							</div>
							<div className="col-sm-12 text-center">
								<span
									onClick={(e) => this.handleSelectAlgorithm(e)}
									className="keyword-object btn btn-outline-dark btn-rounded">similar</span>
								<span
									onClick={(e) => this.handleSelectAlgorithm(e)}
									className="keyword-object btn btn-outline-dark btn-rounded">related</span>
							</div>
								<div className="col-sm-12 text-center">
								<span
									onClick={(e) => this.handleSelectAlgorithm(e)}
									className="keyword-object btn btn-outline-dark btn-rounded">traditional</span>
								<span
									onClick={(e) => this.handleSelectAlgorithm(e)}
									className="keyword-object btn btn-outline-dark btn-rounded">questions</span>
								</div> */}
						</div>
            <div className="temperature row justify-content-evenly">
              <div className="col-sm-12 d-flex temp-input-group justify-content-evenly align-items-center">
                <label htmlFor="customRange2" className="form-label mt-2 mr-1">randomness</label>
                <input type="text"
                  className="form-control temp-input mb-1" min="0" max="1" onChange={(e) => this.props.temperatureRange(e)} value={`${this.props.temperature}`}></input>
                  <i
                    id="temperature-tooltip"
                    className="fas fa-info-circle pl-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-trigger='hover'
                    title="Controls randomness. Lowering results in less random keywords.">
                  </i>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-12 text-center temp-slider">
                <input id="customRange2" type="range" className="form-range" min="0" max="1" step="0.01" value={`${this.props.temperature}`} onChange={(e) => this.props.temperatureRange(e)} ></input>
              </div>
            </div>

            {/* Country */}
            {
              this.displaySettingsOptions()
                ? <div className="row mt-2">
                    <div className="col-sm-12">
                      <label>Country</label>
                      <Select
                        isSearchable
                        menuPlacement="auto"
                        options={this.state.countryOptions}
                        components={{ Option: CustomOption }}
                        onChange={e => {
                          this.setState({ countrySelected: e.value.country_name });
													this.setState({ countryCode: e.value.country_code });
                        }}
                        defaultValue={this.setDefaultCountry()}/>
                    </div>
                  </div>
                : null
            }

            {/* Language */}
            {
              this.displaySettingsOptions()
                ? <div className="row mt-3">
                    <div className="col-sm-12">
                      <label>Language</label>
                      <Select
                        isSearchable
                        menuPlacement="auto"
                        options={this.state.languageOptions}
                        onChange={e => {
                          this.setState({ languageSelected: e.value.language_code });
                        }}
												defaultValue={this.setDefaultLanguage()}/>
                    </div>
                  </div>
                : null
            }
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary success"
              onClick={(e) => this.handleSave(e)}
              >
              {
                this.state.toggleCheckMark
                  ? <i className="fas fa-check"></i>
                  : <span>Save</span>
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
}





const mapStateToProps = state => {
  return {
    keywordSettingsModal: state.keywordSettingsModal,
		serpCountry: state.serpCountry,
		serpLanguage: state.serpLanguage,
    showKeywordSettingsModal: state.showKeywordSettingsModal
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateSelectedKeywordExportOptions: (keywordExportOptions) => dispatch({ type: 'KEYWORD_EXPORT_OPTIONS', value: keywordExportOptions }),
    updateKeywordSettingsModal: (keywordSettingsModal) => dispatch({type: 'KEYWORD_SETTINGS_MODAL', value: keywordSettingsModal}),
    updateShowKeywordSettingsModal: (showKeywordSettingsModal) => dispatch({ type: 'CAN_VIEW_KEYWORD_SETTINGS_MODAL', value: showKeywordSettingsModal })
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(KeywordSettingsModal);
