import axios from 'axios';
import { GetAuthToken } from './AuthStatus';

export async function ResetPasswordApi(params) {
  try {
    if (GetAuthToken()) {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/reset-password`, params, { headers: headers, withCredentials: true})
      return response;
    } else {
      throw new Error('No token cant reset password')
    }
  } catch (error) {
    return error.response;
  }
}

