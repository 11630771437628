import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import { Link } from 'react-router-dom';
import SerpChecker from '../components/SerpChecker/SerpChecker';
import Footer from '../components/Footer/Footer';
import BackToTopArrow from '../components/BackToTopArrow';

class SerpCheckerPage extends Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    render() {
        return (
            <div className="serp-psi-page-bkg">
                <Helmet>
                <meta name="description" content="Instantly check your Google rankings with our fast and accurate SERP Checker tool. Don't wait for automated checkers. See your rankings now, across any device, and any country."/>
                <title>SERP Checker | SEO Keyword Ranking Check | Sandbox</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {/* Facebook meta tags */}
                <meta property="og:title" content="SERP Checker | SEO Keyword Ranking Check | Sandbox" />
                <meta property="og:image" content="" />
                <meta property="og:description" content="Instantly check your Google rankings with our fast and accurate SERP Checker tool. Don't wait for automated checkers. See your rankings now, across any device, and any country." />
                <meta property="og:url" content="https://tools.sandboxweb.io/serp-checker" />
                <meta property="og:type" content="website" />
                {/* Twitter meta tags */}
                <meta property="twitter:title" content="SERP Checker | SEO Keyword Ranking Check | Sandbox" />
                <meta property="twitter:description" content="Instantly check your Google rankings with our fast and accurate SERP Checker tool. Don't wait for automated checkers. See your rankings now, across any device, and any country." />
                <meta property="twitter:image" content="" />
                <meta property="twitter:card" content="" />
                </Helmet>
                <Header />
                <section className="container-fluid shadow-2-strong background1 page-content-padding">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to={'/'}>Tools</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">SERP Checker</li>
                            </ol>
                            </nav>
                        </div>
                        <div className="col-sm-12">
                            <div className="row">
                            <div className="col-sm-12">
                                <div className="algo-tooltip-holder">
                                <h3 className="mb-3 tool-header">Serp Checker</h3>
                                <p className="tool-sub-header">Check your SERP Rankings for SEO</p>

                                </div>
                            </div>
                            </div>
                        <SerpChecker />
                        </div>
                    </div>
                </div>
                <BackToTopArrow />
            </section>

            <section className="container-fluid seo-container">
            <div className="container">
                    <div className="row mt-5 pt-3 pb-5">
                        <div className="col-sm-12">
                            <p>Instantly check your Google rankings with our fast and accurate SERP Checker tool. Don't wait for automated checkers. See your rankings now, across any device, and any country.</p>
                    
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid background1 page-content-padding">
            <Footer />
            </section>
        </div>
        );
}
}
const mapStateToProps = state => {
    return {
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SerpCheckerPage);