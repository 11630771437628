import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class KeywordDrawer extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      serpSearchUrl: "https://tools.sandboxweb.io/serp-analysis-tool?searchTerm=",
      keywordSearchUrl: window.location + "?searchTerm=",
      googleTrendsUrl: "https://trends.google.com/trends/explore?date=all&geo=US&q=",
      googleSearchUrl: "https://www.google.com/search?q=",
      googleImagesEnd: "&tbm=isch"
  };
}

  render() {
    return (
      <div>
        <div className={`offcanvas offcanvas-end ${this.props.canvas}`} tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
          <div className="drawer-header">
            <button
              type="button"
              className="close float-right"
              // data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.hideCanvas()}
              >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="offcanvas-body">
            <div className="row">
              <div className="col-sm-12">
                <a
                  href={this.state.keywordSearchUrl + this.props.cardKeyword + "&algorithm=" + this.props.algorithm}
                  className="btn btn-outline-dark drawer-btn"
                  type="submit"
                  id="drawerKeywordSearch"
                  data-ripple-color="dark"
                  onClick={(e) => this.props.handleSubmit(e)}
                  >
                  Search "{this.props.cardKeyword}"
                </a>
              </div>
              <div className="col-sm-12">
                <a
                  href={`${this.state.serpSearchUrl + this.props.cardKeyword}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-dark drawer-btn"
                  type="submit"
                  id="drawerRunSerp"
                  data-ripple-color="dark">
                  Run Sandbox Serp Analysis
                </a>
              </div>
              {/* <div className="col-sm-12 mb-2">
                <div id="chart-container"></div>
                {
                  this.props.showChartSkeleton
                  ? <div className="drawer-animated-img-holder">
                        <span className="animated-drawer-skeleton skeleton-holder">
                          <img
                            className="flimstrip-preview skeleton-background"
                            src="../images/skeleton.PNG"
                            alt="chart-img-preview"/>
                        </span>
                    </div>: null
                  }

                  {
                    !this.props.showChartSkeleton ?
                      <div className="d-flex justify-content-between">
                        <span className="chart-yr">2008</span>
                        <span className="chart-yr">2015</span>
                        <span className="chart-yr">2022</span>
                      </div>
                    : null
                  }
              </div> */}
              <div className="col-sm-12">
                <a
                  href={this.state.googleTrendsUrl + this.props.cardKeyword}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-dark drawer-btn"
                  type="submit"
                  id="drawerGoogleTrends"
                  data-ripple-color="dark">
                  View Google Trends
                </a>
              </div>
              {/* <div className="col-sm-12 drawer-img-container">
                {
                this.props.keywordDrawerGoogleImage !== ""
                  ? <img
                    className="keyword-drawer-img"
                    src={this.props.keywordDrawerGoogleImage}
                    // src={this.props.googleSerpImage}
                    alt="google-img"></img>
                    : <div className="drawer-animated-img-holder">
                          <span className="animated-drawer-skeleton skeleton-holder">
                            <img
                              className="flimstrip-preview skeleton-background"
                              src="../images/skeleton.PNG"
                              alt="google-img-preview"/>
                          </span>
                      </div>
                  }
              </div> */}
              <div className="col-sm-12">
                <a
                  href={this.state.googleSearchUrl + this.props.cardKeyword + this.state.googleImagesEnd}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-dark drawer-btn"
                  type="submit"
                  id="drawerGoogleImages"
                  data-ripple-color="dark">
                    View Google Images
                </a>
              </div>
              <div className="col-sm-12">
                <a
                  href={this.state.googleSearchUrl + this.props.cardKeyword}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-dark drawer-btn"
                  type="submit"
                  id="drawerGoogleSearch"
                  data-ripple-color="dark">
                    View Google Search
                </a>
              </div>
            </div>
          </div>
        </div>
        {
          this.props.canvas === "show"
          ? <div className={`modal-backdrop fade ${this.props.canvas}`}></div>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
      keywordDrawerGoogleImage: state.keywordDrawerGoogleImage
    };
  };

export default connect(mapStateToProps, null)(withRouter(KeywordDrawer));
