import React, { Component, Suspense } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import { connect } from 'react-redux';
import { Tooltip } from 'mdb-ui-kit';
import { DisplayAccountGateCheck } from '../../functions/DisplayAccountGateCheck';
import LOCALSTORAGE from '../../constants/LocalStorage';
import COUNTRIES from '../../constants/Countries';
import { GetAuthUserId, GetAuthPlanId, GetAuthToken } from '../../functions/AuthStatus';
import { withRouter } from 'react-router-dom';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { updateSignedInStatusSubject } from '../../observables/SignedInStatus';
import { AuthTokenStatus } from '../../functions/AuthTokenStatusApi';
import KeywordExportToolTip from './KeywordExportToolTip';
import KeywordDrawer from './KeywordDrawer';
import { RemainingKwtUsageStatus } from '../../functions/RemainingKwtUsageStatus';
import { KwtRateLimitingApi } from '../../functions/KwtRateLimitingApi';
import { IpRateLimitingApi } from '../../functions/IpRateLimitingApi';
import KeywordSettingsModal from '../Modals/KeywordSettingsModal';
import { MediaMatcher } from '../../functions/MediaMatcher';
import queryString from 'query-string'
import KeywordAlerts from './KeywordAlerts';
import KeywordTabs from './KeywordTabs';
// import { Chart } from 'chart.js';
// import 'chart.js/auto'
// Chart.defaults.elements.line.backgroundColor = "rgb(54, 162, 235)";
// Chart.defaults.elements.line.borderColor =  "rgb(54, 162, 235)";

const KeywordCard = React.lazy(() => import("./KeywordCard"));

class KeywordTool extends Component {
  constructor(props){
    super(props);
    this._isMounted = false;
    this.state = {
      searchTerm: '',
      openAIText: [],
      keywordRow: [],
      resultsReturned: true,
      updateCanViewContentFilterAlert: false,
      viewFilteredContent: true,
      autoSearch: false,
      searchButtonSubmitted: false,
      max_tokens: '',
      res: '',
      tokens_res: '',
      tokens_prompt:'',
      prompt:'',
      panelWidth: 'none',
      openBtn: '',
      promptQA: [],
      dectectedLanguage: '',
      contentFilterRank: 0,
      temperature: 0.7,
      temperatureInput: 0.7,
      temporaryArray: [],
      searchTermForGaEvent: '',
      showSidePanel: false,
      running: false,
      hourRunning: false,
      value: 0,
      hourValue: 0,
      theClock: " ",
      localMinuteTimer: "",
      showOnboardingMessage: false,
      showDeduplicationAlert: false,
      showCreateAccountMessage: false,
      showAdwordsErrorMessage: false,
      showRateLimitAlert: false,
      showKeywordContainer: false,
      adwordsCardResults: [],
      adwordsApiFailed: false,
      adwordsRetryCount: 0,
      selectedKeywordSize: 30,
      keywordSizeArray: [15, 30, 60, 90],
      gpt3Failed: false,
      append: false,
      appendTerm: "",
      lastAppendTerm: "",
      lastPrependTerm: "",
      appendRunning: false,
      currentAlgorithm: "category",
      categorySibTypeOrStyle: "",
      hideAppend: false,
      showIdentifiedCategory: false,
      keywordPlaceholder: "enter category",
      canvas: "",
      cardKeyword: "",
      isQueryParam: false,
      googleSerpImage: "",
      // showChartSkeleton: true,
      refreshCards: false,
      categoryTT: `Enter a parent category, and we'll give you the children below. Use this only when you have a true category, like "pickled vegetables" or "books about witches"`,
      relatedTT: `The related button is best for coming up with semantically related keywords or topics. Enter “cabernet sauvignon” and you’ll get napa valley, sommelier, etc.. `,
      similarTT: `Think of similar results as siblings, under the same parent category. “Flare Jeans” will give you Bell Bottoms, Bootcuts, etc.. "Rocky Road” will give you Strawberry, Mint Chocolate Chip…`,
      variationTT: `The “variation” button gives results that contain the seed keyword. Enter “drones” and you’ll get drone racing, drone with camera, etc.. `,
      showOverlay: false,
      selectedType: "Category",
      categoryCardResults: [],
      similarCardResults: [],
      variationCardResults: [],
      relatedCardResults: [],
      categoryOGCardResults: [],
      // similarOGCardResults: [],
      variationOGCardResults: [],
      relatedOGCardResults: [],
      algorithms: ["category", "variation", "related"],
      relatedFinished: false,
      count: 0,
      placeholderText: [
        "NASDAQ stock tickers", 
        "FIFA players", 
        "Cities in Florida", 
        "F150 aftermarket parts", 
        "Ice cream flavors", 
        "Top cities for airbnb"],
      deletingText: false,
      placeholderValue: 0,
      keywordInput: null, 
      isPrepend: false,
      isAppend: true,
      progressPercentage: 0,
      percentageHangingState: 80,
      showClearAppendBtn: false,
      gatheringResults: ""
    };
  }

handleInputChange = (event) => {
  const value = event.target.value;
  if(value.length >= 175) {
    event.preventDefault();
    alert("only 175 max characters allowed");
  } else {
    this.setState({searchTerm: value});
  }
}

handleSubmit = async (event) => {
  if (event) event.preventDefault();
  if(this.state.searchButtonSubmitted)
    return;

  if(this.state.categoryCardResults.length > 0)
    this.handleClearResults();

  if(this.state.searchTerm !== '') {
    if (GetAuthUserId()) {
      if(this.state.searchTerm === process.env.REACT_APP_DIAGNOSTICS_CODE) {
        this.setState({ showSidePanel: true });
      } else {
        // if(GetAuthPlanId() === 1) { //if we reach gpt3 api limit.
        //   this.linkToKeywordRedirectPage();
        // } else {
          // if(!this.state.rateLimitPerHourHit) {
          //   if(!this.state.rateLimitPerMinuteHit) {
              const keywordsLeft = await RemainingKwtUsageStatus();
              this.isRateLimitReached().then(isLimitReached => {
                if(!isLimitReached && keywordsLeft.kwtSearchesLeft > 0) {
                  this.setState({ adwordsRetryCount: 0 });
                  this.setState({ showKeywordContainer: true });
                  this.setState({ resultsReturned: false });
                  this.setState({ searchButtonSubmitted: true });
                  this.setState({ showOnboardingMessage: true });
                  this.setState({ showAdwordsErrorMessage: false });
                  this.setState({ searchTermForGaEvent: this.state.searchTerm });
                  this.setState({ append: false });
                  this.setState({ appendTerm: this.state.searchTerm });
                  this.setState({ showOverlay: false });
                  this.setState({ relatedFinished: false });
                  this.setState({ progressPercentage: 0 });
                  this.setState({gatheringResults: ""});

                  ReactGA.event({
                    category: 'Keyword Suggestions - ' + this.state.currentAlgorithm,
                    action: 'Keyword Submitted',
                    label: this.state.searchTerm
                  });

                  if(this.state.currentAlgorithm === "similar")
                    this.setState({ showIdentifiedCategory: true });
                  else
                    this.setState({ showIdentifiedCategory: false });

                  if(this.state.currentAlgorithm === "questions")
                    this.setState({ hideAppend: true });
                  else
                    this.setState({ hideAppend: false });

                  if(this.state.currentAlgorithm  !== "category")
                    this.setState({ keywordPlaceholder: "enter keyword" });
                  else
                    this.setState({ keywordPlaceholder: "enter category" });


                  this.handleOpenAIRequest(this.state.searchTerm);
                } else {
                  this.updateKeywordRateLimit(null, null).then(res => {});
                  if(GetAuthPlanId() === 1) {
                    this.linkToFreeTrialPage();
                  } else {
                    this.setState({ showRateLimitAlert: true });
                  }
                }
              });
      }
    } else {
      this.setState({ showCreateAccountMessage: true });
    }
  }
}

handleOpenAIRequest = async (term) => {
  const id = GetAuthUserId();
  let contentFilterArray = [];
  let allKeywordResults = [];
  let parsedTemp = parseFloat(this.state.temperature);
  for (let index = 0; index < this.state.algorithms.length; index++) {
    let responseArray = [];
    let contentFilterRank;
    const currentAlgorithm = this.state.algorithms[index];

    let postObj = {
      userId: id,
      term: term,
      temp: parsedTemp,
      size: "",
      algorithm: currentAlgorithm
    }

    if(this.props.remainingKwtSearches < this.state.selectedKeywordSize) 
      postObj.size = this.props.remainingKwtSearches;
    else 
      postObj.size = this.state.selectedKeywordSize / 3;
    
    if(parsedTemp > 1) 
      postObj.temp = 1;
    else if(parsedTemp < 0) 
      postObj.temp = 0;
    

    this.setState({ resultsReturned: false });
    // this.increaseProgressBar();
  await axios.post(process.env.REACT_APP_OPEN_AI_API, postObj)
    .then(response => {
      let result = [];

      const categoryResultsMapping = this.state.categoryCardResults;
      // const similarResultsMapping = this.state.similarCardResults;
      const variationResultsMapping = this.state.variationCardResults;
      const relatedResultsMapping = this.state.relatedCardResults;

      // if(currentAlgorithm === "similar") {
      //   var typeOrStyle;
      //   if(response.data[0].type_or_style.toLowerCase().includes("similar examples")) {
      //     typeOrStyle = response.data[0].type_or_style.replace(/(Similar examples)(.*)/, '') || response.data[0].type_or_style.replace(/(similar examples)(.*)/, '');
      //   } else {
      //     typeOrStyle = response.data[0].type_or_style
      //   }
      //   this.setState({ categorySibTypeOrStyle: typeOrStyle });
      // }

      if(response.data[0].res.includes(",")) {
        responseArray = response.data[0].res.split(',');
      } else {
        var removeWS = response.data[0].res.replace(/^\s+|\s+$|\s+(?=.*\s)/, ""); //replace front white space.
        var addCommas = removeWS.replace(/^\s+|\s+$|\s+(?=\s)/g, ", ");
        responseArray = addCommas.split(',');
      }
      contentFilterRank = response.data[0].response_filter?.termFilter;
      contentFilterArray.push(contentFilterRank)

      for (let i = 0, il = responseArray?.length; i < il; i++) {
        var phraseNoSpaces = {
          keyword: responseArray[i].trim().toLowerCase(),
          monthly_search_volume: ""
        }
        if(phraseNoSpaces.keyword !== this.state.searchTerm.toLowerCase()) 
          result.push(phraseNoSpaces);
      }
      var fResult = this.handleArrayDuplicateFilter(result);
      allKeywordResults.push(fResult);

      if(this.state.viewFilteredContent) {
        var filteredResult = this.handleArrayDuplicateFilter(result);
        this.handleKeywordLengthFilter(result);
        if(filteredResult.length === 0)
          this.setState({ showDeduplicationAlert: true });

        // keyWordRowMapping.push(filteredResult);
        if(currentAlgorithm === "category") {
          this.setState({gatheringResults: "Category"});
          this.increaseProgressBar();
          categoryResultsMapping.push(filteredResult);
          this.handleKeywordLengthFilter(categoryResultsMapping);
          if(GetAuthPlanId() === 1)
            this.handleNonProPlanLimiting(categoryResultsMapping);
        }
          
        // if(currentAlgorithm === "similar") {
        //   similarResultsMapping.push(filteredResult);
        //   this.handleKeywordLengthFilter(similarResultsMapping);
        //   if(GetAuthPlanId() === 1)
        //     this.handleNonProPlanLimiting(similarResultsMapping);
        // }
          
        if(currentAlgorithm === "variation") {
          this.setState({gatheringResults: "Variation"});
          this.increaseProgressBar();
          variationResultsMapping.push(filteredResult);
          this.handleKeywordLengthFilter(variationResultsMapping);
          if(GetAuthPlanId() === 1)
            this.handleNonProPlanLimiting(variationResultsMapping);
        }
        
        if(currentAlgorithm === "related") {
          this.setState({gatheringResults: "Related"});
          this.increaseProgressBar();
          relatedResultsMapping.push(filteredResult);
          this.handleKeywordLengthFilter(relatedResultsMapping);
          if(GetAuthPlanId() === 1)
            this.handleNonProPlanLimiting(relatedResultsMapping);

            this.handleContentFilter(contentFilterArray);
            if(this.state.progressPercentage < 80) {
              this.setState({progressPercentage: 80 });
            }
            return allKeywordResults;
          }
        
    } 

    }).catch(err => {
      console.log(err);
    });
    if(allKeywordResults.length === 3) {
      this.getBulkOpenAIResultsVolume(allKeywordResults, "related");
    }
  }
}

getBulkOpenAIResultsVolume = async (keywordRow, algorithmType) => {
    let modalOptions;
    let data = [];
    let adwordsResults = [];
    let matchedResults = [];

    if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null && localStorage.getItem(LOCALSTORAGE.keywordSettings)
      !== undefined && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
      modalOptions = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
    } else {
      let defaultSettings = {
        country: "United States",
        language:  "en",
        country_code: "us",
        temp: ".7"
      }
      modalOptions = defaultSettings;
    }
    
    for (var i = 0; i < keywordRow.length; i++) {
        keywordRow[i].map(keyword => {
          if(keyword.keyword !== '') {
            if(!data.includes(keyword.keyword))
              data.push(keyword.keyword);
            
          }
          return keyword;
        });
      }

    let exportOptions = { country_data: modalOptions, keywords: data }

    await axios.post(process.env.REACT_APP_ADWORDS_API, exportOptions)
      .then(res => {
        if(res.status === 200) {
          for (var i = 0; i < res.data[0].length; i++) {
            var adwordsObj = {
                keyword: res.data[0][i].keyword.trim(),
                monthly_search_volume: res.data[0][i].keyword_info.search_volume,
                cpc: res.data[0][i].keyword_info.cpc,
                competition: res.data[0][i].keyword_info.competition,
                keyword_difficulty: res.data[0][i].keyword_properties.keyword_difficulty
            }
            adwordsResults.push(adwordsObj);
          }

        data.forEach(word => {
          var matchObj = {
            keyword: word,
            monthly_search_volume: "-"
          };
          res.data[0].filter(function(obj) {
            if(obj.keyword === word){
              matchObj.monthly_search_volume = obj.keyword_info.search_volume;
            }
            return obj;
          });
          matchedResults.push(matchObj);
        });

        //sort alphabetically needs testing to see if this gets overwritten by volume sorting
        let alpha = matchedResults.sort((a, b) => {
            var keywordA = a.keyword, keywordB = b.keyword;
            if(keywordA < keywordB)
              return -1;
            if(keywordA > keywordB)
              return 1;
            return 0;
        });

        //sort by volume greatest to lowest
        let highestToLowestVol = alpha.sort((a, b) => {
            if(a.monthly_search_volume === "-") {
              a.monthly_search_volume = 0;
            }
            return b.monthly_search_volume - a.monthly_search_volume
          });

        for (var x = 0; x < highestToLowestVol.length; x++) {
          if(highestToLowestVol[x].monthly_search_volume === "-" || highestToLowestVol[x].monthly_search_volume === null)
          {
              let splicedDash = highestToLowestVol.splice(x, 1);
              highestToLowestVol.push(splicedDash[0]);
          }
        }

        this.setState({ refreshCards: true }); //unmounts keyword cards for total info refresh

        // this.setState({ adwordsCardResults: highestToLowestVol });
        // if(algorithmType === "category")
        //   this.setState({ categoryCardResults: highestToLowestVol });

        let categoryResults = this.state.categoryCardResults[0];
        // let similarResults = this.state.similarCardResults[0];
        let variationResults = this.state.variationCardResults[0];
        let relatedResults = this.state.relatedCardResults[0];

        categoryResults.map(card => {
          for (let index = 0; index < highestToLowestVol.length; index++) {
            const element = highestToLowestVol[index];
            if(card.keyword === element.keyword){
              card.monthly_search_volume = element.monthly_search_volume;
              return card;
            }
          }
          return null; // shouldnt reach here
        });

        // similarResults.map(card => {
        //   for (let index = 0; index < highestToLowestVol.length; index++) {
        //     const element = highestToLowestVol[index];
        //     if(card.keyword === element.keyword){
        //       card.monthly_search_volume = element.monthly_search_volume;
        //       return card;
        //     }
        //   }
        //   return null; // shouldnt reach here
        // });

        variationResults.map(card => {
          for (let index = 0; index < highestToLowestVol.length; index++) {
            const element = highestToLowestVol[index];
            if(card.keyword === element.keyword){
              card.monthly_search_volume = element.monthly_search_volume;
              return card;
            }
          }
          return null; // shouldnt reach here
        });

        relatedResults.map(card => {
          for (let index = 0; index < highestToLowestVol.length; index++) {
            const element = highestToLowestVol[index];
            if(card.keyword === element.keyword){
              card.monthly_search_volume = element.monthly_search_volume;
              return card;
            }
          }
          return null; // shouldnt reach here
        });

        this.setState({ categoryCardResults: categoryResults.sort((a, b) => {
          if(a.monthly_search_volume === "-") {
            a.monthly_search_volume = 0;
          }
          return b.monthly_search_volume - a.monthly_search_volume
        })});

        // this.setState({ similarCardResults: similarResults.sort((a, b) => {
        //   if(a.monthly_search_volume === "-") {
        //     a.monthly_search_volume = 0;
        //   }
        //   return b.monthly_search_volume - a.monthly_search_volume
        // }) });

        this.setState({ variationCardResults: variationResults.sort((a, b) => {
          if(a.monthly_search_volume === "-") {
            a.monthly_search_volume = 0;
          }
          return b.monthly_search_volume - a.monthly_search_volume
        }) });

        // this.increaseProgressBar();

        this.setState({ relatedCardResults: relatedResults.sort((a, b) => {
          if(a.monthly_search_volume === "-") {
            a.monthly_search_volume = 0;
          }
          return b.monthly_search_volume - a.monthly_search_volume
        }) });

        this.setState({ resultsReturned: true });
        this.setState({ searchButtonSubmitted: false });
        //append input value = searched term
        var appendInput = document.getElementById("appendSearch");
        if(appendInput)
          appendInput.value = this.state.searchTerm;

        if(!this.state.appendRunning) {
          this.updateKeywordRateLimit(this.state.searchTerm, highestToLowestVol.length).then(isLimitReached => {}); //moved this here to stop using keywords on error
          // this.handleMinuteRateLimit(keywordsPerMinuteMapping);
          // this.handleHourRateLimit(keywordsPerHourMapping);
        }

        if(this.state.appendRunning)
           this.setState({appendRunning: false });

        if(this.state.adwordsApiFailed)
          this.setState({ adwordsApiFailed: false });

        if(this.state.gpt3Failed)
          this.setState({ gpt3Failed: false });

        if(this.state.refreshCards)
          this.setState({ refreshCards: false });



      } else {
        // clear results before searching again but keep container showing
        if(this.state.adwordsRetryCount >= 3) {
          var errorResults = [];
          for (var k = 0; k < keywordRow[0].length; k++) {
            keywordRow[0][k].monthly_search_volume = "-";
            errorResults.push(keywordRow[0][k]);
          }

          let categoryResults = this.state.categoryCardResults[0];
          // let similarResults = this.state.similarCardResults[0];
          let variationResults = this.state.variationCardResults[0];
          let relatedResults = this.state.relatedCardResults[0];

          categoryResults.map(card => {
            for (let index = 0; index < errorResults.length; index++) {
              const element = errorResults[index];
              if(card.keyword === element.keyword){
                card.monthly_search_volume = element.monthly_search_volume;
                return card;
              }
            }
            return null; // shouldnt reach here
          });
  
          // similarResults.map(card => {
          //   for (let index = 0; index < errorResults.length; index++) {
          //     const element = errorResults[index];
          //     if(card.keyword === element.keyword){
          //       card.monthly_search_volume = element.monthly_search_volume;
          //       return card;
          //     }
          //   }
          //   return null; // shouldnt reach here
          // });
  
          variationResults.map(card => {
            for (let index = 0; index < errorResults.length; index++) {
              const element = errorResults[index];
              if(card.keyword === element.keyword){
                card.monthly_search_volume = element.monthly_search_volume;
                return card;
              }
            }
            return null; // shouldnt reach here
          });
  
          relatedResults.map(card => {
            for (let index = 0; index < errorResults.length; index++) {
              const element = errorResults[index];
              if(card.keyword === element.keyword){
                card.monthly_search_volume = element.monthly_search_volume;
                return card;
              }
            }
            return null; // shouldnt reach here
          });

          if(!this.state.appendRunning) //do not want to reduce keywords when running appended
            this.updateKeywordRateLimit(this.state.searchTerm, errorResults.length).then(isLimitReached => {});

          if(modalOptions.country !== "United States" && modalOptions.language !== "en") // dont want to show volume error message if searching in US with en
            this.setState({ showAdwordsErrorMessage: true });

          this.setState({ resultsReturned: true });
     
          this.setState({ searchButtonSubmitted: false });
          if(this.state.appendRunning)
             this.setState({appendRunning: false });

          return; //moved this here to stop using keywords on error
        }

        this.setState({ adwordsApiFailed: true });
        this.setState({ adwordsRetryCount: this.state.adwordsRetryCount + 1 });

        //if GPT3 Fails
        if(keywordRow[0].length === 1) { //only want results to clear when GPT3 fails
          this.setState({ categoryCardResults: [] });
          // this.setState({ similarCardResults: [] });
          this.setState({ variationCardResults: [] });
          this.setState({ relatedCardResults: [] });

          this.setState({ adwordsCardResults: [] }); 
          this.setState({ keywordRow: [] });
          this.setState({ temporaryArray: [] });

          this.setState({ gpt3Failed: true });
          this.handleOpenAIRequest(this.state.searchTerm);
        } else {
          //else if adwords fails retry adwords with same GPT3 results
          this.handleAdwordsRetry(keywordRow);
          console.log("ADWORDS RETRY");
          // return;
        }
      }
  }).catch(err => {
      console.log(err);
  });
  if(algorithmType === "related"  && this.state.relatedCardResults.length > 0)
    this.setState({ relatedFinished: true });

  // const toolTipRA = document.getElementById(`submitKeywordSearchAgain`);
  // if(toolTipRA) new Tooltip(toolTipRA, { boundary: 'window' });
}

getOpenAIResultsVolume = async (keywordRow, algorithmType) => {
  ReactGA.event({
    category: 'Keyword Suggestions - ' + this.state.currentAlgorithm,
    action: 'Requested Returned',
    label: `${this.props.remainingKwtSearches}, ${this.state.selectedKeywordSize}, ${keywordRow[0].length}`
  });
  let modalOptions;
  let data = [];
  let adwordsResults = [];
  let matchedResults = [];

  if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null && localStorage.getItem(LOCALSTORAGE.keywordSettings)
    !== undefined && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
    modalOptions = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
  } else {
    let defaultSettings = {
      country: "United States",
      language:  "en",
      country_code: "us",
      temp: ".7"
    }
    modalOptions = defaultSettings;
  }

  for (var i = 0; i < keywordRow.length; i++) {
      keywordRow[i].map(keyword => {
        if(keyword.keyword !== '') {
          if(keyword.keyword.includes("?")) {
            var question = keyword.keyword.replace(/\s\?/, '');
            data.push(question);
          } else {
            data.push(keyword.keyword);
          }
        }
        return keyword;
      });
    }

    let exportOptions = { country_data: modalOptions, keywords: data }

    await axios.post(process.env.REACT_APP_ADWORDS_API, exportOptions)
      .then(res => {
        if(res.status === 200) {
          for (var i = 0; i < res.data[0].length; i++) {
            var adwordsObj = {
                keyword: res.data[0][i].keyword.trim(),
                monthly_search_volume: res.data[0][i].keyword_info.search_volume,
                cpc: res.data[0][i].keyword_info.cpc,
                competition: res.data[0][i].keyword_info.competition,
                keyword_difficulty: res.data[0][i].keyword_properties.keyword_difficulty
            }
            adwordsResults.push(adwordsObj);
          }

        data.forEach(word => {
          var matchObj = {
            keyword: word,
            monthly_search_volume: "-"
          };
          res.data[0].filter(function(obj) {
            if(obj.keyword === word){
              matchObj.monthly_search_volume = obj.keyword_info.search_volume;
            }
            return obj;
          });
          matchedResults.push(matchObj);
        });

        //sort alphabetically needs testing to see if this gets overwritten by volume sorting
        let alpha = matchedResults.sort((a, b) => {
            var keywordA = a.keyword, keywordB = b.keyword;
            if(keywordA < keywordB)
              return -1;
            if(keywordA > keywordB)
              return 1;
            return 0;
        });

        //sort by volume greatest to lowest
        let highestToLowestVol = alpha.sort((a, b) => {
            if(a.monthly_search_volume === "-") {
              a.monthly_search_volume = 0;
            }
            return b.monthly_search_volume - a.monthly_search_volume
          });

        for (var x = 0; x < highestToLowestVol.length; x++) {
          if(highestToLowestVol[x].monthly_search_volume === "-" || highestToLowestVol[x].monthly_search_volume === null)
          {
              let splicedDash = highestToLowestVol.splice(x, 1);
              highestToLowestVol.push(splicedDash[0]);
          }
        }
        this.setState({ refreshCards: true }); //unmounts keyword cards for total info refresh

        // this.setState({ adwordsCardResults: highestToLowestVol });
        if(algorithmType === "category")
          this.setState({ categoryCardResults: highestToLowestVol });
        
        // if(algorithmType === "similar") 
        //   this.setState({ similarCardResults: highestToLowestVol });

        if(algorithmType === "variation")
          this.setState({ variationCardResults: highestToLowestVol });
        if(algorithmType === "related")
          this.setState({ relatedCardResults: highestToLowestVol });

        this.setState({ resultsReturned: true });
        this.setState({ searchButtonSubmitted: false });

        //append input value = searched term
        var appendInput = document.getElementById("appendSearch");
        if(appendInput)
          appendInput.value = this.state.searchTerm;

        if(!this.state.appendRunning) {
          this.updateKeywordRateLimit(this.state.searchTerm, highestToLowestVol.length).then(isLimitReached => {}); //moved this here to stop using keywords on error
          // this.handleMinuteRateLimit(keywordsPerMinuteMapping);
          // this.handleHourRateLimit(keywordsPerHourMapping);
        }
        console.log(highestToLowestVol);
        if(this.state.appendRunning)
           this.setState({appendRunning: false });

        if(this.state.adwordsApiFailed)
          this.setState({ adwordsApiFailed: false });

        if(this.state.gpt3Failed)
          this.setState({ gpt3Failed: false });

        if(this.state.refreshCards)
          this.setState({ refreshCards: false });

      } else {
        // clear results before searching again but keep container showing
        if(this.state.adwordsRetryCount >= 3) {
          var errorResults = [];
          for (var k = 0; k < keywordRow[0].length; k++) {
            keywordRow[0][k].monthly_search_volume = "-";
            errorResults.push(keywordRow[0][k]);
          }
          console.log("ALGORITHM TYPE: " + algorithmType);
          // this.setState({ adwordsCardResults: errorResults });
          if(algorithmType === "category"){
            this.setState({ categoryCardResults: errorResults });
          }

        
          // if(algorithmType === "similar") 
          //   this.setState({ similarCardResults: errorResults });

          if(algorithmType === "variation")
            this.setState({ variationCardResults: errorResults });

          if(algorithmType === "related")
            this.setState({ relatedCardResults: errorResults });
          // this.setState({ keywordRow: errorResults }); //does not need to be here anymore - was causing an issue
          if(!this.state.appendRunning) //do not want to reduce keywords when running appended
           this.updateKeywordRateLimit(this.state.searchTerm, errorResults.length).then(isLimitReached => {});
          
           if(modalOptions.country !== "United States" && modalOptions.language !== "en") // dont want to show volume error message if searching in US with en
            this.setState({ showAdwordsErrorMessage: true });

          this.setState({ resultsReturned: true });
          this.setState({ searchButtonSubmitted: false });
          if(this.state.appendRunning)
             this.setState({appendRunning: false });
 
          return; //moved this here to stop using keywords on error
        }

        this.setState({ adwordsApiFailed: true });
        this.setState({ adwordsRetryCount: this.state.adwordsRetryCount + 1 });

        //if GPT3 Fails
        if(keywordRow[0].length === 1) {
          this.setState({ adwordsCardResults: [] }); //only want results to clear when GPT3 fails
          this.setState({ keywordRow: [] });
          this.setState({ temporaryArray: [] });
          this.setState({ gpt3Failed: true });
          this.handleOpenAIRequest(this.state.searchTerm);
        } else {
          //else if adwords fails retry adwords with same GPT3 results
          this.handleAdwordsRetry(keywordRow);
          // return;
        }
      }
  }).catch(err => {
      console.log(err);
  });
  if(algorithmType === "related")
    this.setState({ relatedFinished: true });

  const toolTipRA = document.getElementById(`submitKeywordSearchAgain`);
  if(toolTipRA) new Tooltip(toolTipRA, { boundary: 'window' });
}

handleAdwordsRetry = async (keywordRow) => {
  await this.getOpenAIResultsVolume(keywordRow, this.state.selectedType.toLowerCase());
}

handleClearResults = () => {
    this.setState({ categoryCardResults: [] });
    this.setState({ variationCardResults: [] });
    // this.setState({ similarCardResults: [] });
    this.setState({ relatedCardResults: [] });
    this.setState({ categoryOGCardResults: [] });
    this.setState({ variationOGCardResults: [] });
    this.setState({ similarOGCardResults: [] });
    this.setState({ relatedOGCardResults: [] });

    this.setState({ adwordsCardResults: [] });
    this.setState({ keywordRow: [] });
    this.setState({ temporaryArray: [] });
    this.setState({ showOnboardingMessage: false });
    this.setState({ showKeywordContainer: false });
    this.setState({ searchButtonSubmitted: false });
    // this.setState({ searchTerm: ''});
    this.setState({ searchTermForGaEvent: "" }); //maybe uncomment this out later
    this.setState({ gatheringResults: "" });
}

//----------filter functions----------
handleArrayDuplicateFilter = (array) => {
    this.state.keywordRow.filter(function(word) {
      for (var i = 0; i < word.length; i++) {
        for (var r = 0; r < array.length; r++) {
          if(array[r] === word[i]) {
             array.splice(r, 1);
          }
        }
      }
      return array;
    });
    return array;
}

handleKeywordLengthFilter = (array) => {
  // var avgKeywordLength = 0;
  var suggestions = [];
  this.state.keywordRow.filter(function(word) {
      var keywordLengthTotal = 0;
      for (var i = 0; i < word.length; i++) {
        suggestions.push(word[i].keyword.length);
      }
      suggestions.forEach(length => {
        keywordLengthTotal += length;
      })
      var avg = keywordLengthTotal / suggestions.length;
      // if(suggestions.length >= 10) { //think this was here because we thought the more suggestions it has, it's more likely to mess up.
        //see if suggestion length is 2x greater than the avg length.
        var checkLength = avg * 2;
        for (var r = 0; r < array[0].length; r++) {
            if(array[0][r].keyword.length >= checkLength){
              array[0].splice(r, 1);
            }
        }
      // }
      return array; // CHECK BACK LATER TO SEE IF THIS MESSES WITH FILTER
    });

}

handleContentFilter = (rating) => {
  let rank = rating.reduce((a, b) => Math.max(a, b), -Infinity);
  
    if(rank === 0) {
      this.setState({ contentFilterRank: 0 });
      this.setState({ updateCanViewContentFilterAlert: false });
      this.setState({ viewFilteredContent: true });
    } else if(rank === 1) {
      this.setState({ contentFilterRank: 1 });
      this.setState({ updateCanViewContentFilterAlert: true });
      this.setState({ viewFilteredContent: true });
      this.setState({ showOverlay: true });
      // this.setState({ viewFilteredContent: false });
    }
    else if(rank === 2){
      this.setState({ contentFilterRank: 2 });
      this.setState({ updateCanViewContentFilterAlert: true });
      this.setState({ viewFilteredContent: true });
      this.setState({ showOverlay: true });
      // this.setState({ viewFilteredContent: false});
    }
}

viewResponse = (event) => {
    event.preventDefault();
    // this.getOpenAIResultsVolume(this.state.temporaryArray); -- no longer need to call this since we are showing results
    // this.setState({ viewFilteredContent: true });
    this.setState({ updateCanViewContentFilterAlert: false });
    this.setState({ showOverlay: false });
}

//----------rate limit functions----------

handleMinuteRateLimit = (keywords) => {
  var totalPerMinute = 0;
  var totalToLimit;
  var firstSearch = JSON.parse(localStorage.getItem(LOCALSTORAGE.firstKeywordSearch));

  if(firstSearch) {
    totalToLimit = 31;
  } else {
    totalToLimit = 30;
  }

  for (var i = 0; i < keywords.length; i++) {
      totalPerMinute += keywords[i].length;
      if(totalPerMinute > totalToLimit)
      {
        if(totalToLimit === 31) {
          if(totalPerMinute === 33) {
            keywords[i].splice(1, i);
          } else if(totalPerMinute === 32) {
              if(keywords[i].length === 3)
              {
                //totalPerMinute must be 8
                keywords[i].splice(2, i);
              }
              else if(keywords[i].length === 2)
              {
                //totalPerMinute must be 9
                keywords[i].splice(1, i);
              }
          }
        }
        else if(totalToLimit === 30) {
          if(totalPerMinute === 32) {
              if(keywords[i].length === 3)
              {
                //totalPerMinute must be 8
                keywords[i].splice(1, i);
              }

          } else if(totalPerMinute === 31) {
            //trying to = 9 down here since the first search term is not included
            if(keywords[i].length === 3)
            {
              //totalPerMinute must be 7
              keywords[i].splice(2, i);
            }
            else if(keywords[i].length === 2)
            {
              //totalPerMinute must be 8
              keywords[i].splice(1, i);
            }
        }
      }
    }
  }

  if(totalPerMinute >= totalToLimit && this.state.running)
  {
    localStorage.setItem(LOCALSTORAGE.keywordsPerMinute, true);
    const keywordsPerMinute = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordsPerMinute));
    this.setState({ rateLimitPerMinuteHit: keywordsPerMinute });
    localStorage.setItem(LOCALSTORAGE.firstKeywordSearch, false);
  }
  else
  {
    localStorage.setItem(LOCALSTORAGE.keywordsPerMinute, false);
    const resetKeywordsPerMinute = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordsPerMinute));
    this.setState({ rateLimitPerMinuteHit: resetKeywordsPerMinute });
  }
}

handleHourRateLimit = (keywords) => {
  var totalPerHour = 0;

  for (var i = 0; i < keywords.length; i++) {
    totalPerHour += keywords[i].length;
  }

  localStorage.setItem(LOCALSTORAGE.keywordPerHourArray, JSON.stringify(keywords));
  if(totalPerHour >= 150 && this.state.hourRunning)
  {
    localStorage.setItem(LOCALSTORAGE.keywordsPerHour, true);
    const keywordsPerHour= JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordPerHourArray));
    this.setState({ rateLimitPerHourHit: keywordsPerHour });
  }
  else
  {
    localStorage.setItem(LOCALSTORAGE.keywordsPerHour, false);
    const resetKeywordsPerHour = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordsPerHour));
    this.setState({ rateLimitPerHourHit: resetKeywordsPerHour });
  }
}

handleNonProPlanLimiting = (array) => {
  var totalKeywords = 0;
  for (var i = 0; i < array.length; i++) {
    totalKeywords += array[i].length;
    if(totalKeywords >= 21) { //21 includes searched keyword
        // localStorage.setItem(LOCALSTORAGE.ksr, true);
      if(totalKeywords === 23) {
        array[i].splice(1, i);
      }
      else if(totalKeywords === 22) {
        if(array[i].length === 3) {
          array[i].splice(2, i);
        } else if(array[i].length === 2) {
          array[i].splice(1, i);
        }
      }
    }
  }
}

isRateLimitReached = async (searchTerm) => {
  const response = await IpRateLimitingApi();
  const rateLimitResponseMapping = {
    "SUCCESS": () => false, // * Daily limit not reached
    "FAILURE": () => true, // * Daily limit reached
    default: () => false // * default to daily limit not reached if api fails
  };
  return await response && response.status && rateLimitResponseMapping[response.status]
    ? rateLimitResponseMapping[response.status]()
    : rateLimitResponseMapping['default']();

}

updateKeywordRateLimit = async (searchTerm, keywords) => {
  const response = await KwtRateLimitingApi(searchTerm, keywords);
  const rateLimitResponseMapping = {
    200: () => false, // * Daily limit not reached
    429: () => true, // * Daily limit reached
    default: () => false // * default to daily limit not reached if api fails
  };
  this.handleRemainingKwtSearches();
  return await response && response.status && rateLimitResponseMapping[response.status]
    ? rateLimitResponseMapping[response.status]()
    : rateLimitResponseMapping['default']();

}

handleRemainingKwtSearches = async () => {
  const response = await RemainingKwtUsageStatus();
  if(response) {
    if(response.kwtSearchesLeft === null) {
      response.kwtSearchesLeft = 0;
      this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
    } else {
      this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
    }
  }
}

handleUserTokenStatus = async () => {
  const response = await AuthTokenStatus(JSON.parse(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)).token);
  if(response?.data.status === "SUCCESS") {
    this.handleRemainingKwtSearches();
  } else {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel('', '', null, null, 1, null, 1, null)));
      localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, false);
      localStorage.setItem(LOCALSTORAGE.isFirstSerpSearch, true);
    }
    updateSignedInStatusSubject.update(false);
    this.props.updateGateContent('signIn');
    this.props.updateShowAccountGate(true);
  }
}

//----------csv functions----------

handleAdwordsApiRequest = () => {
  let data = [];
  let modalOptions = {
    country: "",
    language: ""
  };

  if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined
  && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null
  && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
    var jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
    modalOptions.country = jsonObj.country;
    modalOptions.language = jsonObj.language;
  } else {
    modalOptions.country = "United States";
    modalOptions.language = "en";
  }

  let selectedAlgo = this.state.selectedType.toLocaleLowerCase();
  let keywordMapping;
  if(selectedAlgo === "category"){
     keywordMapping = this.state.categoryCardResults;
  } else if(selectedAlgo === "similar") {
    keywordMapping = this.state.similarCardResults;
  } else if(selectedAlgo === "variation") {
    keywordMapping = this.state.variationCardResults;
  } else if (selectedAlgo === "related") {
    keywordMapping = this.state.relatedCardResults;
  }
  keywordMapping.map(keyword => {
    if(this.state.appendTerm !== "") {
      var newKeyword;
      if(this.state.append && !keyword.keyword.includes(this.state.appendTerm))
        newKeyword = keyword.keyword + " " + this.state.appendTerm;
      else 
        newKeyword = keyword.keyword;

      data.push(newKeyword);
      keyword.keyword = newKeyword;
      return keyword;
    } else {
      if(keyword.keyword.includes("?")) {
        var question = keyword.keyword.replace(/\s\?/, '');
        data.push(question);
      } else {
        data.push(keyword.keyword);
      }
      return keyword;
    }
  });
  
    // for (var i = 0; i < keywordMapping.length; i++) {
    //   keywordMapping[i].map(keyword => {
    //     if(this.state.appendTerm !== "") {
    //       var newKeyword = keyword.keyword + " " + this.state.appendTerm;
    //       data.push(newKeyword);
    //       keyword.keyword = newKeyword;
    //       return keyword;
    //     }
    //     else {
    //       if(keyword.keyword.includes("?")) {
    //         var question = keyword.keyword.replace(/\s\?/, '');
    //         data.push(question);
    //       } else {
    //         data.push(keyword.keyword);
    //       }
    //       return keyword;
    //     }
    //   });
    // }

  let exportOptions = {
    country_data: modalOptions,
    keywords: data
  }

  let adwordsResults = [];
   axios.post(process.env.REACT_APP_ADWORDS_API, exportOptions)
    .then(res => {
     if(res.status === 200) {
        for (var i = 0; i < data.length; i++) {
          var adwordsObj = {
            keyword: res.data[0][i]?.keyword || data[i],
            monthly_search_volume: res.data[0][i]?.keyword_info.search_volume || 0,
            cpc: res.data[0][i]?.keyword_info.cpc || null,
            competition: res.data[0][i]?.keyword_info.competition|| null,
            keyword_difficulty: res.data[0][i]?.keyword_properties.keyword_difficulty || null
          }
          adwordsResults.push(adwordsObj);
        }

        this.createAdwordsCSVObj(adwordsResults);
      } else {
        //show new adwords message
        this.setState({ showAdwordsErrorMessage: true });
      }

    }).catch(err => {
      console.log(err);
    });
}

createAdwordsCSVObj = (data) => {
  const fileName = 'keywords';
  const headers = {
    keyword: 'keyword',
    monthly_search_volume: 'monthly search volume',
    cpc: 'cpc',
    competition: 'competition',
    keyword_difficulty: 'keyword difficulty'
  };
  let highestToLowestVol = data.sort((a, b) => {
      return b.monthly_search_volume - a.monthly_search_volume
    });
  let values = [...highestToLowestVol.values()];
  this.exportAdwordsCSVFile(headers, values, fileName);
}

exportAdwordsCSVFile = (headers, keywords, fileName) => {
  if(headers) keywords.unshift(headers);
  const jsonObject = JSON.stringify(keywords);
  const csv = this.convertToCSV(jsonObject);
  const exportedFileName = fileName + '.csv' || 'export.csv';
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  // const blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8,%EF%BB%BF'});

  if(navigator.msSaveBlob)
  {
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if(link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

convertToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ',';
      line += array[i][index];
    }
    str += line + '\r\n';
  }
  return str;
}

//----------modal functions----------

handleCategoryState = (algorithm) => {
   this.setState({ currentAlgorithm: algorithm });
}

handleShowKeywordSettingsModal = () => {
  this.props.updateShowKeywordSettingsModal(!this.props.showKeywordSettingsModal);
}

temperatureInput = (event) => {
    this.setState({ temperatureInput: event.currentTarget.value });
    this.setState({ temperature: this.state.temperatureInput});
}

temperatureRange = (event) => {
  if(event && this.state.showDeduplicationAlert)
    this.setState({ showDeduplicationAlert: false });

    this.setState({ temperature: event.currentTarget.value });
}

//----------redirect functions----------

linkToPricingPage = () => {
  ReactGA.event({ category: 'rate limiting - wst', action: 'click on upgrade', label: GetAuthUserId() });
  this.props.history.push({ pathname: '/pricing' });
}

linkToFreeTrialPage = () => {
  // ReactGA.event({ category: 'rate limiting - wst', action: 'click on upgrade', label: GetAuthUserId() });
  this.props.history.push({ pathname: '/free-trial-page'})
}

// linkToKeywordRedirectPage = () => {
//   this.props.history.push({ pathname: '/GPT3-invite' });
// }

//side panel
handleOpenPanel = () => {
  this.setState({panelWidth: 'block'});
  this.setState({openBtn: 'none'});
}

handleClosePanel = () => {
    this.setState({panelWidth: 'none'});
    this.setState({openBtn: ''});
  }

//----------alert functions----------
gpt3KeywordSuggestionsGaEvent = (bubbleText) => {
  ReactGA.event({
    category: 'Keyword Suggestions',
    action: 'Response Clicks',
    label: `${this.state.searchTermForGaEvent}, ${bubbleText}`
  });
}

displayAccountGate = () => {
  if (DisplayAccountGateCheck() && !this.props.displayGateTimer) {
    const displayGateTimer = setTimeout(() => {
      if(localStorage.getItem(LOCALSTORAGE.usrAuthStorage)) {
          this.props.updateGateContent('signIn');
      } else {
          this.props.updateGateContent('createAccount');
      }
      this.props.updateShowAccountGate(true);
    }, 1000);
    this.props.updateDisplayGateTimer(displayGateTimer);
  }
}

closeAlert = () => { //closes filter alert
  this.setState({ updateCanViewContentFilterAlert: false });
  this.setState({ searchButtonSubmitted: false });
  this.setState({ showOverlay: false });
  setTimeout(() => {
    this.setState({ resultsReturned: true });
  },1000);


}

closeDeduplicationAlert = () => { 
  this.setState({ showDeduplicationAlert: false });
}

closeCreateAccountAlert = () => {
  this.setState({ showCreateAccountMessage: false });
}

closeRateLimitAlert = () => {
  this.setState({ showRateLimitAlert: false });
}

closeAdwordsAlert = () => {
  this.setState({ showAdwordsErrorMessage: false });
}

getFlagIcon = () => {
  var jsonObj;
  if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined
  && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null
  && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
     jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
  }
      //<span> {langCode.toLowerCase()} <i className={`${flagCode} flag`}></i></span>
  let flagCode = 'us';
  // let langCode = 'english';
  if (jsonObj) {
    const findCountryCode = COUNTRIES.find(country => country.country_name === jsonObj.country);
    // const findLanguageCode = LANGUAGES.find(lang => lang.language_code === jsonObj.language);

    flagCode = findCountryCode
      ? findCountryCode.country_code
      : jsonObj.country_code
        ? jsonObj.country_code
        : 'us';
    // langCode = findLanguageCode
    //   ? findLanguageCode.language_name
    //     : jsonObj.language
    //       ? jsonObj.language
    //       : 'english';
  }
  return flagCode === 'aq'
    ? <img
      src={'https://www.countryflags.io/aq/flat/16.png'}
      style={{marginRight: '.5em'}}
      alt={flagCode}/>
    : <span> <i className={`${flagCode} flag`}></i></span>

}

handleRetryScrollToTop = (e) => {
    window.scrollTo(0, 0);
    var id = e.target.getAttribute('aria-describedby');
    var toolTip = document.getElementById(id);
    if(toolTip) toolTip.remove();
}

//----------APPEND & algorithm functions----------
handleAppendInputChange = (event) => {
  const value = event.target.value;
  if(value.length >= 175) {
    event.preventDefault();
    alert("only 175 max characters allowed");
  } else {
    this.setState({ appendTerm: value });
  }
}

handleShowAppendInput = (e) => {
  this.setState({ append: !this.state.append });
}

handleAppendSearch = (e) => {
  e.preventDefault();
  if(this.state.appendTerm === "")
    return;

  var selectedAlgo = this.state.selectedType.toLocaleLowerCase();
  var appendArray = [];
  this.setState({appendRunning: true });
  let keywordMapping;
  if(selectedAlgo === "category"){
     keywordMapping = this.state.categoryCardResults;
     if(this.state.categoryOGCardResults.length === 0)
       this.setState({categoryOGCardResults: this.state.categoryCardResults });
  } else if(selectedAlgo === "similar") {
    keywordMapping = this.state.similarCardResults;
    if(this.state.similarOGCardResults.length === 0)
      this.setState({ similarOGCardResults: this.state.similarCardResults });
  } else if(selectedAlgo === "variation") {
    keywordMapping = this.state.variationCardResults;
    if(this.state.variationOGCardResults.length === 0)
      this.setState({ variationOGCardResults: this.state.variationCardResults });
  } else if (selectedAlgo === "related") {
    keywordMapping = this.state.relatedCardResults;
    if(this.state.relatedOGCardResults.length === 0)
      this.setState({ relatedOGCardResults: this.state.relatedCardResults });
  }

  var resultsMap = keywordMapping.map(res => {
    var appendedKeyword = {
      keyword: "",
      monthly_search_volume: ""
    };

    if(res.keyword.includes(this.state.lastAppendTerm) && this.state.lastAppendTerm !== "" && this.state.appendTerm !== "") {
        if(this.state.isAppend)
          appendedKeyword.keyword = res.keyword.replace(this.state.lastAppendTerm, "").trim() + " " + this.state.appendTerm.toLowerCase();
        else 
          appendedKeyword.keyword = `${this.state.appendTerm.toLowerCase() + " " + res.keyword.replace(this.state.lastAppendTerm, "").trim()}`

    } else {
      if(this.state.isAppend)
        appendedKeyword.keyword = res.keyword.trim()  + " " + this.state.appendTerm.toLowerCase();
      else
        appendedKeyword.keyword = this.state.appendTerm.toLowerCase()  + " " +  res.keyword.trim();
    }
    return appendedKeyword;
  });

  this.setState({ lastAppendTerm: this.state.appendTerm });
  //IF we end up doing both at the same time ----------
      // if(this.state.isAppend) {
    //   if(res.keyword.includes(this.state.lastAppendTerm) && this.state.lastAppendTerm !== "" && this.state.appendTerm !== "")
    //       appendedKeyword.keyword = res.keyword.replace(this.state.lastAppendTerm, this.state.appendTerm.toLowerCase());
    //     else {
    //       appendedKeyword.keyword = res.keyword + " " + this.state.appendTerm.toLowerCase();
    //     }
    // } else {
    //   if(res.keyword.includes(this.state.lastPrependTerm) && this.state.lastPrependTerm !== "" && this.state.appendTerm !== "")
    //       appendedKeyword.keyword = res.keyword.replace(this.state.lastPrependTerm, this.state.appendTerm.toLowerCase());
    //     else
    //       appendedKeyword.keyword = `${this.state.appendTerm.toLowerCase() + " " + res.keyword}`
    //       // this.state.appendTerm.toLowerCase() + " " +  res.keyword;
    // }
  // if(this.state.isAppend) { 
  //   this.setState({ lastAppendTerm: this.state.appendTerm });
  // } else {
  //   this.setState({ lastPrependTerm: this.state.appendTerm });
  // }

  appendArray.push(resultsMap);
  this.getOpenAIResultsVolume(appendArray, selectedAlgo);
  this.setState({ showClearAppendBtn: true });
}

handleSelectAlgorithm = (e) => {
  var algBtns = document.querySelectorAll(".keyword-object");
  algBtns.forEach(btn => {
    if(btn === e.currentTarget) {
      if(!e.currentTarget.classList.contains("algorithm-selected")) {
        e.currentTarget.classList.add("algorithm-selected");
      }
      this.handleCategoryState(e.currentTarget.innerText);
    } else {
      if(btn.classList.contains("algorithm-selected")) {
        btn.classList.remove("algorithm-selected");
      }
    }
  })
}

handleCurrentSelectedAlgorithm = () => {
  var algBtns = document.querySelectorAll(".keyword-object");
  algBtns.forEach(btn => {
    if(this.state.currentAlgorithm === btn.innerText) {
      btn.classList.add("algorithm-selected");
    } else {
      if(btn.classList.contains("algorithm-selected")) {
        btn.classList.remove("algorithm-selected");
      }
    }
  });
}

handleClearAppend = () => {
  var selectedAlgo = this.state.selectedType.toLocaleLowerCase();
  if(selectedAlgo === "category"){
    this.setState({ categoryCardResults: this.state.categoryOGCardResults });
    this.setState({ categoryOGCardResults: [] });
 } else if(selectedAlgo === "similar") {
    this.setState({ similarCardResults: this.state.similarOGCardResults });
    this.setState({ similarOGCardResults: []});
 } else if(selectedAlgo === "variation") {
    this.setState({ variationCardResults: this.state.variationOGCardResults });
    this.setState({ variationOGCardResults: []});
 } else if (selectedAlgo === "related") {
    this.setState({ relatedCardResults: this.state.relatedOGCardResults });
    this.setState({ relatedOGCardResults: []});
 }

}

//----------side drawer functions----------
showCanvas = (keyword) => {
  if(this.state.canvas === "" || this.state.canvas === "hide"){
    if(keyword !== this.state.cardKeyword){
      //TODO CHART DATA
      // if(this.state.showChartSkeleton === false)
      //     this.setState({ showChartSkeleton: true });
      // this.handleChartData(keyword);
      this.props.updateKeywordDrawerImage("");
      // this.handleFetchSerpImage(keyword);
    }

    setTimeout(() => {
      this.setState({ canvas: "show" });
    }, 100);
    this.setState({ cardKeyword: keyword });

  }
}

hideCanvas = () => {
  this.setState({ canvas: "hide" });
}

handleCanvasSearch = (e) => {
  const input = document.getElementById("KeywordSearch");
  input.value = this.state.cardKeyword;
  this.setState({ searchTerm: this.state.cardKeyword });
  this.setState({ canvas: "hide" });
  this.handleSubmit(e);
}

parseQueryString = () => {
  const queryParams = queryString.parse(this.props.location.search);
  if (this._isMounted && queryParams.searchTerm) {
    setTimeout(() => {
       this.setState({ currentAlgorithm: queryParams.algorithm });
     }, 100);
     // this.setState({ currentAlgorithm: queryParams.algorithm });
    this.setState({ searchTerm: queryParams.searchTerm }, () => this.handleSubmit(null));
    this.setState({ isQueryParam: true });
  }
}

// handleFetchSerpImage = (keyword) => {
//   const params = {
//     search_type: "images",
//     searchTerm: keyword,
//     gl: this.props.serpCountry,
//   }
//   axios.post(process.env.REACT_APP_CHART_API + "/googleImage", params)
//     .then(response => {
//       if(response) {
//         var firstImg;
//         for (var i = 0; i < response.data?.image_results.length; i++) {
//           if(!response.data?.image_results[i].image.includes("wiki")) {
//             firstImg = response.data?.image_results[i].image;
//             break;
//           }
//         }
//         this.setState({ googleSerpImage: firstImg });
//       }
//     }).catch(err => {
//       console.log(err);
//     });
// }

// handleChartData = async (keyword) => {
//   const params = {
//     searchTerm: keyword,
//   }
//
//   let oldCanvas = document.getElementById("chart");
//   if(oldCanvas){
//     oldCanvas.remove();
//   }
//   let chartStatus = Chart.getChart("chart"); // second
//   if(chartStatus !== undefined) {
//         console.log(chartStatus);
//     chartStatus.destroy();
//         console.log("chart destroyed");
//   }
//   axios.post(process.env.REACT_APP_CHART_API + "/googleTrends", params)
//     .then(response => {
//       if(response) {
//         // let chartStatus2 = Chart.getChart("chart"); // second
//         // if(chartStatus2 !== undefined) {
//         //       console.log(chartStatus2);
//         //   chartStatus2.destroy();
//         //       console.log("chart 2 destroyed");
//         // }
//         //
//         // let oldCanvas2 = document.getElementById("chart");
//         // if(oldCanvas2){
//         //   oldCanvas2.remove();
//         // }
//         let canvas = document.createElement('canvas');
//         canvas.setAttribute('id','chart');
//         canvas.classList.add("none");
//         var context = canvas.getContext('2d');
//         context.clearRect(0,0, canvas.width, canvas.height);
//         document.querySelector('#chart-container').appendChild(canvas);
//
//         var resultSet = [];
//         var trendData;
//         for (var i = 0; i < response.data?.trendData.result[0].items.length; i++) {
//           if(response.data?.trendData.result[0].items[i].type === "google_trends_graph"){
//             trendData = response.data?.trendData.result[0].items[i].data;
//           }
//         }
//         for (var x = 0; x < trendData.length; x++) {
//           if(trendData[x].values[0] !== null){
//             var xy = {
//               x: trendData[x].date_to,
//               y: trendData[x].values[0]
//             }
//             resultSet.push(xy);
//           }
//         }
//
//         const totalDuration = 4000;
//         const delayBetweenPoints = totalDuration / resultSet.length;
//         const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
//         const animation = {
//           x: {
//             type: 'number',
//             easing: 'linear',
//             duration: delayBetweenPoints,
//             from: NaN, // the point is initially skipped
//             delay(ctx) {
//               if (ctx.type !== 'data' || ctx.xStarted) {
//                 return 0;
//               }
//               ctx.xStarted = true;
//               return ctx.index * delayBetweenPoints;
//             }
//           },
//           y: {
//             type: 'number',
//             easing: 'linear',
//             duration: delayBetweenPoints,
//             from: previousY,
//             delay(ctx) {
//               if (ctx.type !== 'data' || ctx.yStarted) {
//                 return 0;
//               }
//               ctx.yStarted = true;
//               return ctx.index * delayBetweenPoints;
//             }
//           }
//         };
//     // var chart =
//     new Chart(context, {
//           type: "line",
//           data: this.chartJsData(resultSet),
//           options: {
//             animation,
//             interaction: {
//               interset: false
//             },
//             responsive: true,
//             plugins: {
//                 title: {
//                   display: true,
//                   text: 'Interest over time',
//                   align: "start",
//                   padding: 15,
//                   font: {
//                     family: "itc-avant-garde-gothic-pro",
//                     weight: 'normal'
//                     }
//                 },
//                 legend: {
//                   display: false
//                 },
//               },
//             scales: {
//               x: {
//                 ticks: {
//                   display: false,
//                 },
//                 grid: {
//                   display: false
//                 }
//               },
//               y: {
//                 ticks: {
//                   display: false
//                 },
//                 grid: {
//                   display: false
//                 }
//               }
//           }
//           },
//           scales: {
//             x: {
//               type: "time",
//               time: {
//                 parser: true,
//                 unit: "month",
//                 tooltipFormat: "D MMM, YYYY",
//               },
//           },
//         }
//       });
//       // chart.update();
//       canvas.classList.remove("none");
//       this.setState({ showChartSkeleton: false });
//     }
//     }).catch(err => {
//       console.log(err);
//     });
// }

// chartJsData = (resultSet) => {
//   return {
//     datasets: [
//       {
//         data: resultSet,
//         pointBorderColor: 'rgba(0, 0, 0, 0)',
//         pointBackgroundColor: 'rgba(0, 0, 0, 0)',
//         pointHoverBackgroundColor: 'rgb(54, 162, 235)',
//         pointHoverBorderColor: 'rgb(54, 162, 235)',
//       }
//     ],
//   };
// };

copyToClipboard = async () => {
  if(navigator.clipboard) {
    let copyUrl = window.location + "?searchTerm=" + this.state.searchTerm;
    navigator.clipboard.writeText(copyUrl).then(() => {
      console.log("url copied! " + copyUrl);
    }).catch(() => {
      console.log("copy url failed");
    });
  }
}
//-----------TAB CHANGE FUNCTIONS-----------

handleKeywordTabChange = (e) => {
//change keyword display here
  e.preventDefault();
  this.setState({ selectedType: e.currentTarget.innerText });
}

isCategoryTabSelected = (type) => {
  const keywordTypeSelectedMapping = {
    Category: () => 'active',
    Similar: () => '',
    Variation: () => '',
    Related: () => ''
  };
  return keywordTypeSelectedMapping[type] 
  ? keywordTypeSelectedMapping[type]() : '';
}

// isSimilarTabSelected = (type) => {
//   const keywordTypeSelectedMapping = {
//     Category: () => '',
//     Similar: () => 'active',
//     Variation: () => '',
//     Related: () => ''
//   };
//   return keywordTypeSelectedMapping[type] 
//   ? keywordTypeSelectedMapping[type]() : '';
// }

isVariationTabSelected = (type) => {
  const keywordTypeSelectedMapping = {
    Category: () => '',
    Similar: () => '',
    Variation: () => 'active',
    Related: () => ''
  };
  return keywordTypeSelectedMapping[type] 
  ? keywordTypeSelectedMapping[type]() : '';
}

isRelatedTabSelected = (type) => {
  const keywordTypeSelectedMapping = {
    Category: () => '',
    Similar: () => '',
    Variation: () => '',
    Related: () => 'active'
  };
  return keywordTypeSelectedMapping[type] 
  ? keywordTypeSelectedMapping[type]() : '';
}
//-----------TAB CONTENT-----------
isCategoryTabContentSelected = (type) => {
  const keywordTypeSelectedMapping = {
    Category: () => 'fade show active',
    Similar: () => '',
    Variation: () => '',
    Related: () => ''
  };
  return keywordTypeSelectedMapping[type] 
  ? keywordTypeSelectedMapping[type]() : '';
}

// isSimilarTabContentSelected = (type) => {
//   const keywordTypeSelectedMapping = {
//     Category: () => '',
//     Similar: () => 'fade show active',
//     Variation: () => '',
//     Related: () => ''
//   };
//   return keywordTypeSelectedMapping[type] 
//   ? keywordTypeSelectedMapping[type]() : '';
// }

isVariationTabContentSelected = (type) => {
  const keywordTypeSelectedMapping = {
    Category: () => '',
    Similar: () => '',
    Variation: () => 'fade show active',
    Related: () => ''
  };
  return keywordTypeSelectedMapping[type] 
  ? keywordTypeSelectedMapping[type]() : '';
}

isRelatedTabContentSelected = (type) => {
  const keywordTypeSelectedMapping = {
    Category: () => '',
    Similar: () => '',
    Variation: () => '',
    Related: () => 'fade show active'
  };
  return keywordTypeSelectedMapping[type] 
  ? keywordTypeSelectedMapping[type]() : '';
}

handleAppendOrPrepend = () => {
  this.setState({isAppend: !this.state.isAppend });
}

increaseProgressBar = () => {
  let multiplier;
  if(this.state.selectedKeywordSize === 15) {
    multiplier = 7;
  } else if(this.state.selectedKeywordSize === 30) {
    multiplier = 6;
  } else if(this.state.selectedKeywordSize === 60) {
    multiplier = 5;
  } else if(this.state.selectedKeywordSize === 90) {
    multiplier = 4;
  }

  let progressBarInterval;
  let randomMilliseconds = 500;
  const millisecondsArry = [4000, 7000, 10000];

  if (this.state.progressPercentage <= this.state.percentageHangingState) {
    progressBarInterval = setInterval(() => {
      const randomNum = Math.floor(Math.random() * multiplier);
      // const randomNum = 25;
      randomMilliseconds = millisecondsArry[Math.floor(Math.random() * millisecondsArry.length)];
      if (this._isMounted){
        if(this.state.progressPercentage >= this.state.percentageHangingState)
          this.setState({progressPercentage: 99 });
        else
           this.setState({progressPercentage: this.state.progressPercentage + randomNum });
      }
      if (this.state.progressPercentage >= this.state.percentageHangingState) clearInterval(progressBarInterval);
    }, randomMilliseconds);
  }

    if(this.state.progressPercentage >= 99) {
      setTimeout(() => {
        this.setState({progressPercentage: 100 });
    }, 1000);
    } 
}

render() {
    return (
        <div className="row mb-2">
          <KeywordAlerts 
            temperature={this.state.temperature}
            duplicationAlert={this.state.showDeduplicationAlert}
            showCreateAccountMessage={this.state.showCreateAccountMessage}
            showRateLimitAlert={this.state.showRateLimitAlert}
            showSettingsModal={this.handleShowKeywordSettingsModal}
            linkToPricingPage={this.linkToPricingPage}
            closeDeduplicationAlert={this.closeDeduplicationAlert}
            closeCreateAccountAlert={this.closeCreateAccountAlert}
            closeRateLimitAlert={this.closeRateLimitAlert}
          />
        {
          this.state.showSidePanel ?
          <div className="testing-parent">
            <div className="testing-div testing-div-outline" style={{display: `${this.state.panelWidth}`}}>
              <h6><b>Retries:</b>  { this.state.adwordsRetryCount } </h6>
              <h6><b>temperature:</b> {this.state.temperature}</h6>
              <h6><b>max tokens:</b> {this.state.max_tokens}</h6>
              <h6><b>response:</b> {this.state.res}</h6>
              <h6><b>tokens response:</b> {this.state.tokens_res}</h6>
              <h6><b>tokens prompt:</b> {this.state.tokens_prompt}</h6>
              <h6><b>language:</b> {this.state.dectectedLanguage}</h6>
              <h6 className="prompt"><b>prompt:</b> {this.state.prompt}</h6>
              <ul className="prompt-list">
                {this.state.promptQA.map((line, key) => {
                  return <li key={key}><h6>{line}</h6></li>
                })}
              </ul>
              <button onClick={() => {this.handleClosePanel()}}
                className="close-panel btn btn-outline-primary"><i className="fas fa-arrow-left"></i></button>
            </div>
            <button onClick={() => {this.handleOpenPanel()}}
              className="show-panel btn btn-outline-primary"
              style={{display: `${this.state.openBtn}`}}><i className="fas fa-arrow-right"></i></button>
          </div> : null
        }

        {
          this.props.showKeywordSettingsModal
          ? <KeywordSettingsModal
            temperature={this.state.temperature}
            handleSubmit={(e) => this.handleSubmit(e)}
            temperatureRange={(e) => this.temperatureRange(e)}
            currentAlgorithm={this.state.currentAlgorithm}
          />
          : null
        }

          <div className="col-sm-12 p-2">
            <form>
              <div className="input-group pb-2">
                <input
                  id="KeywordSearch"
                  type="text"
                  className="form-control"
                  placeholder={""}
                  aria-label="Search Term"
                  aria-describedby="Search Term"
                  value={this.state.searchTerm}
                  onChange={this.handleInputChange}/>
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  id="submitKeywordSearch"
                  data-ripple-color="dark"
                  onClick={(e) => this.handleSubmit(e)}>
                  Search
                </button>
              </div>
              <div className="row">
                {
                  GetAuthToken()
                    ? <div className="col-sm-12 pb-3 kw-settings">
                      <div className="float-left justify-content-between">
                        <span>
                          Use
                          <select
                            className="ml-1 mr-0 pt-1 pr-1 pb-1 pl-1"
                            onChange={(e) => this.setState({ selectedKeywordSize: Number(e.target.value) })}
                            value={this.state.selectedKeywordSize}>
                            {
                              this.state.keywordSizeArray.map((i, index) => {
                                return <option
                                        key={`sample-size${index}`}
                                        value={i}>
                                        {i}
                                      </option>
                              })
                            }
                          </select>
                        </span>
                        <span className="pt-1 pl-1 serp-psi-search-flag-copy body-small kw-settings">of your {this.props.remainingKwtSearches} Keywords</span>
                      </div>
                        <span
                            className="pl-1 pt-1 serp-psi-search-flag-copy body-small float-right">
                            { this.getFlagIcon() }
                            <u
                              className="align-middle"
                              onClick={() => this.handleShowKeywordSettingsModal()}><strong>settings</strong></u>
                      </span>
                    </div> : null
                }
              </div>
            </form>
            
            {
              this.state.showKeywordContainer && this.state.resultsReturned ? 
              <div className="d-flex justify-content-center">
                {this.state.resultsReturned ? 
                <KeywordTabs 
                  handleKeywordTabChange={this.handleKeywordTabChange}
                  selectedType={this.state.selectedType}
                  screenSize={this.props.screenSize}
                />: null}
            </div> 
            : null }
            <div className="row pl-2 pr-2 pb-5 pt-3">
            {
              this.state.showKeywordContainer
                ? <div className="col-sm-12 keyword-container pt-0" onClick={() => this.state.updateCanViewContentFilterAlert ? this.closeAlert() : null}>
                    <div className="relative-alert-panel">
                      {
                        this.state.updateCanViewContentFilterAlert ?
                        <div className="alert-container fade-in warning-container"> 
                          <div className="row d-flex justify-content-center">
                            <div className="col-sm-10 col-md-10 text-center">
                              {
                                this.state.contentFilterRank === 1
                                ? <div
                                  className="alert alert-warning alert-info alert-onboarding"
                                  role="alert"
                                  data-color="info"
                                  onClick={() => this.closeAlert()}>
                                <div className="d-flex justify-content-between">
                                  <span className="alert-copy">
                                    the response may contain sensitive content,
                                    consider adjusting your search terms to keep responses appropriate. <u onClick={(e) => this.viewResponse(e)}>view response</u>
                                  </span>
                                <span onClick={() => this.closeAlert()}> <i className="fas fa-times fa-sm alert-close"></i></span>
                                </div>
                              </div> : this.state.contentFilterRank === 2
                              ? <div
                                  className="alert alert-danger alert-info alert-onboarding"
                                  role="alert"
                                  data-color="info"
                                  onClick={() => this.closeAlert()}
                                  >
                                  <div className="d-flex justify-content-between">
                                    <span className="alert-copy">
                                      the response may contain unsafe content, consider adjusting your search terms to keep responses appropriate. <u onClick={(e) => this.viewResponse(e)}>view response</u>
                                    </span>
                                    <span onClick={() => this.closeAlert()}><i className="fas fa-times fa-sm alert-close"></i></span>
                                  </div>
                                </div> : null
                              }
                          </div>
                        </div>
                        </div>: null
                      }
                      {
                        this.state.showAdwordsErrorMessage ?
                          <div className="alert-container-right fade-in warning-container">
                            <div className="row d-flex justify-content-center">
                              <div className="col-sm-10 col-md-7 text-center">
                                <div
                                  className="alert alert-warning alert-onboarding"
                                  role="alert"
                                  data-color="info"
                                  onClick={() => this.closeAdwordsAlert()}>
                                  <div className="d-flex justify-content-between">
                                    <span className="alert-copy">
                                      {this.state.gpt3Failed ? "Uh oh!" : "No search volume for these keywords"} Try searching again. If that doesn't work, change your search term, and confirm the country and language settings.
                                    </span>
                                    <i className="fas fa-times fa-sm alert-close"></i>
                                  </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          : null
                      }
                    </div>
                      <div className="keyword-panel" style={{filter: `${this.state.showOverlay ? "blur(5px)" : "blur(0px)"}`, pointerEvents: `${this.state.showOverlay ? "none" : "auto"}`}}>
                        {/* {this.state.progressPercentage >= 100 ?    : null} */}
                        <div className="col-sm-12 keyword-inner-panel text-center">
                          {
                            !this.state.refreshCards && this.state.resultsReturned ?
                              <ul className="keyword-list">
                              {
                                this.state.categoryCardResults.length > 0 && this.state.relatedFinished
                                  ? <div className="row ml-0 mr-0 mb-0">
                                      <div className="col-sm-12 p-0 pb-4">
                                          {/* {
                                            this.state.relatedCardResults.length > 0 
                                              ? <div style={{width: "100%"}} className="d-flex justify-content-between">
                                                  <div onClick={(e) => this.handleShowAppendInput(e) }
                                                       className="float-left d-flex align-content-end flex-wrap">
                                                    <div><i class="pt-2 pr-2 fas fa-pen-square fa-2x kwt-edit-icon"></i></div>
                                                    <div>
                                                      <h5 className="pt-3 m-0 append-header">
                                                        <b>modify results {!this.state.append && !this.state.hideAppend? <i className="fas fa-caret-down"></i> : <i className="fas fa-caret-up"></i> }</b>
                                                      </h5>
                                                    </div>
                                                  </div> 
                                                  <div className="text-right">
                                                    <button
                                                      onClick={() => this.handleAdwordsApiRequest()}
                                                      className="btn pr-0 google-serp-export">
                                                      <KeywordExportToolTip />
                                                    </button>
                                                  </div>
                                              </div> : null
                                          } */}
                                          <div style={{width: "100%"}} className="d-flex justify-content-between">
                                                  <div onClick={(e) => this.handleShowAppendInput(e) }
                                                       className="float-left d-flex align-content-end flex-wrap">
                                                    <div><i class="pt-2 pr-2 fas fa-pen-square fa-2x kwt-edit-icon"></i></div>
                                                    <div>
                                                      <h5 className="pt-3 m-0 append-header">
                                                        <b>modify results {!this.state.append && !this.state.hideAppend? <i className="fas fa-caret-down"></i> : <i className="fas fa-caret-up"></i> }</b>
                                                      </h5>
                                                    </div>
                                                  </div> 
                                                  <div className="text-right">
                                                    <button
                                                      onClick={() => this.handleAdwordsApiRequest()}
                                                      className="btn pr-0 google-serp-export">
                                                      <KeywordExportToolTip />
                                                    </button>
                                                  </div>
                                              </div>
                                      </div>

                                      {
                                        this.state.append && !this.state.hideAppend
                                        ? <div className="p-0"> 
                                            <form className=" pb-2">
                                              <div className="input-group d-flex">
                                                <input
                                                    id="appendSearch"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={MediaMatcher() === "mobile" ? `${this.state.isAppend ? "enter text to append" : "enter text to prepend"}` : `${this.state.isAppend ? "enter text to append to each result" : "enter text to prepend to each result"}`}
                                                    aria-label="Append Term"
                                                    aria-describedby="Append Term"
                                                    value={this.state.appendTerm}
                                                    onChange={this.handleAppendInputChange}
                                                  />
                                                  <button
                                                    className="btn btn-outline-primary submit-append"
                                                    type="submit"
                                                    id="submitAppendSearch"
                                                    data-ripple-color="dark"
                                                    onClick={(e) => this.handleAppendSearch(e)}>
                                                    Apply
                                                  </button>
                                              </div>
                                            </form>
                                            <div className="toggle-switch-container float-left">
                                              <span style={{marginRight: "12px"}}>Prepend</span>
                                              <div className="form-check form-switch d-inline-block">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="device-type-switch"
                                                  value={this.state.isAppend}
                                                  checked={this.state.isAppend}
                                                  onChange={this.handleAppendOrPrepend}
                                                  />
                                                <label className="form-check-label" htmlFor="device-type-switch">
                                                <span>Append</span>
                                                </label>
                                              </div>
                                              {this.state.selectedType === "Category" && this.state.categoryOGCardResults.length > 0 ? 
                                                <span className="pl-4 clear-append-btn" onClick={() => this.handleClearAppend()}><u>clear</u></span> : null }
                                              {/* {this.state.selectedType === "Similar" && this.state.similarOGCardResults.length > 0 ? 
                                                <span className="pl-4 clear-append-btn" onClick={() => this.handleClearAppend()}><u>clear</u></span> : null } */}
                                              {this.state.selectedType === "Variation" && this.state.variationOGCardResults.length > 0 ? 
                                                <span className="pl-4 clear-append-btn" onClick={() => this.handleClearAppend()}><u>clear</u></span> : null }
                                              {this.state.selectedType === "Related" && this.state.relatedOGCardResults.length > 0 ? 
                                                <span className="pl-4 clear-append-btn" onClick={() => this.handleClearAppend()}><u>clear</u></span> : null }
                                            </div>
                                         </div> : null 
                                      }

                                      {
                                        this.state.showIdentifiedCategory
                                          ? <div className="pl-0 pr-0 pt-2 col-sm-12 d-flex justify-content-between">
                                              <span className="pt-0 mb-0 msv-text text-left">Identified Category: {this.state.categorySibTypeOrStyle}  </span>
                                              <span className="pt-0 msv-text text-right">monthly search volume</span>
                                          </div> : <div className="pr-0 pt-2"><span className="msv-text float-right">monthly search volume</span></div>
                                      }
                                  </div>
                                : null
                              }

                            <div>
                                <div className="tab-content">
                                  <div 
                                      className={`tab-pane ${this.state.selectedType ? this.isCategoryTabContentSelected(this.state.selectedType) : 'active'}`}
                                      id="category-content">       
                                      {
                                        this.state.categoryCardResults.map((key, index) => {
                                          return <li key={index}>
                                            <Suspense fallback={<div></div>}>
                                            <KeywordCard
                                              keyword={key.keyword}
                                              monthly_search_volume={key.monthly_search_volume}
                                              country={this.props.serpCountry}
                                              canvas={this.state.canvas}
                                              keywordBeingUsed={this.state.cardKeyword}
                                              appendRunning={this.state.selectedType === "Category" && this.state.appendRunning ? true : false}
                                              showCanvas={this.showCanvas}
                                            />
                                          </Suspense>
                                          </li>
                                        })
                                      }
                                  </div>
                                </div>
                                {/* <div className="tab-content">
                                  <div id="similar-content" className={`tab-pane ${this.state.selectedType ? this.isSimilarTabContentSelected(this.state.selectedType) : ''}`}>
                                  {
                                        this.state.similarCardResults.map((key, index) => {
                                          return <li key={index}>
                                            <Suspense fallback={<div></div>}>
                                            <KeywordCard
                                              keyword={key.keyword}
                                              monthly_search_volume={key.monthly_search_volume}
                                              country={this.props.serpCountry}
                                              canvas={this.state.canvas}
                                              keywordBeingUsed={this.state.cardKeyword}
                                              appendRunning={this.state.selectedType === "Similar" && this.state.appendRunning ? true : false}
                                              showCanvas={this.showCanvas}
                                            />
                                          </Suspense>
                                          </li>
                                        })
                                      }
                                  </div>
                                </div> */}
                                <div className="tab-content">
                                  <div id="variation-content" className={`tab-pane ${this.state.selectedType ? this.isVariationTabContentSelected(this.state.selectedType) : ''}`}>
                                  {
                                        this.state.variationCardResults.map((key, index) => {
                                          return <li key={index}>
                                            <Suspense fallback={<div></div>}>
                                            <KeywordCard
                                              keyword={key.keyword}
                                              monthly_search_volume={key.monthly_search_volume}
                                              country={this.props.serpCountry}
                                              canvas={this.state.canvas}
                                              keywordBeingUsed={this.state.cardKeyword}
                                              appendRunning={this.state.selectedType === "Variation" && this.state.appendRunning ? true : false}
                                              showCanvas={this.showCanvas}
                                            />
                                          </Suspense>
                                          </li>
                                        })
                                      }
                                  </div>
                                </div>
                                <div className="tab-content">
                                  <div id="related-content" className={`tab-pane ${this.state.selectedType ? this.isRelatedTabContentSelected(this.state.selectedType) : ''}`}>
                                  {
                                        this.state.relatedCardResults.map((key, index) => {
                                          return <li key={index}>
                                            <Suspense fallback={<div></div>}>
                                            <KeywordCard
                                              keyword={key.keyword}
                                              monthly_search_volume={key.monthly_search_volume}
                                              country={this.props.serpCountry}
                                              canvas={this.state.canvas}
                                              keywordBeingUsed={this.state.cardKeyword}
                                              appendRunning={this.state.selectedType === "Related" && this.state.appendRunning ? true : false}
                                              showCanvas={this.showCanvas}
                                            />
                                          </Suspense>
                                          </li>
                                        })
                                      }
                                  </div>
                                </div>                        
                              </div>


                            {
                               this.state.relatedFinished
                                  ? <div className="row ml-0 mr-0 mb-2">
                                      <div className="col-sm-12 p-0">
                                       <button
                                            id="submitKeywordSearchAgain"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-trigger='hover'
                                            title="run again, and you'll likely get different results"
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-ripple-color="dark"
                                            onClick={(e) => {
                                              this.handleSubmit(e)
                                              this.handleRetryScrollToTop(e);
                                            }}>
                                              <span className="fas fa-redo pr-1 retry"></span> RUN AGAIN
                                          </button>
                                      </div>
                                  </div>
                                : null
                            }

                            </ul> : <div className="d-flex justify-content-center align-middle col-sm-12 p-0">
                              <p className="kwt-powered mb-0">Gathering {this.state.gatheringResults} Results...</p>
                            </div> }
                        </div> 
                    </div>

                    { !this.state.resultsReturned && this.state.progressPercentage < 100 && !this.state.refreshCards
                        ? <div className="row d-flex justify-content-center">
                            <div className="col-sm-12 keyword-progress-container">
                              <div className="kp-bar">
                                <div className="keyword-progress progress">
                                  <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: this.state.progressPercentage + '%' }}
                                      aria-valuenow={this.state.progressPercentage}
                                      aria-valuemin="0"
                                      aria-valuemax="100">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> : null}
                  </div> : null
              }
              <KeywordDrawer
                canvas={this.state.canvas}
                cardKeyword={this.state.cardKeyword}
                hideCanvas={this.hideCanvas}
                handleSubmit={this.handleCanvasSearch}
                algorithm={this.state.currentAlgorithm}
                googleSerpImage={this.state.googleSerpImage}
                chart={this.state.currentChart}
                showChartSkeleton={this.state.showChartSkeleton}
               />
            </div>
          </div>
        </div>

      );
  }

  listenToScroll = () => {
      const scrolled = window.pageYOffset;
      if(scrolled < 300)
      {
        if(document.querySelector(".warning-container-fixed")) {
          var alert = document.querySelector(".warning-container-fixed");
          alert.classList.remove("warning-container-fixed");
          alert.classList.add("warning-container");
        }
      }
      else if(scrolled >= 300 && scrolled < 1000)
      {
          if(document.querySelector('.warning-container')) {
            var alert2 = document.querySelector(".warning-container");
            alert2.classList.remove("warning-container");
            alert2.classList.add("warning-container-fixed");
        }
      }
  }

  customTypeWriter = () => {
    var speed = 75;
    var deleteSpeed = 10;
    var delay = 5;
    const input = this.state.keywordInput;
    if(!this._isMounted) {
      clearTimeout(this.customTypeWriter);
      return;
    };
    if(this.state.keywordInput !== document.activeElement && this.state.keywordInput?.value?.length <= 0) { //make sure input is not focused by user & there is no input value
      if(!this.state.deletingText 
        && this.state.placeholderValue !== this.state.placeholderText.length 
        && this.state.count < this.state.placeholderText[this.state.placeholderValue].length + delay) {
          input.placeholder += this.state.placeholderText[this.state.placeholderValue].charAt(this.state.count);
                    
          this.setState({count: this.state.count + 1});
          setTimeout(this.customTypeWriter, speed);
      } else {
        this.setState({ deletingText: true });                                    //is deleting text 
        if(this.state.deletingText && this.state.count > -1) {
          input.placeholder = this.state.placeholderText[this.state.placeholderValue].substring(0, this.state.count);
          this.setState({count: this.state.count - 1});
          
          setTimeout(this.customTypeWriter, deleteSpeed);
        } else if(this.state.count <= 0) {                                        //if placeholder text empty change to next placeholder in array 
          if(this.state.placeholderValue >= this.state.placeholderText.length) {
            this.setState({placeholderValue: 0});                                 //if placedholder is at the end restart from 0
          } else {
            this.setState({ placeholderValue: this.state.placeholderValue + 1 }); //otherwise continue with array
          }
          this.setState({ count: -10 });                                        //for a short delay before typinh next placeholder
          this.setState({ deletingText: false });
          setTimeout(this.customTypeWriter, speed);
        }
      }
    } else if(this.state.keywordInput === document.activeElement) { 
        if(this.state.keywordInput.placeholder.length > 0 ) //if input is active stop placeholder text 
          input.placeholder = ""; 
        if(this.state.count > 0)                            //reset placeholder count to start from 0 when unfocusing input
          this.setState({count: 0 });
        setTimeout(this.customTypeWriter, speed);
    } else {
        //restart
        if(this.state.keywordInput?.placeholder.length > 0 ) //make sure placeholder text is clear for reset
          input.placeholder = ""; 
        if(this.state.count > 0)                            //make sure count is at 0 for reset
          this.setState({count: 0 });
        setTimeout(this.customTypeWriter, speed);
    }
  }

  componentDidMount() {
      // var int = {
      //   intersect: false
      // }
      // Chart.defaults.hover = int;
      this._isMounted = true;
      let input = document.getElementById("KeywordSearch");
      this.setState({keywordInput: input });
      
      this.customTypeWriter();

      window.addEventListener('scroll', this.listenToScroll, {passive: true});
      this.props.updateKeywordToolStatus(true);
      if(!GetAuthUserId()) this.displayAccountGate();
      if(GetAuthToken()) this.handleUserTokenStatus();

      this.parseQueryString();

      setTimeout(() => {
        this.handleCurrentSelectedAlgorithm();
      },200);

      if(localStorage.getItem(LOCALSTORAGE.keywordSettings) !== undefined
      && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== null
      && localStorage.getItem(LOCALSTORAGE.keywordSettings) !== "") {
        var jsonObj = JSON.parse(localStorage.getItem(LOCALSTORAGE.keywordSettings));
        var settingsObj = {
          country: "",
          language: ""
        }
        if(jsonObj.country === "") {
            settingsObj.country = "United States";
          if(jsonObj.language === "")
            settingsObj.language = "en";

          localStorage.setItem(LOCALSTORAGE.keywordSettings, JSON.stringify(settingsObj));
        }
      }

        this.props.updateScreenSize(MediaMatcher());
        window.addEventListener("resize", () => {
          this.props.updateScreenSize(MediaMatcher());
        });

        window.addEventListener('click', (e) => {
          if(this.state.canvas === "show") {
            if(!document.getElementById('offcanvasRight').contains(e.target)) {
              this.setState({ canvas: "hide" });
            }
          }
          if(this.state.updateCanViewContentFilterAlert) {
            this.setState({ updateCanViewContentFilterAlert: false });
            this.setState({ showOverlay: false });
          }
          if(this.state.showAdwordsErrorMessage) {
            this.closeAdwordsAlert();
          }
        });
      }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.parseQueryString();
    }
  }

  componentWillUnmount() {
    window.addEventListener('scroll', this.listenToScroll);
    this._isMounted = false;
  }
}

const mapStateToProps = state => {
    return {
      showAccountGate: state.showAccountGate,
      displayGateTimer: state.displayGateTimer,
      isKeywordTool: state.isKeywordTool,
      keywordExportOptions: state.keywordExportOptions,
      showKeywordExportModal: state.showKeywordExportModal,
      showKeywordSettingsModal: state.showKeywordSettingsModal,
      remainingKwtSearches: state.remainingKwtSearches,
      screenSize: state.screenSize,
      settingsDeviceSelected: state.settingsDeviceSelected,
      serpDomain: state.serpDomain,
      serpCountry: state.serpCountry,
      serpLanguage: state.serpLanguage,

    };
  };

const mapDispatchToProps = dispatch => {
    return {
      updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
      updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate }),
      updateDisplayGateTimer: (displayGateTimer) => dispatch({ type: 'DISPLAY_GATE_TIMER', value: displayGateTimer }),
      updateKeywordToolStatus: (isKeywordTool) => dispatch({type: 'USING_KWT', value: isKeywordTool }),
      updateScreenSize: (screenSize) => dispatch({ type: 'SCREEN_SIZE', value: screenSize }),
      updateShowKeywordExportModal: (showKeywordExportModal) => dispatch({ type: 'CAN_VIEW_KEYWORD_EXPORT_MODAL', value: showKeywordExportModal }),
      updateRemainingKwtSearches: (remainingKwtSearches) => dispatch({ type: 'REMAINING_KWT_SEARCHES', value: remainingKwtSearches }),
      updateShowKeywordSettingsModal: (showKeywordSettingsModal) => dispatch({ type: 'CAN_VIEW_KEYWORD_SETTINGS_MODAL', value: showKeywordSettingsModal }),
      updateKeywordDrawerImage: (url) => dispatch({type: 'KEYWORD_DRAWER_GOOGLE_IMAGE', value: url })
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KeywordTool));
