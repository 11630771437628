export const PRICING = {
  free: {
  plan: 'free',
  price: 0,
  monthlyPrice: 0,
  yearlyPrice: 0,
  searchLimit: 2,
  billingCycle: 1,
  planId: 1,
  renewDate: null,
  cancelDate: null,
  speedTestLimit: 2,
  speedTestSampleLimit: 3,
  keywordSearchLimit: 60
},
lite: {
  plan: 'lite',
  price: 5,
  monthlyPrice: 5,
  yearlyPrice: 48,
  searchLimit: 50,
  billingCycle: 1,
  planId: 2,
  renewDate: null,
  cancelDate: null,
  speedTestLimit: 50,
  speedTestSampleLimit: 5,
  keywordSearchLimit: 2000,
  keywordsPerMin: 30,
  keywordsPerHour: 150
},
guru: {
  plan: 'guru',
  price: 19,
  monthlyPrice: 19,
  yearlyPrice: 182,
  searchLimit: 100,
  billingCycle: 1,
  planId: 3,
  renewDate: null,
  cancelDate: null,
  speedTestLimit: 100,
  speedTestSampleLimit: 5,
  keywordSearchLimit: 6000,
  keywordsPerMin: 30,
  keywordsPerHour: 150
},
pro: {
  plan: 'pro',
  price: 29,
  monthlyPrice: 29,
  yearlyPrice: 278,
  searchLimit: 500,
  billingCycle: 1,
  planId: 4,
  renewDate: null,
  cancelDate: null,
  speedTestLimit: 250,
  speedTestSampleLimit: 10,
  keywordSearchLimit: 14000,
  keywordsPerMin: 30,
  keywordsPerHour: 150
}
};

export default PRICING;
