import ReactGA from 'react-ga';
import React from 'react';
import ReactDOM from 'react-dom';
import reducer from './store/reducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SerpPsiPage from './pages/SerpPsiPage';
import ProPage from './pages/ProPage';
import TweetJar from './pages/TweetJar';
import GoogleSays from './pages/GoogleSays';
import SecureLinkPage from './pages/SecureLinkPage';
import ResetPasswordEmailPage from './pages/ResetPasswordEmailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AccountPage from './pages/AccountPage';
import PricingPage from './pages/PricingPage';
import PaymentPage from './pages/PaymentPage';
import ConfirmationPage from './pages/ConfirmationPage';
import CancelPage from './pages/CancelPage';
import SpeedTestPage from './pages/SpeedTestPage';
import MixLabPage from './pages/MixLabPage';
import KeywordSearchPage from './pages/KeywordToolPage';
import FreeTrialPage from './pages/FreeTrialPage';
import KeywordToolRedirectPage from './pages/KeywordToolRedirectPage';
import SerpCheckerPage from './pages/SerpCheckerPage';
import ProgrammaticSeoToolPage from './pages/ProgrammaticSeoToolPage';
import './scss/mdb.core.scss';
import './scss/mdb.free.scss';
import './App.scss';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './components/ScrollToTop';
import { GoogleOAuthProvider } from '@react-oauth/google';
ReactGA.initialize('UA-174950315-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const store = createStore(reducer);
const app = (
  <Provider store={store}>
    <GoogleOAuthProvider clientId="825137442306-2k1nphkpeg7pkhhgubr3r749ig91209u.apps.googleusercontent.com">
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/pro" component={ProPage}/>
            <Route exact path="/site-speed-comparison-tool" component={SerpPsiPage}/>
            <Route exact path="/serp-analysis-tool" component={SerpPsiPage}/>
            <Route exact path="/tweetjar" component={TweetJar}/>
            <Route exact path="/tweetjar/googlesays" component={GoogleSays}/>
            <Route exact path="/secure-link" component={SecureLinkPage}/>
            <Route exact path="/reset-password-request" component={ResetPasswordEmailPage}/>
            <Route exact path="/reset-password" component={ResetPasswordPage}/>
            <Route exact path="/account" component={AccountPage}/>
            <Route exact path="/pricing" component={PricingPage}/>
            <Route exact path="/payment" component={PaymentPage}/>
            <Route exact path="/confirmation" component={ConfirmationPage}/>
            <Route exact path="/cancel" component={CancelPage}/>
            <Route exact path="/website-speed-test" component={SpeedTestPage}/>
            <Route exact path="/mixlab" component={MixLabPage}/>
            <Route exact path="/keyword-suggestion-tool" component={KeywordSearchPage}/>
            <Route exact path="/free-trial-page" component={FreeTrialPage}/>
            <Route exact path="/GPT3-invite" component={KeywordToolRedirectPage} />
            <Route exact path="/serp-checker" component={SerpCheckerPage} />
            <Route exact path="/programmatic-seo-idea-tool" component={ProgrammaticSeoToolPage} />
            <Route exact path="/" component={HomePage}/>
          </Switch>
        </Router>
    </GoogleOAuthProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('sandbox-tools'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
