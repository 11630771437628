import React, { Component } from 'react';
import { Tab } from 'mdb-ui-kit';
import { LighthouseScoreColors } from '../../functions/LighthouseScoreColors';
import { updateMetricSelectedSubject } from '../../observables/MetricLabSelected';
import { connect } from 'react-redux';

class MetricTabs extends Component {
	_isMounted = false;
  constructor(props) {
		super();
		this._isMounted = false;
    this.state = {
			selectedLabMetric: 'psi',
			selectedFieldMetric: 'lcp',
			schemaValidatorLink: 'https://validator.schema.org/#url=',
			lastMetricSelected: 'psi',
			iconState: <i className="icon-item fas fa-book fa-lg pr-1"></i>,
			metricIconState: "",
			mobileMetricTitle: "Content",
			metricsPassed: false
    };
	}

	handleLabMetricChange = (event) => {
		const metricData = JSON.parse(event.target.value);
		if (this._isMounted) this.setState({ selectedLabMetric: metricData.metric });
		// * Update observable with metric type selected
		if (this._isMounted) this.setState({lastMetricSelected: metricData.metric});
			this.props.nonMetricTabsDisplay(metricData);
		updateMetricSelectedSubject.update({
			metricType: 'Lab',
			metric: metricData.metric
		});

	}

	handleFieldMetricChange = (event) => {
		const metricData = JSON.parse(event.target.value);
		if (this._isMounted) this.setState({ selectedFieldMetric: metricData.metric });
		// * Update observable with metric type selected
			this.props.nonMetricTabsDisplay(metricData);
		if (this._isMounted) this.setState({lastMetricSelected: metricData.metric});
		updateMetricSelectedSubject.update({
			metricType: 'Field',
			metric: metricData.metric
		});
	}

	isLabTabSelected = (metricType) => {
		const labTabSelectedMapping = {
			Lab: () => 'active',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => '',
			Tech: () => ''
		};
		return labTabSelectedMapping[metricType]
			? labTabSelectedMapping[metricType]()
			: '';
	}

	isFieldTabSelected = (metricType) => {
		const fieldTabSelectedMapping = {
			Lab: () => '',
			Field: () => 'active',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => '',
			Tech: () => ''
		};
		return fieldTabSelectedMapping[metricType]
			? fieldTabSelectedMapping[metricType]()
			: '';
	}

	isFilmstripTabSelected = (metricType) => {
		const filmstripTabSelectedMapping = {
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => 'active',
			Content: () => '',
			Type: () => '',
			Tech: () => ''
		};
		return filmstripTabSelectedMapping[metricType]
			? filmstripTabSelectedMapping[metricType]()
			: '';
	}

	isMetaTabSelected = (metricType) => {
		const contentTabSelectedMapping ={
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => 'active',
			Type: () => '',
			Tech: () => ''
		};
		return contentTabSelectedMapping[metricType]
		? contentTabSelectedMapping[metricType]()
		: '';
	}

	isTypeTabSelected = (metricType) => {
		const typeTabSelectedMapping = {
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => 'active',
			Tech: () => ''
		}
		return typeTabSelectedMapping[metricType]
		? typeTabSelectedMapping[metricType]()
		: '';
	}

	isTechTabSelected = (metricType) => {
		const techTabSelectedMapping = {
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => '',
			Tech: () => 'active'
		}
		return techTabSelectedMapping[metricType]
		? techTabSelectedMapping[metricType]()
		: '';
	}

	isLabTabContentSelected = (metricType) => {
		const labTabContentSelectedMapping = {
			Lab: () => 'fade show active',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => '',
			Tech: () => ''
		};
		return labTabContentSelectedMapping[metricType]
			? labTabContentSelectedMapping[metricType]()
			: '';
	}

	isFieldTabContentSelected = (metricType) => {
		const fieldTabContentSelectedMapping = {
			Lab: () => '',
			Field: () => 'fade show active',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => '',
			Tech: () => ''
		};
		return fieldTabContentSelectedMapping[metricType]
			? fieldTabContentSelectedMapping[metricType]()
			: '';
	}

	isFilmstripTabContentSelected = (metricType) => {
		const filmstripTabContentSelectedMapping = {
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => 'fade show active',
			Content: () => '',
			Type: () => '',
			Tech: () => ''
		};
		return filmstripTabContentSelectedMapping[metricType]
			? filmstripTabContentSelectedMapping[metricType]()
			: '';
	}

	isMetaTabContentSelected = (metricType) => {
		const contentTabContentSelectedMapping = {
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => 'fade show active',
			Type: () => '',
			Tech: () => ''
		};
		return contentTabContentSelectedMapping[metricType]
			? contentTabContentSelectedMapping[metricType]()
			: 'fade show active';
	}

	isTypeTabContentSelected = (metricType) => {
		const typeTabContentSelectedMapping = {
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => 'fade show active',
			Tech: () => ''
		};
		return typeTabContentSelectedMapping[metricType]
		? typeTabContentSelectedMapping[metricType]()
		: '';
	}

	isTechTabContentSelected = (metricType) => {
		const techTabContentSelectedMapping = {
			Lab: () => '',
			Field: () => '',
			Filmstrip: () => '',
			Content: () => '',
			Type: () => '',
			Tech: () => 'fade show active'
		};
		return techTabContentSelectedMapping[metricType]
		? techTabContentSelectedMapping[metricType]()
		: '';
	}

	// * TODO Handle selected metric update
	updateSelectedMetrics = (metricType, metric) => {
		const toggleMetricMapping = {
			Lab: () => {
				return this.props.labMetrics?.[metric]?.label;
			},
			Field: () => {
				return this.props.fieldMetrics?.[metric]?.label;
			}
		};
		// console.log(metricType + ', ' + metric);
		if (toggleMetricMapping[metricType]) return toggleMetricMapping[metricType]();
	}

	handleFieldTabChange = (e) => {
		e.preventDefault();
		updateMetricSelectedSubject.update({
			metricType: 'Field',
			metric: this.state.selectedFieldMetric
		});
		this.props.nonMetricTabsDisplay(this.props.selectedMetrics);
		if(this.props.screenSize === "mobile") this.handleMobileDropdownTitle();

		this.handlePassFailCoreWebVitals();
	}

	handleLabTabChange = (e) => {// made these async ansd awaited  seemed to log the correct psi
		e.preventDefault();
		updateMetricSelectedSubject.update({
			metricType: 'Lab',
			metric: this.state.selectedLabMetric
		});
		this.props.nonMetricTabsDisplay(this.props.selectedMetrics);
		if(this.props.screenSize === "mobile") this.handleMobileDropdownTitle();
	}

	handleFilmstripTabChange = (e) => {
		e.preventDefault();
		updateMetricSelectedSubject.update({
			metricType: 'Filmstrip',
			metric: this.props.selectedMetrics?.metric || this.state.lastMetricSelected
		});
		this.props.nonMetricTabsDisplay(this.props.selectedMetrics);
		if(this.props.screenSize === "mobile") this.handleMobileDropdownTitle();
	}

	handleMetaTabChange = (e) => {
		e.preventDefault();
		updateMetricSelectedSubject.update({
			metricType: 'Content',
			metric:  this.props.selectedMetrics?.metric || this.state.lastMetricSelected
		});
		this.props.nonMetricTabsDisplay(this.props.selectedMetrics);
		if(this.props.screenSize === "mobile") this.handleMobileDropdownTitle();
	}

	handleTypeTabChange = (e) => {
		e.preventDefault();
		updateMetricSelectedSubject.update({
			metricType: 'Type',
			metric: this.props.selectedMetrics?.metric || this.state.lastMetricSelected
		});
		this.props.nonMetricTabsDisplay(this.props.selectedMetrics);
		if(this.props.screenSize === "mobile") this.handleMobileDropdownTitle();

	}

	handleTechTabChange = (e) => {
		e.preventDefault();
		updateMetricSelectedSubject.update({
			metricType: 'Tech',
			metric: this.props.selectedMetrics?.metric || this.state.lastMetricSelected
		});
		this.props.nonMetricTabsDisplay(this.props.selectedMetrics);
		if(this.props.screenSize === "mobile") this.handleMobileDropdownTitle();
	}

	handleMobileDropdownTitle = async () => {
		await this.setState({ metricIconState: this.props.selectedMetrics?.metricType });

		if(this.props.selectedMetric !== null)
		{
			var metric = this.props.selectedMetrics?.metricType;
			if(metric === "Content") {
				await this.setState({ iconState: <i className="icon-item fas fa-book fa-lg pr-1"></i> });
				await this.setState({ mobileMetricTitle: "Content" });
			} else if(metric === "Type") {
				await this.setState({ iconState: <i className="icon-item fas fa-code fa-lg pr-1"></i> });
				await this.setState({ mobileMetricTitle: "Schema Types" });
			} else if(metric === "Tech") {
				await this.setState({ iconState: <i className="icon-item fas fa-glasses fa-lg pr-1"></i> });
				await this.setState({ mobileMetricTitle: "Tech" });
			}	else if(metric === "Lab") {
				await this.setState({ iconState: <i className="icon-item fas fa-flask fa-lg pr-1"></i> });
				await this.setState({ mobileMetricTitle: "Lab" });
			} else if(metric === "Field") {
				await this.setState({ iconState: <i className="icon-item fas fa-users fa-lg pr-1"></i> });
				await this.setState({ mobileMetricTitle: "Field" });
			}
		}

	}

  render() {
    return (
      <div>
				{
					this.props.screenSize === "mobile" ?
						<div className="dropdown mb-3">
						  <div className="btn btn-primary metric-dropdown-btn p-3" type="button" id="metricsDropDown" data-toggle="dropdown" aria-expanded="false">
								{this.props.selectedMetrics ?
									<span>{this.state.iconState} <b>{this.state.mobileMetricTitle}</b> <i className="icon-item fas fa-caret-down pl-1"></i></span>
								: <span><i className="icon-item fas fa-book fa-lg pr-1"></i> <b>Content</b> <i className="icon-item fas fa-caret-down pl-1"></i></span>}
						  </div>
						  <ul
								id={'psi-tabs-dropdown-' + this.props.serpPosition}
								role="tablist"
								className="dropdown-menu metric-dropdown-options" aria-labelledby="metricsDropDown">
									<li>
										<a
											 className={`dropdown-item ${
												 this.props.selectedMetrics
													 ? this.isMetaTabSelected(this.props.selectedMetrics.metricType)
													 : 'active'
											 }`}
											id={'content-' + this.props.serpPosition }
											data-mdb-toggle="tab"
											href={'#content-content-' + this.props.serpPosition}
											role="tab"
											aria-controls={'content-content-' + this.props.serpPosition}
											aria-selected="true"
											onClick={(e)=> {
												this.handleMetaTabChange(e)
											 }}>
											<i className="icon-item fas fa-book fa-lg pr-1"></i>
											<b>Content</b>
										</a>
									</li>
									<li>
										<a
											 className={`dropdown-item ${
												 this.props.selectedMetrics
													 ? this.isTypeTabSelected(this.props.selectedMetrics.metricType)
													 : ''
											 }`}
											id={'type-' + this.props.serpPosition }
											data-mdb-toggle="tab"
											href={'#type-content-' + this.props.serpPosition}
											role="tab"
											aria-controls={'type-content-' + this.props.serpPosition}
											aria-selected="false"
											onClick={(e)=> {
												this.handleTypeTabChange(e)
											 }}>
											<i className="icon-item fas fa-code fa-lg pr-1"></i>
											<b>Schema Types</b>
										</a>
									</li>
									<li>
										<a className={`dropdown-item ${
												this.props.selectedMetrics
													? this.isLabTabSelected(this.props.selectedMetrics.metricType)
													: ''
											}`}
											id={'lab-' + this.props.serpPosition}
											data-mdb-toggle="tab"
											href={'#lab-content-' + this.props.serpPosition}
											role="tab"
											aria-controls={'lab-content-' + this.props.serpPosition}
											aria-selected="false"
											onClick={(e)=> {
												this.handleLabTabChange(e)
											 }}>
											<i className="icon-item fas fa-flask fa-lg pr-1"></i>
											<b>Lab</b>
									</a>
									</li>
									<li>
										<a
											className={`dropdown-item ${
												this.props.selectedMetrics
													? this.isFieldTabSelected(this.props.selectedMetrics?.metricType)
													: ''
											}`}
										  id={'field-' + this.props.serpPosition}
											data-mdb-toggle="tab"
											href={'#field-content-' + this.props.serpPosition}
											role="tab"
											aria-controls={'field-content-' + this.props.serpPosition}
											aria-selected="false"
											onClick={(e)=> {
												this.handleFieldTabChange(e)
											 }}>
											<i className="icon-item fas fa-users fa-lg pr-1"></i>
											<b>Field</b>
										</a>
									</li>
									<li>
										<a
											className={`dropdown-item ${
												this.props.selectedMetrics
													? this.isTechTabSelected(this.props.selectedMetrics?.metricType)
													: ''
											}`}
											id={'tech-' + this.props.serpPosition}
											data-mdb-toggle="tab"
											href={'#tech-content-' + this.props.serpPosition}
											role="tab"
											aria-controls={'tech-content-' + this.props.serpPosition}
											aria-selected="false"
											onClick={(e)=> {
												this.handleTechTabChange(e)
											 }}>
											<i className="icon-item fas fa-glasses fa-lg pr-1"></i>
											<b>Tech</b>
										</a>
									</li>
						  </ul>
					</div> :
					 <ul
							className="nav nav-tabs mt-3 mb-3 metric-tabs"
							id={'psi-tabs-' + this.props.serpPosition}
							role="tablist">
							<li className="nav-item" role="presentation">
								<a
								 className={`nav-link ${
									 this.props.selectedMetrics
										 ? this.isMetaTabSelected(this.props.selectedMetrics.metricType)
										 : 'active'
								 }`}
								id={'content-' + this.props.serpPosition }
								data-mdb-toggle="tab"
								href={'#content-content-' + this.props.serpPosition}
								role="tab"
								aria-controls={'content-content-' + this.props.serpPosition}
								aria-selected="true"
								onClick={(e)=> { this.handleMetaTabChange(e) }}>
								<i className="icon-item fas fa-book fa-lg pr-1"></i>
								<b>Content</b>
							</a>
							</li>
							<li className="nav-item" role="presentation">
								<a
									 className={`nav-link ${
										 this.props.selectedMetrics
											 ? this.isTypeTabSelected(this.props.selectedMetrics.metricType)
											 : ''
									 }`}
									id={'type-' + this.props.serpPosition }
									data-mdb-toggle="tab"
									href={'#type-content-' + this.props.serpPosition}
									role="tab"
									aria-controls={'type-content-' + this.props.serpPosition}
									aria-selected="false"
									onClick={(e)=> { this.handleTypeTabChange(e) }}>
									<i className="icon-item fas fa-code fa-lg pr-1"></i>
									<b>Schema Types</b>
								</a>
								</li>
							<li className="nav-item" role="presentation">
								<a className={`nav-link ${
										this.props.selectedMetrics
											? this.isLabTabSelected(this.props.selectedMetrics.metricType)
											: ''
									}`}
									id={'lab-' + this.props.serpPosition}
									data-mdb-toggle="tab"
									href={'#lab-content-' + this.props.serpPosition}
									role="tab"
									aria-controls={'lab-content-' + this.props.serpPosition}
									aria-selected="false"
									onClick={(e)=> { this.handleLabTabChange(e) }}>
									<i className="icon-item fas fa-flask fa-lg pr-1"></i>
									<b>Lab</b>
								</a>
							</li>
							<li className="nav-item" role="presentation">
								<a
									 className={`nav-link ${
										this.props.selectedMetrics
											? this.isFieldTabSelected(this.props.selectedMetrics.metricType)
											: ''
									}`}
									id={'field-' + this.props.serpPosition}
									data-mdb-toggle="tab"
									href={'#field-content-' + this.props.serpPosition}
									role="tab"
									aria-controls={'field-content-' + this.props.serpPosition}
									aria-selected="false"
									onClick={(e)=> { this.handleFieldTabChange(e) }}>
									<i className="icon-item fas fa-users fa-lg pr-1"></i>
									<b>Field</b>
								</a>
							</li>
							{
								this.props.screenSize === 'desktop'
									? <li className="nav-item" role="presentation">
											<a
												className={`nav-link ${
													this.props.selectedMetrics
														? this.isFilmstripTabSelected(this.props.selectedMetrics.metricType)
														: ''
												}`}
												id={'filmstrip-' + this.props.serpPosition}
												data-mdb-toggle="tab"
												href={'#filmstrip-content-' + this.props.serpPosition}
												role="tab"
												aria-controls={'filmstrip-content-' + this.props.serpPosition}
												aria-selected="false"
												onClick={(e)=> { this.handleFilmstripTabChange(e) }}>
												<i className="icon-item fas fa-film fa-lg pr-1"></i>
												<b>Filmstrip</b>
											</a>
										</li>
									: null
							}
							<li className="nav-item" role="presentation">
								<a
									 className={`nav-link ${
										this.props.selectedMetrics
											? this.isTechTabSelected(this.props.selectedMetrics.metricType)
											: ''
									}`}
									id={'tech-' + this.props.serpPosition}
									data-mdb-toggle="tab"
									href={'#tech-content-' + this.props.serpPosition}
									role="tab"
									aria-controls={'tech-content-' + this.props.serpPosition}
									aria-selected="false"
									onClick={(e)=> { this.handleTechTabChange(e) }}>
									<i className="icon-item fas fa-glasses fa-lg pr-1"></i>
									<b>Tech</b>
								</a>
							</li>
					</ul>
				}
				<div
					className="tab-content"
					id={'psi-tabs-content-' + this.props.serpPosition}>
					<div
						className={`tab-pane lab-content ${
							this.props.selectedMetrics
								? this.isLabTabContentSelected(this.props.selectedMetrics.metricType)
								: ''
						}`}
						id={'lab-content-' + this.props.serpPosition}
						role="tabpanel"
						aria-labelledby={'lab-' + this.props.serpPosition}>

						{
							Object.keys(this.props.labMetrics).length > 0
								? <div>
										<div className="lab-content-flex">
											{
												Object.keys(this.props.labMetrics).map((key) => {
													return <div className="btn-radio-group" key={`lab-metric-${this.props.labMetrics[key].label}`}>
																	<input
																		type="radio"
																		className="btn-check"
																		name={`lab-metric-${this.props.labMetrics[key].label}-${this.props.serpPosition}`}
																		id={`lab-metric-${this.props.labMetrics[key].label}-${this.props.serpPosition}`}
																		autoComplete="off"
																		value={`{"metric": "${this.props.labMetrics[key].label}", "metricDisplayValue": "${this.props.labMetrics[key].displayValue}", "metricScoreColorClass": "${LighthouseScoreColors(this.props.labMetrics[key].score)}"}`}
																		onChange={this.handleLabMetricChange}
																		checked={
																			this.props.selectedMetrics
																				? this.updateSelectedMetrics(this.props.selectedMetrics.metricType, this.props.selectedMetrics.metric) === this.props.labMetrics[key].label
																				: this.state.selectedLabMetric === this.props.labMetrics[key].label
																		}
																		/>
																	<label
																		className={`btn btn-outline-dark btn-rounded ml-1 mr-1 mt-1 mb-1 ${LighthouseScoreColors(this.props.labMetrics[key].score)}` }
																		htmlFor={`lab-metric-${this.props.labMetrics[key].label}-${this.props.serpPosition}`}>
																			<span className="metric-label-type pr-1">{this.props.labMetrics[key].label}</span>
																			{this.props.labMetrics[key].displayValue}
																	</label>
																</div>
												})
											}
										</div>

										<div className="metrics-load-psi-link">
											<a
												href={this.props.viewMetricsUrl}
												target="_blank"
												rel="noopener noreferrer"
												className="link">
												<u>load PSI</u>
											</a>
										</div>
									</div>
								: !this.props.psiResultsError
							?	<div className="d-flex ml-4 mt-3">
										<h4 className="m-0">Still Loading </h4>
		                <div className="spinner-border mr-4 ml-4" role="status">
		                  <span className="sr-only">Loading...</span>
		                </div>
		             </div> : <div className="metrics-no-data ml-3 mt-2">
										<h4 className="m-0">no data</h4>
										<a
											href={this.props.viewMetricsUrl}
											target="_blank"
											rel="noopener noreferrer"
											className="link">
											<u>load PSI</u>
										</a>
									</div>
						}
					</div>
					<div
						className={`tab-pane ${
							this.props.selectedMetrics
								? this.isFieldTabContentSelected(this.props.selectedMetrics.metricType)
								: ''
						}`}
						id={'field-content-' + this.props.serpPosition}
						role="tabpanel"
						aria-labelledby={'field-' + this.props.serpPosition}>

						{
							Object.keys(this.props.fieldMetrics).length > 0
								? <div>
										<div className="metric-field-container">
											<div className="metric-field-flex-container">
												<div>
													<p className="core-web-vitals">Core Web Vitals</p>
													{
														Object.keys(this.props.fieldMetrics).length > 0
														? this.handlePassFailCoreWebVitals()
														: null
													}
												</div>
												{
													Object.keys(this.props.fieldMetrics).map((objKey, index) => {
														return index < 3
															? <div className="btn-radio-group" key={`field-metric-${this.props.fieldMetrics[objKey].label}`}>
																	<input
																		type="radio"
																		className="btn-check"
																		name={`field-metric-${this.props.fieldMetrics[objKey].label}-${this.props.serpPosition}`}
																		id={`field-metric-${this.props.fieldMetrics[objKey].label}-${this.props.serpPosition}`}
																		autoComplete="off"
																		value={`{"metric": "${this.props.fieldMetrics[objKey].label}", "metricDisplayValue": "${this.props.fieldMetrics[objKey].displayValue}", "metricScoreColorClass": "${LighthouseScoreColors(this.props.fieldMetrics[objKey].score)}"}`}
																		onChange={this.handleFieldMetricChange}
																		checked={
																			this.props.selectedMetrics
																				? this.updateSelectedMetrics(this.props.selectedMetrics.metricType, this.props.selectedMetrics.metric) === this.props.fieldMetrics[objKey].label
																				: this.state.selectedFieldMetric === this.props.fieldMetrics[objKey].label
																		}/>
																	<label
																		className={`btn btn-outline-dark btn-rounded ml-1 mr-1 mt-1 mb-1 ${this.props.fieldMetrics[objKey].speedColor}` }
																		htmlFor={`field-metric-${this.props.fieldMetrics[objKey].label}-${this.props.serpPosition}`}>
																		{`${this.props.fieldMetrics[objKey].label} ${this.props.fieldMetrics[objKey].displayValue}`}
																	</label>
																</div>
															: null
													})
												}


											</div>

											{
												this.props.fieldMetrics['fcp']
													? <div className="metirc-field-fcp-flex-container">
															<div className="btn-radio-group" key={`field-metric-${this.props.fieldMetrics['fcp'].label}`}>
																<input
																	type="radio"
																	className="btn-check"
																	name={`field-metric-${this.props.fieldMetrics['fcp'].label}-${this.props.serpPosition}`}
																	id={`field-metric-${this.props.fieldMetrics['fcp'].label}-${this.props.serpPosition}`}
																	autoComplete="off"
																	value={`{"metric": "${this.props.fieldMetrics['fcp'].label}", "metricDisplayValue": "${this.props.fieldMetrics['fcp'].displayValue}", "metricScoreColorClass": "${LighthouseScoreColors(this.props.fieldMetrics['fcp'].score)}"}`}
																	onChange={this.handleFieldMetricChange}
																	checked={
																		this.props.selectedMetrics
																			? this.updateSelectedMetrics(this.props.selectedMetrics.metricType, this.props.selectedMetrics.metric) === this.props.fieldMetrics['fcp'].label
																			: this.state.selectedFieldMetric === this.props.fieldMetrics['fcp'].label
																	}/>
																<label
																	className={`btn btn-outline-dark btn-rounded ml-1 mr-1 mt-1 mb-1 ${this.props.fieldMetrics['fcp'].speedColor}` }
																	htmlFor={`field-metric-${this.props.fieldMetrics['fcp'].label}-${this.props.serpPosition}`}>
																	{`${this.props.fieldMetrics['fcp'].label} ${this.props.fieldMetrics['fcp'].displayValue}`}
																</label>
															</div>
														</div>
													: null
											}
										</div>
										<div className="metrics-load-psi-link">
											<a
												href={this.props.viewMetricsUrl}
												target="_blank"
												rel="noopener noreferrer"
												className="link">
												<u>load PSI</u>
											</a>
										</div>
									</div>
								: !this.props.psiResultsError && Object.keys(this.props.labMetrics).length === 0
							?	<div className="d-flex ml-4 mt-3">
										<h4 className="m-0">Still Loading </h4>
		                <div className="spinner-border mr-4 ml-4" role="status">
		                  <span className="sr-only">Loading...</span>
		                </div>
		             </div> : <div className="metrics-no-data ml-3 mt-2">
										<h4 className="m-0">no data</h4>
										<a
											href={this.props.viewMetricsUrl}
											target="_blank"
											rel="noopener noreferrer"
											className="link">
											<u>load PSI</u>
										</a>
									</div>
						}
					</div>
					{
						this.props.screenSize === 'desktop'
							? <div
									className={`tab-pane ${
										this.props.selectedMetrics
											? this.isFilmstripTabContentSelected(this.props.selectedMetrics.metricType)
											: ''
									}`}
									id={'filmstrip-content-' + this.props.serpPosition}
									role="tabpanel"
									aria-labelledby={'filmstrip-' + this.props.serpPosition}>
									{this.props.filmstrip.length > 0
									?	<ul className="filmstrip-container">
											{
												this.props.filmstrip.map(({data}, index) => {
													return <li key={'flimstrip-preview' + index}>
																	<img
																		src={data}
																		key={'filmstrip-' + index}
																		alt={'flimstrip-' + index}/>
																</li>
												})
											}
										</ul>
								: !this.props.psiResultsError && Object.keys(this.props.labMetrics).length === 0
									 ? <div className="d-flex ml-4 mt-3">
												<h4 className="m-0">Still Loading </h4>
												<div className="spinner-border mr-4 ml-4" role="status">
													<span className="sr-only">Loading...</span>
												</div>
								 			</div>
								 : <div className="metrics-no-data ml-3 mt-2">
										<h4 className="m-0">no data</h4>
										<a
											href={this.props.viewMetricsUrl}
											target="_blank"
											rel="noopener noreferrer"
											className="link">
											<u>load PSI</u>
										</a>
									</div> }
								</div>
							: null
					}
					<div
						className={`tab-pane ${
							this.props.selectedMetrics
								? this.isMetaTabContentSelected(this.props.selectedMetrics.metricType)
								: 'fade show active'
						}`}
						id={'content-content-' + this.props.serpPosition}
						role="tabpanel"
						aria-labelledby={'content-' + this.props.serpPosition}>
						<div className='meta-content'>
							{this.props.meta !== "no meta data" && this.props.wordCount > 10
							 ?
							<ul className="meta-list">
										<li className="meta-list-item"><div><span className='content-title'>title tag</span>: <span className='content-info'>{this.props.meta.title}</span></div></li>
										<li className="meta-list-item"><div><span className='content-title'>description</span>: <span className='content-info'>{this.props.meta.description}</span></div></li>
										<li className="meta-list-item"><div><span className='content-title'>language</span>: <span className='content-info'>{this.props.meta.lang}</span></div></li>
										<li className="meta-list-item"><div><span className='content-title'>h1</span>: <span className='content-info'>{this.props.meta.h1}</span></div></li>
										<li className="meta-list-item">{ this.props.meta.h2
												? <div>
														<span className='content-title'>h2</span>: ({this.props.meta.h2 !== undefined ? this.props.meta.h2['size'] : ' '})
														<span className='content-info'> {this.props.meta.h2['filtered']?.length !== 0 && this.props.meta.h2['filtered'][0] !== undefined ? this.props.meta.h2['filtered'][0] : 'no h2 tags found!'}</span>
														<span className='content-info'>{this.props.meta.h2['filtered']?.length > 0 && this.props.meta.h2['filtered'][1] !== undefined ?', ' + this.props.meta.h2['filtered'][1] : ''}</span>
														<span className='content-info'>{this.props.meta.h2['filtered']?.length > 1 &&  this.props.meta.h2['filtered'][2] !== undefined ?', ' + this.props.meta.h2['filtered'][2] + '...' : ''} </span>
													</div> : <div>
														<span className='content-title'>h2</span>:
													</div>
											} </li>
										{/* <li className="meta-list-item"><div className="canonical-url"><span className='content-title'>canonical url</span>: <span className='content-info'>{this.props.meta.url}</span></div></li> */}
						 </ul> : <div className="metrics-no-data"><h4 className="m-0">Data Unavailable</h4></div>
						 }
						</div>
					</div>
					<div
						className={`tab-pane ${
							this.props.selectedMetrics
								? this.isTypeTabContentSelected(this.props.selectedMetrics.metricType)
								: ''
						}`}
						id={'type-content-' + this.props.serpPosition}
						role="tabpanel"
						aria-labelledby={'type-' + this.props.serpPosition}>
						<div className="schema-types">
							{this.props.types !== "no types"
								? <ul className="meta-list">
										{this.props.types !== "undefined" && this.props.types?.length > 0
											? this.props.types?.map((data, index) => {
												return <li className="type-data" key={index}><b>{data}</b></li>
											})
											: <div className="metrics-no-data"><h4 className="m-0">Data Unavailable</h4></div>
										}
								</ul> : <div className="metrics-no-data"><h4 className="m-0">Data Unavailable</h4></div>
							}
						</div>
						<div className="schema-link metrics-load-psi-link">
							<a
								href={this.state.schemaValidatorLink + this.props.url}
								target="_blank"
								rel="noopener noreferrer"
								className="link">
								<u>validate schema</u>
							</a>
						</div>
					</div>
					<div
						className={`tab-pane ${
							this.props.selectedMetrics
								? this.isTechTabContentSelected(this.props.selectedMetrics.metricType)
								: ''
						}`}
						id={'tech-content-' + this.props.serpPosition}
						role="tabpanel"
						aria-labelledby={'tech-' + this.props.serpPosition}>
						{
							this.props.tech !== ""
							? <div>
									<div className="list-wrap">
											<div className="tech-list-title"><span><b>http codes</b>: </span></div>
											<ul className="tech-list list-wrap mb-0">
												{
													this.props.tech?.map((data, index) => {
														return <div key={index} className="tech-item-holder">
															{parseInt(data.status) < 300 && parseInt(data.status) > 100
																	? <li
																		onMouseEnter={(e) => this.handleToolTip(e)}
																		onMouseLeave={(e) => this.handleToolTip(e)}
																		className="tech-list-item badge bg-success">
																		<span className="align-baseline">{data.status}</span>
 																	 <span
																		 id={"tool-tip-url " + this.props.serpPosition +  index}
																		 className="tool-tip-urls none"
																		 >{data.redirectUrl !== undefined ? data.redirectUrl : data.url}</span>
																		</li>
																: parseInt(data.status) < 400 && parseInt(data.status) > 200
																	? <li
																		onMouseEnter={(e) => this.handleToolTip(e)}
																		onMouseLeave={(e) => this.handleToolTip(e)}
																		className="tech-list-item badge bg-primary">
																		<span className="align-baseline">{data.status}</span>
																		<span
																			id={"tool-tip-url " + this.props.serpPosition + index}
																			className="tool-tip-urls none"
																			>{data.redirectUrl !== undefined ? data.redirectUrl : data.url}</span>
																	</li>
																	: <li
																		onMouseEnter={(e) => this.handleToolTip(e)}
																		onMouseLeave={(e) => this.handleToolTip(e)}
																		className="tech-list-item badge bg-danger">
																		<span className="align-middle">{data.status}</span>
																		<span
																			id={"tool-tip-url " + this.props.serpPosition + index}
																			className="tool-tip-urls none"
																			>{data.redirectUrl !== undefined ? data.redirectUrl : data.url}</span>
																	</li>
															}
														</div>
													})
												}
											</ul>
								</div>
								{
									this.props.meta !== "no meta data" && this.props.wordCount > 10
									? <div className="tech-list-title"><b>canonical url</b>: <span className="canonical-url"> {this.props.meta.url === this.props.url ? "self referencing" : this.props.meta.url}</span></div>
										:	this.props.tech !== ""
											? this.props.tech?.map((data, index) => {
												return <div key={index} className="tech-list-title"><b>canonical url</b>: <span className="canonical-url"> {data.url === this.props.url ? "self referencing" : data.url}</span></div>
											}) : null
								}
							</div>
						: <div className="metrics-no-data"><h4 className="m-0">Data Unavailable</h4></div>
					}
					</div>
				</div>
			</div>
    );
	}

	handleToolTip = (e) =>{
		const toolTip = document.getElementById(e.currentTarget.children[1].id);
		if(toolTip) {
			if(toolTip.classList.contains("none")) {
				toolTip.classList.remove("none");
			} else if(!toolTip.classList.contains("none")) {
				toolTip.classList.add("none");
			}
		}
	}

	handlePassFailCoreWebVitals = () => {
		var coreWebVitals = [];
		Object.keys(this.props.fieldMetrics).forEach((key, index) => {
				if(this.props.fieldMetrics[key].label !== "fcp") {
						coreWebVitals.push(this.props.fieldMetrics[key]);
					}
		});

		if(coreWebVitals.length > 0) {
			const hasPassed = (value) => value.speedColor === "psi-score-pass";
			var passOrFail = coreWebVitals.every(hasPassed);
			if(passOrFail) {
				return <div className="metric-badge-holder"><span className="badge bg-success metric-badge">Pass</span></div>
			} else {
				return <div className="metric-badge-holder"><span className="badge bg-danger metric-badge">Fail</span></div>
			}
		}
	}

	componentDidMount() {
		this._isMounted = true;
		const triggerTabList = [].slice.call(document.querySelectorAll(`#psi-tabs-${this.props.serpPosition} a`));
		triggerTabList.forEach((triggerEl) => {
			const tabTrigger = new Tab(triggerEl);
  		triggerEl.addEventListener('click', (event) => {
				event.preventDefault();
    		tabTrigger.show();
  		});
		});
		if(this.props.screenSize === "mobile") this.handleMobileDropdownTitle();

		this.selectedMetricSubscription = updateMetricSelectedSubject.get().subscribe(isMetricChanged => {
			if(isMetricChanged) this.handleMobileDropdownTitle();
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.selectedMetricSubscription.unsubscribe();
	}
}


const mapStateToProps = state => {
  return {
		selectedMetrics: state.selectedMetrics,
		screenSize: state.screenSize,
		flimstripMap: state.flimstripMap
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateSelectedMetrics: (selectedMetrics) => dispatch({ type: 'SELECTED_METRICS', value: selectedMetrics })
    // updateFlimstripMap: (flimstripMap) => dispatch({ type: 'FLIMSTRIP_MAP', value: flimstripMap }),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricTabs);
