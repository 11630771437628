export const PAYPALPLANS = {
  monthly: {
    lite: process.env.REACT_APP_PAYPAL_MONTH_LITE_ID,
    guru: process.env.REACT_APP_PAYPAL_MONTH_GURU_ID,
    pro: process.env.REACT_APP_PAYPAL_MONTH_PRO_ID
  },
  yearly: {
    lite: process.env.REACT_APP_PAYPAL_YEAR_LITE_ID,
    guru: process.env.REACT_APP_PAYPAL_YEAR_GURU_ID,
    pro: process.env.REACT_APP_PAYPAL_YEAR_PRO_ID
  }
};

export default PAYPALPLANS;
