import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import KeywordTool from '../components/KeywordTool/KeywordTool';
import BackToTopArrow from '../components/BackToTopArrow';
import ReactGA from 'react-ga';

class KeywordSearchPage extends Component {
  constructor(props) {
    super();
    this.state = {
      algorithmToolTip:`CATEGORY
      parent level category input -> child level keyword output (cars -> toyota)

      RELATED
      semantically related
      (toyota -> electric cars)

      SIMILAR
      child level keyword input -> child level keyword output (toyota -> honda)

      VARIATION
      old school modifiers
      (toyota -> buy toyota car)`

      // QUESTIONS
      // on-topic questions
      // (toyota -> why is toyota building humanoid robots ?)`,
    };
  }
  handleToggleAlgorithmToolTip = (e) => {
    e.preventDefault();

    const toolTipAl = document.getElementById(`algorithm-tooltip`);
    if(!toolTipAl.classList.contains("none")) {
      toolTipAl.classList.add("none");
    }	else {
      toolTipAl.classList.remove("none");
    }
  }

  render() {
    return (
      <div className="serp-psi-page-bkg">
        <Helmet>
          <meta name="description" content="The first keyword research tool powered by ChatGPT. Explore any category for unique keyword ideas."/>
          <title>Keyword research tool for programmatic SEO  | Sandbox</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/* Facebook meta tags */}
          <meta property="og:title" content="Keyword research tool for programmatic SEO | Sandbox" />
          <meta property="og:image" content="" />
          <meta property="og:description" content="The first keyword research tool powered by ChatGPT. Explore any category for unique keyword ideas." />
          <meta property="og:url" content="https://tools.sandboxweb.io/keyword-suggestion-tool" />
          <meta property="og:type" content="website" />
          {/* Twitter meta tags */}
          <meta property="twitter:title" content="ChatGPT Keyword Research Tool | Sandbox" />
          <meta property="twitter:description" content="The first keyword research tool powered by ChatGPT. Explore any category for unique keyword ideas." />
          <meta property="twitter:image" content="" />
          <meta property="twitter:card" content="" />
        </Helmet>
        <Header />
        <section className="container-fluid shadow-2-strong background1 page-content-padding">
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={'/'}>Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Keyword Tool</li>
                  </ol>
                </nav>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="algo-tooltip-holder">
                      <h3 className="mb-3 tool-header">Keyword research for programmatic SEO</h3>
                      <p className="tool-sub-header">Find large keyword sets, apply modifiers</p>
                    </div>
                  </div>
                </div>
                <KeywordTool />
              </div>
            </div>
          </div>
        <BackToTopArrow />
        </section>

        <section className="container-fluid seo-container">
          <div className="container">
            <div className="row mt-5 pt-3 pb-5">
              <div className="col-sm-6">
                <h3><strong>What does this tool do?</strong></h3>
                <p><strong>This is a keyword research tool for programmatic SEO.</strong> Start by using the search button to generate a list of entities, and then use “modify results” to add modifiers. This will give you search volume for large clusters of entity-based keywords.</p> <p>Here's a few examples, with the searched entity in [brackets] and the modifier in <i>italics</i></p>
                <ul>
                  <li><i>exercises for</i> [muscle types]</li>
                  <li>[competitive colleges] <i>admission rate</i></li>
                  <li><i>air quality in</i> [city]</li>
                 </ul>     

                <p><strong>You can use natural language.</strong> Enter a simple term like “indoor plants” or a more complex phrase like “indoor plants that grow well with no direct sunlight”.</p>
                <p><strong>The “category” section helps you explore the children under a parent entity.</strong> The other sections can drive ideas for new categories to search. They also provide keyword suggestions (similar to other SEO tools). </p>
                <p><strong>If you don't like the results you get, try searching a few more times.</strong> You'll likely get different results, because ChatGPT is essentially mining a huge, internet hive brain for ideas.</p>
                <p><strong>This tool works well in any language.</strong> We auto-detect the language of the keyword you enter, and give you results in the same language. However, our search volume data is sparse for many countries outside the USA, so feel free to export results and pull search volume elsewhere (for now).</p>
                <p>Want to learn more about programmatic SEO? Check out <a href="https://www.sandboxweb.io/programmatic-seo" target="_blank" rel="noopener noreferrer"><u><strong>our guide</strong></u></a></p>
                <p>Want to hire us for a programmatic SEO build? Learn more  <a href="https://www.sandboxweb.io/programmatic-seo-consulting" target="_blank" rel="noopener noreferrer"><u><strong>here</strong></u></a></p>
              </div>
              <div className="col-sm-6">
                <h3><strong>Powered by ChatGPT</strong></h3>
                <p><strong>ChatGPT is an open-sourced AI system developed by the OpenAI team.</strong> The largest AI system ever created, it generates natural language with algorithms that are pre-trained on a massive data set- 45 TB of text information gathered from all corners of the internet.</p>
                <p><strong>Using ChatGPT has its pros and cons.</strong> We love ChatGPT because it can understand the child-parent relationship among keywords, topics, and entities. This is great for research, and it's something that no other keyword tool really gets. On the downside, the AI is prone to go off the rails occasionally, giving nonsensical, confusing, or even outright false information. Keep that in mind. </p>
              </div>
            </div>
            </div>
        </section>
        <section className="container-fluid background1 page-content-padding">
          <Footer />
        </section>
      </div>
      );
  }
  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeywordSearchPage);
