export const DOMAINS = [
  {
    "google_domain": "google.ad",
    "country_name": "Andorra"
  },
  {
    "google_domain": "google.ae",
    "country_name": "United Arab Emirates"
  },
  {
    "google_domain": "google.al",
    "country_name": "Albania"
  },
  {
    "google_domain": "google.am",
    "country_name": "Armenia"
  },
  {
    "google_domain": "google.as",
    "country_name": "American Samoa"
  },
  {
    "google_domain": "google.at",
    "country_name": "Austria"
  },
  {
    "google_domain": "google.az",
    "country_name": "Azerbaijan"
  },
  {
    "google_domain": "google.ba",
    "country_name": "Bosnia and Herzegovina"
  },
  {
    "google_domain": "google.be",
    "country_name": "Belgium"
  },
  {
    "google_domain": "google.bf",
    "country_name": "Burkina Faso"
  },
  {
    "google_domain": "google.bg",
    "country_name": "Bulgaria"
  },
  {
    "google_domain": "google.bi",
    "country_name": "Burundi"
  },
  {
    "google_domain": "google.bj",
    "country_name": "Benin"
  },
  {
    "google_domain": "google.bs",
    "country_name": "Bahamas"
  },
  {
    "google_domain": "google.bt",
    "country_name": "Bhutan"
  },
  {
    "google_domain": "google.by",
    "country_name": "Belarus"
  },
  {
    "google_domain": "google.ca",
    "country_name": "Canada"
  },
  {
    "google_domain": "google.cc",
    "country_name": "Cocos (Keeling) Islands"
  },
  {
    "google_domain": "google.cd",
    "country_name": "Democratic Republic of the Congo",
    "country_code": "cg"
  },
  {
    "google_domain": "google.cf",
    "country_name": "Central African Republic"
  },
  {
    "google_domain": "google.cg",
    "country_name": "Republic of the Congo",
    "country_code": "cg"
  },
  {
    "google_domain": "google.ch",
    "country_name": "Switzerland"
  },
  {
    "google_domain": "google.ci",
    "country_name": "Ivory Coast",
    "country_code": "ci"
  },
  {
    "google_domain": "google.cl",
    "country_name": "Chile"
  },
  {
    "google_domain": "google.cm",
    "country_name": "Cameroon"
  },
  {
    "google_domain": "google.cn",
    "country_name": "China"
  },
  {
    "google_domain": "google.co.ao",
    "country_name": "Angola"
  },
  {
    "google_domain": "google.co.bw",
    "country_name": "Botswana"
  },
  {
    "google_domain": "google.co.ck",
    "country_name": "Cook Islands"
  },
  {
    "google_domain": "google.co.cr",
    "country_name": "Costa Rica"
  },
  {
    "google_domain": "google.co.id",
    "country_name": "Indonesia"
  },
  {
    "google_domain": "google.co.il",
    "country_name": "Israel"
  },
  {
    "google_domain": "google.co.in",
    "country_name": "India"
  },
  {
    "google_domain": "google.co.jp",
    "country_name": "Japan"
  },
  {
    "google_domain": "google.co.ke",
    "country_name": "Kenya"
  },
  {
    "google_domain": "google.co.kr",
    "country_name": "South Korea",
    "country_code": "kp"
  },
  {
    "google_domain": "google.co.ls",
    "country_name": "Lesotho"
  },
  {
    "google_domain": "google.co.ma",
    "country_name": "Morocco"
  },
  {
    "google_domain": "google.co.mz",
    "country_name": "Mozambique"
  },
  {
    "google_domain": "google.co.nz",
    "country_name": "New Zealand"
  },
  {
    "google_domain": "google.co.th",
    "country_name": "Thailand"
  },
  {
    "google_domain": "google.co.tz",
    "country_name": "Tanzania",
    "country_code": "tz"
  },
  {
    "google_domain": "google.co.ug",
    "country_name": "Uganda"
  },
  {
    "google_domain": "google.co.uk",
    "country_name": "United Kingdom"
  },
  {
    "google_domain": "google.co.uz",
    "country_name": "Uzbekistan"
  },
  {
    "google_domain": "google.co.ve",
    "country_name": "Venezuela"
  },
  {
    "google_domain": "google.co.vi",
    "country_name": "United States Virgin Islands",
    "country_code": "vi"
  },
  {
    "google_domain": "google.co.za",
    "country_name": "South Africa"
  },
  {
    "google_domain": "google.co.zm",
    "country_name": "Zambia"
  },
  {
    "google_domain": "google.co.zw",
    "country_name": "Zimbabwe"
  },
  {
    "google_domain": "google.com",
    "country_name": "United States"
  },
  {
    "google_domain": "google.com.af",
    "country_name": "Afghanistan"
  },
  {
    "google_domain": "google.com.ag",
    "country_name": "Antigua and Barbuda"
  },
  {
    "google_domain": "google.com.ai",
    "country_name": "Anguilla"
  },
  {
    "google_domain": "google.com.ar",
    "country_name": "Argentina"
  },
  {
    "google_domain": "google.com.au",
    "country_name": "Australia"
  },
  {
    "google_domain": "google.com.bd",
    "country_name": "Bangladesh"
  },
  {
    "google_domain": "google.com.bh",
    "country_name": "Bahrain"
  },
  {
    "google_domain": "google.com.bn",
    "country_name": "Brunei",
    "country_code": "bn"
  },
  {
    "google_domain": "google.com.bo",
    "country_name": "Bolivia"
  },
  {
    "google_domain": "google.com.br",
    "country_name": "Brazil"
  },
  {
    "google_domain": "google.com.bz",
    "country_name": "Belize"
  },
  {
    "google_domain": "google.com.co",
    "country_name": "Colombia"
  },
  {
    "google_domain": "google.com.cu",
    "country_name": "Cuba"
  },
  {
    "google_domain": "google.com.cy",
    "country_name": "Cyprus"
  },
  {
    "google_domain": "google.com.do",
    "country_name": "Dominican Republic"
  },
  {
    "google_domain": "google.com.ec",
    "country_name": "Ecuador"
  },
  {
    "google_domain": "google.com.eg",
    "country_name": "Egypt"
  },
  {
    "google_domain": "google.com.et",
    "country_name": "Ethiopia"
  },
  {
    "google_domain": "google.com.fj",
    "country_name": "Fiji"
  },
  {
    "google_domain": "google.com.gh",
    "country_name": "Ghana"
  },
  {
    "google_domain": "google.com.gi",
    "country_name": "Gibraltar"
  },
  {
    "google_domain": "google.com.gt",
    "country_name": "Guatemala"
  },
  {
    "google_domain": "google.com.hk",
    "country_name": "Hong Kong"
  },
  {
    "google_domain": "google.com.jm",
    "country_name": "Jamaica"
  },
  {
    "google_domain": "google.com.kh",
    "country_name": "Cambodia"
  },
  {
    "google_domain": "google.com.kw",
    "country_name": "Kuwait"
  },
  {
    "google_domain": "google.com.lb",
    "country_name": "Lebanon"
  },
  {
    "google_domain": "google.com.lc",
    "country_name": "Saint Lucia"
  },
  {
    "google_domain": "google.com.ly",
    "country_name": "Libya",
    "country_code": "ly"
  },
  {
    "google_domain": "google.com.mm",
    "country_name": "Myanmar"
  },
  {
    "google_domain": "google.com.mt",
    "country_name": "Malta"
  },
  {
    "google_domain": "google.com.mx",
    "country_name": "Mexico"
  },
  {
    "google_domain": "google.com.my",
    "country_name": "Malaysia"
  },
  {
    "google_domain": "google.com.na",
    "country_name": "Namibia"
  },
  {
    "google_domain": "google.com.ng",
    "country_name": "Nigeria"
  },
  {
    "google_domain": "google.com.ni",
    "country_name": "Nicaragua"
  },
  {
    "google_domain": "google.com.np",
    "country_name": "Nepal"
  },
  {
    "google_domain": "google.com.om",
    "country_name": "Oman"
  },
  {
    "google_domain": "google.com.pa",
    "country_name": "Panama"
  },
  {
    "google_domain": "google.com.pe",
    "country_name": "Peru"
  },
  {
    "google_domain": "google.com.pg",
    "country_name": "Papua New Guinea"
  },
  {
    "google_domain": "google.com.ph",
    "country_name": "Philippines"
  },
  {
    "google_domain": "google.com.pk",
    "country_name": "Pakistan"
  },
  {
    "google_domain": "google.com.pr",
    "country_name": "Puerto Rico"
  },
  {
    "google_domain": "google.com.py",
    "country_name": "Paraguay"
  },
  {
    "google_domain": "google.com.qa",
    "country_name": "Qatar"
  },
  {
    "google_domain": "google.com.sa",
    "country_name": "Saudi Arabia"
  },
  {
    "google_domain": "google.com.sb",
    "country_name": "Solomon Islands"
  },
  {
    "google_domain": "google.com.sg",
    "country_name": "Singapore"
  },
  {
    "google_domain": "google.com.sl",
    "country_name": "Sierra Leone"
  },
  {
    "google_domain": "google.com.sv",
    "country_name": "El Salvador"
  },
  {
    "google_domain": "google.com.tj",
    "country_name": "Tajikistan"
  },
  {
    "google_domain": "google.com.tr",
    "country_name": "Turkey"
  },
  {
    "google_domain": "google.com.tw",
    "country_name": "Taiwan",
    "country_code": "tw"
  },
  {
    "google_domain": "google.com.ua",
    "country_name": "Ukraine"
  },
  {
    "google_domain": "google.com.uy",
    "country_name": "Uruguay"
  },
  {
    "google_domain": "google.com.vc",
    "country_name": "Saint Vincent and the Grenadines"
  },
  {
    "google_domain": "google.com.vn",
    "country_name": "Vietnam",
    "country_code": "vn"
  },
  {
    "google_domain": "google.cv",
    "country_name": "Cape Verde"
  },
  {
    "google_domain": "google.cx",
    "country_name": "Christmas Island"
  },
  {
    "google_domain": "google.cz",
    "country_name": "Czech Republic"
  },
  {
    "google_domain": "google.de",
    "country_name": "Germany"
  },
  {
    "google_domain": "google.dj",
    "country_name": "Djibouti"
  },
  {
    "google_domain": "google.dk",
    "country_name": "Denmark"
  },
  {
    "google_domain": "google.dm",
    "country_name": "Dominica"
  },
  {
    "google_domain": "google.dz",
    "country_name": "Algeria"
  },
  {
    "google_domain": "google.ee",
    "country_name": "Estonia"
  },
  {
    "google_domain": "google.es",
    "country_name": "Spain"
  },
  {
    "google_domain": "google.fi",
    "country_name": "Finland"
  },
  {
    "google_domain": "google.fm",
    "country_name": "Micronesia, Federated States of"
  },
  {
    "google_domain": "google.fr",
    "country_name": "France"
  },
  {
    "google_domain": "google.ga",
    "country_name": "Gabon"
  },
  {
    "google_domain": "google.ge",
    "country_name": "Georgia"
  },
  {
    "google_domain": "google.gf",
    "country_name": "French Guiana"
  },
  {
    "google_domain": "google.gl",
    "country_name": "Greenland"
  },
  {
    "google_domain": "google.gm",
    "country_name": "Gambia"
  },
  {
    "google_domain": "google.gp",
    "country_name": "Guadeloupe"
  },
  {
    "google_domain": "google.gr",
    "country_name": "Greece"
  },
  {
    "google_domain": "google.gy",
    "country_name": "Guyana"
  },
  {
    "google_domain": "google.hn",
    "country_name": "Honduras"
  },
  {
    "google_domain": "google.hr",
    "country_name": "Croatia"
  },
  {
    "google_domain": "google.ht",
    "country_name": "Haiti"
  },
  {
    "google_domain": "google.hu",
    "country_name": "Hungary"
  },
  {
    "google_domain": "google.ie",
    "country_name": "Ireland"
  },
  {
    "google_domain": "google.io",
    "country_name": "British Indian Ocean Territory"
  },
  {
    "google_domain": "google.iq",
    "country_name": "Iraq"
  },
  {
    "google_domain": "google.is",
    "country_name": "Iceland"
  },
  {
    "google_domain": "google.it",
    "country_name": "Italy"
  },
  {
    "google_domain": "google.jo",
    "country_name": "Jordan"
  },
  {
    "google_domain": "google.kg",
    "country_name": "Kyrgyzstan"
  },
  {
    "google_domain": "google.ki",
    "country_name": "Kiribati"
  },
  {
    "google_domain": "google.kz",
    "country_name": "Kazakhstan"
  },
  {
    "google_domain": "google.la",
    "country_name": "Laos",
    "country_code": "la"
  },
  {
    "google_domain": "google.li",
    "country_name": "Liechtenstein"
  },
  {
    "google_domain": "google.lk",
    "country_name": "Sri Lanka"
  },
  {
    "google_domain": "google.lt",
    "country_name": "Lithuania"
  },
  {
    "google_domain": "google.lu",
    "country_name": "Luxembourg"
  },
  {
    "google_domain": "google.lv",
    "country_name": "Latvia"
  },
  {
    "google_domain": "google.md",
    "country_name": "Moldova",
    "country_code": "md"
  },
  {
    "google_domain": "google.mg",
    "country_name": "Madagascar"
  },
  {
    "google_domain": "google.mk",
    "country_name": "Macedonia",
    "country_code": "mk"
  },
  {
    "google_domain": "google.ml",
    "country_name": "Mali"
  },
  {
    "google_domain": "google.mn",
    "country_name": "Mongolia"
  },
  {
    "google_domain": "google.ms",
    "country_name": "Montserrat"
  },
  {
    "google_domain": "google.mu",
    "country_name": "Mauritius"
  },
  {
    "google_domain": "google.mv",
    "country_name": "Maldives"
  },
  {
    "google_domain": "google.mw",
    "country_name": "Malawi"
  },
  {
    "google_domain": "google.ne",
    "country_name": "Niger"
  },
  {
    "google_domain": "google.nf",
    "country_name": "Norfolk Island"
  },
  {
    "google_domain": "google.nl",
    "country_name": "Netherlands"
  },
  {
    "google_domain": "google.no",
    "country_name": "Norway"
  },
  {
    "google_domain": "google.nr",
    "country_name": "Nauru"
  },
  {
    "google_domain": "google.nu",
    "country_name": "Niue"
  },
  {
    "google_domain": "google.pl",
    "country_name": "Poland"
  },
  {
    "google_domain": "google.ps",
    "country_name": "Palestine",
    "country_code": "ps"
  },
  {
    "google_domain": "google.pt",
    "country_name": "Portugal"
  },
  {
    "google_domain": "google.ro",
    "country_name": "Romania"
  },
  {
    "google_domain": "google.rs",
    "country_name": "Serbia",
    "country_code": "rs"
  },
  {
    "google_domain": "google.ru",
    "country_name": "Russia",
    "country_code": "ru"
  },
  {
    "google_domain": "google.rw",
    "country_name": "Rwanda"
  },
  {
    "google_domain": "google.sc",
    "country_name": "Seychelles"
  },
  {
    "google_domain": "google.se",
    "country_name": "Sweden"
  },
  {
    "google_domain": "google.sh",
    "country_name": "Saint Helena, Ascension and Tristan da Cunha",
    "country_code": "sh"
  },
  {
    "google_domain": "google.si",
    "country_name": "Slovenia"
  },
  {
    "google_domain": "google.sk",
    "country_name": "Slovakia"
  },
  {
    "google_domain": "google.sm",
    "country_name": "San Marino"
  },
  {
    "google_domain": "google.sn",
    "country_name": "Senegal"
  },
  {
    "google_domain": "google.so",
    "country_name": "Somalia"
  },
  {
    "google_domain": "google.sr",
    "country_name": "Suriname"
  },
  {
    "google_domain": "google.td",
    "country_name": "Chad"
  },
  {
    "google_domain": "google.tg",
    "country_name": "Togo"
  },
  {
    "google_domain": "google.tk",
    "country_name": "Tokelau"
  },
  {
    "google_domain": "google.tl",
    "country_name": "Timor-Leste"
  },
  {
    "google_domain": "google.tm",
    "country_name": "Turkmenistan"
  },
  {
    "google_domain": "google.tn",
    "country_name": "Tunisia"
  },
  {
    "google_domain": "google.to",
    "country_name": "Tonga"
  },
  {
    "google_domain": "google.tt",
    "country_name": "Trinidad and Tobago"
  },
  {
    "google_domain": "google.vg",
    "country_name": "British Virgin Islands",
    "country_code": "vg"
  },
  {
    "google_domain": "google.vu",
    "country_name": "Vanuatu"
  },
  {
    "google_domain": "google.ws",
    "country_name": "Samoa"
  }
];

export default DOMAINS;