import React, { Component } from 'react';
import { connect } from 'react-redux';
import LOCALSTORAGE from '../../constants/LocalStorage';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { GetUserSettingApi } from '../../functions/UserSettingApi';
import { CreateAccountApi } from '../../functions/CreateAccountApi';
import PRICING from '../../constants/Pricing'
import PLANS from '../../constants/Plans';

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
			password: '',
			isPasswordVaild: true,
			passwordErrorMsg: '',
			passwordErrorMsgOptions: {
				blank: 'Please enter a password.',
				invalid: 'Password must contain between 8 and 16 characters.'
			}
		};
	}

	handlePasswordInputChange = (event) => {
		const value = event.target.value;
    this.setState({ password: value });
  }

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.validatePassword()) this.savePassword();
	}

	validatePassword = () => {
		let isValid = false;
		if (this.state.password !== '') {
			this.state.password.length >= 8 && this.state.password.length <= 16
				? isValid = true
				: this.setState({ passwordErrorMsg: this.state.passwordErrorMsgOptions.invalid });
		} else {
			this.setState({ passwordErrorMsg: this.state.passwordErrorMsgOptions.blank });
		}
		this.setState({ isPasswordVaild: isValid });
		return isValid;
	}

	updateIsEmailSubscribedLocalStorage = () => {
		if (typeof localStorage !== 'undefined')
			localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, true);
	}

	savePassword = () => {
		const params = {
			email: this.props.createPasswordEmail,
			password: this.state.password
		};

		CreateAccountApi(params).then(res => {
			if (res) {
				if (res.status === 200) {
					if (typeof localStorage !== 'undefined' && res.data.token) {
            const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
            localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
					}
					const bodyEl = document.getElementsByTagName('body')[0];
					if (bodyEl) bodyEl.classList.remove('modal-open');
					this.updateIsEmailSubscribedLocalStorage();
					this.props.updateShowAccountGate(false);
					GetUserSettingApi().then(data => {
						if (data) {
							this.props.updateSelectedDevice(data.deviceType = 1 ? 'mobile' : 'desktop');
							this.props.updateSerpDomain(data.googleDomain);
							this.props.updateSerpCountry(data.country);
							this.props.updateSerpLanguage(data.language);
						}
					});
					this.updateCurrentPlan(res.data);
				} else {
					console.error('create password CreateAccountApi Error: ');
				}
			}
		})
	}

	toggleShowPassword = () => {
		const createPasswordInputEl = document.querySelector('#createPassword');
		const showPasswordToggleBtn = document.querySelector('#createPasswordToggle');
		if (createPasswordInputEl && showPasswordToggleBtn) {
			createPasswordInputEl.type = createPasswordInputEl.type === 'password'
				? 'text'
				: 'password';
			showPasswordToggleBtn.innerHTML = createPasswordInputEl.type === 'password'
				? 'show'
				: 'hide';
		}
	}

	updateCurrentPlan = (userData) => {
    const currentPlan = PRICING[PLANS[userData.planId]];
    currentPlan.renewDate = userData.renewsAt;
		currentPlan.billingCycle = userData.billingCycle;
		currentPlan.cancelDate = userData.cancelsAt;
    this.props.updateCurrentPlan(currentPlan);
  }

  render() {
    return (
			<div>
				<div>
					<h3 className="mt-5 text-center">Create a password</h3>
				</div>
				<div>
					<form onSubmit={this.handleSubmit} noValidate>
						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-8">
								<div className="pb-3">
									<label htmlFor="createPassword">Please create a password</label>
									<div className="input-group">
										<input
											type="password"
											id="createPassword"
											className={`form-control ${!this.state.isPasswordVaild ? 'error' : ''}`}
											value={this.state.password}
											onChange={this.handlePasswordInputChange} />
										<button
											className="btn btn-outline-primary"
											type="button"
											id="createPasswordToggle"
											data-ripple-color="dark"
											onClick={()=> {this.toggleShowPassword()}}>
											show
										</button>
									</div>
									<label className="error">
										<span>
											{
												!this.state.isPasswordVaild
													? this.state.passwordErrorMsg
													: null
											}
										</span>
									</label>
									{
										this.state.isPasswordVaild
											? <span className="input-notes">8 - 16 characters</span>
											: null
									}
								</div>
							</div>
						</div>

						<div className="pt-3 pb-3 text-center">
							<button
								className="btn btn-dark mr-3"
								type="submit"
								data-ripple-color="dark"
								onClick={(e)=> {this.handleSubmit(e)}}>
								submit
							</button>
						</div>
					</form>
				</div>
			</div>
    );
	}
}

const mapStateToProps = state => {
  return {
		signInCreateAccountModalEl: state.signInCreateAccountModalEl,
		createPasswordEmail: state.createPasswordEmail
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateShowSignInCreateAccountModal: (modal) => dispatch({ type: 'SHOW_SIGN_IN_CREATE_ACCOUNT_MODAL', value: modal }),
		updateShowAccountGate: (showAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: showAccountGate }),
		updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
