import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header/Header';
import { updateSearchTwitterSubject } from '../observables/SearchTwitter';
import Footer from '../components/Footer/Footer';
import { GetAuthUserId } from '../functions/AuthStatus';

let selectedSources = new Set();

class GoogleSays extends Component {
  constructor(props) {
    super();
		this.state = {
      sources: [
        {
          source: 'JohnMu',
          image: '../images/john_mu.png'
        },
        {
          source: 'g33konaut',
          image: '../images/g33konaut.jpg'
        },
        {
          source: 'dannysullivan',
          image: '../images/danny_sullivan.jpg'
        },
        {
          source: 'methode',
          image: '../images/methode.jpg'
        },
        {
          source: 'googlewmc',
          image: '../images/googlewmc.png'
        },
        {
          source: 'searchliaison',
          image: '../images/searchliaison.jpg'
        }
			],
			topics: [
        "CORE WEB VITALS",
        "SITE SPEED",
        "RANKING",
        "INDEXING",
        "H1",
        "GSC",
        "CANONICAL",
        "TITLE TAG",
        "UX",
        "NOFOLLOW",
        "JAVASCRIPT",
        "SEO",
        "MIGRATION",
        "PAGINATION",
        "NOINDEX",
        "CLS",
        "SCHEMA",
        "PASSAGES",
        "BUYING LINKS",
        "LSI",
        "DOMAIN AUTHORITY",
        "IFRAME",
        "DISAVOW",
        "BOLD TEXT",
        "GUEST POST",
        "HREFLANG",
        "ANCHOR TEXT",
        "A/B",
        "301",
        "CRAWLING",
        "302",
        "BLACK HAT",
        "ABOVE THE FOLD",
        "FCP",
        "E-A-T",
        "GPT3",
        "DROP IN TRAFFIC",
        "BREADCRUMB",
        "BACKLINK",
        "CRAWL BUDGET"
      ],
      customTopic: '',
      selectedTopic: '',
      twitterSearchUrl: 'https://twitter.com/search?f=live',
      selectedGoogleSaysSources: [],
      isDisableSearchTwitterBtn: true
    };
  }

  gotToSandboxweb = () => {
    window.location.href = 'https://www.sandboxweb.io/';
	}

  handleCustomTopic = (event) => {
    this.setState({ customTopic: event.target.value }, () => {
      this.checkSearchTwitterBtnState();
    });
  }

  handleTopicChange = (event) => {
    this.setState({ customTopic: '' });
    this.setState({ selectedTopic: event.target.value }, () => {
      this.checkSearchTwitterBtnState();
    });
  }

  // checkUserSession = () => {
  //   if (DisplayAccountGateCheck()) {
  //     this.props.updateGateContent('createAccount');
  //     this.props.updateShowAccountGate(true);
  //   } else {
  //     this.searchTwitter();
  //   }
  // }

  searchTwitter = () => {
    let sources = '';
    let twitterSerchUrl = '';
    for (let i=0, il=this.state.selectedGoogleSaysSources.length; i<il; i++) {
      i === il - 1
        ? sources += ` from:${this.state.selectedGoogleSaysSources[i]}`
        : sources += ` from:${this.state.selectedGoogleSaysSources[i]}, OR `;
    }
    if (this.state.customTopic !== '') {
      this.setState({ selectedTopic: this.state.customTopic }, () => {
        this.setState({ topics: [...this.state.topics, ...[this.state.customTopic]] });
        this.setState({ customTopic: '' });
        twitterSerchUrl = encodeURI(`${this.state.twitterSearchUrl}&q=${this.state.selectedTopic}(${sources})`);
        ReactGA.event({ category: 'google says', action: 'query', label: this.state.selectedTopic });
        ReactGA.event({ category: 'google says', action: 'click', label: this.state.selectedGoogleSaysSources.toString() });
        ReactGA.event({ category: 'google says - usage', action: this.state.selectedTopic, label: GetAuthUserId() });
        window.open(twitterSerchUrl, '_blank');
      });
    } else {
      twitterSerchUrl = encodeURI(`${this.state.twitterSearchUrl}&q=${this.state.selectedTopic}(${sources})`);
      ReactGA.event({ category: 'google says', action: 'query', label: this.state.selectedTopic });
      ReactGA.event({ category: 'google says', action: 'click', label: this.state.selectedGoogleSaysSources.toString() });
      ReactGA.event({ category: 'google says - usage', action: this.state.selectedTopic, label: GetAuthUserId() });
      window.open(twitterSerchUrl, '_blank');
    }
  }

  checkSearchTwitterBtnState = () => {
    (this.state.selectedGoogleSaysSources.length > 0 && this.state.selectedTopic !== '') ||
    (this.state.selectedGoogleSaysSources.length > 0 && this.state.customTopic !== '')
      ? this.setState({ isDisableSearchTwitterBtn: false })
      : this.setState({ isDisableSearchTwitterBtn: true });
  }

  cardClick = (source) => {
		if (selectedSources.has(source)) {
			selectedSources.delete(source)
			this.setState({isChecked: false});
		} else {
			selectedSources.add(source);
			this.setState({isChecked: true});
		}
    this.setState({ selectedGoogleSaysSources: Array.from(selectedSources) });
		selectedSources.size === 0
			?	this.setState({ isDisableSearchTwitterBtn: true })
      : this.setState({ isDisableSearchTwitterBtn: false });
	}

  render() {
    return (
      <div className="container-fluid page-background1 pb-5">
        <Helmet>
          <meta name="description" content="Search SEO Tweets from the Google team"/>
          <title>Google Says - Search Twitter for SEO Tweets | Sandbox</title>
          {/* Facebook meta tags */}
          <meta property="og:title" content="Google Says - Search Twitter for SEO Tweets | Sandbox" />
          <meta property="og:image" content="//tools.sandboxweb.io/images/google_says.png" />
          <meta property="og:description" content="Search SEO Tweets from the Google team" />
          <meta property="og:url" content="https://tools.sandboxweb.io/tweetjar/googlesays" />
          <meta property="og:type" content="website" />
          {/* Twitter meta tags */}
          <meta property="twitter:title" content="Google Says - Search Twitter for SEO Tweets | Sandbox" />
          <meta property="twitter:description" content="Search SEO Tweets from the Google team" />
          <meta property="twitter:image" content="//tools.sandboxweb.io/images/google_says.png" />
          <meta property="twitter:card" content="" />
        </Helmet>
        <Header />
        <div className="container page-content-padding">
          <div className="row pt-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Google Says</li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12">
              <h3 className="pb-4">Search SEO Tweets from the Google Team</h3>
            </div>
            <div className="col-sm-12">
              <p>Select one or more sources</p>
            </div>
            {/* Source cards */}
            {
              this.state.sources.map(({source, image}, index) => {
                return <div className="col-sm-12 col-md-6 col-lg-4" key={`${source}-${index}`}>
                  <div
                    className="card shadow-2-strong mb-3 google-says google-says-card"
                    onClick={() => this.cardClick(source)}>
                    <div className="card-body d-flex justify-content-evenly align-items-center">
                      <img
                        src={image}
                        className="rounded-circle"
                        alt={source} />
                      <h4>{source}</h4>
                      <div className="form-check">
                        <input
                          className="form-check-input mb-1"
                          type="checkbox"
                          value={source}
                          id={source}
                          aria-label={source}
                          readOnly
                          checked={selectedSources.has(source)}/>
                      </div>
                    </div>
                  </div>
                </div>
              })
            }
            {/* Topic radio group */}
            <div className="col-sm-12 mt-5 mb-5">
              <p>Select a topic</p>
              {
                this.state.topics.map((topic, index) => {
                  return <div
                    className="btn-radio-group"
                    key={`${topic}-${index}`}>
                    <input type="radio"
                      className="btn-check"
                      name="topics"
                      id={topic}
                      autoComplete="off"
                      value={topic}
                      onChange={this.handleTopicChange}
                      checked={this.state.selectedTopic === topic}/>
                    <label
                      className="btn btn-outline-dark btn-rounded ml-1 mr-1 mt-1 mb-1"
                      htmlFor={topic}>
                      {topic}
                    </label>
                  </div>
                })
              }
            </div>
            {/* Add custom topic */}
            <div className="col-sm-12 mb-4">
              <p>Or, search your own topic</p>
              <input
								type="text"
								id="customTopic"
								className="form-control mb-4"
								value={this.state.customTopic}
								onChange={this.handleCustomTopic}/>
              <button
								className="btn btn-dark"
								type="submit"
								id="searchOnTwitter"
								data-ripple-color="dark"
                onClick={() => this.searchTwitter()}
                disabled={ this.state.isDisableSearchTwitterBtn }>
								search on twitter
							</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.subscription = updateSearchTwitterSubject.get().subscribe(isUpdateSearchTwitterSubject => {
      if (isUpdateSearchTwitterSubject) {
        this.searchTwitter();
        updateSearchTwitterSubject.update(false);
      }
    });
    for (var i = 0; i < this.state.sources.length; i++) {
      this.cardClick(this.state.sources[i].source);
    }
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
	}
}

const mapStateToProps = state => {
  return {
    selectedGoogleSaysSources: state.selectedGoogleSaysSources
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
    updateShowAccountGate: (isShowAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: isShowAccountGate })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleSays);
