import React, { Component } from 'react';
import PsiResults from './PsiResults';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { updateMetricSelectedSubject } from '../../observables/MetricLabSelected';
import { updateViewAllMetricsSubject } from '../../observables/ViewAllMetrics';

class SerpPsiCard extends Component {
	_isMounted = false;
  constructor(props) {
    super();
    this._isMounted = false;
    this.state = {
			showTitleClick: false,
			flimstripData: {},
      handleCountDisplay: false,
      handleDisplayMetric: [],
      progressPercentage: 0,
      showProgressBar: true,
      psiResultsError: false,
      showMetrics: false,
      skeletonImage: "../images/skeleton.PNG",
			displayState: ''
    };
		this.timeoutWordCount = null;
	}

	titleClick = (url) => {
		this.setState({showTitleClick: true});
		setTimeout(() => {
			this.setState({showTitleClick: false});
			window.open(url, '_blank');
		}, 100);
	}

	siteSpeedConsultingGaEvent = () => {
    ReactGA.event({ category: 'speed comparison', action: 'click', label: 'consulting button' });
		window.open('https://www.sandboxweb.io/site-speed-consulting', '_blank');
  }

	handleFilmstripData = (serpPosition, flimstripImages) => {
		this.setState({ flimstripData: flimstripImages });
	}

	handleShowMetricClick = () => {
		this.child.showMetrics();
		updateMetricSelectedSubject.update({
			metricType: 'Filmstrip',
			metric: this.props.selectedMetrics?.metric
		});
		this.setState({ showMetrics: !this.state.showMetrics });
	}

  handleWordCountDisplay = () => {
    //word count error fixed. Some sites were still spinning. So added this time out boolean to each card. Also, sites that showed Error. Started spinning again after more results clicked - fixed .
    this.timeoutWordCount = setTimeout(() => {
        this.setState({ handleCountDisplay: true });
    }, 25000)
  }

  handleShowMetricDisplay = (e) => {
		if(this.child?.showMetrics() !== undefined)
    	this.child.showMetrics();

	  this.setState({ showMetrics: !this.state.showMetrics });
		// updateViewAllMetricsSubject.update(!this.state.showMetrics);
		//ANCHOR TO MORE DATA CARD
		//  var observer = null;
		//  const moreDataBtn = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
		//  const config = { attributes: true, childList: true, subtree: true };
		//
		//  const callback = function(mutationsList, observer) {
		// 	for(const mutation of mutationsList) {
	  //       if (mutation.type === 'childList') {
	  //           console.log('A child node has been added or removed.');
	  //       }
	  //       else if (mutation.type === 'attributes') {
		// 					var newTop = mutation.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect();
		// 					console.log(newTop);
		// 					var scroll_to = (newTop.top + window.scrollY) - 52;
		// 					console.log("Scroll to: " + scroll_to);
		// 					window.scrollTo({
		// 						top: scroll_to
		// 					});
	  //       }
	  //   }
		// 	observer.disconnect();
		// };
		//
		// observer = new MutationObserver(callback);
		// observer.observe(moreDataBtn, config);

  }

	retryPsiResults = (e) => {
		this.child.retryPsiResults(e);
		this.setState({ showProgressBar: !this.state.showProgressBar });
	}

	// Temporary - If the state of this is not changed here. Then this.child.handleMetricsDisplay does not update correctly
	handleChildMetricsDisplay = async (obj, psiResultsError) => {
	    var metricDisplayMap = obj;
	    await this.setState({ handleDisplayMetric: metricDisplayMap });
	    this.setState({ psiResultsError: psiResultsError });
	  }

	handleChildProgressBarDisplay = (barValue, showBar) => {
	  this.setState({ progressPercentage: barValue })
	  this.setState({ showProgressBar: showBar });

	}

  render() {
    return (
      <div className="serp-card col-sm-12">
				{
					!this.props.goProCardData
						? <div className="card shadow-2-strong mb-3">
								<div className="card-body clickable">
									<div className="row">
										<div className="col-sm-12 new-psi-container">
											<div className="psi-card-filmstrip-and-link-container">
												{
													this.props.screenSize === 'desktop' && this.state.flimstripData?.data
														? <div>
																<span onClick={()=> { this.handleShowMetricClick() }}>
																	<img
																		className="flimstrip-preview"
																		src={this.state.flimstripData?.data}
																		alt={'flimstrip-preview'}/>
																</span>
															</div>
                              :  this.props.screenSize === 'desktop'
                              ? <div className="animated-img-holder">
                                    <span className="animated-filmstrip skeleton-holder">
                                      <img
                                        className="flimstrip-preview skeleton-background"
                                        src="../images/skeleton.PNG"
                                        alt={'flimstrip-preview'}/>
                                    </span>
  															</div> : null
                          }
  												<div className="bg-image bg-padding">
                            <div onClick={()=> {this.titleClick(this.props.url)}}>
															<div className="d-flex justify-content-between">
																<div>
																	<h5 className="card-title">{this.props.domain}</h5>
																	<a
																		className="card-subtitle mb-2 link"
																		href={this.props.url}
																		target="_blank"
																		rel="noopener noreferrer">
																		{this.props.title}
																	</a>
																</div>
																<div>
																	<div className="serp-card-rank-holder">
																		<span className="serp-card-rank-position">{this.props.rank}</span>
																	</div>
																</div>
															</div>

  													{
  														this.state.showTitleClick
  															? <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}></div>
  															: null
  													}
                            </div>
                            {
															!this.state.psiResultsError
                            		? <div className={this.state.showProgressBar ? "d-flex align-items-center" : 'align-items-center'}>
														{
															this._isMounted
																? this.child.handleMetricsDisplay(this.props.selectedMetrics)
															: null
														}

														{
                              this.state.showProgressBar
                              	? <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: this.state.progressPercentage + '%' }}
                                      aria-valuenow={this.state.progressPercentage}
                                      aria-valuemin="0"
                                      aria-valuemax="100">
                                    </div>
                                  </div> : null
                              	}
                            	</div> : null
                           }

													 <div className="metrics-title">
													 {
															this.props.wordCount > 0
																?  'Word count: ' + this.props.wordCount
																: this.props.wordCount === "N/A" && this.state.handleCountDisplay
																	? 'Word count: Error' : null
													 }
													 </div>

													 {this.props.wordCount > 0 || this.state.handleCountDisplay || !this.state.showProgressBar
															? <span className="more-data mt-1">
																	<span
																		onClick={(e) => this.handleShowMetricDisplay(e)}>
																		{
																			'more data'
																		}
																		{
																			this.state.showMetrics
																				? <i className="icon-item fas fa-caret-up pl-1"></i>
																				: <i className="icon-item fas fa-caret-down pl-1"></i>
																		}
																	</span>
																</span>
															: null}
													 {
														 this.state.psiResultsError
															 ? <div>
																	 <span className="error">Error: </span>
																	 <a
																		 className="link"
																		 href={this.state.viewMetricsUrl}
																		 target="_blank"
																		 rel="noopener noreferrer">
																		 Load PSI
																	 </a>
																	 {
																		 !this.state.retryAtemptFailed
																			 ? <span>
																					 <span> | </span>
																					 <a
																						 className="link"
																						 onClick={(e) => this.retryPsiResults(e)}
																						 href="/">
																						 Retry
																					 </a>
																				 </span>
																			 : null
																	 }
																 </div>
															 : null
													 }
  												</div>
                      </div>
                      <PsiResults
                        url={this.props.url}
                        serpPosition={this.props.serpPosition}
                        onFlimstripData={this.handleFilmstripData}
                        onRef={ref => (this.child = ref)}
                        meta={this.props.meta}
                        wordCount={this.props.wordCount}
                        types={this.props.types}
                        moreResultsClicked={this.props.moreResultsClicked}
                        parentMetricDisplay={this.handleChildMetricsDisplay}
                        parentProgressBarDisplay={this.handleChildProgressBarDisplay}
												tech={this.props.techTabResults}
                      />
										</div>
									</div>
								</div>
							</div> : null

            // <div className="card shadow-2-strong mb-3">
						// 		<div className="card-body">
						// 			<div className="d-flex align-items-center pb-2">
						// 				<span className="pr-2">
						// 					Improve your vitals
						// 				</span>
						// 				<button
						// 					type="button"
						// 					className="btn btn-dark btn-sm d-sm-none d-md-block"
						// 					onClick={()=> {this.siteSpeedConsultingGaEvent()}}>
						// 					learn more
						// 				</button>
						// 			</div>
						// 		</div>
						// 	</div>
				}
			</div>
    );
  }
  componentDidMount(){
    this._isMounted = true;
    this.handleWordCountDisplay();
    this.subscriptionViewAllMetrics = updateViewAllMetricsSubject.get().subscribe(isUpdateViewAllMetricsSubject => {
      if (this._isMounted) this.setState({ showMetrics: isUpdateViewAllMetricsSubject });
    });
	}

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timeoutWordCount);

		if(!this._isMounted)
    	this.subscriptionViewAllMetrics.unsubscribe();
  }
}

const mapStateToProps = state => {
  return {
		flimstripMap: state.flimstripMap,
		screenSize: state.screenSize,
    selectedMetrics: state.selectedMetrics
  };
};


export default connect(mapStateToProps, null)(SerpPsiCard);
