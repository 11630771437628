import React, { Component } from 'react';

class EmptyProgressBar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
    };
  }

	render() {
    return (
      <div className="progress speed-test-progress-bar">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: '0%' }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100">
        </div>
      </div>
    );
  }

  componentDidMount() {
    this._isMounted = true;
	}

  componentWillUnmount() {
		this._isMounted = false;
	}
}

export default EmptyProgressBar;