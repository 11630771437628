import React, { Component } from 'react';
import { connect } from 'react-redux';


class ViewFilterClearedAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
closeAlert = () => {
  this.props.updateCanViewContentFilterAlert(false);
}
  render() {
      return (
        <div className="alert-container fade-in">
  				<div className="row d-flex justify-content-center">
  					<div className="col-sm-10 col-md-7 text-center">
  						<div
  							className="alert alert-info alert-onboarding"
  							role="alert"
  							data-color="info"
  							onClick={() => this.closeAlert()}>
  							<div className="d-flex justify-content-between">
  								<span className="alert-copy">
  								   Your filters have been cleared
  								</span>
  								<i className="fas fa-times fa-sm alert-close"></i>
  							</div>
  						</div>
  					</div>
  				</div>
  			</div>
      );
    }
}
const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
  return {
    updateCanViewContentFilterAlert: (canViewFilterClearedAlert) => dispatch({ type: 'CAN_VIEW_FILTER_CLEARED_ALERT', value: canViewFilterClearedAlert})
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewFilterClearedAlert);
