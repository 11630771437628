import axios from 'axios';
import { GetAuthToken } from './AuthStatus';

export async function RateLimitingApi(rateLimitType, searchTerm, pageNum, ignoreRateLimit, speedTestSampleSize, speedTestUrl) {
  if (GetAuthToken()) {
    try {
      const headers = {
        'Authorization': `Bearer ${GetAuthToken()}`,
        'Content-Type': 'application/json'
      };
      const params = {
        searchString: searchTerm,
        pagination: pageNum,
        ignoreLimit: ignoreRateLimit || false,
        limitType: rateLimitType,
        speedTestSampleSize: speedTestSampleSize,
        speedTestUrl: speedTestUrl
      };
      console.log(params);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/rate-limit`, params, { headers: headers, withCredentials: true})
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

