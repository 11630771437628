import axios from 'axios';

export async function MagicLinkApi(email, params) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVICE}/backend-service/v1/secure-link/${email}`, params, { withCredentials: true})
    return response;
  } catch (error) {
    return error.response;
  }
}

