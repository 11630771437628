import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

class ProPage extends Component {
  constructor(props) {
    super();
		this.state = {
      emailAddress: '',
      isEmailAddressError: false,
      emailAddressErrorMsg: 'Please provide a valid email address.',
      emailSubscribeSuccess: false
    };
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({emailAddress: value});
  }
  
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.emailAddress !== '') {
      const regex = new RegExp(/\S+@\S+\.\S+/, 'i');
      regex.test(this.state.emailAddress)
        ? this.proEmailSubscribe(this.state.emailAddress)
        : this.setState({isEmailAddressError: true});
    } else {
      this.setState({isEmailAddressError: true});
    }
  }

  proEmailSubscribe = (emailAddress) => {
    const params = {
      emailAddress: emailAddress
    };
    
    axios.post(`${process.env.REACT_APP_PRO_EMAIL_SUBSCRIBE_API}`, params)
    .then(res => {
      if (res) {
        this.setState({emailSubscribeSuccess: true});
      }
    }).catch(error => {
      console.error('pro email subscribe Error: ', error);
    })
	}

  render() {
    return (
      <div className="container-fluid page-background1 pb-5">
        <Helmet>
          <meta name="description" content=""/>
          <title>Pro | Sandbox</title>
        </Helmet>
        <Header />
        <section className="container page-content-padding">
          <div className="row pt-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'/'}>Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Sandbox Pro</li>
                </ol>
              </nav>
            </div>
            <div className="col-sm-12">
              <h3 className="pb-4">Sandbox Pro</h3>
            </div>
            <div className="col-sm-12">
            <p>So you want to track speed over time? Our paid version launches January 2021.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <p><strong>Track Scores over Time</strong></p>
            </div>
            <div className="col-sm-12">
              <p>SERP-level tracking on both your PSI scores and deeper metrics like the core web vitals. Keep tabs on your most important pages and visualize the impact that site speed has on rankings.</p>
            </div>

            <div className="col-sm-12">
              <p><strong>Deeper Metrics</strong></p>
            </div>
            <div className="col-sm-12">
              <p>We surface deeper PSI metrics (LCP, TTI, etc.), and allow you to sort and filter your SERP competitors on these.</p>
            </div>

            <div className="col-sm-12">
              <p><strong>PSI Score Smoothing</strong></p>
            </div>
            <div className="col-sm-12">
              <p>We pull scores multiple times per day and average them to stabilize your data.</p>
            </div>

            <div className="col-sm-12">
              <p><strong>Unlimited Queries</strong></p>
            </div>
            <div className="col-sm-12">
              <p>Explore as many SERPs as you'd like with unlimited queries</p>
            </div>
          </div>
          {
            !this.state.emailSubscribeSuccess
              ? <form onSubmit={this.handleSubmit} noValidate>
                  <label htmlFor="proEmailAddress">Enter your email for a demo</label>
                  <div className="input-group pb-5">
                    <input
                      id="proEmailAddress"
                      type="email"
                      className={`form-control ${this.state.isEmailAddressError ? 'error' : ''}`}
                      placeholder=""
                      aria-label="Enter Email address"
                      aria-describedby="Enter Email address"
                      value={this.state.emailAddress}
                      onChange={this.handleInputChange}/>
                    <button
                      className="btn btn-outline-primary"
                      type="submit"
                      id="submitProEmailAddress"
                      data-ripple-color="dark">
                      Submit
                    </button>
                    <label className="error">
                      <span>
                        {
                          this.state.isEmailAddressError
                            ? this.state.emailAddressErrorMsg
                            : null
                        }
                      </span>
                    </label>
                  </div>
                </form>
              : <div className="row">
                  <div className="col-sm-12">
                    <p>Success</p>
                  </div>
                </div> 
          }
        </section>
        <Footer />
      </div>
    );
  }
}
export default ProPage;
