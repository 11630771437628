import React from 'react';
import { withRouter } from 'react-router-dom';

class BackToTopArrow extends React.Component {
  constructor(props) {
    super();
    this._isMounted = false;
    this.state = {

    };
  }
  backToTop = () => {
    window.scrollTo({
      top:0,
      behavior: "smooth"
    });

  }

  toggleArrow = () => {
      const arrow = document.querySelector('#btp-arrow');
      if(arrow) {
        if(window.pageYOffset > 500) {
          if(arrow.classList.contains('arrow-none')) {
            arrow.classList.remove('arrow-none');
          }
        } else {
          if(!arrow.classList.contains('arrow-none')) {
            arrow.classList.add('arrow-none');
          }
        }
      }
  }

render() {
  return (
    <div id="btp-arrow" className="arrow-none">
        <div className="back-to-top btn-outline-primary" onClick={()=> { this.backToTop(); }}>
            <i className="fas fa-arrow-up back-to-top-arrow"></i>
        </div>
    </div>
  );
}

componentDidMount() {
  this._isMounted = true;
  window.addEventListener('scroll', this.toggleArrow);
}
componentWillUnmount() {
  this._isMounted = false;
  window.addEventListener('scroll', this.toggleArrow);
}


}
export default withRouter(BackToTopArrow);
