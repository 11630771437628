import React, { Component } from 'react';
import { Tooltip } from 'mdb-ui-kit';

class KeywordExportToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
	}

  render() {
    return (
      <i
        className="fas fa-file-download fa-2x"
        id="keyword-export"
        data-toggle="tooltip"
        data-placement="top"
        data-trigger='hover'
        title="Export keyword data, including search volume and cpc in your specified location">
      </i>
    );
  }

  componentDidMount() {
    const toolTipExport = document.getElementById(`keyword-export`);
    if(toolTipExport) new Tooltip(toolTipExport, { boundary: 'window' });
  }

}

export default KeywordExportToolTip;
