import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import LOCALSTORAGE from '../../constants/LocalStorage';
import AuthStatusModel from '../../functions/AuthStatusModel';
import { GetUserSettingApi } from '../../functions/UserSettingApi';
import { SignInApi } from '../../functions/SignInApi';
import { GoogleSignInApi } from '../../functions/GoogleSignInApi';
import { CreateAccountApi } from '../../functions/CreateAccountApi';
import { withRouter } from 'react-router-dom';
import { updateSearchTwitterSubject } from '../../observables/SearchTwitter';
import { updateSignedInStatusSubject } from '../../observables/SignedInStatus';
import PRICING from '../../constants/Pricing'
import PLANS from '../../constants/Plans';
import { GetAuthUserId } from '../../functions/AuthStatus';
import { RemainingSerpUsageStatus } from '../../functions/RemainingSerpUsageStatus';
import { RemainingWstUsageStatus } from '../../functions/RemainingWstUsageStatus';
import { RemainingKwtUsageStatus } from '../../functions/RemainingKwtUsageStatus';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { MediaMatcher } from '../../functions/MediaMatcher';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
			email: '',
			password: '',
			isEmailAddressValid: true,
			emailAddressErrorMsg: '',
			emailAddressErrorMsgOptions: {
				blank: 'Please enter your email address.',
				notRegistered: `We don't seem to have that email on file.`,
				invalid: 'Please enter a valid email address.',
        google: 'You signed up with Google, please use Google to sign in'
			},
			isPasswordVaild: true,
			passwordErrorMsg: '',
			passwordErrorMsgOptions: {
				blank: 'Please enter a password.',
				invalid: 'Incorrect password. Try again, or use secure link sign in.',
				invalidCharCount: 'Password must contain between 8 and 16 characters.',
        google: 'You signed up with Google, please use Google to sign in'
			}
		};
	}

	handleEmailInputChange = (event) => {
    const value = event.target.value;
    this.setState({email: value});
	}

	handlePasswordInputChange = (event) => {
		const value = event.target.value;
    this.setState({password: value});
  }

	handleSubmit = (event) => {
		event.preventDefault();
		const isEmailVaild = this.validateEmail();
		if (isEmailVaild) this.signIn();
	}

	validateEmail = () => {
		let isValid = false;
		if (this.state.email !== '') {
			const regex = new RegExp(/\S+@\S+\.\S+/, 'i');
			regex.test(this.state.email)
				? isValid = true
				: this.setState({emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.invalid})
		} else {
			this.setState({emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.blank});
		}
		this.setState({isEmailAddressValid: isValid});
		return isValid;
	}

	validatePassword = () => {
		let isValid = false;
		if (this.state.password !== '') {
			this.state.password.length >= 8 && this.state.password.length <= 16
				? isValid = true
				: this.setState({passwordErrorMsg: this.state.passwordErrorMsgOptions.invalid});
		} else {
			this.setState({passwordErrorMsg: this.state.passwordErrorMsgOptions.blank});
		}
		this.setState({isPasswordVaild: isValid});
		return isValid;
	}

	updateIsEmailSubscribedLocalStorage = () => {
		if (typeof localStorage !== 'undefined')
			localStorage.setItem(LOCALSTORAGE.isEmailSubscribed, true);
	}

	toggleCreateAccount = () => {
		this.props.updateGateContent('createAccount');
		this.props.updateSignInCreateAccountModalContent('createAccount');
	}

	resetPassword = () => {
		let params = {
			originUrl: window.location.pathname,
			searchKeyword: this.props.searchTermQuery
		};
		if (this.props.serpDomain !== 'google.com' ||
			this.props.serpCountry !== 'us' ||
			this.props.serpLanguage !== 'en'
		) {
			params = {
				deviceType: this.props.settingsDeviceSelected === 'mobile' ? 1 : 2,
				googleDomain: this.props.serpDomain,
				country: this.props.serpCountry,
				language: this.props.serpLanguage,
				searchKeyword: this.props.searchTermQuery,
				originUrl: window.location.pathname
			}
		}
		this.props.updateResetPasswordSettingsParams(params);
		this.closeAccountGateModal();
		this.closeSignInCreateAccountModal();
		this.props.history.push({ pathname: '/reset-password-request' });
	}

	magicLink = () => {
		let params = {
			originUrl: window.location.pathname,
			searchKeyword: this.props.searchTermQuery
		};
		if (this.props.serpDomain !== 'google.com' ||
			this.props.serpCountry !== 'us' ||
			this.props.serpLanguage !== 'en'
		) {
			params = {
				deviceType: this.props.settingsDeviceSelected === 'mobile' ? 1 : 2,
				googleDomain: this.props.serpDomain,
				country: this.props.serpCountry,
				language: this.props.serpLanguage,
				searchKeyword: this.props.searchTermQuery,
				originUrl: window.location.pathname
			}
		}
		this.props.updateMagicLinkSettingsParams(params);
		this.closeAccountGateModal();
		this.closeSignInCreateAccountModal();
		this.props.history.push({ pathname: '/secure-link' });
	}

	toggleShowPassword = () => {
		const signInPasswordInputEl = document.querySelector('#signInPassword');
		const showPasswordToggleBtn = document.querySelector('#SignInShowPasswordToggle');
		if (signInPasswordInputEl && showPasswordToggleBtn) {
			signInPasswordInputEl.type = signInPasswordInputEl.type === 'password'
				? 'text'
				: 'password';
			showPasswordToggleBtn.innerHTML = signInPasswordInputEl.type === 'password'
				? 'show'
				: 'hide';
		}
	}

	getUserSettings = () => {
		GetUserSettingApi().then(data => {
			if (data) {
				this.props.updateSelectedDevice(data.deviceType = 1 ? 'mobile' : 'desktop');
				this.props.updateSerpDomain(data.googleDomain);
				this.props.updateSerpCountry(data.country);
				this.props.updateSerpLanguage(data.language);
			}
		});
	}

	signIn = () => {
		const params = {
			email: this.state.email,
			password: this.state.password
		};
		this.setState({ isEmailAddressValid: true });
		this.setState({ isPasswordVaild: true });
		this.setState({ emailAddressErrorMsg: '' });
		this.setState({ passwordErrorMsg: '' });

		SignInApi(params).then(res => {
			if (res) {
				if (res.status === 200) {
					if (typeof localStorage !== 'undefined' && res.data.token) {
						const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
						localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
          }
					this.updateIsEmailSubscribedLocalStorage();
					this.closeAccountGateModal();
					this.closeSignInCreateAccountModal();
					this.getUserSettings();
					this.updateCurrentPlan(res.data);
					updateSearchTwitterSubject.update(true);
					updateSignedInStatusSubject.update(true);
					ReactGA.event({ category: 'authentication', action: 'sign in', label: GetAuthUserId() });

          this.handleRemainingWstSearches();
          this.handleRemainingSerpSearches();
          this.handleRemainingKwtSearches();
          window.location.reload();
        } else {
					this.signInErrorHandler(res);
				}
			}
		}).catch(error => {
			console.error('catch error sign in function ', error);
		});
	}

	signInErrorHandler = (res) => {
		const errorStatusMap = {
			404: () => {
				// * Email address does not exist
				this.setState({ isEmailAddressValid: false });
				this.setState({ emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.notRegistered });
			},
			409: () => {
				const conflictMessageMapping = {
					'Code: email_not_found; Result Set Count: 0': () => {
						// * Email does not exist error
						this.setState({ isEmailAddressValid: false });
						this.setState({ emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.notRegistered });
					},
					'Code: password_incorrect': () => {
						// * Incorrect password error
						this.setState({ isPasswordVaild: false });
            if(res.data.google) {
              this.setState({ isEmailAddressValid: false });
              this.setState({ emailAddressErrorMsg: this.state.emailAddressErrorMsgOptions.google });
            } else {
              this.setState({ passwordErrorMsg: this.state.passwordErrorMsgOptions.invalid });
            }

					},
					'Code: old_user; User exists in the system but password is empty.': () => {
						// * Migrating user with empty password that enters a password instead of leaving it blank
						if (this.state.password !== '') {
							if (this.state.password.length > 8 && this.state.password.length < 16) {
								// * call regiser
								this.createAccount();
							} else {
								// * password character length error message
								this.setState({ isPasswordVaild: false });
								this.setState({ passwordErrorMsg: this.state.passwordErrorMsgOptions.invalidCharCount });
							}
						} else {
							// * Migrating user with empty password
							this.props.updateCreatePasswordEmail(this.state.email);
							this.props.updateGateContent('createPassword');
							this.closeSignInCreateAccountModal();
							this.props.updateShowAccountGate(true);
						}
					}
				};
				if (conflictMessageMapping[res.data.message]) conflictMessageMapping[res.data.message]();
			}
		};
		res && errorStatusMap[res.status]
			? errorStatusMap[res.status]()
			: console.error('sign in Error: ', res);
	}

	createAccount = () => {
		const params = {
			email: this.state.email,
			password: this.state.password
		};
		CreateAccountApi(params).then(res => {
			if (res) {
				if (res.status === 200) {
					if (typeof localStorage !== 'undefined' && res.data.token) {
						localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(res.data.name, res.data.email, res.data.userId, res.data.token, res.data.google)));
					}
					this.updateIsEmailSubscribedLocalStorage();
					this.closeAccountGateModal();
					this.closeSignInCreateAccountModal();
					this.getUserSettings();
					this.updateCurrentPlan(res.data);
          window.location.reload();
				}
			}
		});
	}

	closeAccountGateModal = () => {
		const bodyEl = document.getElementsByTagName('body')[0];
		if (bodyEl) bodyEl.classList.remove('modal-open');
		this.props.updateShowAccountGate(false);
	}

	closeSignInCreateAccountModal = () => {
		if (this.props.signInCreateAccountModalEl) this.props.signInCreateAccountModalEl.hide();
		this.props.updateShowSignInCreateAccountModal(false);
	}

	updateCurrentPlan = (userData) => {
    const currentPlan = PRICING[PLANS[userData.planId]];
    currentPlan.renewDate = userData.renewsAt;
		currentPlan.billingCycle = userData.billingCycle;
		currentPlan.cancelDate = userData.cancelsAt;
    this.props.updateCurrentPlan(currentPlan);
  }

  handleRemainingSerpSearches = async () => {
    const response = await RemainingSerpUsageStatus();
    if(response) {
      if(response.numOfSerpLeft === null)
      {
        response.numOfSerpLeft = 0;
        this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
      }
      else
      {
        this.props.updateRemainingSerpSearches(response.numOfSerpLeft);
      }
    }
  }

  handleRemainingWstSearches = async () => {
    const response = await RemainingWstUsageStatus();
    if(response) {
      if(response.numOfwebsiteSearchesLeft === null)
      {
        response.numOfwebsiteSearchesLeft = 0;
        this.props.updateRemainingWSTSearches(response.numOfwebsiteSearchesLeft);
      }
      else
      {
        this.props.updateRemainingWSTSearches(response.numOfwebsiteSearchesLeft);
      }
    }
  }

  handleRemainingKwtSearches = async () => {
    const response = await RemainingKwtUsageStatus();
    if(response)
    {
      if(response.kwtSearchesLeft === null)
      {
        response.kwtSearchesLeft = 0;
        this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
      }
      else
      {
        this.props.updateRemainingKwtSearches(response.kwtSearchesLeft);
      }
    }
  }

  signInCredentialResponse = async (response) => {
    var decoded = jwt_decode(response.credential);
    const params = {
      email: decoded.email,
    };

    GoogleSignInApi(params).then(res => {
      if (res) {
        if (res.status === 200) {
          if (typeof localStorage !== 'undefined' && res.data.token) {
            const { name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google } = res.data;
            localStorage.setItem(LOCALSTORAGE.usrAuthStorage, JSON.stringify(new AuthStatusModel(name, email, userId, token, planId, renewsAt, billingCycle, cancelsAt, google)));
          }
          this.updateIsEmailSubscribedLocalStorage();
          this.closeAccountGateModal();
          this.closeSignInCreateAccountModal();
          this.getUserSettings();
          this.updateCurrentPlan(res.data);
          updateSearchTwitterSubject.update(true);
          updateSignedInStatusSubject.update(true);
          ReactGA.event({ category: 'authentication - Google Sign In', action: 'sign in', label: GetAuthUserId() });

          this.handleRemainingWstSearches();
          this.handleRemainingSerpSearches();
          this.handleRemainingKwtSearches();
          window.location.reload();
        } else {
          this.signInErrorHandler(res);
        }
      }
    }).catch(error => {
      console.error('catch error sign in function ', error);
    });
  };

  render() {
    return (
			<div>
				<div>
					<h3 className="mt-3 text-center">Sign in to Sandbox</h3>
					<p className="text-center">Welcome back to Sandbox! Enter your email to get started.</p>
				</div>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-8 pt-3 pb-3">
            <div className="google-sign-in">
              <GoogleLogin
                logo_alignment="left"
                type="standard"
                text="signin_with"
                theme="filled_black"
                size="medium"
                width="275px"
                onSuccess={this.signInCredentialResponse}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>
            {/* <GoogleCustom
              googleSignIn={this.signInCredentialResponse}
            /> */}
            <div className="text-center row pt-3 pb-2">
              <div className="col-5 pr-0">
                <hr className="signin-hr"/>
              </div>
              <div className="col-2 p-0 mt-1">
                or
              </div>
              <div className="col-5 pl-0">
                <hr className="signin-hr"/>
              </div>


            </div>
          </div>
        </div>
				<div className="sign-in-form">
					<form onSubmit={this.handleSubmit} noValidate>
						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-8">
								<div className="pb-3">
									<label htmlFor="signInEmail">Email</label>
									<input
										type="email"
										id="signInEmail"
										className={`form-control ${!this.state.isEmailAddressValid ? 'error' : ''}`}
										value={this.state.email}
										onChange={this.handleEmailInputChange} />
									<label className="error">
										<span>
											{
												!this.state.isEmailAddressValid
													? this.state.emailAddressErrorMsg
													: null
											}
										</span>
									</label>
								</div>
							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-8">
								<div className="pb-3">
									<label htmlFor="signInPassword">Password</label>
									<div className="input-group">
										<input
											type="password"
											id="signInPassword"
											className={`form-control ${!this.state.isPasswordVaild ? 'error' : ''}`}
											value={this.state.password}
											onChange={this.handlePasswordInputChange} />
										<button
											className="btn btn-outline-primary"
											type="button"
											id="SignInShowPasswordToggle"
											data-ripple-color="dark"
											onClick={()=> {this.toggleShowPassword()}}>
											show
										</button>
									</div>
									<label className="error">
										<span>
											{
												!this.state.isPasswordVaild
													? this.state.passwordErrorMsg
													: null
											}
										</span>
									</label>
								</div>
							</div>
						</div>

						<div className="pt-3 pb-3 text-center">
							<button
								className="btn btn-dark"
								type="submit"
								id="signInSubmit"
								data-ripple-color="dark">
								sign in
							</button>

							<p className="pt-3 pb-2">
								Forgot your password? <u onClick={()=> {this.resetPassword()}}>Reset it</u>, or
							</p>

							<button
								className="btn btn-dark"
								type="submit"
								id="sendMagicLink"
								data-ripple-color="dark"
								onClick={()=> {this.magicLink()}}>
								<i className="fas fa-lock pr-1"></i> get a secure link to sign in
							</button>

							<p className="pt-4">Create an account <u onClick={()=> {this.toggleCreateAccount()}}>here</u></p>
						</div>
					</form>
				</div>
			</div>
    );
	}
  componentDidMount() {
    window.addEventListener("resize", () => {
      this.props.updateScreenSize(MediaMatcher());
    });
  }
}



const mapStateToProps = state => {
  return {
    screenSize: state.screenSize,
		signInCreateAccountModalEl: state.signInCreateAccountModalEl,
		settingsDeviceSelected: state.settingsDeviceSelected,
		serpDomain: state.serpDomain,
		serpCountry: state.serpCountry,
		serpLanguage: state.serpLanguage,
		searchTermQuery: state.searchTermQuery
  };
};

const mapDispatchToProps = dispatch => {
  return {
		updateGateContent: (gateContent) => dispatch({ type: 'ACCOUNT_GATE_CONTENT', value: gateContent }),
		updateShowAccountGate: (showAccountGate) => dispatch({ type: 'SHOW_ACCOUNT_GATE', value: showAccountGate }),
		updateSignInCreateAccountModalContent: (content) => dispatch({ type: 'SIGN_IN_CREATE_ACCOUNT_MODAL_CONTENT', value: content }),
		updateShowSignInCreateAccountModal: (showSignInCreateAccountModal) => dispatch({ type: 'SHOW_SIGN_IN_CREATE_ACCOUNT_MODAL', value: showSignInCreateAccountModal }),
		updateSelectedDevice: (selected) => dispatch({ type: 'SETTINGS_DEVICE_SELECTED', value: selected }),
		updateSerpDomain: (domainSelected) => dispatch({ type: 'SERP_DOMAIN', value: domainSelected }),
		updateSerpCountry: (countrySelected) => dispatch({ type: 'SERP_COUNTRY', value: countrySelected }),
		updateSerpLanguage: (languageSelected) => dispatch({ type: 'SERP_LANGUAGE', value: languageSelected }),
		updateCreatePasswordEmail: (createPasswordEmail) => dispatch({ type: 'CREATE_PASSWORD_EMAIL', value: createPasswordEmail }),
		updateMagicLinkSettingsParams: (magicLinkSettingsParams) => dispatch({ type: 'MAGIC_LINK_SETTINGS_PARAMS', value: magicLinkSettingsParams }),
		updateResetPasswordSettingsParams: (resetPasswordSettingsParams) => dispatch({ type: 'RESET_PASSWORD_LINK_SETTINGS_PARAMS', value: resetPasswordSettingsParams }),
		updateCurrentPlan: (currentPlan) => dispatch({ type: 'CURRENT_PLAN', value: currentPlan }),
    updateRemainingWSTSearches: (remainingSearches) => dispatch({ type: 'REMAINING_WST_SEARCHES', value: remainingSearches }),
    updateRemainingSerpSearches: (remainingSearches) => dispatch({ type: 'REMAINING_SERP_SEARCHES', value: remainingSearches }),
    updateRemainingKwtSearches: (remainingKeywords) => dispatch({ type: 'REMAINING_KWT_SEARCHES', value: remainingKeywords }),
    updateScreenSize: (screenSize) => dispatch({ type: 'SCREEN_SIZE', value: screenSize })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
