import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from '../components/Header/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import ProgrammaticSeoTool from '../components/ProgrammaticSeoTool/ProgrammaticSeoTool';
import BackToTopArrow from '../components/BackToTopArrow';
import ReactGA from 'react-ga';

class ProgrammaticSeoToolPage extends Component {
  constructor(props) {
    super();
    this.state = {
    };
  }
  handleToggleAlgorithmToolTip = (e) => {
    e.preventDefault();

    const toolTipAl = document.getElementById(`algorithm-tooltip`);
    if(!toolTipAl.classList.contains("none")) {
      toolTipAl.classList.add("none");
    }	else {
      toolTipAl.classList.remove("none");
    }
  }

  render() {
    return (
      <div className="serp-psi-page-bkg">
        <Helmet>
          <meta name="description" content="Get keyword ideas, entities, and categories for programmatic SEO."/>
          <title>Keyword research tool for programmatic SEO  | Sandbox</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/* Facebook meta tags */}
          <meta property="og:title" content="Programmatic SEO Idea Tool| Sandbox" />
          <meta property="og:image" content="" />
          <meta property="og:description" content="Get keyword ideas, entities, and categories for programmatic SEO." />
          <meta property="og:url" content="https://tools.sandboxweb.io/keyword-suggestion-tool" />
          <meta property="og:type" content="website" />
          {/* Twitter meta tags */}
          <meta property="twitter:title" content="Programmatic SEO Idea Tool | Sandbox" />
          <meta property="twitter:description" content="Get keyword ideas, entities, and categories for programmatic SEO." />
          <meta property="twitter:image" content="" />
          <meta property="twitter:card" content="" />
        </Helmet>
        <Header />
        <section className="container-fluid shadow-2-strong background1 page-content-padding">
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb pr-0">
                    <li className="breadcrumb-item">
                      <Link to={'/'}>Tools</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Programmatic SEO Ideas</li>
                  </ol>
                </nav>
              </div>
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="algo-tooltip-holder">
                      <h3 className="mb-3 tool-header">Get ideas for programmatic SEO</h3>
                      <p className="tool-sub-header">Enter your buisness type or website purpose</p>
                    </div>
                  </div>
                </div>
                <ProgrammaticSeoTool />
              </div>
            </div>
          </div>
        <BackToTopArrow />
        </section>

        <section className="container-fluid seo-container">
          <div className="container">
            <div className="row mt-5 pt-3 pb-5">
              <div className="col-sm-6">
                <h3><strong>What does this tool do?</strong></h3>
                <p><strong>This is an idea generation tool for programmatic SEO.</strong> You can search by business or website purpose, 
                and the tool will give you ideas for large clusters of keywords you can target. Here's a few examples of ideas for various businesses:</p>
                <ul>
                  <li>real estate sales → housing market in [city]</li>
                  <li>dog food → [ingredient] in dog food</li>
                  <li>music streaming service → bands like [band]</li>
                 </ul>     

                <p><strong>You can use natural language.</strong> Enter a simple term like “roofing” or a more complex phrase like “roofing company that only uses sustainable materials”</p>
                <p><strong>If you don't like the results you get, try searching a few more times.</strong> You'll likely get different results, because, well that’s how ChatGPT works.</p>
                <p>Want to learn more about programmatic SEO? Check out <a href="https://www.sandboxweb.io/programmatic-seo" target="_blank" rel="noopener noreferrer"><u><strong>our guide</strong></u></a></p>
                <p>Want to hire us for a programmatic SEO build? Learn more  <a href="https://www.sandboxweb.io/programmatic-seo-consulting" target="_blank" rel="noopener noreferrer"><u><strong>here</strong></u></a></p>
              </div>
              <div className="col-sm-6">
                <h3><strong>Powered by ChatGPT</strong></h3>
                <p><strong>ChatGPT is the most exciting tech since the toaster.</strong> A cutting-edge AI language model developed by OpenAI, it is designed to generate human-like text.</p>
                <p><strong>Using ChatGPT has its pros and cons.</strong> We love ChatGPT because its an SEO idea generating superhero. On the downside, it is prone to go off the rails occasionally, hallucinating nonsensical, confusing, or even outright false information. Keep that in mind.</p>
              </div>
            </div>
            </div>
        </section>
        <section className="container-fluid background1 page-content-padding">
          <Footer />
        </section>
      </div>
      );
  }
  componentDidMount() {
    ReactGA.initialize('UA-174950315-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgrammaticSeoToolPage);
